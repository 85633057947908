import axios from 'axios';

const indicator = {
  // we = WorkplanElement
  weCommitments(workplanElements) {
    return (workplanElements || []).filter(item => {
      return item.type=='commitment'
        && item.obligation=='required'
        && item.status != 'na'
    }).reduce((a, b) => { return {
      numerator: a.numerator + (b.checked == true ? 1 : 0),
      denominator: a.denominator + 1
    }}, {numerator: 0, denominator: 0});
  },
  wePolicies(workplanElements) {
    return (workplanElements || []).filter(item => {
      return item.type=='policy'
        && item.obligation=='required'
        && item.status != 'na'
    }).reduce((a, b) => { return {
      numerator: a.numerator + (b.checked == true ? 1 : 0),
      denominator: a.denominator + 1
    }}, {numerator: 0, denominator: 0});
  },
  weDemonstrations(workplanElements) {
    return (workplanElements || []).filter(item => {
      return item.type=='demonstration'
        && item.obligation=='required'
        && item.status != 'na'
    }).reduce((a, b) => { return {
      numerator: a.numerator + (b.checked == true ? 1 : 0),
      denominator: a.denominator + 1
    }}, {numerator: 0, denominator: 0});
  },
  weLastUpdate(workplanElements) {
    
    return (workplanElements || []).filter(item => {
      return item.obligation=='required'
    }).reduce((a, b) => { 
      // console.log(a, b.modified_at);
      return (!(b.modified_at) || a > b.modified_at) ? a : b.modified_at
    }, null);
  },

  // req = Requirement
  reqComplete(requirements) {
    return (requirements || []).filter(item => {
      return item.item_type=='requirement'
        && item.appreciation_status_key!='non_applicable'
    }).reduce((a, b) => { return {
      numerator: a.numerator + (b.requirement_status_key == 'complete' ? 1 : 0),
      denominator: a.denominator + 1
    }}, {numerator: 0, denominator: 0});
  },
  reqConforming(requirements) {
    return (requirements || []).filter(item => {
      return item.item_type=='requirement'
        && item.appreciation_status_key!='non_applicable'
    }).reduce((a, b) => { return {
      numerator: a.numerator + (b.appreciation_status_key == 'conforme' ? 1 : 0),
      denominator: a.denominator + 1
    }}, {numerator: 0, denominator: 0});
  }
}

export default { indicator };