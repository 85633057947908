import axios from 'axios';

const downloadFile = (item, oziOnPrefix) => {
  if(item.repository == 'ozi-on') {
    if(oziOnPrefix == null)
      oziOnPrefix = '';
    window.open(oziOnPrefix+item.url, "_blank");
  } else if(item.repository == 'collab') {
    // console.log(item.metadata.filename);
    axios
      .get((process.env.VUE_APP_server_base || "") + '/api/repo/serve_file.php',
        { params: {document_id: item.document_id}, responseType: 'blob' })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url;
        // link.target = "_blank";
        link.setAttribute('download', item.metadata.filename)
        document.body.appendChild(link)
        link.click()
      })
  } else {
    window.open(item.url, "_blank");
  }
};

const downloadTmpFile = (file) => {
  axios
    .get((process.env.VUE_APP_server_base || "") + '/api/repo/serve_tmp_file.php',
      { params: {relative_path: file}, responseType: 'blob' })
    .then((response) => {
      const url = URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url;
      // link.target = "_blank";
      link.setAttribute('download', 'documents.zip');
      document.body.appendChild(link)
      link.click()
    })
};

const api = {
  async removeFromRequirement(document_id, requirement_id) {
    await axios
      .put((process.env.VUE_APP_server_base || "") + '/api/document/remove_from_requirement.php', {
        document_id: document_id,
        requirement_id: requirement_id,
      })
      .then((response) => {
        
      })
  }
}

export default { downloadFile, downloadTmpFile, api };