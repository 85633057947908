<template>
  <v-container fluid class="mt-4">
    <v-alert
      v-if="requirements.length == 0"
      border="top"
      colored-border
      type="info"
      elevation="2"
    >
      {{ $t('error.dossier_no_requirement') }}
    </v-alert>
    <div v-else>
      <v-row>
        <v-col class="text-h5 mb-4">
          {{ $tc('requirement.label', 2) }}
        </v-col>
        <v-col class="text-right">
          <v-btn color="primary" outlined @click="handleExport">
            <v-icon>mdi-file-excel</v-icon>{{$t('label.export')}}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="requirements"
        hide-default-footer
        disable-pagination
        dense
        :loading="isLoading"
        item-key="requirement_def_id"
      >
        <template v-slot:item="{ item }">  
          <tr @click="handleRowClick(item)">
            <template v-if="item.item_type == 'header'">
              <td class="pt-4 pb-1" :colspan="headers.length">{{ $langobj.parse(item.name) }}</td>
            </template>
            <template v-else>
              <td class="mt-3">
                <v-avatar size="24" color="primary" class="mr-2">
                  <span v-if="item.identifier.length > 2" 
                    class="white--text" style="font-size: 0.5em; font-weight: bold;">
                    {{item.identifier}}
                  </span>
                  <span v-else 
                    class="white--text" style="font-size: 0.9em; font-weight: bold;">
                    {{item.identifier}}
                  </span>
                </v-avatar>
                {{ $langobj.parse(item.name) }}
              </td>
              <td>{{ item.planned_end_date }} </td>
              <td>
                <v-chip 
                  v-if="item.requirement_status_lang"
                  dark small class="mb-1" 
                  :color="item.requirement_status_aes ? item.requirement_status_aes.colour : 'grey'"
                >
                  {{ $langobj.parse(item.requirement_status_lang) }}
                </v-chip>
              </td>
              <td>
                <v-chip 
                  v-if="item.appreciation_status_lang"
                  dark small class="mb-1" 
                  :color="item.appreciation_aes ? item.appreciation_aes.colour : 'grey'"
                >
                  {{ $langobj.parse(item.appreciation_status_lang) }}
                </v-chip>
              </td>
              <td>{{ item.modified_at_by_sp }}</td>
              
            </template>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import Vue from 'vue';
import i18n from '@/i18n';

export default Vue.extend({
  name: 'ListeExigences',
  props: [],
  data () {
    return {
      tab: null,
      isLoading: false,
      requirements: []
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$tc('requirement.label', 1), value: 'identifier' },
        { text: this.$t('requirement.planned_end_date'), value: 'planned_end_date' },
        { text: this.$t('requirement.status'), value: 'requirement_status_lang' },
        { text: this.$t('requirement.appreciation'), value: 'appreciation_status_lang' },
        { text: this.$t('requirement.modified_at_by_sp'), value: 'modified_at_by_sp' },

      ];
    },
    locale() { return i18n.locale; }
  },

  methods: {
    handleRowClick(item) {
      if(item.item_type == 'header')
        return;
      // this.$router.push('/collab/dossiers/32/exigences/3');
      this.$router.push({
        name: 'requirement_workplan', 
        params: {
          requirement_id: item.requirement_id
        }
      })
    },
    handleExport() {
      this.$axios
        .get(this.$root.$data.server_base + '/api/requirement/export_requirements.php',
          { params: {
              designation_dossier_id: this.$route.params.designation_dossier_id,
              service_provider_id: this.$route.params.service_provider_id,
              locale: i18n.locale,
            }, responseType: 'blob'
          })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Dossier-${this.$route.params.designation_dossier_id}-${this.locale}-${new Date().toLocaleDateString()}.xlsx`
          )
          document.body.appendChild(link)
          link.click()
        })
    },
    refresh() {
      this.isLoading = true;

      this.$axios
        .get(this.$root.$data.server_base + '/api/requirement/list.php',
          { params: {
            designation_dossier_id: this.$route.params.designation_dossier_id,
            service_provider_id: this.$route.params.service_provider_id,
          }})
        .then(response => {
          this.requirements = response.data.requirements;
          this.isLoading = false
        });
    },

  },
  mounted () {
    this.refresh();
  }
});

</script>