<template>
  <div>
    <h2>{{ $tc("report.hr.label") }}</h2>
    <v-row class="mt-2">
      <v-col cols=2>
        <v-text-field
          v-model="search"
          outlined
          dense
          :label="$t('label.search')"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="itemsFlattened"
      item-key="human_resource_id"
      :loading="isLoading"
      :search="search"
      :items-per-page="50"
      :footer-props="{itemsPerPageOptions:[25,50,100,-1]}"
    >
      <template v-slot:[`item.total`]="{ item }">
        <v-row>
          <v-col cols="4">
            {{ item.total_employed_ft }}
          </v-col>
          <v-col cols="4">
            {{ item.total_employed_pt }}
          </v-col>
          <v-col cols="4">
            {{ item.total_employed_ca }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.rqrd`]="{ item }">
        <v-row>
          <v-col cols="4">
            {{ item.rqrd_french_ft }}
          </v-col>
          <v-col cols="4">
            {{ item.rqrd_french_pt }}
          </v-col>
          <v-col cols="4">
            {{ item.rqrd_french_ca }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.evalreq`]="{ item }">
        <v-row>
          <v-col cols="4">
            {{ item.eval_rqrd_ft }}
          </v-col>
          <v-col cols="4">
            {{ item.eval_rqrd_pt }}
          </v-col>
          <v-col cols="4">
            {{ item.eval_rqrd_ca }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.actual`]="{ item }">
        <v-row>
          <v-col cols="4">
            {{ item.actual_french_ft }}
          </v-col>
          <v-col cols="4">
            {{ item.actual_french_pt }}
          </v-col>
          <v-col cols="4">
            {{ item.actual_french_ca }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.comp`]="{ item }">
        {{item.fl_o_acronym || '?'}}
        <span class="grey--text">|</span>
        {{item.fl_w_acronym || '?'}}
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: "ReportHR",
  data() { return {
    hr: [],
    isLoading: false,
    search: ""
  }},

  components: {
  },

  computed: {
    headers() {
      return [
        { text: this.$t('report.hr.pos'), value: 'point_service_one_lng' },
        { text: this.$t('report.hr.department'), value: 'department' },
        { text: this.$t('report.hr.program'), value: 'functional_center_one_lng' },
        { text: this.$t('report.hr.position'), value: 'position_title' },
        { text: this.$t('report.hr.total'), value: 'total',
          filterable: false, sortable: false, align: 'center' },
        { text: this.$t('report.hr.rqrd'), value: 'rqrd',
          filterable: false, sortable: false, align: 'center' },
        { text: this.$t('report.hr.actual'), value: 'actual',
          filterable: false, sortable: false, align: 'center' },
        { text: this.$t('report.hr.evalreq'), value: 'evalreq',
          filterable: false, sortable: false, align: 'center' },
        { text: this.$t('report.hr.comp'), value: 'comp',
          filterable: false, sortable: false, align: 'center' },
      ];
    },
    itemsFlattened() {
      return this.hr.map(item => (
        { ...item,
          point_service_one_lng: this.$langobj.parse(item.point_service_lng),
          functional_center_one_lng: this.$langobj.parse(item.functional_center_lng),
        }
      ))
    }
  },

  methods: {
    refresh: function() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/report/get_report_hr.php',
          { params: { 
              service_provider_id: this.$route.params.service_provider_id,
              workplan_id: this.$route.params.workplan_id,
        }})
        .then(response => {
          this.hr = response.data.hr;
          this.isLoading = false;
        });
    },
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
</style>