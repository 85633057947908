<template>
  <div>
    <v-row class="mb-8">
      <v-col class="my-0 doc_cat" cols="2">
        <p class="text-right">{{ label }}
          <help-dialog
            x-small
            activatorClass="ml-0"
            :help-key="helpKey"
          />
        </p>
      </v-col>
      <v-col class="pt-0 my-0" cols="10">
        <v-simple-table
          dense
          class="row-pointer"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width:60px;">{{ $tc('requirement.label') }}</th>
                <th>{{ $tc('document.title') }}</th>
                <th style="width:110px;">{{ $tc('document.created') }}</th>
                <th style="width:60px;">Actions</th>
              </tr>
            </thead>
            <tbody v-if="loading">
              <td colspan="4">
                <v-progress-linear indeterminate />
              </td>
            </tbody>
            <tbody v-else>
              <template v-for="req in filteredList">
                <tr v-if="req.docs == null || req.docs.length == 0" :key="req.requirement_id">
                  <td>
                    <router-link :to="{
                      name: 'requirement_realisations',
                      params: { requirement_id: req.requirement_id }}"
                    ><RequirementNumber :identifier="req.identifier" />
                    </router-link>
                  </td>
                  <td colspan="3">{{ $t('report.no_document') }}</td>
                </tr>
                <tr v-else v-for="(item, idoc) in req.docs" :key="item.requirement_id+'_'+idoc">
                  <td v-if="idoc === 0" :rowspan="req.docs.length">
                    <router-link :to="{
                      name: 'requirement_realisations',
                      params: { requirement_id: item.requirement_id }}"
                    ><RequirementNumber :identifier="req.identifier" />
                    </router-link>
                  </td>
                  <td>
                    <v-icon v-if="item.obsolete_at" color="red" small>mdi-clock-alert</v-icon>
                    <a @click="handleFileDownload(item)">
                      <document-mdi-icon 
                        :url="item.repository=='ozi-on' ? oziOnPrefix+item.url : item.url"
                      />{{item.title}}
                    </a>
                  </td>
                  <td>{{ item.created_at ? item.created_at.substring(0, 10) : "" }}</td>
                  <td>
                    <v-icon
                      v-if="!readonly"
                      small
                      class="mr-2"
                      @click="$emit('editDocument', item)"
                    >mdi-pencil</v-icon>
                    <v-icon
                      v-if="!readonly && !disableDelete"
                      small
                      @click="$emit('deleteDocument', item)"
                    >mdi-delete</v-icon>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.doc_cat {
  border-right: solid 2px #fbab26;
  border-top: solid 2px #fbab26;
}
</style>

<script>
import DocumentMdiIcon from '@/components/DocumentMdiIcon';
import RequirementNumber from '@/components/framework/RequirementNumber';
import DocumentUtils from '@/utils/DocumentUtils';
import unorm from 'unorm';

const clean = (text) => {
  return unorm
    .nfd(text)
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

export default {
  components: {
    DocumentMdiIcon,
    RequirementNumber,
  },
  name: 'DocumentListRequirementsSection',
  props: {
    label: String,
    readonly: Boolean,
    requirements: Array,
    search: String,
    loading: Boolean,
    disableDelete: Boolean,
    oziOnPrefix: String,
    helpKey: String,
  },
  computed: {
    filteredList() {
      const filteredList = this.requirements
        .map(req => { return {
          ...req, docs: (req.docs || []).filter((doc) =>
            (clean(doc.title).includes(this.search.toLocaleLowerCase())))
          }
        });
      return filteredList;      
    }
  },
  
  methods: {
    handleRowClick() {},
    handleFileDownload(item) {
      DocumentUtils.downloadFile(item, this.oziOnPrefix);     
    },
  },
  
}
</script>