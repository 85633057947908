<template>
  <div v-can="'admin'">
    <p><strong>Serveur de la BD: </strong>{{db_host}}</p>
    <p><strong>BD: </strong>{{db}}</p>
    <p><strong>Durée de vie des demandes de réinitialisation des MDP: </strong>{{token_lifetime}}</p>
    <p><strong>Redirection des courriels: </strong>
      <span v-if="email_override">{{email_override}}</span>
      <span v-else><em>Non</em></span>
    </p>
    <p />
    Cette version est basée sur l'entrée git suivante: <br /><br />
    <pre>{{version}}</pre>
    <pre>{{JSON.stringify(php_config, undefined, 2)}}</pre>
  </div>
</template>
<script>
import axios from 'axios';
import gitInfo from '!raw-loader!@/assets/gitInfo.txt';
import {privileges} from '@/mixins/privileges.js';

export default {
  data: function() {return {
    version: gitInfo,
    db_host: '',
    db: '',
    token_lifetime: '',
    email_override: '',
    php_config: {},
  }},
  mixins: [privileges],
  methods: {
    refresh: function() {
      axios
        .get(this.$root.$data.server_base + '/api/admin/version.php',
          { params: {}})
        .then(response => {
          this.db_host = response.data.db_host;
          this.db = response.data.db;
          this.token_lifetime = response.data.token_lifetime;
          this.email_override = response.data.email_override;
          this.php_config = response.data.php_config;
        })
    },
  },
  mounted () {
    this.refresh();
  }
}
</script>
