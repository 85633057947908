<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('document.modify_properties') }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-form v-model="isFormValid" ref="form">
          <v-row>
            <v-col cols="1">
              {{ $t('document.file') }}
            </v-col>
            <v-col cols="11">
              <v-text-field
                v-model="document.url"
                label="Nom ou URL du fichier"
                append-icon="mdi-open-in-new"
                :loading="isLoading"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="document.title"
                :label="$t('document.title')"
                :rules="[(v) => $validators.required(v, [$t('document.title')])]"
                :loading="isLoading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              {{ $t('document.content') }}
            </v-col>
            <v-col cols="11">
              <v-checkbox
                class="mt-0"
                v-model="document.provider_assoc_details.governing"
                hide-details
              >
                <template v-slot:label>
                  <div>
                    <p class="mb-0">{{ $t('document.cat.governing') }}</p>
                    <small>{{ $t('document.cat.governing_small') }}</small>
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox
                class="mt-1"
                v-model="document.dossier_assoc_details.commitment"
                hide-details
              >
                <template v-slot:label>
                  <div>
                    <p class="mb-0">{{ $t('document.cat.engagement') }}</p>
                    <small>{{ $t('document.cat.engagement_small') }}</small>
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox
                class="mt-1"
                v-model="document.dossier_assoc_details.policy"
                hide-details
              >
                <template v-slot:label>
                  <div>
                    <p class="mb-0">{{ $t('document.cat.policy') }}</p>
                    <small>{{ $t('document.cat.policy_small') }}</small>
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox
                class="mt-1"
                v-model="document.dossier_assoc_details.demonstration"
                hide-details
              >
                <template v-slot:label>
                  <div>
                    <p class="mb-0">{{ $t('document.cat.requirement') }}</p>
                    <small>{{ $t('document.cat.requirement_small') }}</small>
                  </div>
                </template>
              </v-checkbox>
              
              <v-expand-transition>
                <v-select
                  v-show="document.dossier_assoc_details.demonstration"
                  class="ml-8"
                  v-model="document.dossier_assoc_details.requirements"
                  :items="requirementItems"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$tc('requirement.label', 2)"
                  multiple
                  :hint="$t('document.cat.requirement_choose')"
                  persistent-hint
                  @change="reorderSelectedRequirements"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ item.identifier }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-expand-transition>

              <tip-tap
                class="mt-6"
                v-model="document.description"
                label="Notes"
                :loading="isLoading"
              ></tip-tap>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              {{ $t('document.history') }}
            </v-col>
            <v-col cols="11">
              <p v-if="document.metadata.creation_date">
                {{ $t('document.created_on') }} {{ moment(document.metadata.creation_date).format('LL')}}
              </p>  
              <p>
                {{ $t('document.uploaded_on') }} {{ moment.utc(document.created_at).local().format('LL')}}
              </p>
              <p v-if="document.obsolete_at">
                {{ $t('document.expired_on') }} {{ moment.utc(document.obsolete_at).local().format('LL')}}
              </p>
              <v-checkbox v-model="obsolete" hide-details class="mt-0">
                <template v-slot:label>
                  <div>
                    <p class="mb-0">{{ $t('document.expire') }}</p>
                    <small>{{ $t('document.expire_small') }}</small>
                  </div>
                </template>
              </v-checkbox>

              <div class="mt-6">
                <v-btn
                  color="primary"
                  :loading="saving"
                  :disabled="disableSave"
                  @click="handleSave"
                >
                  {{ $t('label.save') }}
                </v-btn>
                <v-btn
                  class="ml-2"
                  color="primary"
                  outlined
                  @click="dialog = false"
                >{{ $t('label.cancel') }}</v-btn>
              </div>
            </v-col>        
          </v-row>         
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import TipTap from '../framework/TipTap.vue';
import ObjectUtils from '@/utils/ObjectUtils';


// TODO traduction
export default Vue.extend({
  name: 'EditDocumentDialog',
  components: {
    TipTap
  },
  props: {
    value: false,
    id: {type: Number}
  },
  data() {return {
    storeData: {},
    document: { 
      metadata: {},
      dossier_assoc_details: {},
      provider_assoc_details: {},
      service_provider_id: null,
    },
    requirements: [],
    isLoading: false,
    saving: false,
    selectedRequirements: [],
    isFormValid: true,
  }},

  computed: {
    disableSave() {
      return !this.isFormValid || _.isEqual(this.storeData, this.document);
    },
    dialog: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    },
    obsolete: {
      get () { return !!this.document.obsolete_at; },
      set (value) { this.document.obsolete_at = value ? 'now()' : null }
    },
    headers() {
      return [
        { text: this.$tc('document.assoc_type'), value: 'item_type' },
        { text: this.$tc('document.assoc_name'), value: 'item_name' },
        { text: this.$tc('label.action'), value: 'actions' },
      ];
    },
    requirementItems() {
      return (this.requirements || [])
        .filter(e => e.item_type == 'requirement')
        .map((option) => ({
          identifier: option.identifier,
          text: option.identifier + ' - ' + this.$langobj.parse(option.name),
          value: option.requirement_id,
        }))

    }
  },
  watch: {
    value() {
      if(this.value) {
        this.get(); 
      } else {
        this.document = { 
          metadata: {},
          dossier_assoc_details: {},
          provider_assoc_details: {},
        };
      }
    },
  },
  methods: {
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }  

      let obj = ObjectUtils.getObjectDiff(this.document, this.storeData);  
      obj.document_id = this.document.document_id;
      obj.service_provider_id = this.document.service_provider_id;
      // console.log(obj);

      this.saving = true;
      this.$axios
        .put(this.$root.$data.server_base + '/api/document/upsert.php', {
            document: obj,
            designation_dossier_id: this.$route.params.designation_dossier_id
        })
        .then(response => {
          this.document = response.data.document;
          this.saving = false;
          this.dialog = false;
          this.$emit('refresh');
        });
    },
    reorderSelectedRequirements() {
      this.document.dossier_assoc_details.requirements = 
        this.requirements.filter(x => 
          this.document.dossier_assoc_details.requirements.includes(x.requirement_id))
          .map(x => x.requirement_id);
    },
    get() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/document/get_in_dossier.php',
          { params: { 
            document_id: this.id,
            designation_dossier_id: this.$route.params.designation_dossier_id
        }})
        .then(response => {
          this.storeData = response.data.document;
          this.document = _.cloneDeep(this.storeData);
          this.isLoading = false
        });

      this.$axios
        .get(this.$root.$data.server_base + '/api/requirement/list.php',
          { params: { 
              designation_dossier_id: this.$route.params.designation_dossier_id,
              service_provider_id: this.$route.params.service_provider_id
          }})
        .then(response => {
          this.requirements = response.data.requirements;
          this.isLoading = false
        });
    }
  }
});
</script>