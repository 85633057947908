<template>
  <v-container fluid class="mt-4">
    <p class="text-h5">{{ $t('dossier.menu.workplan') }}</p>
    <v-row class="mb-2">
      <v-spacer></v-spacer>
      <v-col class="pt-0" cols="2">
        <v-checkbox
          v-model="showBestPractices"
          :label="$t('designation_model.element.obligation.best_practices')"
          hide-details
        />
      </v-col>
    </v-row>

    <workplan-section
      :elements="commitments"
      :title="$t('designation_model.element.type.commitment_title')"
      :showBestPractices="showBestPractices"
      @refresh="refresh"
    />
    <workplan-section
      :elements="policies"
      :title="$t('designation_model.element.type.policy_title')"
      :showBestPractices="showBestPractices"
      @refresh="refresh"
    />
    <workplan-section
      :elements="demonstrations"
      :title="$t('designation_model.element.type.demonstration_title')"
      :showBestPractices="showBestPractices"
      @refresh="refresh"
    />
  </v-container>
</template>

<script>
import WorkplanSection from '../../components/dossiers/WorkplanSection.vue'

export default {
  components: {
    WorkplanSection

  },
  name: 'DocumentElements',
  props: ['readonly'],
  data: function() {return {
      workplanElements: [],
      isLoading: false,
      get showBestPractices() {
        return localStorage.getItem('dossierElements.showBestPractices') === "true"
          || false;
      },
      set showBestPractices(newVal) {
        localStorage.setItem('dossierElements.showBestPractices', newVal);
      }, 
  }},
  computed: {
    commitments() {
      return this.workplanElements.filter(item => {
        return item.type=='commitment'
      })
    },
    policies() {
      return this.workplanElements.filter(item => {
        return item.type=='policy'
      })
    },
    demonstrations() {
      return this.workplanElements.filter(item => {
        return item.type=='demonstration'
      })
    }
  },
  
  methods: {
    handleRowClick() {},
    handleEditItem(item) {
      this.$root.notDone();
    },
    handleDeleteItem(item) {
      this.$root.notDone();
    },
    refresh() {
      this.isLoading = true
      this.$axios
        .get(this.$root.$data.server_base + '/api/workplan_element/list.php', 
        { params: {
            service_provider_id: this.$route.params.service_provider_id,
            designation_dossier_id: this.$route.params.designation_dossier_id,
        }})
        .then((response => {
          this.workplanElements = response.data.elements;
        }))  
    },
  
  },
  mounted() {
    this.refresh()
  }
}
</script>