<!-- <template>
  <v-card>
    <v-toolbar
      color="primary"
      dark
      flat
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Attestation de conformité 2018</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider color="#fbab26"></v-tabs-slider>
          <v-tab>Statut</v-tab>
          <v-tab>Services</v-tab>
          <v-tab>Exigences</v-tab>
          <v-tab>RH</v-tab>            
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>Ici va le statut.</v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>Ici vont les services.</v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <liste-exigences></liste-exigences>          
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>Ici va le plan RH.</v-card-text>
        </v-card>
      </v-tab-item>


    </v-tabs-items>
  </v-card>
</template> -->

<template>
  <v-container fluid>
    <v-card>
      <v-app-bar clipped-left color="#9066B3" dark style="z-index: 5">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title>Attestation de conformité 2018</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
      <div style="display: flex; min-height: 500px;">
        <v-navigation-drawer clipped width="250" style="min-width: 200px; height: auto;">
        
          <v-list dense nav>
            <v-subheader>Dossier</v-subheader>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              router :to="item.to"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
          
        <router-view />

      
      </div>
    
 
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue';
import ListeExigences from '@/components/dossiers/ListeExigences';

export default Vue.extend({
  name: 'DossierTop',
  components: {
    ListeExigences,
  },
  data () {
    return {
      tab: null,
      items: [
        { title: 'Sommaire', icon: 'mdi-view-dashboard', to: '/collab/dossiers/32/sommaire'},
        { title: 'Prochaines étapes', icon: 'mdi-state-machine', to: '/collab/dossiers/32/statut'},
        { title: 'Étendue', icon: 'mdi-contain', to: ''},
        { title: 'Clientèle', icon: 'mdi-human-male-female-child', to: ''},
        { title: 'Exigences', icon: 'mdi-playlist-check', to: '/collab/dossiers/32/exigences'},
        { title: 'RH', icon: 'mdi-doctor', to: '/collab/dossiers/32/rh'},        
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
});

</script>