<template>  
  <v-container fluid class="d-flex justify-center align-center points" style="height: 100%">
    <div style="opacity: 1">
      <v-container>
        <v-card width="500" :loading="loading">
          <v-form @submit.prevent="handleSubmit" autocomplete="off">
            <v-card-text>
              <v-row class="d-flex align-end mx-0 mt-1">
                <v-img
                  alt="OZi"
                  class="shrink mr-2"
                  contain
                  src="@/assets/logo.svg"
                  transition="scale-transition"
                  width="150"
                  height="47.25"
                />
                <v-spacer />
                <div>Collab</div>
              </v-row>
            </v-card-text>
            <v-card-title>{{ $t('login.forgotPassword') }}</v-card-title>
            <v-card-text>
              <div v-show="!success">
                {{ $t('password.forgot_password_instruction') }}
              </div>
              <v-text-field 
                v-if="!success"
                v-model="identifier"
                :label="`${$tc('password.username_or_email')}*`"                
                @input="error = null"
                required
              />               
                         
              <v-alert dense v-show="error" type="error" outlined>
                {{ $t('password.forgot_password_error') }}
              </v-alert>
              <v-alert dense v-show="success" type="success">
                {{ $t('password.forgot_password_success') }}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn 
                v-show="!success"
                type="submit"
                color="primary"
                :disabled="!enableSubmit"
              >
                <v-icon left>mdi-lock-reset</v-icon>
                {{$tc('label.submit')}}
              </v-btn>
              <v-spacer />
              <v-btn v-show="!(error || success)" color="primary" text :to="{name: 'login'}">
                {{ $tc('label.cancel') }}
              </v-btn>
              <v-btn v-show="error || success" color="primary" text>
                {{ $tc('label.support') }}
              </v-btn>
            </v-card-actions> 
          </v-form>
        </v-card>
        <div class="d-flex justify-center mt-4">
          <locale-selector />
        </div>
      </v-container>
    </div>
  </v-container>

</template>

<script>
import LocaleSelector from '@/components/locale/LocaleSelector.vue';

export default {
  name: 'ForgotPassword',
  components: {
    LocaleSelector
  },
  data: function() {return {
    identifier: "",
    showPassword: false,
    respData: {},
    tokenError: false,
    success: false,
    error: false,
    loading: false,
  }},
  computed: {
    enableSubmit() {
      return this.identifier.length > 2;
    }
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.$axios
        .put("/api/admin/password/forgot_password.php", {
            identifier: this.identifier.trim(),
            locale: this.$locale,
          })
        .then(response => {
          this.loading = false;
          this.error = response.data.error ? true : false;
          this.success = response.data.success ? true : false;
        }
      );      
    },
  },
  mounted () {
    window.scrollTo(0, 0);
  }

}
</script>

<style>
.points::before {
  content: "";
  opacity: 0.4;
  background: url('~@/assets/img/points.png');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
</style>