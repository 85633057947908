<template>
  <v-card>
    <v-card-title>{{ $tc('hr.label') }}</v-card-title>
    <v-card-subtitle>2021-2022</v-card-subtitle>
    <v-card-text>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :hide-default-footer="true"
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
export default {
  name: 'SPHumanResources',
  props: {
    humanresources: {},
  },
  computed: {
    headers() { return [
      { text: this.$tc('hr.position'), value: 'position' },
      { text: this.$tc('hr.number'), value: 'nbr' }
    ]},
    items() { return [
      { position: this.$tc('hr.required'), nbr: this.humanresources.requis },
      { position: this.$tc('hr.filled'), nbr: this.humanresources.combles },
      { position: this.$tc('hr.gap'), nbr: this.humanresources.ecart }
    ]}
  }

}
</script>
