<template>
  <transition name="modal" >
    <div class="modal-mask" v-show="showModal">
      <div class="modal-block modal-block">
        <section class="panel">
          <header class="panel-heading">
              <strong>Statut des services</strong>
          </header>
          <div class="panel-body">
            <div class="modal-wrapper">
              <div class="modal-text">
                <p>Voulez-vous également mettre à jour le statut des services à Désigné?</p>
                
                <ul>
                  <li v-for="(service, index) in services" :key="service.service_id">{{ service.service_name }}</li>
                </ul>
              </div>
            </div>
          </div>
          <footer class="panel-footer">
            <div class="row">
              <div class="col-md-12 text-right">
                <a class="btn btn-default" v-on:click="cancel">Non</a>&nbsp;&nbsp;<a class="btn btn-default" v-on:click="confirmUpdate">Oui</a>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

export default {
  props: ['dispatch', 'dossierId', 'serviceProviderId'],
  data: function() { return {
    services: [],
    showModal: false
  }},
  methods: {
    cancel() {
      this.done()
    },
    confirmUpdate() {
      let data = {
        dossier_id: this.dossierId
      }
      
      axios
        .post(this.$root.$data.server_base + '/api/dossier_update_services_statuses.php',
          data,
          {headers:{
              'Content-Type': 'application/json',
          }})
        .then(response => {
          this.done()
        })
    },
    done() {
      this.showModal = false
      this.$emit('done')
    },
    verifyServicesToUpdate() {
      axios
        .get(this.$root.$data.server_base + '/api/service_provider_dossier.php',
          { params: {dossier_id:this.dossierId,service_provider_id: this.serviceProviderId}})
        .then(response => {
          this.services = response.data.dossier.service_id_list || []
          if (this.services.length) {
            this.showModal = true
          }
        })
    }
  },
  watch: {
    dispatch(newVal) {
      if (newVal === true) {
        this.verifyServicesToUpdate()
      } else {
        this.done()
      }
    }
  }
}
</script>