<template>
  <v-sheet height="100%" color="grey lighten-3">
    <v-container fluid>
      <v-card class="mb-2" color="white" v-if="priv('admin')">
        <v-card-text>
          <v-select
            hide-details
            v-model="regionId"
            :label="$tc('provider.region', 1)"
            :items="
              (regions || []).map((option) => ({
                text: $langobj.parse(option.name),
                value: option.region_id,
              }))
              .sort((a,b) => a.text.localeCompare(b.text))"
            @change="refresh"
          >
          </v-select>
        </v-card-text>
      </v-card>

      <v-skeleton-loader v-if="loading" class="mx-auto" max-width="330" type="card"
      ></v-skeleton-loader>

      <div v-if="serviceproviders.length == 0 && ! loading"
        class="d-flex"
      >
        <span class="mt-12 mx-auto text-h3 grey--text text--lighten-1">
          {{ $t(priv('admin') ? 'error.no_service_provider' : 'error.no_assigned_service_provider') }}
        </span>
      </div>
      <div class="d-flex flex-wrap mb-4">
        <v-card
          v-for="provider in orderedProviders"
          :key="provider.service_provider_id"
          min-width="330px"
          max-width="400px"
          class="ma-1 pa-0"
        >
          <v-card-text
            :class="colourForSPHeader(provider)"
            class="pb-2 pt-2 lighten-5 item-pointer"
            @click="$router.push(
              { name: 'fournisseurs',
                 params: { service_provider_id: provider.service_provider_id 
              }})
            "
            >
            <div class="d-flex">
              <span>
                <v-icon :color="colourForSPHeader(provider)">
                  {{ iconForSPHeader(provider) }}
                </v-icon>
              </span>
              <span class="ml-1 black--text">{{ $langobj.parse(provider.name) }}</span>
            </div>
            <div class="mt-1" v-if="provider.first_approval_date">
              <v-icon color="green">mdi-certificate</v-icon>
              {{ provider.first_approval_date }}
              <span v-if="provider.last_approval_date
                && provider.last_approval_date != provider.first_approval_date"
              >
                <v-icon>mdi-fast-forward</v-icon>
                {{ provider.last_approval_date }}
              </span>
            </div>
            <div
              class="mt-1" 
              v-else-if="provider.identification_date && provider.designation_status_id == 1"
            >
              <v-icon color="amber">mdi-tag-text</v-icon>
              {{ provider.identification_date }}
            </div>
          </v-card-text>  
          <v-divider class="my-0"></v-divider>

          <v-card-text class="mt-2 mb-1 py-0" v-if="provider.next_submit_due_date">
            <v-icon
              small
              :color="moment().diff(moment(provider.next_submit_due_date)) > 0 ? 'red':''"
            >
              mdi-bullseye-arrow
            </v-icon>
            {{ $t('provider.dashboard.next_due_date') }}: {{ provider.next_submit_due_date }}
          </v-card-text>

          <v-card-text class="mt-1 mb-2 py-0">
            <v-icon small>mdi-clock-fast</v-icon>
            <template v-if="provider.last_update">
              {{ moment.utc(provider.last_update ).local().format('LL') }}
            </template>
            <template v-else>
              <em>{{ $t('provider.dashboard.no_update')}}</em>
            </template>

            <template v-if="provider.last_sp_login">
              <v-chip 
                v-if="moment().diff(moment.utc(provider.last_sp_login), 'days') <= 7"
                small
                color="green darken-1"
                class="ml-2 py-0 px-1"
                text-color="white"
              ><v-icon small>mdi-login</v-icon>
              </v-chip> 
              <v-icon v-else small class="ml-2">mdi-login</v-icon>
              {{ moment.utc(provider.last_sp_login ).local().format('LL') }}
            </template>
          </v-card-text>

          <v-divider />

          <v-card-text class="pt-1">
            <div v-if="provider.dossiers.length == 0">
              <span class="mt-12 mx-auto text-subtitle-1 grey--text text--lighten-1">
                {{ $t('error.no_active_dossier') }}
              </span>
            </div>
          
            <div class="item-pointer mb-2"
              v-for="dossier in provider.dossiers"
              v-show="dossier.dossier_status!='approved'"
              :key="dossier.designation_dossier_id"
              @click="$router.push(
                { name: 'dossier_summary', 
                  params: {
                    service_provider_id: provider.service_provider_id,
                    designation_dossier_id: dossier.designation_dossier_id
                  }
                })
              "
            >
              <div class="d-flex">
                <span class="mr-auto">{{ $langobj.parse(dossier.name) }}</span>
                <span class="ml-3" v-if="dossier.end_or_due_date">
                  {{ dossier.end_or_due_date }}
                </span>
                <span class="ml-3 grey--text text--lighten-2" v-else><em>Date ??</em></span>
              </div>
              <div class="pl-3">
                <span>
                  <v-icon :color="dossier.status_aes.colour" left>
                    mdi-circle-medium
                  </v-icon>
                  {{ $langobj.parse(dossier.status_name) }}
                </span>
                
                <v-progress-linear
                  :value="100*dossier.workplan_element_num/dossier.workplan_element_denom"
                  color="primary"
                  height="8"
                >
                </v-progress-linear>
              </div>
            </div>
          </v-card-text>          
        </v-card>
      </div>
    </v-container>
  </v-sheet>
</template>

<style scoped>
  .item-pointer:hover {
    cursor: pointer;
  }
</style>

<script>
import {privileges} from '@/mixins/privileges.js';

export default {
  name: 'Tdb',
  mixins: [privileges],
  data: function() {return {
    regionId: null,
    serviceproviders: [],
    regions: [],
    tickets: [],
    lifecycle_tickets: [],
    current_user_id: '',
    loading: false,
  }},
  computed: {
    orderedProviders() {
      return this.serviceproviders
        .map(a => ({
          ...a,
          sortableName: this.$langobj.parse(a.name),
          sortableDuedate: a.next_submit_due_date || '9999-99-99',
        }))
        .sort((a,b) =>
          a.sortableDuedate.localeCompare(b.sortableDuedate) ||
          a.sortableName.localeCompare(b.sortableName)
        );
    }
  },
  methods: {
    colourForSPHeader(service_provider) {
      if( service_provider.designation_status_id == 1 )
        return 'amber';
      else if( service_provider.designation_status_id == 4)
        return 'grey';
      else
        return 'green';
    },
    iconForSPHeader(service_provider) {
      if( service_provider.designation_status_id == 1 )
        return 'mdi-alpha-i-box';
      else if( service_provider.designation_status_id == 4)
        return '';
      else
        return 'mdi-alpha-d-box';
    },
    refresh() {
      this.loading = true;
      this.serviceproviders = [];
      this.$axios
        .get(this.$root.$data.server_base + '/api/dashboard/agent_summary.php',
          {params: {region_id: this.regionId}})
        .then(response => {
          this.serviceproviders = response.data.serviceproviders
          // this.tickets = response.data.tickets
          // this.lifecycle_tickets = response.data.lifecycle_tickets
          this.current_user_id = response.data.current_user_id
          this.loading = false;
        })
    },
    getOptions() {
      this.$axios
        .get(this.$root.$data.server_base + '/api/region/list.php')
        .then(response => {
          this.regions = response.data.regions;
        });
    }
  },
  mounted () {
    this.refresh();
    this.getOptions();
    /*this.polling = setInterval(function () {
      this.intervalExpiry();
    }.bind(this), 1000);*/
  },
  beforeDestroy () {
    // clearInterval(this.polling)
  }
}
</script>
