<template>

    <v-container style="background-color: #eee" fluid>

      <v-row>
        <v-col cols="12" sm="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <h3>Attestation à compléter avant le 29 mars 2022</h3>
                </v-col>
                <v-spacer />
                <v-col>
                  <v-btn color="primary"><v-icon class="mr-1">mdi-file-pdf-box</v-icon>Exporter</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="mb-6">
            <v-card-title>Prochaines étapes</v-card-title>            
            <v-card-text>
              <v-btn color="primary">Soumettre pour évaluation</v-btn>
            </v-card-text>

          </v-card>
           <v-card>
            <v-card-title>Activité récente</v-card-title>
            
            <v-card-text>
         
              <ul>
                <li>2022.02.05: Commentaire ajouté à l'exigence 9</li>
                <li>2022.01.24: Modification faite aux tableau des RH</li>
              </ul>
            
            </v-card-text>

            
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card>
            <v-card-title>Clientèle</v-card-title>
            <v-card-subtitle>Clientèle totale en 2020-2021: 245 </v-card-subtitle>
            <v-card-text>
         
              <p class="db-graph-title mb-0 mt-3">Francophones parmi la population ciblée</p>

              <v-progress-linear value="27" color="#fbab26" height="20">
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
              
              <p class="db-graph-title mb-0 mt-3">Francophones parmi la clientèle</p>
              <v-progress-linear value="48" color="#9066B3" height="20">
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <p class="db-card-bottom mb-0">
                <v-icon small class="mr-1" color="green">mdi-check-decagram</v-icon>Complété et approuvé par le ministère</p>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-title>Exigences</v-card-title>
            <v-card-text>
              <p class="db-graph-title mb-0">Exigences complétées</p>

              <v-progress-linear value="65" color="#fbab26" height="20">
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
              
              <p class="db-graph-title mb-0 mt-3">Exigences conformes</p>
              <v-progress-linear value="50" color="#9066B3" height="20">
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <p class="db-card-bottom mb-0">
                <v-icon small class="mr-1">mdi-clock</v-icon>Mis à jour le 4 février
              </p>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
           <v-card>
            <v-card-title>Ressources humaines</v-card-title>
            <v-card-subtitle>Total de 27 postes déclarés dans 3 programmes / services.</v-card-subtitle>
            
            <v-card-text>            
              <p class="db-graph-title mb-0">Postes désignés / total des postes</p>
              <v-progress-linear value="43" color="#A7B366" height="20">
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>

              <p class="db-graph-title mb-0 mt-3">Postes désignés comblés</p>
              <v-progress-linear value="67" color="#9066B3" height="20">
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>


            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <p class="db-card-bottom mb-0">
                <v-icon small class="mr-1">mdi-clock</v-icon>Mis à jour le 4 février
              </p>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'InfoGenerale',
  data() {return {
    postes: 78,
    exigences: 65,
    nom: '',
    lettre_patentes: '',
    adresse: '',
    description: '',
  }},
});
</script>

<style scoped lang="scss">
p.db-card-bottom {
  font-size: 0.8em;
  font-weight: 300;
  color: grey;
}
p.db-graph-title {
  font-size: 0.9em;
  font-weight: 400;
  color: black;
}
p.db-alert {
  color: #C62828
}
ul {
  font-size: 0.9em;
  font-weight: 300;
  line-height: 1.1em;

  li {
    margin-bottom: 4px;
  }
}
</style>