<template>
  <div>
    <p>Pour la période: {{ report.client_year }}</p>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('report.fls34.item') }}
            </th>
            <th class="text-left">
              {{ $t('report.fls34.population') }}
            </th>
            <th class="text-left">
              {{ $t('report.fls34.french_pop') }}
            </th>
            <th class="text-left">
              {{ $t('report.fls34.french_percent') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Clients</td>
            <td>{{ report.client_total_population }}</td>
            <td>{{ report.client_french_population }}</td>
            <td>{{ percent(report.client_french_population, report.client_total_population) }}</td>
          </tr>
          <tr>
            <td>Visites</td>
            <td>{{ report.client_visits }}</td>
            <td>{{ report.client_french_visits }}</td>
            <td>{{ percent(report.client_french_visits, report.client_visits) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: "ReportClientele",
  props: ['report'],
  computed: {
    
  },
  methods: {
    percent(n, d) {
      const percent = (n * 100 / d).toFixed(2);
      return isNaN(percent) ? "-" : percent + " %";
    }
  }
});
</script>