<template>
  <div>
    <router-link class="text-decoration-none" :to="{name: 'contacts'}">
      <span class="text-h6 text--primary">←&nbsp;</span>
      {{ $t('label.return_to_list') }}
    </router-link>
    <h2>{{ isCreate ? $t('contact.create') : $t('contact.modify') }}</h2>
    <v-form v-model="isFormValid" ref="form">
      <v-row>
        <v-col cols="4">
          <v-select 
            v-model="internal.contact_type"
            :label="$t('contact.role')"
            :items="
              (contactTypeOptions || []).map((option) => ({
                text: $langobj.parse(option.name),
                value: option.enum_key,
            }))"
          >
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-select 
            v-model="internal.is_active"
            :label="$t('contact.status')"
            :items="itemsIsActive"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-text-field
        v-model="internal.first_name"
        :loading="loading"
        :rules="[(v) => $validators.required(v, [$t('contact.first_name')])]"
        :label="$t('contact.first_name')"
      />
      <v-text-field
        v-model="internal.last_name"
        :loading="loading"
        :rules="[(v) => $validators.required(v, [$t('contact.last_name')])]"
        :label="$t('contact.last_name')"
      />
      <v-text-field
        v-model="internal.position"
        :loading="loading"
        :label="$t('contact.position')"
      />
      <v-text-field
        v-model="internal.telephone"
        :loading="loading"
        :label="$t('contact.telephone')"
        prepend-icon="mdi-phone"
      />
      <v-text-field
        v-model="internal.mobile_phone"
        :loading="loading"
        :label="$t('contact.mobile')"
        prepend-icon="mdi-cellphone"
      />
      <v-text-field
        v-model="internal.email"
        :loading="loading"
        :label="$t('contact.email')"
        prepend-icon="mdi-email"
      />
      <v-row>  
        <v-col>
          <v-btn color="primary" @click="handleSave" :disabled="disableSave" :loading="saving">
            {{ $t('label.save') }}
          </v-btn>
          <v-btn color="primary" text @click="handleCancel">
            {{ $t('label.cancel') }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col class="text-right">
          <small v-if="internal.created_at" class="grey--text font-italic">
            {{ $t('label.modified') }}
            {{ moment.utc(internal.modified_at || internal.created_at).local().fromNow() }}
          </small>                        
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import ObjectUtils from '@/utils/ObjectUtils';

export default {
  name: "ContactDetails",
  data() { return {
    isFormValid: true,
    internal: {},
    storeData: {},
    contactTypeOptions: [],
    loading: false,
    saving: false
  }},

  computed: {
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    },
    isCreate() {
      return this.$route.params.contact_id == 'cr';
    },
    itemsIsActive() {
      return [
        { text: this.$t('label.active'), value: 1 },
        { text: this.$t('label.inactive'), value: 0 },
      ];
    }
  },

  methods: {
    refresh() {
      this.$axios
        .get(this.$root.$data.server_base + '/api/admin/enum/list.php',
          {params: {family_key: 'contact_type'}})
        .then((response) => {
          this.contactTypeOptions = response.data.enums;
        });

      if(this.$route.params.contact_id == 'cr')
        return;
      this.loading = true;
      this.saving = false;
      this.$axios
        .get(this.$root.$data.server_base + '/api/contact/get.php',
          { params: { 
              service_provider_id: this.$route.params.service_provider_id,
              contact_id: this.$route.params.contact_id
          }})
        .then((response) => {
          this.storeData = response.data.contact;
          this.internal = _.cloneDeep(this.storeData);
          this.loading = false;
        });
    },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }  
      let obj = {};
      let api = "";
      if(this.isCreate) {
        api = "/api/contact/create.php";
        obj = this.internal;  
      }
      else {
        api = "/api/contact/update.php";
        obj = ObjectUtils.getObjectDiff(this.internal, this.storeData);  
        obj.contact_id = this.internal.contact_id;
      }
      obj.service_provider_id = this.$route.params.service_provider_id;
      
      this.saving = true;
      this.$axios
        .put(api, obj, { headers: {'Content-Type': 'application/json' }})
        .then((response) => {
          this.storeData = response.data;
          this.internal = _.cloneDeep(this.storeData);
          this.saving = false;

          this.$router.push({name: 'contacts'});
        });
    },
    handleCancel() {
      if(this.isCreate)
        this.$router.push({name: 'contacts'});
      else
        this.refresh();
    },
  },
  mounted() {
    this.refresh()
  }
}
</script>
