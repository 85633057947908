<template>
  <div>
    <h2>{{ $t('admin.emails') }}</h2>
    <v-row class="mt-2">
      <v-col cols=2>
        <v-text-field
          v-model="search"
          outlined
          dense
          :label="$t('label.search')"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :items-per-page="20"
      :footer-props="{itemsPerPageOptions:[20,50,100]}"
      dense
      :headers="headers"
      :items="emails"
      item-key="key"
      :loading="isLoading"
      @click:row="handleRowClick"
      :search="search"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ $langobj.parse(item.name) }}
      </template>
      <template v-slot:[`item.subject`]="{ item }">
        {{ $langobj.parse(item.subject) }}
      </template>
    </v-data-table>
  </div>  
</template>

<script>
import axios from 'axios';

export default {
  data: function() {
    return {
      emails: [],
      isLoading: true,
      search: ""
    }
  },

  computed: {
    headers() {
      return [
        { text: this.$tc('email.key'), value: 'key' },
        { text: this.$tc('email.name'), value: 'name' },
        { text: this.$tc('email.subject'), value: 'subject' },
      ];
    }
  },

  methods: {
    getDataFromApi() {
      this.isLoading = true;
      axios
        .get(this.$root.$data.server_base + "/api/admin/email/list.php",
          {params:{}})
        .then(response => {
          this.emails = response.data;
          this.isLoading = false
        })
    },
    handleRowClick(row) {
      this.$router.push(
        { name: 'admin-email-details', params: { key: row.key }}
      );
    },
  },
  watch: {
    show_inactive_users: {
      handler () { this.getDataFromApi() }
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>