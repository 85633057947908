<template>
  <div>
    <v-row class="mt-0">
      <v-col cols="12" sm="8">
        <status-selector
          ref="requirement_status_key"
          v-model="requirement.requirement_status_key"
          @save="handleSaveOneParam('requirement_status_key', ...arguments)"
          :readonly="readonly"
        ></status-selector>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col>
        <toggable-tip-tap
          ref="deliverable_description"
          v-model="requirement.deliverable_description"
          :label="$t('requirement.deliverable')"
          @save="handleSaveOneParam('deliverable_description', ...arguments)"
          :readonly="readonly"
        ></toggable-tip-tap>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col>
        <toggable-tip-tap
          ref="sp_content"
          v-model="requirement.sp_content.notes"
          :label="$t('requirement.notes')"
          @save="handleSaveOneParam('sp_content', ...arguments)"
          :readonly="readonly"
        ></toggable-tip-tap>
      </v-col>
    </v-row>

    

    <p class="mt-6 mb-4 text-h6">{{ $tc('designation_model.element.label', 2) }}</p>
    <workplan-for-requirement
      :elements="workplanElements"
      @refresh="getWorkPlan"
    />

    <v-row align="center">
      <v-col>
        <template v-if="!editMode">
          
          <editable-document-list
            :label="$t('requirement.documents')"
            :documents='requirement.documents'
            :readonly="readonly"
            :requirement="requirement"
            @refresh="$emit('refresh')"
          />
        </template>
      </v-col>
    </v-row>

    <!-- <v-row align="center">
      <v-col>
        <template v-if="!editMode">
          <div class="text-h6 mt-3">Liste de vérification</div>
          <div>À compléter</div>
        </template>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import Vue from 'vue';
import i18n, { locales } from '@/i18n';
import WorkplanForRequirement from '@/components/dossiers/WorkplanForRequirement.vue';
import EditableDocumentList from '@/components/document/EditableDocumentList.vue';
import StatusSelector from './StatusSelector.vue';

export default {
  components: { EditableDocumentList, StatusSelector, WorkplanForRequirement },
  name: 'TabRealisations',
  props: ['readonly', 'requirement'],
  data () {
    return {
      workplanElements: [],
      editMode: false,
      startDateModal: false,
      endDateModal: false,
      responsable: '',
      livrable: '',
      other: '',
      items: [
        "Non-débuté",
        "En développement",
        "Soumis pour évaluation",
        "En évaluation",
        "Provisoirement conforme",
        "Non-conforme",
        "Approuvé par la communauté"
      ]
    }
  },
  computed: {
    locale() {return i18n.locale;},
  },
  methods: {
    handleSaveOneParam(key, value) {
      this.$axios
        .put(this.$root.$data.server_base + '/api/requirement/update.php',
          { requirement_id: this.requirement.requirement_id,
            service_provider_id: this.$route.params.service_provider_id,
            [key]: this.requirement[key]})
        .then((response => {
          this.$refs[key].markAsSaved();
        }))
    },
    getWorkPlan() {
      if(!this.requirement.requirement_def_id)
        return;

      this.$axios
        .get(this.$root.$data.server_base + '/api/workplan_element/list.php', 
        { params: {
            service_provider_id: this.$route.params.service_provider_id,
            designation_dossier_id: this.$route.params.designation_dossier_id,
            requirement_def_id: this.requirement.requirement_def_id,
        }})
        .then((response => {
          this.workplanElements = response.data.elements;
        }))
    },
  },
  watch: {
    requirement() {
      this.getWorkPlan();    
    }
  },
  mounted () {
    this.getWorkPlan();
  }
};
</script>