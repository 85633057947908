<template>
  <div class="app_row row" :class="{ editing: readmode == false }">
    <div v-show="readmode" @click="$router.push({ name: 'document', params: { service_provider_id: serviceProviderId, document_id: document.document_id } })">
      <div class="col-sm-11 text-muted" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
        <!-- <i style="padding-right: 10px;" class="fas fa-bars fa-spacer document-sort-handle" aria-hidden="true"></i> -->
        <a :href="document.url" v-on:click="$event.stopPropagation()" class="document-link" target="_blank">
          <DocumentIcon :url="document.url" />{{ document.title }}</a>
          
        <span v-if="text_description != ''" style="margin-left: 15px;" v-text="text_description"></span>
        <span v-else="" style="margin-left: 15px; color: #ccc"><em>Entrer une description...</em></span>
      </div>
      <div class="col-sm-1">
        <a v-on:click="confirmDocumentDelete" style="cursor:pointer"><em class="fas fa-trash-alt fa-spacer" aria-hidden="true"></em></a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.document-link {
  color: #0088cc;
  
  &:hover, &:focus {
    text-decoration: underline;
    color: #0072ab;
  }
}

.editing {
  background: white !important;
  border: 1px solid #ccc !important;
  border-width: 1px 0 1px 0 !important;
  margin: 5px 0px;
}

// .document-sort-handle {
//   color: #ccc;
// }
</style>

<script>
  import DocumentIcon from '@/components/DocumentIcon.vue'
  
  export default {
    props: ['document', 'serviceProviderId'],
    data: function() { return {
      readmode: true
    }},
    components: {
      DocumentIcon
    },
    computed: {
      text_description() {
        if (!this.document.description_text || this.document.description_text === '') { return '' }
        return this.document.description_text
      }
    },
    methods: {
      confirmDocumentDelete(e) {
        e.stopPropagation()
        this.$emit('confirmDelete', this.document.document_id)
      }
    }
  }
</script>