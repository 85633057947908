<template>
  <v-dialog v-model="propModel" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ title ? title : $t('label.delete') }}
      </v-card-title>

      <v-card-text class="mt-3">
        <slot></slot>  
        <div v-if="!$slots.default">
          {{ $t('label.are_you_sure') }}
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="propModel = false">
          {{ $t('label.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="loading = true; $emit('confirmed', id)"
        >
          <v-icon class="mr-2">{{ btnIcon }}</v-icon>
          {{ btnLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  components: {
  },

  props: {
    value: false,
    title: {
      type: String,
    },
    btnLabel: {
      type: String,
      default: () => (`$t('label.delete')`),
    },
    btnIcon: {
      type: String,
      default: 'mdi-trash-can',
    },
    id: {
      default: null,
    }
  },

  data () { return {
    loading: false,
  }},

  computed: {
    propModel: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },

  watch: {
    value() {
      if(this.value == false)
        this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
</style>
