var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: 'admin-providers'}}},[_c('span',{staticClass:"text-h6 text--primary"},[_vm._v("← ")]),_vm._v(" "+_vm._s(_vm.$t('label.return_to_list'))+" ")]),_c('h2',[_vm._v(_vm._s(_vm.isCreate ? _vm.$t('provider.create') : _vm.$t('provider.modify')))]),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('v-card-text',[_c('div',{staticClass:"mt-2 grey--text"},[_vm._v(_vm._s(_vm.$langobj.parse(_vm.internal.name)))])]),_c('v-divider',{staticClass:"mx-4"}),(!_vm.isCreate)?_c('v-card-text',[_c('div',[_c('v-btn',{staticClass:"px-1",attrs:{"color":"primary","text":""},on:{"click":_vm.$root.notDone}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t('label.delete'))+" ")],1)],1)]):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-card-text',[_vm._l((_vm.$locales),function(locale){return _c('v-row',{key:locale.value,staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"1"}},[_c('p',{staticClass:"text-h4 primary--text"},[_vm._v(_vm._s(locale.toLocaleUpperCase()))])]),_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"spellcheck":"false","label":_vm.$langobj.label(_vm.$t('provider.name'), locale),"rules":[(v) => _vm.$validators.required(v, [_vm.$t('provider.name')])],"loading":_vm.loading},model:{value:(_vm.internal.name[locale]),callback:function ($$v) {_vm.$set(_vm.internal.name, locale, $$v)},expression:"internal.name[locale]"}})],1)],1)}),_c('v-select',{attrs:{"label":_vm.$t('provider.designation_status'),"items":(_vm.status_options || []).map((option) => ({
                  text: _vm.$langobj.parse(option.name),
                  value: option.id,
                })),"rules":[(v) => _vm.$validators.required(v, [_vm.$t('provider.designation_status')])],"loading":_vm.loading},model:{value:(_vm.internal.designation_status_id),callback:function ($$v) {_vm.$set(_vm.internal, "designation_status_id", $$v)},expression:"internal.designation_status_id"}}),_c('v-select',{attrs:{"label":_vm.$t('provider.region'),"items":(_vm.region_options || []).map((option) => ({
                  text: _vm.$langobj.parse(option.name),
                  value: option.region_id,
                })),"rules":[(v) => _vm.$validators.required(v, [_vm.$t('provider.region')])],"loading":_vm.loading},model:{value:(_vm.internal.region_id),callback:function ($$v) {_vm.$set(_vm.internal, "region_id", $$v)},expression:"internal.region_id"}}),_c('v-select',{attrs:{"label":_vm.$t('provider.ozi_agency'),"items":(_vm.ozi_agency_items || []).map((option) => ({
                  text: _vm.$langobj.parse(option.name),
                  value: option.agency_id,
                })),"loading":_vm.loading,"clearable":"","no-data-text":"Choisir la région en premier"},model:{value:(_vm.internal.agency_id),callback:function ($$v) {_vm.$set(_vm.internal, "agency_id", $$v)},expression:"internal.agency_id"}}),_c('v-select',{attrs:{"label":_vm.$t('provider.state'),"loading":_vm.loading,"items":_vm.status_items,"rules":[(v) => _vm.$validators.required(v, [_vm.$t('provider.state')])]},model:{value:(_vm.internal.state),callback:function ($$v) {_vm.$set(_vm.internal, "state", $$v)},expression:"internal.state"}})],2)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disableSave},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.$t('label.save'))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('label.cancel'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }