<template>
  <div>
    <report-row :label="$t('report.bdssf34.designation_status')">
      {{ $langobj.parse(report.design_status_name) }}
    </report-row>

    <report-row :label="$t('report.bdssf34.application_type')">
      {{ $langobj.parse(report.designation_status_lang) }}
    </report-row>

    <report-row :label="$t('report.bdssf34.program_list')">
      <div v-html="asHtml(report.all_services)" />
    </report-row>

    <report-row :label="$t('report.bdssf34.program_list_partial')">
      <div v-html="asHtml(report.services_subject)" />
    </report-row>

    <report-row :label="$t('report.bdssf34.services_for_others')">
      <div v-html="asHtml(report.other_ministry)" />
    </report-row>

    <report-row :label="$t('report.bdssf34.other_designated_services')">
      <div v-html="asHtml(report.other_design_services)" />
    </report-row>

    <report-row :label="$t('report.bdssf34.unique_services')">
      <div v-html="asHtml(report.unique_services)" />
    </report-row>

  </div>
</template>

<script>
import Vue from 'vue';
import ReportRow from '@/components/report/reportRow.vue';

export default Vue.extend({
  props: ['report'],
  
  components: {
    ReportRow
  },

  methods: {
    asHtml(str) {
      if(str)
        return str.replace(/\n/g, '<br />');
      return '';
    }
  },
});
</script>

<style scoped lang="scss">
</style>