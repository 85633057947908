<template>
  <v-container fluid>
    <v-card class="d-flex mt-3" outlined>
      <v-card-title>
        <div style="width: 200px">
          <v-tooltip bottom open-delay="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" 
                class="mr-4" icon exact :to="{name: 'dossier_requirements'}"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.return_to_list') }}</span>
          </v-tooltip>
          {{ $tc('requirement.label') }}:
        </div>  
      </v-card-title>
      <v-card-text class="pl-0 pt-4">
        <v-select 
          class="ml-4"
          v-model="selectedRequirement"
          :items="requirementItems"
          outlined
          dark
          dense
          hide-details
          background-color="primary"
          @input="handleNextRequirement"
        >
          <template v-slot:selection="{ item }">
            <v-avatar size="24" color="white" class="mr-2">
              <span v-if="item.identifier.length > 2" 
                class="primary--text" style="font-size: 0.5em; font-weight: bold;">
                {{item.identifier}}
              </span>
              <span v-else 
                class="primary--text" style="font-size: 0.9em; font-weight: bold;">
                {{item.identifier}}
              </span>
            </v-avatar>
            {{ item.text }}
          </template>
          <template v-slot:item="{ item }">
            {{item.identifier}} - {{ item.text }}
          </template>
        </v-select>
        <p class="ml-4 mt-3">
          {{ $langobj.parse(requirement.requirement_text) }}
        </p>
      </v-card-text>
    </v-card>

    <v-card class="mt-3" flat>
      <v-tabs class="pt-2">

        <v-tabs-slider color="#fbab26"></v-tabs-slider>
        <v-tab
          v-for="item in leftMenuItems"
          :key="item.title"
          :to="item.to"
        >
          <v-icon left>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-tab>
        <v-tabs-items>
          <v-card flat>
          
            <v-card-text>
              

              <router-view
                :requirement="requirement"
                :readonly="readonly"
                @refresh='refresh'>
              </router-view>
            </v-card-text>
          </v-card>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-container>
</template>


<script>
import Vue from 'vue';
import {privileges} from '@/mixins/privileges.js';

export default Vue.extend({
  name: 'AccOneRequirement',
  components: {},
  mixins: [privileges],
  props: ['readonly', 'requirements'],
  data () {
    return {
      selectedRequirement: null,
      tab: null,
      row: null,
      requirement: { 
        identifier: '',
        help_text_1: {},
        help_text_2: {},
        help_text_3: {},
        sp_content: {},
        other_feedback: {},
      },
      
      isLoading: false,
    }
  },
  computed: {
    leftMenuItems() {
      return this.filterArrayForPrivilege([
        { 
          title: this.$t('requirement.menu.workplan'),
          icon: 'mdi-account-hard-hat',
          to: {name: 'requirement_workplan'}
        },
        {
          title: this.$t('requirement.menu.deliverable'),
          icon: 'mdi-check',
          to: {name: 'requirement_realisations'}
        },
        {
          title: this.$t('requirement.menu.evaluations'),
          icon: 'mdi-certificate',
          to: {name: 'requirement_evaluation'},
          privilege: 'agent',
        },
        {
          title: this.$t('requirement.menu.conversations'),
          icon: 'mdi-message-outline',
          to: {name: 'requirement_conversation'}
        },
        {
          title: this.$t('requirement.menu.resources'),
          icon: 'mdi-book-alphabet',
          to: {name: 'requirement_resources'}
        },
      ])
    },
    requirementItems() {
      return this.requirements.map(item => (
        {
          value: item.requirement_id,
          text: this.$langobj.parse(item.name),
          identifier: item.identifier,
        }
      )).filter(item => item.value != null )
    }
  },
  watch: {
    $route() { this.refresh(); }
  },
  methods: {
    handleNextRequirement() {
      this.$router.push({
        name: this.$route.name, 
        params: { requirement_id: this.selectedRequirement }
      });
    },
    refresh() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/requirement/get_one_requirement.php',
          { params: {
            designation_dossier_id: this.$route.params.designation_dossier_id,
            service_provider_id: this.$route.params.service_provider_id,
            requirement_id: this.$route.params.requirement_id
          }})
        .then(response => {
          this.requirement = response.data.requirement;
          this.isLoading = false
        });
    }
  },
  mounted () {
    this.selectedRequirement = Number(this.$route.params.requirement_id);
    this.refresh();
  }
});
</script>
