<template>
  <v-container v-if="priv('superadmin')">
    <h2>{{ $t('admin.scripts') }}</h2>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t('label.description') }}</th>
            <th class="text-left">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in scripts" :key="item.file">
            <td>{{ item.name }}</td>
            <td>
              <v-btn color="primary" @click="multiStepExec(item.file, 1)">
                {{ $t('label.execute') }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-show="results.length" class="px-3">
      <br />
      <h3 class="mb-3">{{ $t('label.result') }}</h3>
      <v-row>
        <v-col cols="6">{{ $t('label.action') }}</v-col>
        <v-col cols="4">{{ $t('label.hour') }}</v-col>
        <v-col cols="2">{{ $t('label.result') }}</v-col>
      </v-row>

      <template v-for="result in results">
        <v-row :key="result.timestamp" style="border-top: 1px solid rgba(0,0,0,0.1)">
          <v-col cols="6" class="py-2">{{ result.step_desc }}</v-col>
          <v-col cols="4" class="py-2">{{ result.timestamp }}</v-col>
          <v-col cols="2" class="py-2">
            <template v-if="result.step_successful">
              <v-icon color="green">mdi-check-bold</v-icon> {{ $t('label.success') }}
            </template>
            <template v-else>
              <v-icon color="red">mdi-alert-circle</v-icon> {{ $t('label.failed') }}
            </template>
          </v-col>
        </v-row>
        <v-row v-if="result.error" :key="'error'+result.timestamp">
          <v-col class="pl-8 caption pt-0 pb-1 text-wrap">{{ result.error }}</v-col>
        </v-row>
      </template>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios';
import {privileges} from '@/mixins/privileges.js';

export default {
  data: function() {return {
    db_host: '',
    db: '',
    results: []
  }},

  mixins: [privileges],

  computed: {
    scripts() {return [
      // {name: 'Migration #0 - Clés primaires', file: 'collab_upgrade_0_pk'},
      // {name: 'Migration #1 - Fournisseurs', file: 'collab_upgrade_1'},
      // {name: 'Migration #2 - Contacts', file: 'collab_upgrade_2'},
      // {name: 'Migration #3 - Modèles de désignation', file: 'collab_upgrade_3'},
      // {name: 'Migration #4 - Status et dossiers', file: 'collab_upgrade_4'},
      // {name: 'Migration #5 - Exigences', file: 'collab_upgrade_5'},
      // {name: 'Migration #6 - Énumérations', file: 'collab_upgrade_6'},
      {name: 'Migration #7 - Séquences', file: 'collab_upgrade_7_sequence'},
      // {name: 'Migration #8 - Valeurs par défaut', file: 'collab_upgrade_8'},
      // {name: 'Migration #9 - Valeurs modified_at', file: 'collab_upgrade_9'},
      // {name: 'Migration #10 - Dossiers', file: 'collab_upgrade_10'},
      // {name: 'Migration #11 - Bulles aide', file: 'collab_upgrade_11'},
      // {name: 'Migration #12 - Documents', file: 'collab_upgrade_12_documents'},
      // {name: 'Migration #13 - Billets', file: 'collab_upgrade_13_tickets'},
      // {name: 'Migration #14 - Commentaires', file: 'collab_upgrade_14_comments'},
      // {name: 'Migration #15 - Element Def.', file: 'collab_upgrade_15_element_def'},
      // {name: 'Migration #16 - Associations de documents', file: 'collab_upgrade_16_documents_v2'}
      {name: 'Migration #17 - Repo - documents', file: 'collab_upgrade_17_documents_v3'},
      {name: 'Migration #18 - BDSSF - Import', file: 'collab_upgrade_18_bdssf_import'},
      {name: 'Migration #19 - BDSSF - OHFS Number', file: 'collab_upgrade_19_ohfs_number'},
      {name: 'Migration #21 - Soumission', file: 'collab_upgrade_21_submission'}
    ]},
  },

  methods: {

    exec: function(action) {
      axios
        .get(this.$root.$data.server_base + '/api/admin/'+action+'.php',
          { params: {}})
        .then(response => {
          this.$checkApiErrors(response.data);
        })
        .catch(error => {
          // console.log(error)
        })
    },

    multiStepExec: function(action, step) {
      this.$axios
        .get(this.$root.$data.server_base + '/api/admin/scripts/'+action+'.php',
          { params: {step}})
        .then(response => {
          this.results.push(response.data);
          if(response.data.next_step) {
            this.$nextTick(this.multiStepExec(action, response.data.next_step));            
          } else {
            this.notifyEnd();
          }
        })
        .catch(error => {
          // console.log(error)
        })
    },
    notifyEnd() {
      this.$notify({type: "info",group: 'std',
        title: 'Script complété',
        text: "Le script a été complété",
        duration: 4000 });
    }
  },
  mounted () {
    // this.refresh();
  }
}
</script>
