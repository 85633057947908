<template>
  <div>
    <div
      v-if="(comment_id==0) && readmode_comment"
      @click="editMode" class="new-comment-box"
      >
      <em>{{ $t('comment.add') }}</em>
    </div>
    <div v-show="(comment_id!=0)">
      <div class="d-flex">
        <user-avatar :user="comment.created_by_user" class="mr-2 mb-1" />
        <div>
          <strong>{{comment.created_by}}</strong>
          
          <span v-show="comment.created_at" class="created_at"> - {{ createdAtStr }}</span>&nbsp;&nbsp;
          <v-btn small icon color="primary"
            v-show="comment.edit&&!readonly"
            @click="editMode"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn small icon color="primary"
            v-show="comment.edit&&!readonly"
            @click.stop="dialog = true"
            
          >
            <v-icon small>mdi-trash-can</v-icon>
          </v-btn>
      
          <div v-if="readmode_comment" class="mb-4">
            <div v-html="comment.comment"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!readmode_comment" class="mb-4">
      <tip-tap 
        v-model="commentedit"
        spellcheck="false"
        readonly
      />
      <v-btn 
        color="primary"
        class="mr-2"
        :loading="isSaving"
        @click="save_comment"
        small
      >
        {{ $t('label.save') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        small
        @click="cancelComment"
      >
        {{ $t('label.cancel') }}
      </v-btn>
    </div>
   
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
          {{ $t('label.delete') }} ?  
        </v-card-title>
        <v-card-text>{{ $t('label.are_you_sure') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="red" @click="delete_comment">{{ $t('label.delete') }}</v-btn>
          <v-btn color="primary" text @click="dialog = false">{{ $t('label.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.new-comment-box {
  margin: -8px;
  padding: 8px;
  border-radius: 4px;
  color: #999;

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }  
}
.created_at {
  color: #999;
  font-size: 85%;
}
</style>

<script>

import UserAvatar from '@/components/user/UserAvatar.vue';

export default {
  name: 'Comment',
  props: ['parent_id','parent_class','comment_id','readonly'],
  
  components: {
    UserAvatar
  },

  data: function() {return {
    dialog: false,
    show_confirm_comment:false,
    readmode_comment:true,
    comment:{created_by_user:{},created_at: null},
    commentedit:'',
    oldcomment:'',
    errors:{comment:'',commentedit:''},
    isSaving: false
  }},

  computed: {
    createdAtStr() {
      return this.moment.utc(this.comment.created_at).local().format("LLL") + ' (' +
        this.moment.utc(this.comment.created_at).fromNow() +')';
    }
  },
  watch: {
    $route() {
      this.readmode_comment = true;
    }
  },
  methods:
  {
    refresh() {
      if(this.comment_id>0) {
        this.$axios
          .get(this.$root.$data.server_base + '/api/comment/comment.php',
            { params: {parent_id:this.parent_id,parent_class: this.parent_class,comment_id: this.comment_id}})
          .then(response => (
            this.comment = response.data.comment,
            this.commentedit = this.comment.comment
            ))
        }
    },
    editMode() {
      if(this.comment_id>0)
        this.commentedit = this.comment.comment;
      else
        this.commentedit = '';
      this.readmode_comment=false;
    },
   
    cancelComment() {
      if(this.comment_id>0)
        this.commentedit = this.comment.comment;
      else
        this.commentedit = '';

      this.readmode_comment=true;
    },

    save_comment() {
      var empty_field = false;

      if(this.commentedit == '')
      {
        this.errors.commentedit = this.messages.required;
        empty_field = true;
      }
      else
        this.errors.commentedit = '';

      if(!empty_field)
      {
        let data = {
          parent_class:this.parent_class,
          parent_id:this.parent_id,
          comment_id:this.comment_id,
          comment:this.commentedit
        };
        this.isSaving = true;
        this.$axios
          .put( this.$root.$data.server_base + '/api/comment/comment_save.php', {
            parent_class:this.parent_class,
            parent_id:this.parent_id,
            comment_id:this.comment_id,
            comment:this.commentedit
          } )
          .then(() => {
            this.readmode_comment=true;
            this.refresh();
            this.$parent.refresh();
            this.isSaving = false;
          });
      }
    },
    
    delete_comment() {

      let data = {
          comment_id:this.comment_id,
          delete:true
      };

      this.$axios
        .post(this.$root.$data.server_base + '/api/comment/comment_save.php',
          data,
            {headers:{
                'Content-Type': 'application/json',
            }})
        .then(() => (this.dialog=false,this.$parent.refresh()))
    },

  },
  mounted () {
    this.messages = this.$root.$data.messages;
    this.refresh();
  },
  

}
</script>
