<template>
  <v-container fluid>
    <h2>Programmes / services</h2>
    <v-row justify="end" class="mb-4">
      <v-col md="8">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-3" color="primary" elevation="2" v-bind="attrs" v-on="on">
              <v-icon class="mr-1">mdi-plus</v-icon>Ajouter
            </v-btn>
          </template>
          <v-card class="pb-2">
            <v-card-title>
              <span class="text-h5">Programme / service</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-text-field label="Nom*" required></v-text-field>
                <tip-tap label="Description" />                   
                <v-select
                  :items="items_design"
                  label="Désignation pour les SEF*"
                  required
                ></v-select>
                <v-autocomplete
                  :items="items_livraison"
                   label="Mode de livraison des services"
                   multiple
                ></v-autocomplete>
                  
              </v-container>
              <small>*indique un champ obligatoire</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Annuler
              </v-btn>
              <v-btn color="primary" @click="dialog = false">
                <v-icon class="mr-1">mdi-plus</v-icon>Ajouter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col md="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      item-key="nom"
      :search="search"
      @click:row="dialog = true"
    ></v-data-table>
  </v-container>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'OngletStatut',

  data () {
    return {
      search: "",
      dialog: false,
      items_design: ["Désigné", "Presque désigné", "Identifié", "Sans objet"],
      items_livraison: ["En présentiel", "À domicile", "En virtuel", "Sans rendez-vous"],
      items: [
        { nom: "Administration Services à domicile et services communautaires - Services de soutien communautaire",
          designation: "Désigné", finance: "MSSLD", livraison: "En personne" },
        { nom: "Aide à la vie autonome pour personnes âgées à risque élevé",
          designation: "Presque désigné", finance: "MSSLD", livraison: "En personne" },
        { nom: "Popote roulante",
          designation: "Presque désigné", finance: "MSSLD", livraison: "À domicile" },
        { nom: "Repas en groupe - Repas communautaire",
          designation: "Désigné", finance: "MSSLD", livraison: "En personne" },
        { nom: "Service de transport",
          designation: "Désigné", finance: "MSSLD", livraison: "En personne" },
        { nom: "Gestion de cas – le programme SMILE aide les aînés à rester autonomes",
          designation: "Désigné", finance: "MSSLD", livraison: "En personne / Télétravail" },
        { nom: "Entretien ménager",
          designation: "Désigné", finance: "MSSLD", livraison: "À domicile" },
      ],
      headers: [
        {
          text: 'Nom du programme / service',
          align: 'start',
          
          value: 'nom',
        },
        { text: 'Désignation', value: 'designation' },
        { text: 'Financement', value: 'finance' },
        { text: 'Mode de livraison', value: 'livraison' },
      ],
        
    }    
  }
});
</script>