<template>
  <v-container fluid class="mt-4">
    <dossier-creation v-model="createDialog"
      @refresh="refresh"
    />
    <v-btn @click="createDialog = true" color="primary">
      <v-icon>mdi-plus</v-icon>{{ $tc('dossier.create') }}
    </v-btn>

    <v-checkbox v-model="showArchived"
      :label="$t('dossier.show_archived') + ' ('+archivedDossierCount+')'"
    >
    </v-checkbox>

    <v-data-table
      :items-per-page="25"
      :footer-props="{itemsPerPageOptions:[10,25,50,-1]}"
      class="mt-4"
      dense
      :headers="headers"
      :items="localeAwareItems"
      item-key="element_id"
      :loading="isLoading"
      @click:row="handleRowClick"
    >
      <template v-slot:[`item.name_lang`]="{ item }">
        <v-icon v-if="item.status=='archived'">mdi-archive-lock</v-icon>
        {{ item.name_lang }}
      </template>
      <template v-slot:[`item.last_update_time`]="{ item }">
        <span class="text-no-wrap">
          {{ moment.utc(item.last_update_time).local().fromNow() }}
        </span>
      </template>
      <template v-slot:[`item.dossier_status`]="{ item }">
        <v-icon :color="item.dossier_status_aes.colour">mdi-circle-medium</v-icon>
        {{ item.dossier_status }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import DossierCreation from '@/components/dossiers/DossierCreation.vue';
export default {
  name: 'ServiceProviderDossier',
  components: { DossierCreation },
  props: ['oziweb_wp_url'],
  data: function() {return {
    archivedDossierCount: 0,
    createDialog: false,
    dossiers:[],
    isLoading: false,
    get showArchived() {
      return localStorage.getItem('dossierList.showArchived') === "true"
        || false;
    },
    set showArchived(newVal) {
      localStorage.setItem('dossierList.showArchived', newVal);
    }
  }},

  watch: {
    showArchived() { this.refresh(); }
  },

  methods: {
    handleRowClick(row) {
      this.$router.push(
        { name: 'dossier_summary', params: { designation_dossier_id: row.designation_dossier_id }}
      );
    },    

    refresh() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/service_provider/dossier_list.php',
          { params: {
            service_provider_id: this.$route.params.service_provider_id,
            show_archived: this.showArchived
          }})
        .then(response => (
          this.$checkApiErrors(response.data),
          this.dossiers = response.data.dossiers,
          this.isLoading = false
        ));
      this.$axios
        .get(this.$root.$data.server_base + '/api/service_provider/get_archived_dossier_count.php',
          { params: {
            service_provider_id: this.$route.params.service_provider_id
          }})
        .then(response => (
          this.$checkApiErrors(response.data),
          this.archivedDossierCount = response.data.count
        ));
    },
    
  },
  computed: {
    localeAwareItems() {
      return this.dossiers
        .map(item => {
          return {...item,
            name_lang: this.$langobj.parse(item.name_lang),
            dossier_type_lang: this.$langobj.parse(item.dossier_type_lang),
            dossier_status: this.$langobj.parse(item.dossier_status_lang),
            designation_model_name: this.$langobj.parse(item.designation_model_name),
          };
        })
    },
    headers() {
      return [
        { text: this.$tc('dossier.label'), value: 'name_lang' },
        { text: this.$tc('dossier.type'), value: 'dossier_type_lang' },
        { text: this.$tc('dossier.model'), value: 'designation_model_name' },
        { text: this.$tc('dossier.start_date'), value: 'start_date' },
        { text: this.$tc('dossier.due_date'), value: 'due_date' },
        { text: this.$tc('dossier.date_of_designation'), value: 'date_of_designation' },
        { text: this.$tc('dossier.status'), value: 'dossier_status' },
        // { text: this.$tc('dossier.since'), value: 'last_update_time' },
      ];
    }
  },

  mounted () {
    this.refresh()
  }
}
</script>
