import { render, staticRenderFns } from "./ListDocumentsItem.vue?vue&type=template&id=480bee15&scoped=true&"
import script from "./ListDocumentsItem.vue?vue&type=script&lang=js&"
export * from "./ListDocumentsItem.vue?vue&type=script&lang=js&"
import style0 from "./ListDocumentsItem.vue?vue&type=style&index=0&id=480bee15&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "480bee15",
  null
  
)

export default component.exports