var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('ticket.create')))]),_c('v-form',{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"label":_vm.$t('ticket.type'),"items":(_vm.ticket_type_option || []).map((option) => ({
              text: _vm.$langobj.parse(option.name),
              value: option.ticket_type_key,
            })),"rules":[(v) => _vm.$validators.required(v)]},on:{"change":_vm.loadStatus},model:{value:(_vm.ticket_type_key),callback:function ($$v) {_vm.ticket_type_key=$$v},expression:"ticket_type_key"}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStatus),expression:"showStatus"}],staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"label":_vm.$t('ticket.status'),"items":(_vm.status_option || []).map((option) => ({
              text: _vm.$langobj.parse(option.name_lang),
              value: option.status_id,
            })),"rules":[(v) => _vm.$validators.required(v)]},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"label":_vm.$t('ticket.responsable'),"items":_vm.responsable_option,"item-text":"responsable_name","item-value":"user_id","rules":[(v) => _vm.$validators.required(v)]},model:{value:(_vm.responsable),callback:function ($$v) {_vm.responsable=$$v},expression:"responsable"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"label":_vm.$tc('provider.label', 1),"items":(_vm.service_provider_option || []).map((option) => ({
              text: _vm.$langobj.parse(option.name),
              value: option.service_provider_id,
            }))
            .sort((a,b) => a.text.localeCompare(b.text))},model:{value:(_vm.service_provider_id),callback:function ($$v) {_vm.service_provider_id=$$v},expression:"service_provider_id"}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEffectiveDate),expression:"showEffectiveDate"}]},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('toggable-date-picker',{attrs:{"native-label":"","label":_vm.$t('ticket.effective_at')},model:{value:(_vm.effective_at),callback:function ($$v) {_vm.effective_at=$$v},expression:"effective_at"}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDueDate),expression:"showDueDate"}],staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('toggable-date-picker',{attrs:{"native-label":"","label":_vm.$t('ticket.due_date'),"messages":_vm.dueDateMessages},model:{value:(_vm.due_date),callback:function ($$v) {_vm.due_date=$$v},expression:"due_date"}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPriority),expression:"showPriority"}]},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('priority-pulldown',{model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}})],1)],1),_c('v-text-field',{attrs:{"label":_vm.$t('ticket.title'),"rules":[(v) => _vm.$validators.required(v)]},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('tip-tap',{attrs:{"label":_vm.$t('ticket.details')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","loading":_vm.saving},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.$t('label.save'))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('label.cancel'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }