<template>
  <div class="row za-add">
    <div class="col-sm-12" style="padding-top:10px">
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label class="control-label">Titre du document * </label>
            <input ref='title' class="form-control" v-model="edit.title">
          </div>
        </div>
        <div class="col-sm-8">
          <div class="form-group">
            <label class="control-label">Adresse URL du document * </label>
            <div class="document-url-wrapper">
              <div class="has-input-overlay" style="flex: 1; margin-right: 0.5em;">
                <div class="input-group">
                  <div class="input-group-addon document-icon"><DocumentIcon :url="edit.url" /></div>
                  <input ref='url' class="form-control" v-model="edit.url" placeholder="p. ex. https://docs.google.com/..." autofocus v-on:focus="selectFieldContents($event)">
                </div>
                <a class="za-label-action input-overlay-right btn btn-xs" :disabled="!isUrlValid" :class="{ 'btn-primary': isUrlValid }" :href="edit.url" @click="preventLinkIfUrlInvalid($event)" target="_blank" style="white-space: nowrap">Ouvrir<i style="margin-left: 0.5em;" class="fas fa-external-link-alt fa-spacer" aria-hidden="true"></i></a>
              </div>
              <file-picker-button :config="gConfig" @picked="handleFilePicked" style="white-space: nowrap; padding-left: 1em; padding-right: 1em;" class="btn btn-default btn-xs">
                Google Drive&hellip;
              </file-picker-button>
            </div>
            <slot name="url-help-text"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12" style="padding-top:10px">
      <label class="control-label">Description</label>
      <div style="margin-bottom: 5px">
        <tip-tap v-model="edit.description" placeholder="Entrer une description du document (optionnel)..."></tip-tap>
      </div>
    </div>
    
    <div class="col-sm-12">
      <div>
        <button type="button" class="btn btn-sm" :class="{ 'btn-primary': canSave }" :disabled="!canSave" v-on:click="save()"><i class="fas fa-save" aria-hidden="true"></i>
          <template v-if="saveButtonLabel">
            {{ saveButtonLabel }}
          </template>
          <template v-else>
            <template v-if="isNew"> Ajouter</template>
            <template v-else> Enregistrer</template>
          </template>
        </button>
        <button v-if="showCancelButton" type="button" class="btn btn-link btn-sm" v-on:click="cancel()">
          <template v-if="cancelButtonLabel">
            {{ cancelButtonLabel }}
          </template>
          <template v-else>
            Annuler
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .input-group-addon.document-icon {
    padding-left: 8px;
    padding-right: 3px;
  }
  
  .document-url-wrapper {
    display: flex;
    flex-direction: row;
    
    .has-input-overlay {
      position: relative;
      
      .input-overlay-right {
        position: absolute;
        top: 50%;
        right: 0.5em;
        z-index: 10;
        transform: translateY(-50%);
        box-shadow: 0px 0px 5px 3px white;
        
        &[disabled] {
          color: #999;
        }
      }
    }
  }
</style>

<script>
  import DocumentIcon from '@/components/DocumentIcon.vue'
  import FilePickerButton from '@/components/FilePickerButton'
  // import Editor from '@/components/Editor.vue'
 
  export default {
    props: ['document', 'onlyUpdateParents', 'isNew', 'showCancelButton', 'saveButtonLabel', 'cancelButtonLabel'],
    data: function() { return {
      edit: {
        document_id: null,
        url: '',
        title: '',
        description: ''
      },
      messages: {},
      gConfig: {}
    }},
    created() {
      this.gConfig = {
        // The Browser API key obtained from the Google API Console.
        developerKey: this.$root.$data.google_drive_api_key,
  
        // The Client ID obtained from the Google API Console. Replace with your own Client ID.
        clientId: this.$root.$data.google_drive_client_id,
        
        // Scope to use to access user's drive.
        scope: 'https://www.googleapis.com/auth/drive.file'
      }
    },
    components: {
      DocumentIcon,
      FilePickerButton,
      // Editor
    },
    methods: {
      copyDocumentToEdit() {
        if (!this.document) return
        
        if (this.onlyUpdateParents) {
          const updatedDocument = this.document
          this.edit.ticket_id_list = updatedDocument.ticket_id_list
          this.edit.designation_dossier_id_list = updatedDocument.designation_dossier_id_list
          this.edit.service_id_list = updatedDocument.service_id_list
          this.edit.requirement_num_list = updatedDocument.requirement_num_list
        } else {
          this.edit = JSON.parse(JSON.stringify(this.document))
        }
      },
      
      save() {
        this.$emit('save', this.edit)
        this.done()
      },
      
      cancel() {
        this.done()
      },
      
      done() {
        this.$emit('done')
      },
      
      selectFieldContents(event) {
        const field = event.target
        window.setTimeout(function() {
          field.select()
        }, 10); // required for Safari
      },
      
      preventLinkIfUrlInvalid(event) {
        if (!this.isUrlValid) { event.preventDefault() }
      },
      
      handleFilePicked(data) {
        if(data.action !== 'picked' || data.docs.length === 0) { return }
        
        const doc = data.docs[0]
        
        this.edit.url = doc.url
        this.edit.title = doc.name
      }
    },
    watch: {
      document() {
        this.copyDocumentToEdit()
      },
      'edit.url': function(newVal) {
        this.$nextTick(function() {
          if (this.isUrlValid || newVal === '') {
            this.$emit('url-updated', newVal.trim())
          }
        })
      }
    },
    computed: {
      isModified() {
        return JSON.stringify(this.edit) !== JSON.stringify(this.document)
      },
      canSave() {
        if (this.edit.title === undefined || this.edit.title.trim() === '') return false
        if (!this.isUrlValid) return false
        if (this.isNew) return true
        return this.isModified
      },
      isUrlValid() {
        if (this.edit.url === undefined || this.edit.url.trim() === '') return false
        return this.edit.url.trim().match(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/)
      }
      
    },
    mounted() {
      this.copyDocumentToEdit()
      this.messages = this.$root.$data.messages
    }
  }
</script>