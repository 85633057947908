<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('dossier.import') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text :loading="isImporting" @click="handleImport">
            {{ $t('label.import') }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <v-form v-model="isFormValid" ref="form">    
          <p class="text-h6 mt-4">Données à importer</p>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="workplan_id"
                :items="preCreateInfo.workplans"
                :label="$t('report.period')"
                item-text="period"
                item-value="workplan_id"
                :rules="[(v) => $validators.required(v, [$t('report.period')])]"
              >
                <template v-slot:item="{ item }">
                  {{item.period}}
                  {{item.submitted_date ? ' (soumis le '+item.submitted_date+')' : '(non soumis)'}}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
    
        <v-card-actions>
          <v-btn color="primary" :loading="isImporting" @click="handleImport">
            {{ $t('label.import') }}
          </v-btn>
          <v-btn color="primary" text @click="dialog = false">
            {{ $t('label.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    
  </v-dialog>
</template>

<script>
  export default {
    props: ['value'],
    data () {
      return {
        workplan_id: null,        
        preCreateInfo: {},
        isLoading: false,
        isImporting: false,
        isFormValid: true,
      }
    },
    watch: {
      value() {
        if(this.value==true) {
          this.getPreCreationInfo();
        } else {
          this.$refs.form.reset();
        }
      }
    },
    computed: {
      dialog: {
        get () { return this.value },
        set (value) { this.$emit('input', value) }
      },
    },
    methods: {
      getPreCreationInfo() {
        this.isLoading = true;
        this.$axios
          .get(this.$root.$data.server_base + '/api/dossier/precreate_info.php',
            { params: {
              service_provider_id: this.$route.params.service_provider_id,
            }})
          .then(response => {
            this.preCreateInfo = response.data;
            this.isLoading = false
          });
      },
      handleImport() {
        if(!this.$refs.form.validate()) {
          const invalidField = this.$refs.form.$children.find((e) => !e.valid)
          if (invalidField)
            invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
          return
        }
        
        this.isImporting = true;
        let data = {
          service_provider_id: this.$route.params.service_provider_id,
          designation_dossier_id: this.$route.params.designation_dossier_id,
          workplan_id: this.workplan_id,
        }

        this.$axios
          .put(this.$root.$data.server_base + '/api/document/reimport_docs_from_ozi.php', data)
          .then(response => {
            // this.preConvertInfo = response.data;
            this.isImporting = false;
            this.$notify({type: 'success', group: 'std',
              title: 'Importation',
              text: 'Importé avec succès',
              duration: 4000
            });
            this.dialog = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.isImporting = false;
            this.$notify({type: 'error', group: 'std',
              title: 'Importation',
              text: 'Erreur du serveur',
              duration: 4000
            })
          });
      }
    },
  }
</script>