import Vue from 'vue';
import VueI18n, { Locale } from 'vue-i18n';
import axios from 'axios';
import moment from 'moment';

Vue.use(VueI18n);

const locales = [
  {
    value: 'fr',
    label: 'Français',
    fallback: ['en'],
  },
  {
    value: 'en',
    label: 'English',
    fallback: ['fr'],
  },
];

const i18n = new VueI18n({});

const setI18n = async (
  locale = localStorage.getItem('locale') ||
    navigator.language.substr(0, 2)
) => {
  // fallback to fr if locale is not valid/supported
  if (!locales.some((item) => item.value === locale)) {
    locale = 'fr';
  }

  const messages = await import(`@/i18n/${locale}.json`);
  i18n.setLocaleMessage(locale, messages.default);

  localStorage.setItem('locale', locale);
  i18n.locale = locale;
  i18n.fallbackLocale = locale;
  axios.defaults.headers.common['Accept-Language'] = locale;
  document.querySelector('html').setAttribute('lang', locale);

  moment.locale(locale);
};

export default i18n;
export { locales, setI18n };