<template>
  <v-row>
    <v-col class="pt-0 my-0" cols="12">
      <div v-if="filteredItems.length == 0">
        <em class="grey--text">{{ $t('error.no_content') }}</em>
      </div>
      <v-card
        v-else
        flat
        class="d-flex align-center"
        v-for="element in filteredItems"
        :key="'a'+element.element_def_id"
      >
        <v-icon v-if="element.status == 'na'" class="mt-1 mr-2">
          mdi-selection-remove
        </v-icon>
        <v-checkbox
          v-else
          v-model="element.checked"
          class="my-2"
          hide-details
          @change="commitChange(element)"
        />
        
        <div class="my-1">
          <v-menu
            v-model="element.showMenu"
            absolute
            offset-y
            style="max-width: 600px"
            :disabled="!priv('agent')"
          >
            <template v-slot:activator="{ on, attrs }">
              <p
                :style="[priv('agent') ? {} : {cursor: 'text'}]"
                v-bind="attrs"
                v-on="on"
                class="mb-0 mt-1"
                :class="{'text-decoration-line-through': element.status == 'na'}"
              >
                {{$langobj.parse(element.text)}}
                <v-tooltip
                  v-if="element.obligation == 'best_practice'"
                  bottom
                  open-delay="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="green">mdi-seal-variant</v-icon>
                  </template>
                  <span>{{ $t('designation_model.element.obligation.best_practice') }}</span>
                </v-tooltip>
              </p>
            </template>
            <v-list dense>
              <v-list-item
                v-if="element.status != 'na' && !element.checked"
                @click="handleToggle(element)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-checkbox-marked-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('designation_model.element.mark.complete') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="element.status != 'na' && element.checked"
                @click="handleToggle(element)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-checkbox-blank-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('designation_model.element.mark.incomplete') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="element.status != 'na'"
                @click="handleToggleActive(element)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-selection-remove</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('designation_model.element.mark.na') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="element.status == 'na'"
                @click="handleToggleActive(element)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-plus-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('designation_model.element.mark.active') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <div
            v-if="element.checked && element.modified_at && element.status != 'na'"
            class="signature grey--text"
          >
            {{moment.utc(element.modified_at).local().format('LLL')}} - 
            {{element.modified_by_user.first_name}}
            {{element.modified_by_user.last_name}}
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.doc_cat {
  border-right: solid 2px #fbab26;
  border-top: solid 2px #fbab26;
}
.req_dot {
  font-size: 0.8em;
  font-weight: bold;
}
.signature {
  font-size: 0.8em;
  font-style: italic;
}
</style>

<script>
import {privileges} from '@/mixins/privileges.js';

export default {
  mixins: [privileges],
  components: {},
  name: 'WorkplanSection',
  props: ['readonly', 'elements', 'title'],
  
  data: function() {return {}},
  computed: {
    filteredItems() {
      const showNaItems = this.priv('agent');
      return this.elements.map((e) => ({ showMenu: false, ...e }))
        .filter(e => { 
          return (e.status != 'na' || showNaItems)
      });
    }
  },
  
  methods: {
    handleToggle(item) {
      item.checked = !item.checked;
      this.commitChange(item);
    },
    handleToggleActive(item) {
      item.status = item.status=='na' ? 'active' : 'na';
      this.commitChange(item);
    },
    commitChange(item) {
      this.$axios
        .put(this.$root.$data.server_base + '/api/workplan_element/upsert.php', 
        {
          service_provider_id: Number(this.$route.params.service_provider_id),
          designation_dossier_id: this.$route.params.designation_dossier_id,
          element_def_id: item.element_def_id,
          checked: item.checked,
          status: item.status,
        })
        .then((response => {
          this.$emit('refresh');
        }))  
    },
  },
  mounted() {
  }
}
</script>