<template>
  <div>
    <delete-dialog v-model="deleteDialog"
      v-on:delete-object="handleDelete"
    >
    </delete-dialog>
    <router-link class="text-decoration-none" :to="{name: 'admin-design-models'}">
      <span class="text-h6 text--primary">←&nbsp;</span>
      {{ $t('label.return_to_list') }}
    </router-link>
  
    <v-row class="mt-2">
      <v-col cols=3>
        <div>
          <v-card-text>
            <div>{{ $langobj.parse(internal.name) }}</div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <div>
              <v-btn class="px-1" color="primary" text @click="deleteDialog = !deleteDialog">
                {{ $t('label.delete') }}
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-col>
      <v-col cols=9>
        <v-card>
          <v-card-text>
            <v-row
              v-for="locale in $locales"
              :key="locale.value"
              class="mt-2"
            >
              <v-col cols=1>
                <p class="text-h4 primary--text">{{ locale.toLocaleUpperCase() }}</p>
              </v-col>
              <v-col cols=11>
                <v-text-field
                  v-model="internal.name[locale]"
                  :label="$langobj.label($t('designation_model.name'), locale)"
                  :loading="isLoading"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="handleSave" :disabled="disableSave">
              {{ $t('label.save') }}
            </v-btn>
            <v-btn color="primary" text @click="handleCancel">
              {{ $t('label.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            <v-row>
              <v-col>
                {{ $tc('designation_model.requirement.label', 2)}}
              </v-col>
              <v-col class="text-right">
                <v-btn @click="handleCreateRequirement" color="primary" text>
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('label.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="headers"
              :items="requirements"
              item-key="key"
              :loading="isLoading"
              @click:row="handleRequirementRowClick"
            >
              <template v-slot:[`item.name`]="{ item }">
                {{ $langobj.parse(item.name) }}
              </template>
              <template v-slot:[`item.item_type`]="{ item }">
                {{ $t('designation_model.requirement.item_type.'+item.item_type) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            <v-row>
              <v-col>
                {{ $tc('designation_model.element.label', 2)}}
              </v-col>
              <v-col class="text-right">
                <v-btn @click="handleCreateElement" color="primary" text>
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('label.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="elementHeaders"
              :items="elements"
              item-key="element_def_id"
              :loading="isLoading"
              @click:row="handleElementRowClick"
            >
              <template v-slot:[`item.text`]="{ item }">
                {{ $langobj.parse(item.text) }}
              </template>
              <template v-slot:[`item.obligation`]="{ item }">
                {{ $t('designation_model.element.obligation.'+item.obligation) }}
              </template>
              <template v-slot:[`item.type`]="{ item }">
                {{ $t('designation_model.element.type.'+item.type) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card> 
      </v-col>
    </v-row>

  </div>  
</template>

<script>
import _ from 'lodash';
import DeleteDialog from '@/components/framework/DeleteFromListDialog.vue';

export default {
  name: 'DesignationModelDetails',
  components: { DeleteDialog },
  data: function() {
    return {
      deleteDialog: false,
      storeData: null,
      internal: {id: null, state: null, name: {} },
      requirements: [],
      elements: [],
      isLoading: true,
    }
  },

  computed: {
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    },
    headers() {
      return [
        { text: this.$tc('designation_model.requirement.identifier'), value: 'identifier' },
        { text: this.$tc('designation_model.requirement.name'), value: 'name' },
        { text: this.$t('designation_model.requirement.item_type.label'), value: 'item_type' },
        { text: this.$tc('designation_model.requirement.sort'), value: 'sort' },
      ];
    },
    elementHeaders() {
      return [
        { text: this.$t('designation_model.element.requirement'), value: 'requirement_identifier' },
        { text: this.$tc('designation_model.element.text'), value: 'text' },
        { text: this.$t('designation_model.element.obligation.label'), value: 'obligation' },
        { text: this.$t('designation_model.element.type.label'), value: 'type' },
        { text: this.$tc('designation_model.element.sort'), value: 'sort' },
      ];
    }
  },

  methods: {
    getDataFromApi() {
      this.isLoading = true;
      this.$axios
        .get("/api/admin/designation_model/get.php",
          {params:{id: this.$route.params.designation_model_id}})
        .then(response => {
          this.storeData = response.data.model;
          this.internal = _.cloneDeep(response.data.model);
          this.isLoading = false;
      });
      this.$axios
        .get("/api/admin/requirement_def/list.php",
          {params:{designation_model_id: this.$route.params.designation_model_id}})
        .then(response => {
          this.requirements = response.data.requirements;
      });
      this.$axios
        .get("/api/admin/element_def/list.php",
          {params:{designation_model_id: this.$route.params.designation_model_id}})
        .then(response => {
          this.elements = response.data.elements;
      });
    },
    handleDelete() {
      this.$axios
        .delete('/api/admin/designation_model/remove.php', {
          params: { id: this.$route.params.designation_model_id }})
        .then(response => {

        });
    },
    handleSave() {
      this.$axios
        .put("/api/admin/designation_model/update.php", this.internal,
           { headers: {'Content-Type': 'application/json' }})
        .then((response) => {
          this.storeData = response.data.requirements;
          this.internal = _.cloneDeep(response.data.model);
          this.isLoading = false;
        });
    },
    handleCancel() {
      this.getDataFromApi();
    },
    handleCreateRequirement() {
      this.$router.push(
        {name: 'admin-requirement-def-detail', params: { requirement_def_id: 'cr' }}
      );
    },
    handleRequirementRowClick(row) {
      this.$router.push(
        {
          name: 'admin-requirement-def-detail',
          params: { requirement_def_id: row.requirement_def_id }
        }
      );
    },
    handleCreateElement() {
      this.$router.push(
        {name: 'admin-element-def-detail', params: { element_def_id: 'cr' }}
      );
    },
    handleElementRowClick(row) {
      this.$router.push(
        {
          name: 'admin-element-def-detail',
          params: { element_def_id: row.element_def_id }
        }
      );
    }
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>