<template>
  <div>
    <v-card :loading="appreciationLoading" outlined class="mb-2">
      <v-card-text>
        <div class="text-h6">{{$t('requirement.interpretation')}}</div>
        <div v-html="requirement.interpretation"></div>
        <div v-if="appreciation" class="mt-3">
          <div class="text-h6">{{$t('requirement.appreciation')}}</div>
          <Appreciation :appreciation="appreciation" readonly> </Appreciation>
        </div>

      </v-card-text>
    </v-card>
    <ListComment
      :parent_id="this.requirement.requirement_id"
      parent_class="requirement_conversation"
      :readonly="readonly"
    ></ListComment>
  </div>
</template>

<script>
import Vue from 'vue';
import ListComment from '@/components/comment/ListComment.vue';
import Appreciation from '@/components/appreciation/Appreciation.vue';

export default Vue.extend({
  name: 'TabConversation',
  components: {ListComment, Appreciation},

  props: ['requirement','readonly'],
  data () { return {
    appreciation: null,
    appreciationLoading: false,
  }},
  methods: {
    handleSaveOneParam(key, value) {
      this.$axios
        .put(this.$root.$data.server_base + '/api/requirement/update.php',
          { requirement_id: this.$route.params.requirement_id,
            service_provider_id: this.$route.params.service_provider_id,
            [key]: this.requirement[key]})
        .then((response => {
          this.$refs[key].markAsSaved();
        }))
    },
    refresh() {
      this.appreciationLoading = true;
      this.appreciation = null;
      this.$axios
        .get(this.$root.$data.server_base + '/api/requirement/get_published_appreciation.php',
          { params: {
            service_provider_id: this.$route.params.service_provider_id,
            requirement_id: this.$route.params.requirement_id,
          }})
        .then(response => {
          this.appreciation = response.data.appreciation;
          this.appreciationLoading = false;
        });
    },
  },
  mounted () {
    this.refresh();
  },
  watch: {
    requirement(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.refresh()
      }
    }
  },
});
</script>