<template>
  <div>
    <report-row :label="$tc('report.bdssf34.regions', report.regions_served.length)">
      <ul>
        <li v-for="region in report.regions_served" :key="region">{{ region }}</li>
      </ul>
    </report-row>

    <report-row :label="$t('report.bdssf34.population_served')">
      NA
    </report-row>

    <report-row :label="$t('report.bdssf34.clientele_description')">
      {{ $langobj.parse(report.clientele_lang) }}
    </report-row>
    
    <report-row :label="$t('report.fls34.population')">
      NA
    </report-row>
  </div>
</template>

<script>
import Vue from 'vue';
import ReportPopulations from '@/components/report/reportPopulations.vue';
import ReportClientele from '@/components/report/reportClientele.vue';
import ReportRow from '@/components/report/reportRow.vue';

export default Vue.extend({
  props: ['report'],
 
  components: {
    ReportPopulations,
    ReportClientele,
    ReportRow,
  },
});
</script>

<style scoped lang="scss">
</style>