<template>
  <v-dialog v-model="propModel" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ $t('label.download') }}
      </v-card-title>

      <v-card-text class="mt-3">
        {{ $t('document.download.collecting') }}
        <v-progress-linear
          :indeterminate="loading"
          rounded
          height="6"
        ></v-progress-linear>
      </v-card-text>

      <v-card-text class="mt-3">
        <div v-if="!loading">
          {{ $t('document.download.collect_success', {count: successCount}) }}
        </div>
        <div v-if="failedCount">
          {{ $t('document.download.collect_failed', {count: failedCount}) }}
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="propModel = false"
        >
          {{ $t('label.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="handleDownloadArchive"
          :loading="saving"
          :disabled="!(archiveFile && successCount)"
        >
          <v-icon class="mr-2">mdi-folder-download-outline</v-icon>
          {{ $t('label.download') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import DocumentUtils from '@/utils/DocumentUtils';

export default Vue.extend({
  components: {
  },

  props: {
    value: false,
    title: {
      type: "",
    },
    id: {
      type: ""
    },
  },

  data() { return {
    loading: true,
    saving: false,
    successCount: 0,
    failedCount: 0,
    archiveFile: null,
  }},

  computed: {
    propModel: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },

  watch: {
    value() {
      if(this.value === true) {
        this.handlePrepareFiles();
      }
    }
  },

  methods: {
    handlePrepareFiles() {
      this.loading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/dossier/get_documents_in_archive.php',
          { params: {
            service_provider_id: this.$route.params.service_provider_id,
            designation_dossier_id: this.$route.params.designation_dossier_id
        }})
        .then(response => {
          this.loading = false;
          this.archiveFile = response.data.archive;
          this.successCount = response.data.files;
          this.failedCount = response.data.failures;
        })
    },
    handleDownloadArchive() {
      this.saving = true;
      DocumentUtils.downloadTmpFile(this.archiveFile);
      this.saving = false;
      this.failedCount = this.successCount = 0;
      this.propModel = false;
    }
  },
});
</script>

<style scoped lang="scss">
</style>
