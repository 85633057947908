<template>
  <div class="row">
    <div class="col-sm-12" >
      <div class="form-group">
        <div class="text-h6 mt-2 mb-2">{{ $tc('comment.label', 2) }}</div>
        <Comment class="mb-4" v-show="!readonly" :parent_id="parent_id" :parent_class="parent_class" :comment_id="0"></Comment>
        <div v-for="comment in comments" :key="comment.comment_id">
            <Comment :parent_id="parent_id" :parent_class="parent_class" :comment_id="comment.comment_id" :readonly="readonly"></Comment>
        </div>  
      </div>

    </div>
    
  </div>
</template>
<style>
.created_at {
  color: #999;
  font-size: 85%;
}
</style>

<script>

import axios from 'axios';
import Comment from '@/components/comment/Comment.vue'

export default {
  name: 'ListComment',
  props: ['parent_id','parent_class','readonly'],

  data: function() {return {
    
    comments:[],
   
    }
  },
  components: {
    Comment
  },
  methods:
  {
    refresh: function() {
      if(this.parent_id>0)
      {
        axios
          .get(this.$root.$data.server_base + '/api/comment/comments.php',
            { params: {parent_id:this.parent_id,parent_class: this.parent_class}})
          .then(response => {
            this.comments = response.data.comments
          })
      }
    },
   
  },
  mounted () {
    this.refresh();
  },
  watch: {
    parent_id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.refresh()
      }
    }
  }

}
</script>
