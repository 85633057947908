const empty =
  {
    value: null,
    label: { en: "Not specified", fr: "Non spécifiée" },
    iconClass: '',
    color: ''
  }

export const priorities = [
  {
    value: 4, 
    label: { en: "Very High", fr: "Très haute" },
    iconClass: 'mdi-chevron-double-up',
    color: 'rgb(199, 4, 4)'
  },
  {
    value: 3, 
    label: { en: "High", fr: "Haute" },
    iconClass: 'mdi-chevron-up',
    color: 'rgb(199, 4, 4)'
  },
  {
    value: 2, 
    label: { en: "Medium", fr: "Moyenne" },
    iconClass: 'mdi-equal',
    color: '#faab26'
  },
  {
    value: 1, 
    label: { en: "Low", fr: "Basse" },
    iconClass: 'mdi-chevron-down',
    color: 'primary'
  },
]

const prioritiesByValue = {
  4: priorities[0],
  3: priorities[1],
  2: priorities[2],
  1: priorities[3]
}

export const getPriorityFromValue = function(value) {
  if (!value) { return empty }
  return prioritiesByValue[value]
}

export const prioritiesMixin = {
  data: function() {
    return {
      priorities
    }
  },
  methods: {
    getPriorityFromValue
  }
}