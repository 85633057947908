<template>
  <div>
    <ul>
      <li v-for="sector in sectors" :key="sector.id">
        {{ $langobj.parse(sector) }}
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['sectors'],
  computed: {
  },
});
</script>