<template>
  <div>
    <router-link class="text-decoration-none" :to="{name: 'admin-providers'}">
      <span class="text-h6 text--primary">←&nbsp;</span>
      {{ $t('label.return_to_list') }}
    </router-link>
    <h2>{{ isCreate ? $t('provider.create') : $t('provider.modify') }}</h2>
    <v-row class="mt-2">
      <v-col cols=3>
        <div>
          <v-card-text>         
            <div class="mt-2 grey--text">{{ $langobj.parse(internal.name) }}</div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text v-if="!isCreate">
            <div>
              <v-btn class="px-1" color="primary" text @click="$root.notDone">
                <v-icon class="mr-1">mdi-delete</v-icon> {{ $t('label.delete') }}
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-col>

      <v-col cols=9>
        <v-card>
          <v-form v-model="isFormValid" ref="form">
            <v-card-text>
              <v-row
                v-for="locale in $locales"
                :key="locale.value"
                class="mt-2"
              >
                <v-col cols=1>
                  <p class="text-h4 primary--text">{{ locale.toLocaleUpperCase() }}</p>
                </v-col>
                <v-col cols=11>
                  <v-text-field
                    spellcheck="false"
                    v-model="internal.name[locale]"
                    :label="$langobj.label($t('provider.name'), locale)"
                    :rules="[(v) => $validators.required(v, [$t('provider.name')])]"
                    :loading="loading"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-select
                :label="$t('provider.designation_status')"
                v-model="internal.designation_status_id"
                :items="
                  (status_options || []).map((option) => ({
                    text: $langobj.parse(option.name),
                    value: option.id,
                  }))
                "
                :rules="[(v) => $validators.required(v, [$t('provider.designation_status')])]"
                :loading="loading"
              ></v-select>

              <v-select
                :label="$t('provider.region')"
                v-model="internal.region_id"
                :items="
                  (region_options || []).map((option) => ({
                    text: $langobj.parse(option.name),
                    value: option.region_id,
                  }))
                "
                :rules="[(v) => $validators.required(v, [$t('provider.region')])]"
                :loading="loading"
              ></v-select>

               <v-select
                :label="$t('provider.ozi_agency')"
                v-model="internal.agency_id"
                :items="
                  (ozi_agency_items || []).map((option) => ({
                    text: $langobj.parse(option.name),
                    value: option.agency_id,
                  }))
                "
                :loading="loading"
                
                clearable
                no-data-text="Choisir la région en premier"
              ></v-select>

              <v-select 
                :label="$t('provider.state')"
                v-model="internal.state"
                :loading="loading"
                :items="status_items"
                :rules="[(v) => $validators.required(v, [$t('provider.state')])]"
              ></v-select>        
          
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn color="primary" @click="handleSave" :disabled="disableSave">
              {{ $t('label.save') }}
            </v-btn>
            <v-btn color="primary" text @click="handleCancel">
              {{ $t('label.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>  
</template>

<script>
import _ from 'lodash';
import ObjectUtils from '@/utils/ObjectUtils';

export default {
  name: 'AdminServiceProviderDetails',
  data: function() {
    return {
      storeData: null,
      internal: {region_id: null, name: {}},
      loading: false,
      isFormValid: true,

      region_options: [],
      status_options: [],
      ozi_agency_options: [],
    }
  },

  computed: {
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    },
    isCreate() {
      return this.$route.params.service_provider_id == 'cr';
    },
    status_items() { return [
      { text: this.$tc('label.active'), value: 1 },
      { text: this.$tc('label.inactive'), value: 2 },
    ]},
    ozi_agency_items() { return (this.ozi_agency_options || [])
      .filter(agency => (agency.region_id == this.internal.region_id))
    }
  },

  methods: {
    getDataFromApi() {
      this.$axios
        .get("/api/service_provider/options.php")
        .then(response => {
          this.region_options = response.data.regions;
          this.status_options = response.data.designation_status;
          this.ozi_agency_options = response.data.ozi_agencies;
      });

      if(this.$route.params.service_provider_id == 'cr')
        return;
      this.loading = true;
      this.$axios
        .get("/api/service_provider/get.php",
          {params:{service_provider_id: this.$route.params.service_provider_id}})
        .then(response => {
          this.storeData = response.data.provider;
          this.internal = _.cloneDeep(this.storeData);
          this.loading = false;
      });
    },
  
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }        
      let obj = {};
      let api = "";
      if(this.isCreate) {
        api = "/api/service_provider/create.php";
        obj = this.internal;
      }
      else {
        api = "/api/service_provider/update.php";
        obj = ObjectUtils.getObjectDiff(this.internal, this.storeData);  
        obj.service_provider_id = this.internal.service_provider_id;
      }

      this.$axios
        .put(api, obj, { headers: {'Content-Type': 'application/json' }})
        .then((response) => {          
          this.$router.push({name: 'admin-providers'});
        });
    },
    handleCancel() {
      if(this.isCreate || this.disableSave) {
        this.$router.push({name: 'admin-providers'});
      } else {
        this.getDataFromApi();
      }
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>