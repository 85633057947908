<template>
  <div>
    <h2>{{ $t('admin.designation_models') }}</h2>
    <v-data-table
      dense
      :headers="headers"
      :items="models"
      item-key="id"
      :loading="isLoading"
      @click:row="handleRowClick"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ $langobj.parse(item.name) }}
      </template>
    </v-data-table>
  </div>  
</template>

<script>
import axios from 'axios';

export default {
  data: function() {
    return {
      models: [],
      isLoading: true,
    }
  },

  computed: {
    headers() {
      return [
        { text: this.$tc('designation_model.name'), value: 'name' },
        { text: this.$tc('designation_model.state'), value: 'state' },
      ];
    }
  },

  methods: {
    getDataFromApi() {
      this.isLoading = true;
      axios
        .get(this.$root.$data.server_base + "/api/admin/designation_model/list.php",
          {params:{}})
        .then(response => {
          this.models = response.data.models;
          this.isLoading = false
        })
    },
    handleRowClick(row) {
      console.log(row);
      this.$router.push(
        { name: 'admin-design-model-details', params: { designation_model_id: row.id }}
      );
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>