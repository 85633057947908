import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n, { locales } from '@/i18n';
import axios from 'axios';

import 'jquery';
import 'bootstrap';

import '@/components/framework';
import ValidateUtils from './utils/ValidateUtils';
import LocaleUtils from './utils/LocaleUtils';

// import Chart from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// Chart.plugins.unregister(ChartDataLabels);

// import "./assets/bootstrap/css/bootstrap.css"
// import "./assets/porto/css/theme.css"
// import "./assets/porto/css/theme-custom.css"
import {messages} from './assets/porto/js/validate.js'

import { getClientTimezoneOffsetInHoursNegative } from './assets/za_libs/timezone.js'

Vue.config.productionTip = false

import moment from 'moment';
moment.locale('fr-CA');
Vue.prototype.moment = moment;

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill(); // support for el.scrollIntoView({ behavior: 'smooth' }), el.scrollTo({ behavior: 'smooth' })

import Notifications from 'vue-notification';

import vuetify from '@/plugins/vuetify';

Vue.use(Notifications);

Vue.prototype.$messages = messages;
Vue.prototype.$checkApiErrors = function(response) {
  if(response && response.error) {
    this.$notify({type: "error",group: 'std',
            title: 'Server Error',
            text: response.error,
            duration: 5000
    });
    return true;
  }
  if(response && response.success) {
    this.$notify({type: "success",group: 'std',
            title: 'Success',
            text: response.success
    });
  }
  if(response && response.denied_provider) {

    router.push({name:'liste_fournisseurs'}); 
    this.$notify({type: "error",group: 'std',
            title: 'Server Error',
            text: response.denied_provider,
            duration: 5000
    });
    return true;

  }
  if(response && response.denied_service) {

    router.push({name:'liste_fournisseurs'}); 
    this.$notify({type: "error",group: 'std',
            title: 'Server Error',
            text: response.denied_service,
            duration: 5000
    });
    return true;

  }
  if(response && response.denied_dossier) {

    router.push({name:'liste_fournisseurs'}); 
    this.$notify({type: "error",group: 'std',
            title: 'Server Error',
            text: response.denied_dossier,
            duration: 5000
    });
    return true;

  }
  if(response && response.denied_ticket) {

    router.push({name:'liste_billets'}); 
    this.$notify({type: "error",group: 'std',
            title: 'Server Error',
            text: response.denied_ticket,
            duration: 5000
    });
    return true;

  }

  if(response && response.denied_requirement) {

    router.push({name:'liste_fournisseurs'}); 
    this.$notify({type: "error",group: 'std',
            title: 'Server Error',
            text: response.denied_requirement,
            duration: 5000
    });
    return true;

  }
  
  return false;
}

//  in case we reload
axios.defaults.headers.common['X-Custom-Authorization'] = localStorage.jwt;

axios.interceptors.response.use((response) => {
    if(response.data.server_error) {
      Vue.notify({type: "error", group: 'std',
        title: i18n.t('server_error.title'),
        text: i18n.t('server_error.'+response.data.server_error),
        duration: 5000
      });
    }
    if(response.data.server_success) {
      Vue.notify({type: "success", group: 'std',
        title: i18n.t('server_success.title'),
        text: i18n.t('server_success.'+response.data.server_success),
        duration: 4000
      });
    }
    return response
  },
  function (error) {
    // intercept the global error
    // let originalRequest = error.config
    if (error.response && error.response.status === 401) {
      router.push({name:'login'}); 
    }
    // TODO this should be done more eloquently
    if (error.response && error.response.status === 500) {
      console.log(error.response.data); 
      Vue.notify({type: "error" ,group: 'std',
            title: 'Server Error',
            text: "Status: "+error.response.status,
            duration: 5000
    });
    return true;
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  config.headers.common['ZA-Client-Timezone-Offset-Hours'] = getClientTimezoneOffsetInHoursNegative();
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axios.defaults.baseURL = process.env.VUE_APP_server_base || "";
Vue.prototype.$axios = axios;

Vue.mixin(
  Vue.extend({
    computed: {
      $locale() {
        return i18n.locale;
      },

      $locales() {
        return locales.map((locale) => locale.value);
      },

      $validators() {
        return ValidateUtils.validators;
      },

      $langobj() {
        return LocaleUtils.langobj;
      },
    },
  })
);

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
  data() { return{
    firstName: "(inconnu)",
    messages:messages,
    server_base: process.env.VUE_APP_server_base || "",
    privilege: null,
    // server_base: "",
    google_drive_api_key: process.env.VUE_APP_google_drive_api_key || "",
    google_drive_client_id: process.env.VUE_APP_google_drive_client_id || "",
  }},
  methods: {
    notDone() {
      this.$notify({type: "warn",group: 'std',
            title: 'Fonction incomplète',
            text: "Cette fonction n'a pas été encore activée",
            duration: 4000 });
    }
  },
}).$mount('#app');
