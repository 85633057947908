<template>
  <div 
    @dragover.prevent
    @drop.prevent
    @dragover="dropZoneActive=true"
    @dragleave="dropZoneActive=false"
    @drop="dropFile"
    class="drop-zone"
    :class="{'active': dropZoneActive}"
  >
    <edit-document-dialog
      v-model="editDialog"
      :id="documentIdToEdit" 
      @refresh="$emit('refresh')"
    />
    <delete-from-list-dialog
      v-model="deleteDialog"
      :title="$t('document.delete')"
      :id="documentIdToEdit"
      @delete-object="reallyDoDeleteItem"
    >
      {{ $t('document.delete_requirement_explanation') }}
    </delete-from-list-dialog>
    <upload-document-dialog
      v-model="addDialog"
      :requirement_id="parseInt($route.params.requirement_id)"
      :draggedFiles="draggedFiles"
      @refresh="$emit('refresh')"
    />
    
    <div class="text-h6 mt-1">{{ label }}
      <v-btn text color="primary" @click="addDialog=!addDialog">
        <v-icon>mdi-file-plus</v-icon>{{ $t('label.add') }}
      </v-btn>
    </div>
    <v-skeleton-loader v-if="!filteredDocuments" type="text"></v-skeleton-loader>
    <v-simple-table class="mb-2" dense v-else-if="filteredDocuments && filteredDocuments.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t('document.title') }}</th>
            <th v-if="priv('agent')" class="text-center">{{ $t('document.audience') }}</th>
            <th class="text-center" style="min-width: 110px; width: 110px;">{{ $t('document.created') }}</th>
            <th v-if="!readonly" class="text-center" style="min-width: 70px; width: 70px;">
              {{ $t('label.action') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in filteredDocuments"
            :key="item.document_id"
          >
            <td>
              <v-icon v-if="item.obsolete_at" color="red" small>mdi-clock-alert</v-icon>
              <v-icon color="primary">{{ iconForFilename(item.url) }}</v-icon>
              <a class="text-decoration-none" @click="handleFileDownload(item)">
                {{ item.title }}
              </a>
            </td>
            <td class="text-center" v-if="priv('agent')">
              <v-icon v-if="item.audience=='all'" small class="mr-2">mdi-eye</v-icon>
            </td>
            <td class="text-center">
              {{ item.created_at ? item.created_at.substring(0, 10) : "" }}
            </td>
            <td v-if="!readonly" class="text-center">
              <v-icon small class="mr-1" @click="handleEditItem(item)">mdi-pencil</v-icon>
              <v-icon small @click="handleDeleteItem(item)">mdi-delete</v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p v-else class="text--disabled mb-2"><em>{{ $t('report.no_document') }}</em></p>
  </div>
</template>
<style lang="scss" scoped>
.drop-zone {
  margin: -10px;
  padding: 7px;
  border: 3px solid white;

  &.active {
    border-radius: 6px;
    border: 3px dashed grey;
    background-color: #eee;
  }
}
</style>

<script>
import Vue from 'vue';
import EditDocumentDialog from '@/components/document/EditDocumentDialog.vue';
import UploadDocumentDialog from '@/components/document/UploadDocumentDialog.vue';
import DeleteFromListDialog from '@/components/framework/DeleteFromListDialog.vue';
import DocumentUtils from '@/utils/DocumentUtils';
import {privileges} from '@/mixins/privileges.js';

export default Vue.extend({
  name: "EditableDocumentList",
  mixins: [privileges],
  props: ['documents', 'url_prefix','readonly', 'label', 'requirement'],
  components: {
    EditDocumentDialog,
    UploadDocumentDialog,
    DeleteFromListDialog
  },
  data() { return {
    editDialog: false,
    addDialog: false,
    deleteDialog: false,
    documentIdToEdit: null,
    dropZoneActive: false,
    draggedFiles: [],
  }},
  computed: {
    filteredDocuments() {
      if(this.priv('agent'))
        return this.documents;
      return this.documents.filter(e => (e.audience == "all"))
    }
  },
  methods: {
    dropFile(event) {
      this.dropZoneActive = false;
      this.draggedFiles = event.dataTransfer.files;
      this.addDialog = true;
    },
    handleEditItem(item) {
      this.documentIdToEdit = item.document_id;
      this.editDialog = true;
    },
    handleDeleteItem(item) {
      this.documentIdToEdit = item.document_id;
      this.deleteDialog = true;
    },
    handleFileDownload(item) {
      DocumentUtils.downloadFile(item, '' /*this.url_prefix*/);     
    },
    async reallyDoDeleteItem() {
      await DocumentUtils.api.removeFromRequirement(
        this.documentIdToEdit,
        this.requirement.requirement_id
      );
      this.$emit('refresh');
    },
    // TODO - reconcile with mdiIcon forFile
    iconForFilename(str) {     
      switch(str) {
        case (str.match(/\.pdf$/) || {}).input:
          return "mdi-file-pdf-box";
          
        case (str.match(/\.xls(s)?/) || {}).input:
          return "mdi-file-excel";
          
        case (str.match(/\.doc(x)?/) || {}).input:
          return "mdi-file-word";
       
        case (str.match(/\.ppt(x)?/) || {}).input:
          return "mdi-file-powerpoint";

        case (str.match(/\.jpg$/) || {}).input:
        case (str.match(/\.jpeg$/) || {}).input:
        case (str.match(/\.png$/) || {}).input:
        case (str.match(/\.gif$/) || {}).input:
        case (str.match(/\.svg$/) || {}).input:
          return "mdi-file-image";

        case (str.match(/\.txt$/) || {}).input:
          return "mdi-text-box";
      }
      return "mdi-file";
    },
  },
});
</script>