import i18n from '@/i18n';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params), // will look in i18n files for $vuetify object for translations - https://vuetifyjs.com/en/customization/internationalization#create-translation
  },
  theme: {
    themes: {
      light: {
        primary: '#226cbf',
      },
    },
  },
});
