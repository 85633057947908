<template>
  <v-container fluid>
    <h1>Information générale sur l'organisation</h1>
    <v-form
    ref="form"
    lazy-validation
    >
      <v-text-field
        v-model="nom"
        label="Nom"
        required
      ></v-text-field>
      <v-text-field
        v-model="lettre_patentes"
        label="Nom selon les lettres patentes"
        required
      ></v-text-field>
      <v-text-field
        v-model="adresse"
        label="Adresse principale / Siège social"
        required
      ></v-text-field>
      <tip-tap
        v-model="description"
        label="Historique brève"        
    />
    </v-form>
  </v-container>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'InfoGenerale',
  data() {return {
    nom: '',
    lettre_patentes: '',
    adresse: '',
    description: '',
  }},
});
</script>
