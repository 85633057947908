<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Importation des données d'un dossier</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text :loading="isImporting" @click="handleImport">Importer</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      
      <v-list three-line subheader>
        <v-subheader>À importer dans le dossier {{dossier.name}}</v-subheader>
         <v-list-item v-if="preConvertInfo.current_requirements">
          <v-alert outlined type="error">
            Il semble déjà y avoir des exigences qui auraient été créées ou importées. Procéder de l'avant avec l'importation <strong>va supprimer toute cette information.</strong>
          </v-alert>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-exclamation-thick</v-icon>  
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Modèle de désignation</v-list-item-title>
            <v-list-item-subtitle>Ceci identifie le format des exigences dans lequel les données seront importées.</v-list-item-subtitle>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="designation_model_id"
                  :items="preConvertInfo.models"
                  label="Modèle"
                  item-text="name"
                  item-value="id" 
                >
                  <template v-slot:selection="{ item }">
                    {{ $langobj.parse(item.name) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ $langobj.parse(item.name) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="oziData"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Données d'un rapport OZi</v-list-item-title>
            <v-list-item-subtitle>Ceci inclut l'information entrée par le fournisseur et la grille d'évaluation, y compris les documents préalablement téléversés. Il faut choisir la période cible qui représente le mieux l'année du dossier.</v-list-item-subtitle>
            <v-row v-if="oziData">
              <v-col cols="4">
                <v-select
                  v-model="workplan_id"
                  :items="preConvertInfo.workplans"
                  label="Période"
                  item-text="period"
                  item-value="workplan_id"
                  :disabled="!oziData"
                >
                  <template v-slot:item="{ item }">
                    {{item.period}}
                    {{item.submitted_date ? ' (soumis le '+item.submitted_date+')' : '(non soumis)'}}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="zoneaData"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Billets, documents et évaluations des exigences de Zone-A liés aux exigences.</v-list-item-title>
            <span style="font-size: 0.9em;">
              Ceci inclut:
              <ul class="text--secondary">
                <li>{{preConvertInfo.requirement_interpretation}} interprétation(s) du produit final des exigences de l'agent responsable</li>
                <li v-if="preConvertInfo.requirement_tickets.requirement_appreciation">
                  {{preConvertInfo.requirement_tickets.requirement_appreciation}} billets de type appréciations.
                </li>
                <li v-if="preConvertInfo.requirement_tickets.note">
                  {{preConvertInfo.requirement_tickets.note}} notes liées aux exigences.
                </li>
                <li v-if="preConvertInfo.requirement_tickets.task">
                  {{preConvertInfo.requirement_tickets.task}} taches liées aux exigences.
                </li>
                <li v-if="preConvertInfo.requirement_tickets.evaluation_request">
                  {{preConvertInfo.requirement_tickets.evaluation_request}} demandes d'évaluation.
                </li>
                <li v-if="preConvertInfo.za_documents">
                  Jusqu'à {{preConvertInfo.za_documents}} document(s), ceux liés aux exigences. (Les autres documents ont déjà été importés, car ils ne sont pas liés aux exigences)
                </li>
                <li v-if="preConvertInfo.za_comments">
                  {{preConvertInfo.za_comments}} commentaire(s) liés aux exigences
                </li>
              </ul>     
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: ['value', 'dossier', 'requirements'],
    data () {
      return {
        notifications: false,
        sound: true,
        designation_model_id: null,
        oziData: false,
        workplan_id: null,
        zoneaData: false,
        documents: false,
        comments: false,

        preConvertInfo: {requirement_tickets: {}, current_requirements: 0},
        isLoading: false,
        isImporting: false,
      }
    },
    watch: {
      value() {
        this.getPreConversionInfo();
      }
    },
    computed: {
      dialog: {
        get () { return this.value },
        set (value) { this.$emit('input', value) }
      },
    },
    methods: {
      getPreConversionInfo() {
        this.isLoading = true;
        this.$axios
          .get(this.$root.$data.server_base + '/api/dossier/preconvert_info.php',
            { params: {
              designation_dossier_id: this.$route.params.designation_dossier_id,
              service_provider_id: this.$route.params.service_provider_id,
            }})
          .then(response => {
            this.preConvertInfo = response.data;
            this.isLoading = false
          });
      },
      handleImport() {
        this.isImporting = true;
        this.$axios
          .get(this.$root.$data.server_base + '/api/dossier/import_from_ozi.php',
            { params: {
                designation_dossier_id: this.$route.params.designation_dossier_id,
                service_provider_id: this.$route.params.service_provider_id,
                workplan_id: this.oziData ? this.workplan_id : null,
                designation_model_id: this.designation_model_id,
                zone_a_tickets: this.zoneaData,
            }})
          .then(response => {
            // this.preConvertInfo = response.data;
            this.isImporting = false;
            this.$notify({type: 'success', group: 'std',
              title: 'Importation',
              text: 'Importé avec succès',
              duration: 4000
            });
            this.dialog = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.isImporting = false;
            this.$notify({type: 'error', group: 'std',
              title: 'Importation',
              text: 'Erreur du serveur',
              duration: 4000
            })
          });
      }
    },
  }
</script>