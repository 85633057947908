<template>
  <div class="row">
    <div class="col-sm-12" >
      <div class="form-group">
        <div class="text-h6 mt-3 mb-2">
          {{ $t('dossier.appreciation') }}
          <v-btn v-if="!readonly" small text color="primary" @click="handleAdd">
            <v-icon class="mr-1">mdi-plus</v-icon>{{$t('label.add')}}
          </v-btn>
        </div>
        <div class="mt-4 mb-4" v-if="showAdd">
          <v-form ref="form">
            <v-select
              :label="$t('requirement.appreciation')"
              v-model="appreciation_key"
              required
              dense
              :loading="isLoading"
              :items="
                (appreciationStatusOptions || []).map((option) => ({
                  text: $langobj.parse(option.name_lang),
                  value: option.ticket_status_key,
                }))
              "
              :rules="[(v) => $validators.required(v)]"
            ></v-select>
            <tip-tap v-model="description" :label="$t('requirement.notes')"/>
            <v-btn color="primary" class="mr-2" :loading="isSaving" small
              @click="handleCreateAppreciation"
            >
              {{ $t('label.save') }}
            </v-btn>
            <v-btn color="primary" text small @click="handleCancel">
              {{ $t('label.cancel') }}
            </v-btn>
          </v-form>
        </div>

        <div v-for="item in appreciations" :key="item.ticket_id">
            <Appreciation
              :appreciation="item"
              @refresh="refresh"
              :readonly="readonly"
            ></Appreciation>
        </div>  
      </div>

    </div>
    
  </div>
</template>
<style>
.created_at {
  color: #999;
  font-size: 85%;
}
</style>

<script>
import Appreciation from '@/components/appreciation/Appreciation.vue'

export default {
  name: 'ListAppreciation',
  props: ['service_provider_id','requirement_id','readonly'],

  data: function() {return {
    showAdd: false,
    appreciation_key: '',
    description: '',
    isSaving: false,
    isLoading: false,
    appreciationStatusOptions: [],

    appreciations:[],
  }},
  components: {
    Appreciation
  },

  methods:
  {
    handleAdd() {
      this.showAdd = !this.showAdd;
      if(this.appreciationStatusOptions.length == 0) {
        this.getAppreciationStatusOptions();
      }
    },
    handleCancel() {
      this.showAdd = false;
      this.description = this.appreciation_key = '';
    },
    handleCreateAppreciation() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }
      this.isSaving;

      let data = {
        ticket_status_key: this.appreciation_key,
        service_provider_id: this.service_provider_id,
        requirement_id: this.requirement_id,
        description: this.description
      }
        
      this.$axios
        .put(this.$root.$data.server_base + '/api/requirement/add_appreciation.php',
          data)
        .then(() => {
          this.isSaving = false;
          this.showAdd = false;
          this.description = this.appreciation_key = '';
          this.refresh();
        }
      );
    },
    getAppreciationStatusOptions() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + "/api/admin/ticket_status/list.php",
          {params:{ticket_type_id: 2}})
        .then(response => {
          this.appreciationStatusOptions = response.data.items;
          this.isLoading = false
        })
    },
    refresh() {
      if(this.requirement_id>0)
      {
        this.$axios
          .get(this.$root.$data.server_base + '/api/requirement/get_appreciations.php',
            { params: {
              service_provider_id: this.service_provider_id,
              requirement_id: this.requirement_id
            }})
          .then(response => {
            this.appreciations = response.data.appreciations
          })
      }
    },
   
  },
  mounted () {
    this.refresh();
  },
  watch: {
    requirement_id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showAdd = false;
        this.refresh();
      }
    }
  }

}
</script>
