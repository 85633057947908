<template>
  <div>
    <ResetPasswordDialog
      v-if="!isCreate"
      v-model="showResetPasswordDialog" 
      :user_id="internal.user_id"
      :email="internal.email"
    />
    <UserRoleDialog
      v-model="showUserRoleDialog"
      :role="roleToEdit"
      :roleOptions="roles"
      @refresh="refresh"
    />
    <UserSendEmailDialog
      v-model="showUserSendEmailDialog"
      :userId="internal.user_id"
      @refresh="refresh"
    />
    <DeleteDialog v-model="deleteDialog"
      v-on:delete-object="handleDeleteUser"
    />
    <v-container >
      <h2>
        <return-to-list :to="{name: 'admin-users'}"></return-to-list>
        {{ isCreate ? $t('user.create') : $t('user.modify') }}
      </h2>
      <v-row class="mt-2">
        <v-col cols=3>
          <div>
            <v-card-title>
              <v-avatar color="teal" size="48">
                <span class="white--text text-h5">{{ avatarText }}</span>
              </v-avatar>
            </v-card-title>
            <template v-if="!isCreate">
              <v-card-text>
                <div>{{ internal.first_name }} {{ internal.last_name }}</div>
                <div>{{ internal.email }}</div>
                <div :class="internal.state != 2 ? 'green--text' : ''">{{ $langobj.parse(internal.state_lng) }}</div>
                <div>{{ $t('user.last_login') }}: {{ moment(internal.last_login).fromNow() }}</div>            
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-text>
                <div>
                  <v-btn class="px-1" color="primary" text @click="resetPassword">
                    {{ $t('password.reset_password') }}
                  </v-btn>
                </div>
                <div>
                  <v-btn class="px-1" color="primary" text @click="showUserSendEmailDialog = true">
                    {{ $t('user.send_email') }}
                  </v-btn>
                </div>
                <div>
                  <v-btn v-if="internal.state !=2" class="px-1" color="primary" text @click="handleToggleState">
                    {{ $t('user.deactivate') }}
                  </v-btn>
                  <v-btn v-else class="px-1" color="primary" text @click="handleToggleState">
                    {{ $t('user.activate') }}
                  </v-btn>
                </div>
                <div>
                  <v-btn class="px-1" color="primary" text @click="deleteDialog = true">
                    {{ $t('label.delete') }}
                  </v-btn>
                </div>
              </v-card-text>
            </template>
          </div>
        </v-col>
        <v-col cols=9>
          <v-card class="mb-4">
            <v-card-title>
              {{ $t('user.info') }}
            </v-card-title>
            <v-card-text>
              <v-form ref='form'>
                <v-text-field
                  v-model="internal.username"
                  :label="$tc('user.username')"
                  :rules="[(v) => $validators.required(v, [$t('user.username')])]"
                ></v-text-field>
                <v-text-field
                  v-model="internal.first_name"
                  :label="$tc('user.first_name')"
                ></v-text-field>
                <v-text-field
                  v-model="internal.last_name"
                  :label="$tc('user.last_name')"
                ></v-text-field>
                <v-text-field
                  v-model="internal.email"
                  :label="$tc('user.email')"
                  :rules="[(v) => $validators.required(v, [$t('user.email')])]"
                ></v-text-field>
                <v-select
                  v-model="internal.language_preference"
                  :label="$tc('user.language_preference')"
                  :items="$locales"
                  item-text="label"
                  :placeholder="$tc('label.none')"
                ></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="handleSave" :disabled="disableSave">
                {{ $t('label.save') }}
              </v-btn>
              <v-btn color="primary" text @click="handleCancel">
                {{ $t('label.cancel') }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="!isCreate">
            <v-card-title>
              {{ $tc('role.label', 2) }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-right"
                @click="handleCreateRole"
                color="primary"
                outlined
              >
                <v-icon>mdi-plus</v-icon>
                  {{ $t('label.add') }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                dense
                :headers="roleHeaders"
                :items="internalRoles"
                item-key="role_id"
                :items-per-page="-1"
                :hide-default-footer="true"
                :item-class="rowClass"
              >
                <template v-slot:[`item.name`]="{ item }">
                  {{ $langobj.parse(item.name) }}
                </template>
                <template v-slot:[`item.access`]="{ item }">
                  <div>
                    <span v-if="item.regions">
                      {{ $tc('role.regions', item.regions.length) }}:  
                      {{ flattenNameArray(item.regions) }}
                    </span>
                    <span v-if="item.providers">
                      {{ $tc('role.providers', item.providers.length) }}:  
                      {{ flattenNameArray(item.providers) }}
                    </span>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click.stop="handleToggleRoleState(item)">
                    {{ item.state != 'active' ? 'mdi-account' : 'mdi-account-off-outline' }}
                  </v-icon>
                  <v-icon small class="mr-2" @click="handleEditRole(item)">mdi-pencil</v-icon>
                  <v-icon small @click="handleDeleteRole(item)">mdi-delete</v-icon>
                </template>
              </v-data-table> 
            </v-card-text>
          </v-card>
          
        </v-col>
      </v-row>
    </v-container>
  </div>  
</template>

<script>
import UserUtils from '@/utils/UserUtils';
import ResetPasswordDialog from '@/components/admin/ResetPasswordDialog';
import UserRoleDialog from '@/components/admin/UserRoleDialog';
import UserSendEmailDialog from '@/components/admin/UserSendEmailDialog';
import DeleteDialog from '@/components/framework/DeleteFromListDialog.vue';
import ObjectUtils from '@/utils/ObjectUtils';

export default {
  name: 'UserDetails',
  components: {
    ResetPasswordDialog,
    UserRoleDialog,
    UserSendEmailDialog,
    DeleteDialog
  },
  data: function() {
    return {
      storeData: null,
      internal: UserUtils.generateDefault(),
      internalRoles: [],
      roles: [],
      agencies: [],
      isLoading: true,
      showResetPasswordDialog: false,
      showUserRoleDialog: false,
      showUserSendEmailDialog: false,
      roleToEdit: {},
      deleteDialog: false,
    }
  },

  computed: {
    avatarText() {
      return (this.internal.first_name ? this.internal.first_name.charAt(0) : '?') +
        ( this.internal.last_name ? this.internal.last_name.charAt(0) : '?' );
    },
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    },
    isCreate() {
      return this.$route.params.user_id == 'cr';
    },
    roleHeaders() {
      return [
        { text: this.$tc('role.label', 1), value: 'name' },
        { text: this.$t('role.access'), value: 'access' },
        { text: this.$tc('label.action'), value: 'actions', width: '100px'},
      ];
    }
  },

  methods: {
    rowClass(item) {
      return item.state != 'active' ? "text--disabled text-decoration-line-through" : "";
    },
    getOptions() {
      this.$axios
        .get("/api/admin/user/provision_options.php")
        .then(response => {
          this.roles = response.data.roles;
      });
    },
    getDataFromApi() {
      this.getOptions();

      if(this.$route.params.user_id == 'cr')
        return;
      this.isLoading = true;
      this.$axios
        .get("/api/admin/user/get.php",
          {params:{user_id: this.$route.params.user_id}})
        .then(response => {
          this.storeData = response.data.user;
          this.internal = _.cloneDeep(this.storeData);
          this.internalRoles = response.data.roles;
          this.isLoading = false
      })
    },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }
     
      let obj = this.internal;
      if(this.isCreate) {
      }
      else {
        obj = ObjectUtils.getObjectDiff(this.internal, this.storeData);
        obj.user_id = this.internal.user_id;
      }
      if('username' in obj)
        obj.username = obj.username.trim();
      if('email' in obj)
        obj.email = obj.email.trim();

      const api = this.isCreate
        ? '/api/admin/user/create.php'
        : '/api/admin/user/update.php';

      this.$axios
        .put(api, obj)
        .then((response) => {
          if(this.isCreate)
            this.$router.replace(
              { name: 'admin-user-details', params: { user_id: response.data.user_id }}
            );
          else
            this.$router.go(-1);
        });
    },
    handleDeleteUser() {
      this.$axios
        .put('/api/admin/user/remove.php', {user_id: this.internal.user_id})
        .then((response) => {
          this.$router.go(-1);
        })
    },
    handleCancel() {
      this.getDataFromApi();
    },
    handleToggleState() {
      const newState = ( this.internal.state == 2 ? 1 : 2);
      this.$axios
        .put("/api/admin/user/update.php", {
          user_id: this.internal.user_id,
          state: newState
        })
        .then((response) => {
          this.getDataFromApi();
        });
    },
    resetPassword() {
      this.showResetPasswordDialog = true;
    },
    refresh() {
      this.getDataFromApi();
    },
    flattenNameArray(arrayWithName) {
      return arrayWithName
        .map((e) => this.$langobj.parse(e.name))
        .join(', ');
    },
    // Role
    handleCreateRole() {
      this.roleToEdit = {user_id: this.$route.params.user_id};
      this.showUserRoleDialog = true;
    },
    handleEditRole(row) {
      this.roleToEdit = row;
      this.showUserRoleDialog = true;
    },
    handleDeleteRole(row) {
      this.$axios
        .get("/api/admin/role/remove_user_role.php",
          {params:{user_role_id: row.user_role_id}})
        .then(response => {
          this.refresh();
        })
    },
    handleToggleRoleState(row) {
      const obj = {
        user_role_id: row.user_role_id,
        state: row.state == 'active' ? 'inactive' : 'active',
      };
      this.$axios
        .put('/api/admin/role/update_user_role.php', obj,
          { headers: {'Content-Type': 'application/json' }})
        .then((response) => {
          this.refresh();
        });
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>