import { render, staticRenderFns } from "./BdssfReportDesignation.vue?vue&type=template&id=d99aa27a&scoped=true&"
import script from "./BdssfReportDesignation.vue?vue&type=script&lang=js&"
export * from "./BdssfReportDesignation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d99aa27a",
  null
  
)

export default component.exports