<template>
  <span>
    <template v-if="urlMatch === 'google-document'">
      <v-icon color="primary" small class="mr-1">mdi-text-box</v-icon>
    </template>
    <template v-if="urlMatch === 'google-spreadsheet'">
      <v-icon color="primary" small class="mr-1">mdi-google-spreadsheet</v-icon>
    </template>
    <template v-if="urlMatch === 'google-presentation'">
      <v-icon color="primary" small class="mr-1">mdi-file-presentation-box</v-icon>
    </template>
     <template v-if="urlMatch === 'google-drive'">
      <v-icon color="primary" small class="mr-1">mdi-google-drive</v-icon>
    </template>
    <template v-if="urlMatch === 'use-extension'">
      <v-icon color="primary" small class="mr-1">{{mdiIconForExtension}}</v-icon>
    </template>
  </span>  
</template>

<script>

  export default {
    props: ['url'],
    data: function() { return {
    }},
    computed: {
      urlMatch() {
        if (!this.url) return 'use-extension'
        if (0 === this.url.indexOf('https://docs.google.com/document/d/')) return 'google-document'
        if (0 === this.url.indexOf('https://docs.google.com/spreadsheets/d/')) return 'google-spreadsheet'
        if (0 === this.url.indexOf('https://docs.google.com/presentation/d/')) return 'google-presentation'
        if (0 === this.url.indexOf('https://drive.google.com/')) return 'google-drive'
        if (this.urlExtension === 'csv') return 'google-spreadsheet'
        return 'use-extension'
      },
      urlExtension() {
        if (!this.url) return ''
        try {
          const url = new URL(this.url)
          return url.pathname.split('.').pop();
        } catch(e) {
          // not a full URL
          return this.url.split('.').pop();
        }
      },
      mdiIconForExtension() {
        switch(this.urlExtension) {
          case "pdf":
            return "mdi-file-pdf-box";
          case "xls": case "xlsx":
            return "mdi-file-excel-box";
          case "doc": case "docx":
            return "mdi-text-box";
          case "ppt": case "pptx":
            return "mdi-file-powerpoint-box";
          case "jpg": case "jpeg": case "png": case "gif": case "svg":
            return "mdi-file-image";
          case "txt":
            return "mdi-note-text";
        }
        return "mdi-file";
      }
    }
  }
</script>