<template>
  <v-dialog
    :value='value'
    @input="$emit('input', $event)"
    width="700"
    persistent
  >
    <v-card :loading="loading">
      <v-card-title class="text-h5 grey lighten-2 mb-3">
        {{ $tc('role.label', 1) }}
      </v-card-title>

      <v-card-text>
        <v-select
          v-model="internal.role_key"
          :label="$tc('role.label', 1)"
          :items="
            (roleOptions || []).map((option) => ({
              text: $langobj.parse(option.name),
              value: option.role_key
          }))"
          @change="disableSave = false"
        >
        </v-select>
        <v-autocomplete
          v-if="internal.role_key == 'sp'"
          v-model="internal.providerSelection"
          :items="
            (providerOptions || []).map((option) => ({
              text: $langobj.parse(option.name),
              value: option.service_provider_id
            }))
            .sort((a,b) => a.text.localeCompare(b.text))"
          :label="$tc('role.providers', 2)"
          multiple
          chips
          deletable-chips
          :placeholder="$t('role.all_providers')"
          @change="disableSave = false"
        ></v-autocomplete>
        <v-autocomplete
          v-if="internal.role_key == 'agent'"
          v-model="internal.regionSelection"
          :items="
            (regionOptions || []).map((option) => ({
              text: $langobj.parse(option.name),
              value: option.region_id
            }))
            .sort((a,b) => a.text.localeCompare(b.text))"
          :label="$tc('role.regions', 2)"
          multiple
          chips
          deletable-chips
          :placeholder="$t('role.all_regions')"
          @change="disableSave = false"
        ></v-autocomplete>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" @click="handleSave" :disabled="disableSave">
          {{ $t('label.save') }}
        </v-btn>
        <v-btn color="primary" text @click="$emit('input', false)">
          {{ $t('label.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>     
</template>

<script>
export default {
  name: 'UserRoleDialog',
  props: ['value','role','user_role_id', 'roleOptions'],
  data() { return {
    internal: {},
    storedData: {},
    loading: false,
    success: false,
    providerOptions: [],
    regionOptions: [],
    disableSave: false,
  }},
  watch: {
    value() {
      if(this.value==true) {
        // The dialog was just opened.
        this.getProvisionOptions();
        this.internal = _.cloneDeep(this.role);
        this.internal.providerSelection = (this.role.providers || [])
          .map(e => e.service_provider_id);
        this.internal.regionSelection = (this.role.regions || [])
          .map(e => e.region_id);
        this.storedData = _.cloneDeep(this.internal);
        this.disableSave = true;
      }
    }
  },
  computed: {
    isCreate() {
      return this.internal.user_role_id == null;
    },
  },
  methods: {
    roleIdForKey(role_key) {
      return this.roleOptions.find(e => e.role_key == role_key).role_id;
    },

    getProvisionOptions() {
      this.$axios
        .get("/api/admin/role/provision_options.php")
        .then(response => {
          this.providerOptions = response.data.providers;
          this.regionOptions = response.data.regions;
      })
    },

    handleSave() {
      const obj = {
        user_id: Number(this.internal.user_id),
        user_role_id: this.internal.user_role_id,
        role_id: this.roleIdForKey(this.internal.role_key),
        access_privileges: {
          ...(this.internal.role_key == 'sp' &&
            { providers: this.internal.providerSelection }),
          ...(this.internal.role_key == 'agent' &&
            { regions: this.internal.regionSelection })
        }
      }
      const api = this.isCreate
        ? '/api/admin/role/add_user_role.php'
        : '/api/admin/role/update_user_role.php';
      
      this.$axios
        .put(api, obj, { headers: {'Content-Type': 'application/json' }})
        .then((response) => {
          this.$emit('refresh');
          this.$emit('input', false);
        });
    }
  }
}
</script>