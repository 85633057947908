<template>
  <div>
    <router-link class="text-decoration-none" :to="{name: 'admin-design-model-details'}">
      <span class="text-h6 text--primary">←&nbsp;</span>
      {{ $t('label.return_to_list') }}
    </router-link>
    <h2>{{ isCreate ? $t('designation_model.requirement.create') : $t('designation_model.requirement.modify') }}</h2>
    <v-row class="mt-2">
      <v-col cols=3>
        <div>
          <v-card-text>
            <v-avatar size="44" :style="avatarStyle"
              color="primary" class="exigence-no">
              <span class="white--text">{{internal.identifier}}</span>
            </v-avatar>          
            <div class="mt-2 grey--text">{{ $langobj.parse(internal.name) }}</div>
            <small v-if="internal.modified_at" class="grey--text font-italic">
              {{ $t('label.modified') }}
              {{ moment.utc(internal.modified_at).local().fromNow() }}
            </small>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <div>
              <v-btn class="px-1" color="primary" text @click="handleDelete">
                <v-icon class="mr-1">mdi-delete</v-icon> {{ $t('label.delete') }}
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-col>

      <v-col cols=9>
        <v-card>
          <v-form v-model="isFormValid" ref="form">
            <v-card-text>
              <v-text-field
                v-model="internal.identifier"
                :label="$t('designation_model.requirement.identifier')"
                :rules="[(v) => $validators.required(v, [$t('designation_model.requirement.identifier')])]"
              ></v-text-field>
              <v-text-field
                v-model="internal.sort"
                :label="$t('designation_model.requirement.sort')"
                :rules="[(v) => $validators.number(v, [$t('designation_model.requirement.sort')])]"
              ></v-text-field>
              <v-select
                v-model="internal.item_type"
                :items="itemTypeItems"
                :label="$t('designation_model.requirement.item_type.label')"
                :rules="[(v) => $validators.required(v, [$t('designation_model.requirement.item_type.label')])]"
              ></v-select>
              <v-row
                v-for="locale in $locales"
                :key="locale.value"
                class="mt-2"
              >
                <v-col cols=1>
                  <p class="text-h4 primary--text">{{ locale.toLocaleUpperCase() }}</p>
                </v-col>
                <v-col cols=11>
                  <v-text-field
                    spellcheck="false"
                    v-model="internal.name[locale]"
                    :label="$langobj.label($t('designation_model.requirement.name'), locale)"
                    :rules="[(v) => $validators.required(v, [$t('designation_model.requirement.name')])]"
                    :loading="isLoading"
                  ></v-text-field>
                  <v-textarea
                    spellcheck="false"
                    v-model="internal.requirement_text[locale]"
                    :label="$langobj.label($t('designation_model.requirement.full'), locale)"
                    :loading="isLoading"
                    rows="3"
                  ></v-textarea>
                  <v-text-field
                    spellcheck="false"
                    v-model="internal.help_text_1.title[locale]"
                    :label="$langobj.label($t('designation_model.requirement.help_title', [1]), locale)"
                    :loading="isLoading"
                  ></v-text-field>
                  <tip-tap
                    extended
                    spellcheck="false"
                    v-model="internal.help_text_1.text[locale]"
                    :label="$langobj.label($t('designation_model.requirement.help_text', [1]), locale)"
                    :loading="isLoading"
                  />
                  <v-text-field
                    spellcheck="false"
                    v-model="internal.help_text_2.title[locale]"
                    :label="$langobj.label($t('designation_model.requirement.help_title', [2]), locale)"
                    :loading="isLoading"
                  ></v-text-field>
                  <tip-tap
                    extended
                    spellcheck="false"
                    v-model="internal.help_text_2.text[locale]"
                    :label="$langobj.label($t('designation_model.requirement.help_text', [2]), locale)"
                    :loading="isLoading"
                  />
                  <v-text-field
                  spellcheck="false"
                    v-model="internal.help_text_3.title[locale]"
                    :label="$langobj.label($t('designation_model.requirement.help_title', [3]), locale)"
                    :loading="isLoading"
                  ></v-text-field>
                  <tip-tap
                    extended
                    spellcheck="false"
                    v-model="internal.help_text_3.text[locale]"
                    :label="$langobj.label($t('designation_model.requirement.help_text', [3]), locale)"
                    :loading="isLoading"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn color="primary" @click="handleSave" :disabled="disableSave">
              {{ $t('label.save') }}
            </v-btn>
            <v-btn color="primary" text @click="handleCancel">
              {{ $t('label.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>  
</template>

<script>
import _ from 'lodash';
import ObjectUtils from '@/utils/ObjectUtils';

export default {
  name: 'DesignationModelDetails',
  data: function() {
    return {
      storeData: null,
      internal: {id: null, name: {}, requirement_text: {}, identifier: '',
        help_text_1: {title: {}, text: {}},
        help_text_2: {title: {}, text: {}},
        help_text_3: {title: {}, text: {}},
      },
      isLoading: false,
      isFormValid: true,
    }
  },

  computed: {
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    },
    isCreate() {
      return this.$route.params.requirement_def_id == 'cr';
    },
    avatarStyle() {
      return String(this.internal.identifier).length < 3 
        ? 'font-size: x-large; font-weight: bold;' : 'font-weight: bold;'
    },
    test() {
      return String(this.internal.identifier).length;
    },
    itemTypeItems() {
      return [
        { text: this.$t('designation_model.requirement.item_type.requirement'), value: 'requirement' },
        { text: this.$t('designation_model.requirement.item_type.header'), value: 'header' },
      ];
    }
  },

  methods: {
    getDataFromApi() {
      if(this.$route.params.requirement_def_id == 'cr')
        return;
      this.isLoading = true;
      this.$axios
        .get("/api/admin/requirement_def/get.php",
          {params:{requirement_def_id: this.$route.params.requirement_def_id}})
        .then(response => {
          this.storeData = response.data.requirement;
          this.internal = _.cloneDeep(this.storeData);
          this.isLoading = false;
      });
    },
    handleDelete() {
      this.$axios
        .delete('/api/admin/requirement_def/remove.php', {
          params: {requirement_def_id: this.$route.params.requirement_def_id}})
        .then(response => {
          if(!response.data.server_error)
            this.$router.push({name: 'admin-design-model-details'});
        });
    },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }        
      let obj = {};
      let api = "";
      if(this.isCreate) {
        api = "/api/admin/requirement_def/create.php";
        obj = this.internal;
        obj.designation_model_id = this.$route.params.designation_model_id;
        console.log(obj);
      }
      else {
        api = "/api/admin/requirement_def/update.php";
        obj = ObjectUtils.getObjectDiff(this.internal, this.storeData);  
        obj.requirement_def_id = this.internal.requirement_def_id;
      }

      this.$axios
        .put(api, obj, { headers: {'Content-Type': 'application/json' }})
        .then((response) => {
          this.storeData = response.data.requirement;
          this.internal = _.cloneDeep(this.storeData);
          this.isLoading = false;

          if(this.isCreate)
            this.$router.push({name: 'admin-design-model-details'});
        });
    },
    handleCancel() {
      this.getDataFromApi();
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>