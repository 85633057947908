<template>
  <div>
    RH
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'OngletStatut',

  data () {
    return {
      search: "",
      dialog: false,
      items_design: ["Désigné", "Presque désigné", "Identifié", "Sans objet"],
      items_livraison: ["En présentiel", "À domicile", "En virtuel", "Sans rendez-vous"],
      items: [
        { pds: "Campus principal", service: "Services aux résidents", poste: "Nurse Manager Operations",
          total: "3 t-pl, 2 t-pa, 0 oc", requis: "3 t-pl, 2 t-pa, 0 oc", comble: "3 t-pl, 2 t-pa, 0 oc", finance: "MSSLD" },
        { pds: "Campus principal", service: "Services aux résidents", poste: "Nurse Manager Operations",
          total: "3 t-pl, 2 t-pa, 0 oc", requis: "3 t-pl, 2 t-pa, 0 oc", comble: "3 t-pl, 2 t-pa, 0 oc", finance: "MSSLD" },
        { pds: "Campus principal", service: "Services aux résidents", poste: "Nurse Manager Operations",
          total: "3 t-pl, 2 t-pa, 0 oc", requis: "3 t-pl, 2 t-pa, 0 oc", comble: "3 t-pl, 2 t-pa, 0 oc", finance: "MSSLD" },
        { pds: "Campus principal", service: "Services aux résidents", poste: "Nurse Manager Operations",
          total: "3 t-pl, 2 t-pa, 0 oc", requis: "3 t-pl, 2 t-pa, 0 oc", comble: "3 t-pl, 2 t-pa, 0 oc", finance: "MSSLD" },
      ],
      headers: [
        {
          text: 'Nom du programme / service',
          align: 'start',
          
          value: 'nom',
        },
        { text: 'Désignation', value: 'designation' },
        { text: 'Financement', value: 'finance' },
        { text: 'Mode de livraison', value: 'livraison' },
      ],
        
    }    
  }
});
</script>