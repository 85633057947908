<template>
  <v-container fluid class="pt-8 px-4">
    <AreYouSureDialog
      v-model="submitDialog"
      :title="$t('dossier.submit.title')"
      :btnLabel="$t('label.submit')"
      btnIcon="mdi-send-check"
      @confirmed="handleSubmit"
    >
      {{ $t('dossier.submit.confirm') }}
    </AreYouSureDialog>
    <v-row>
      <v-col cols="12" sm="12" v-if="!isLoading && requirements.length==0 && !readonly">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="9">
                <h3 class="red--text" v-if="requirements.length==0">
                  {{ $t('dossier.convert.notice') }}
                </h3>
              </v-col>
              <v-spacer />
              <v-col class="text-right">
                <v-btn color="primary" @click="importDialog=true">
                  {{ $t('dossier.convert.submit') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <dossier-conversion v-model="importDialog" :dossier="dossier" 
          :requirements="requirements"
          @refresh="$emit('refresh')"/>
      </v-col>

      <v-col cols="12" sm="12">
        <v-card>
          <v-card-text>
            <div class="d-flex justify-space-between">
              <v-chip
                :color="dossier.dossier_status_aes.colour"
                label
                text-color="white"
              >
                <v-icon left>mdi-circle-medium</v-icon>
                {{ $langobj.parse(dossier.dossier_status_lang) }}
                <span v-if="dossier.dossier_status == 'approved'
                  && dossier.date_of_designation"
                >
                  &nbsp;{{ $t('date.on', 
                    [moment.utc(dossier.date_of_designation).local().format('LL')]) }}
                </span>
              </v-chip>
              <div class="mt-2" v-if="showDueDate">
                <p v-if="dossier.due_date">
                  {{ $t('dossier.dashboard.due_date',
                    [moment.utc(dossier.due_date).local().format('LL')])}}
                </p>
                <p v-else>{{ $t('dossier.dashboard.no_due_date') }}</p>
              </div>
              <v-btn color="primary" 
                :disabled="dossier.dossier_status != 'in_progress'"
                @click="submitDialog=true"
              >
                <v-icon class="mr-1">mdi-send-check</v-icon>{{ $t('label.submit') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card @click="$router.push({name: 'dossier_elements'})" class="mb-6">
          <v-card-title>{{ $t('dossier.dashboard.completed_elements') }}</v-card-title>
          <v-card-text v-if="!isLoading && workplanElements.length == 0">
            <em>{{ $t('error.no_content') }}</em>
          </v-card-text>
          <v-card-text v-else>
            <DossierElementProgress
              color="#fbab26"
              :title="$t('provider.dashboard.commitments')"
              :numerator="weCommitments.numerator"
              :denominator="weCommitments.denominator"
            />
            <DossierElementProgress
              color="#fbab26"
              class="mt-3"
              :title="$t('provider.dashboard.policy')"
              :numerator="wePolicies.numerator"
              :denominator="wePolicies.denominator"
            />
            <DossierElementProgress
              class="mt-3"
              :title="$t('provider.dashboard.demonstrations')"
              :numerator="weDemonstrations.numerator"
              :denominator="weDemonstrations.denominator"
            />
          </v-card-text>
          <template v-if="weLastUpdate">
            <v-divider></v-divider>
            <v-card-actions>
              <p class="db-card-bottom mb-0">
                <v-icon small class="mr-1">mdi-clock</v-icon>
                {{ $t('ticket.updated') }} {{ weLastUpdate }}
              </p>
            </v-card-actions>
          </template>
        </v-card>

        <v-card @click="$router.push({name: 'dossier_requirements'})" class="mb-4">
          <v-card-title>{{ $t('dossier.dashboard.requirements') }}</v-card-title>
          <v-card-text v-if="!isLoading && requirements.length == 0">
            <em>{{ $t('error.no_content') }}</em>
          </v-card-text>
          <v-card-text v-else>
            <DossierElementProgress
              color="#fbab26"
              :title="$t('dossier.dashboard.completed_reqs')"
              :numerator="reqComplete.numerator"
              :denominator="reqComplete.denominator"
            />
            <DossierElementProgress
              class="mt-3"
              :title="$t('dossier.dashboard.conforming_reqs')"
              :numerator="reqConforming.numerator"
              :denominator="reqConforming.denominator"
            />
          </v-card-text>

          
        </v-card>

      </v-col>

      <v-col cols="12" sm="6">
        <v-card @click="$router.push({name: 'dossier_history'})" class="mb-6">
          <v-card-title>{{ $t('dossier.dashboard.history') }}</v-card-title>
          <v-card-text v-if="!isLoading && filteredHistoryTickets.length == 0">
            <em>{{ $t('error.no_content') }}</em>
          </v-card-text>
          <v-card-text v-else class="py-0">
            <v-timeline align-top dense>
              <v-timeline-item
                v-for="item in filteredHistoryTickets"
                :key="item.ticket_id"
                small
                :color="item.effective_at ? 'primary' : 'grey'"
              >
                <div>
                  <strong>{{ $langobj.parse(item.name_lang)}}</strong>
                  &mdash;&nbsp;{{item.effective_at}}
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title>{{ $t('hr.label') }}</v-card-title>
          <v-card-text>
            <v-skeleton-loader
              :boilerplate="true"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
            <em>{{ $t('error.feature_not_ready') }}</em>
          </v-card-text>
        </v-card>
        <!-- <v-card>
          <v-card-title>Activité récente</v-card-title>
          
          <v-card-text>  
            <ul>
              <li>Ces données sont fictives</li>
              <li>2022.02.05: Commentaire ajouté à l'exigence 9</li>
              <li>2022.01.24: Modification faite aux tableau des RH</li>
            </ul>
          
          </v-card-text>           
        </v-card> -->
        <!-- <v-card>
          <v-card-title>Clientèle</v-card-title>
          <v-card-subtitle>Clientèle totale en 2020-2021: 245 </v-card-subtitle>
          <v-card-text>
        
            <p class="db-graph-title mb-0 mt-3">Francophones parmi la population ciblée</p>

            <v-progress-linear value="27" color="#fbab26" height="20">
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
            
            <p class="db-graph-title mb-0 mt-3">Francophones parmi la clientèle</p>
            <v-progress-linear value="48" color="#9066B3" height="20">
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <p class="db-card-bottom mb-0">
              <v-icon small class="mr-1" color="green">mdi-check-decagram</v-icon>Données fictives</p>
          </v-card-actions>
        </v-card> -->
      </v-col>
      
      <!-- <v-col cols="12" sm="6">
          <v-card>
          <v-card-title>Ressources humaines</v-card-title>
          <v-card-subtitle>Total de 27 postes déclarés dans 3 programmes / services.</v-card-subtitle>
          
          <v-card-text>            
            <p class="db-graph-title mb-0">Postes désignés / total des postes</p>
            <v-progress-linear value="43" color="#A7B366" height="20">
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>

            <p class="db-graph-title mb-0 mt-3">Postes désignés comblés</p>
            <v-progress-linear value="67" color="#9066B3" height="20">
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <p class="db-card-bottom mb-0">
              <v-icon small class="mr-1">mdi-clock</v-icon>Données fictives
            </p>
          </v-card-actions>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>
<script>
import Vue from 'vue';
import DossierConversion from '@/components/dossiers/DossierConversion.vue';
import DossierElementProgress from '@/components/dashboard/DossierElementProgress.vue';
import AreYouSureDialog from '@/components/framework/AreYouSureDialog';
import DashboardUtils from '@/utils/DashboardUtils';
import {privileges} from '@/mixins/privileges.js';

export default Vue.extend({
  name: 'DossierSummary',
  components: {
    DossierConversion,
    DossierElementProgress,
    AreYouSureDialog,
  },
  mixins: [privileges],
  props: ['dossier', 'readonly'],
  data() {return {
    submitDialog: false,
    importDialog: false,
    isLoading: true,
    postes: 78,
    exigences: 65,
    nom: '',
    lettre_patentes: '',
    adresse: '',
    description: '',
    requirements: [],
    workplanElements: [],
    historyTickets: [],
  }},
  computed: {
    filteredHistoryTickets() {
      return this.historyTickets.filter( history => (
          history.audience == 'all'
        ))
        .slice(0, 4);
    },

    showDueDate() {
      return this.dossier.dossier_status == 'in_progress' ||
        this.dossier.dossier_status == 'not_started'
    },

    weCommitments() { 
      return DashboardUtils.indicator.weCommitments(this.workplanElements);
    },
    wePolicies() { 
      return DashboardUtils.indicator.wePolicies(this.workplanElements);
    },
    weDemonstrations() { 
      return DashboardUtils.indicator.weDemonstrations(this.workplanElements);
    },
    weLastUpdate() { 
      const lastUpdate = DashboardUtils.indicator.weLastUpdate(this.workplanElements);
      return lastUpdate ? this.moment.utc(lastUpdate).local().format('LL') : null;
    },

    reqComplete() { 
      return DashboardUtils.indicator.reqComplete(this.requirements);
    },
    reqConforming() { 
      return DashboardUtils.indicator.reqConforming(this.requirements);
    },

    reqMaxDate() {
      return this.moment.utc(this.requirements.map((item) => item.modified_at).sort().pop())
        .local().format('LL');
    }


  },
  methods: {
    refresh() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/requirement/list.php',
          { params: {
            designation_dossier_id: this.$route.params.designation_dossier_id,
            service_provider_id: this.$route.params.service_provider_id,
          }})
        .then(response => {
          this.requirements = response.data.requirements;
          this.isLoading = false;
        });
      this.$axios
        .get(this.$root.$data.server_base + '/api/workplan_element/list.php',
          { params: {
            designation_dossier_id: this.$route.params.designation_dossier_id,
            service_provider_id: this.$route.params.service_provider_id,
          }})
        .then(response => {
          this.workplanElements = response.data.elements;
          // this.isLoading = false;
        });
      this.$axios
        .get(this.$root.$data.server_base + '/api/dossier/get_history.php',
          { params: {
            designation_dossier_id: this.$route.params.designation_dossier_id,
            service_provider_id: this.$route.params.service_provider_id,
          }})
        .then(response => {
          this.historyTickets = response.data.history_tickets;
          // this.isLoading = false;
        });
    },
    handleSubmit() {
      this.$axios
        .put(this.$root.$data.server_base + '/api/dossier/submit.php', {
            designation_dossier_id: Number(this.$route.params.designation_dossier_id),
            service_provider_id: Number(this.$route.params.service_provider_id),
          })
        .then(response => {
          this.$emit('refresh');
          this.refresh();
          this.submitDialog = false;
        });
    }
  },
  mounted () {
    this.refresh();
  }
});
</script>

<style scoped lang="scss">
p.db-card-bottom {
  font-size: 0.8em;
  font-weight: 300;
  color: grey;
}
p.db-graph-title {
  font-size: 0.9em;
  font-weight: 400;
  color: black;
}
p.db-alert {
  color: #C62828
}
ul {
  font-size: 0.9em;
  font-weight: 300;
  line-height: 1.1em;

  li {
    margin-bottom: 4px;
  }
}
</style>