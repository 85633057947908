<template>
  <div>
    <v-row class="mb-8">
      <v-col class="my-0 doc_cat" cols="2">
        <p class="text-right">{{ label }}
          <help-dialog
            x-small
            activatorClass="ml-0"
            :help-key="helpKey"
          />
        </p>
      </v-col>
      <v-col class="pt-0 my-0" cols="10">
        <v-data-table
          dense
          :items-per-page="15"
          :hide-default-footer="documents.length <= 15"
          :footer-props="{itemsPerPageOptions:[15,30,100]}"
          :headers="headers"
          :items="documents"
          :loading="loading"
          :search="search"
          class="row-pointer"
          @click:row="handleRowClick"
        >
          <template v-slot:[`item.title`]="{ item }">
            <v-icon v-if="item.obsolete_at" color="red" small>mdi-clock-alert</v-icon>
            <a @click="handleFileDownload(item)">
              <document-mdi-icon 
                :url="item.repository=='ozi-on' ? oziOnPrefix+item.url : item.url"
              />{{item.title}}
            </a>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at ? item.created_at.substring(0, 10) : "" }}
          </template>
          <template v-slot:[`item.linked_items`]="{ item }">
            <template v-for="(assoc, index) in item.linked_items">
              <router-link :key="assoc.item_id" :to="{
                name: 'requirement_realisations',
                params: { requirement_id: assoc.item_id }}"
              >{{
                assoc.requirement_identifier
              }}</router-link><template v-if="index < item.linked_items.length-1">,&nbsp;</template>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="!readonly"
              small
              class="mr-2"
              @click="$emit('editDocument', item)"
            >mdi-pencil</v-icon>
            <v-icon
              v-if="!readonly && !disableDelete"
              small
              @click="$emit('deleteDocument', item)"
            >mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.doc_cat {
  border-right: solid 2px #fbab26;
  border-top: solid 2px #fbab26;
}
</style>

<script>
import DocumentMdiIcon from '@/components/DocumentMdiIcon';
import DocumentUtils from '@/utils/DocumentUtils';

export default {
  components: {
    DocumentMdiIcon,
  },
  name: 'DocumentListSection',
  props: {
    readonly: Boolean,
    label: String,
    linkRequirements: Boolean,
    documents: Array,
    search: String,
    loading: Boolean,
    disableDelete: Boolean,
    oziOnPrefix: String,
    helpKey: String,
  },
  computed: {
    headers() {
      if(this.linkRequirements) {
        return [
          { text: this.$tc('document.title'), value: 'title' },
          { text: this.$tc('requirement.label'), value: 'linked_items', sortable: false, width: '180px' },
          { text: this.$tc('document.created'), value: 'created_at', width: '120px' },
          { text: 'Actions', value: 'actions', sortable: false, width: '60px' }
        ];
      } else {
        return [
          { text: this.$tc('document.title'), value: 'title' },
          { text: this.$tc('document.created'), value: 'created_at', width: '120px' },
          { text: 'Actions', value: 'actions', sortable: false, width: '60px' }
        ];
      }
    },
  },
  
  methods: {
    handleRowClick() {},
    handleFileDownload(item) {
      DocumentUtils.downloadFile(item, this.oziOnPrefix);     
    },
  },
  
}
</script>