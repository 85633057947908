var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.value,"width":"700","persistent":""},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"text-h5 grey lighten-2 mb-3"},[_vm._v(" "+_vm._s(_vm.$tc('role.label', 1))+" ")]),_c('v-card-text',[_c('v-select',{attrs:{"label":_vm.$tc('role.label', 1),"items":(_vm.roleOptions || []).map((option) => ({
            text: _vm.$langobj.parse(option.name),
            value: option.role_key
        }))},on:{"change":function($event){_vm.disableSave = false}},model:{value:(_vm.internal.role_key),callback:function ($$v) {_vm.$set(_vm.internal, "role_key", $$v)},expression:"internal.role_key"}}),(_vm.internal.role_key == 'sp')?_c('v-autocomplete',{attrs:{"items":(_vm.providerOptions || []).map((option) => ({
            text: _vm.$langobj.parse(option.name),
            value: option.service_provider_id
          }))
          .sort((a,b) => a.text.localeCompare(b.text)),"label":_vm.$tc('role.providers', 2),"multiple":"","chips":"","deletable-chips":"","placeholder":_vm.$t('role.all_providers')},on:{"change":function($event){_vm.disableSave = false}},model:{value:(_vm.internal.providerSelection),callback:function ($$v) {_vm.$set(_vm.internal, "providerSelection", $$v)},expression:"internal.providerSelection"}}):_vm._e(),(_vm.internal.role_key == 'agent')?_c('v-autocomplete',{attrs:{"items":(_vm.regionOptions || []).map((option) => ({
            text: _vm.$langobj.parse(option.name),
            value: option.region_id
          }))
          .sort((a,b) => a.text.localeCompare(b.text)),"label":_vm.$tc('role.regions', 2),"multiple":"","chips":"","deletable-chips":"","placeholder":_vm.$t('role.all_regions')},on:{"change":function($event){_vm.disableSave = false}},model:{value:(_vm.internal.regionSelection),callback:function ($$v) {_vm.$set(_vm.internal, "regionSelection", $$v)},expression:"internal.regionSelection"}}):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disableSave},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.$t('label.save'))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" "+_vm._s(_vm.$t('label.cancel'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }