<template>
  <div>
    <v-simple-table dense class="row-pointer">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('report.fls34.number') }}
            </th>
            <th class="text-left">
              {{ $t('label.description') }}
            </th>
            <th class="text-left">
              {{ $t('report.fls34.declared_status') }}
            </th>
            <th class="text-left">
              {{ $t('report.fls34.appreciation') }}
            </th>
            <th class="text-left">
              {{ $t('report.fls34.number_of_documents') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in requirements"
            :key="item.num"
            @click="handleRowClick(item)"
          >
            <td>{{ item.num }}</td>
            <td>{{ $langobj.parse(item.name) }}</td>
            <td>{{ $langobj.parse(item.status) }}</td>
            <td>{{ $langobj.parse(item.appreciation_obj) }}</td>
            <td>{{ item.document_count }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ReportRequirements",
  data() { return {
  }},

  props: {
    requirements: Array
  },

  components: {
  },

  computed: {
  },

  methods: {
    handleRowClick(item) {
      this.$router.push(
        { name: 'flsreport_requirement_detail', params:
          {
            service_provider_id: this.$route.params.service_provider_id,
            workplan_id: this.$route.params.workplan_id,
            requirement_id: item.workplan_requirements_id
          }
        }
      );
    },
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/collab.scss";
</style>