<template>
  <span>
    <template v-if="urlMatch === 'google-document'">
      <i class="far fa-file-alt fa-spacer document-icon"></i>
    </template>
    <template v-if="urlMatch === 'google-spreadsheet'">
      <span class="fa-stack fa-spacer document-icon">
        <i class="far fa-file fa-stack-2x"></i>
        <i class="fas fa-table fa-stack-1x"></i>
      </span>
    </template>
    <template v-if="urlMatch === 'google-presentation'">
      <span class="fa-stack fa-spacer document-icon">
        <i class="far fa-file fa-stack-2x"></i>
        <i class="fas fa-square fa-stack-1x"></i>
      </span>
    </template>
    <template v-if="urlMatch === 'use-extension'">
      <i :class="iconClassForExtension" class="fa-spacer document-icon"></i>
    </template>
  </span>  
</template>

<style scoped lang="scss">
.fa-stack {
  font-size: 1em;
  // padding-right: 5px;
  transform: translateY(-1px);
  
  display: inline-block;
  height: 1em;
  line-height: 1em;
  position: relative;
  vertical-align: middle;
  width: 1em;
  
  .fa-stack-2x {
    font-size: 1em;
  }
  
  .fa-stack-1x {
    font-size: 0.5em;
  }
  
  .fa-table, .fa-square {
    transform: scale(0.8) translateY(2px);
  }
  
  .fa-square {
    opacity: 0.3;
  }
}

i.document-icon {
  width: 1em;
  display: inline-block;
  text-align: center;
}
</style>

<script>

  export default {
    props: ['url'],
    data: function() { return {
    }},
    computed: {
      urlMatch() {
        if (!this.url) return 'use-extension'
        if (0 === this.url.indexOf('https://docs.google.com/document/d/')) return 'google-document'
        if (0 === this.url.indexOf('https://docs.google.com/spreadsheets/d/')) return 'google-spreadsheet'
        if (0 === this.url.indexOf('https://docs.google.com/presentation/d/')) return 'google-presentation'
        if (0 === this.url.indexOf('https://drive.google.com/file/d/')) return 'use-extension'
        if (this.urlExtension === 'csv') return 'google-spreadsheet'
        return 'use-extension'
      },
      urlExtension() {
        if (!this.url) return ''
        try {
          const url = new URL(this.url)
          return url.pathname.split('.').pop();
        } catch(e) {
          // not a full URL
          return this.url.split('.').pop();
        }
      },
      iconClassForExtension() {
        switch(this.urlExtension) {
          case "pdf":
            return "far fa-file-pdf";
          case "xls": case "xlsx":
            return "far fa-file-excel";
          case "doc": case "docx":
            return "far fa-file-word";
          case "ppt": case "pptx":
            return "far fa-file-powerpoint";
          case "jpg": case "jpeg": case "png": case "gif": case "svg":
            return "far fa-file-image";
          case "txt":
            return "far fa-file-alt";
        }
        return "far fa-file";
      }
    }
  }
</script>