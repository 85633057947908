<template>
  <v-container fluid>
    <v-form v-model="isFormValid" ref="form">
      <v-row class="mt-3">
        <v-col cols="12" sm="6" class="py-1">
          <v-text-field
            v-model="internal.name_lang.fr"
            :label="$langobj.label($t('dossier.name'), 'FR')"
            :rules="[(v) => $validators.required(v, [$t('dossier.name')])]"
            :loading="isLoading"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6" class="py-1">
          <v-text-field
            v-model="internal.name_lang.en"
            :label="$langobj.label($t('dossier.name'), 'EN')"
            :loading="isLoading"
            :readonly="readonly"
          >
            <template v-slot:append-outer>
              <help-dialog help-key="dossier.name.en"/>
            </template></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6" class="py-1">
          <v-select 
            v-model="internal.dossier_type"
            :label="$t('dossier.type')"
            :items="dossierTypeOptions"
            item-value="enum_key"
            :readonly="readonly"
          >
            <template v-slot:item='{ item }'>
              {{ $langobj.parse(item.name) }}
            </template>
            <template v-slot:selection='{ item }'>
              {{ $langobj.parse(item.name) }}
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="6" class="py-1">
          <v-select 
            v-model="internal.dossier_status"
            :label="$t('label.status')"
            :items="dossierStatusOptions"
            item-value="enum_key"
            :readonly="readonly"
          >
            <template v-slot:item='{ item }'>
              <v-icon left :color="item.aes.colour">mdi-circle-medium</v-icon>
              {{ $langobj.parse(item.name) }}
            </template>
            <template v-slot:selection='{ item }'>
              <v-icon :color="item.aes.colour">mdi-circle-medium</v-icon>
              {{ $langobj.parse(item.name) }}
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col sm="6" md="4" class="py-1">
          <v-dialog
            ref="startDateDialog"
            v-model="startDateModal"
            :return-value.sync="internal.start_date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="internal.start_date"
                :label="$t('dossier.start_date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append-outer>
                  <help-dialog help-key="dossier.start_date"/>
                </template>
              </v-text-field>
            </template>
            <v-date-picker v-model="internal.start_date" :locale="locale" scrollable :disabled="readonly">
              <v-spacer></v-spacer>
              <v-btn v-if="!readonly" text color="primary" @click="internal.start_date = null">
                {{ $t('label.clear') }}
              </v-btn>
              <v-btn v-if="!readonly" text color="primary" @click="startDateModal = false">
                {{ $t('label.cancel') }}
              </v-btn>
              <v-btn text color="primary" @click="$refs.startDateDialog.save(internal.start_date)">
                {{ $t('label.ok') }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>


      <v-row class="mt-0">
        <v-col sm="6" md="4" class="py-1">
          <v-dialog
            ref="dueDateDialog"
            v-model="dueDateModal"
            :return-value.sync="internal.due_date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="internal.due_date"
                :label="$t('dossier.due_date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append-outer>
                  <help-dialog help-key="dossier.due_date"/>
                </template>
              </v-text-field>
            </template>
            <v-date-picker v-model="internal.due_date" :locale="locale" scrollable :disabled="readonly">
              <v-spacer></v-spacer>
              <v-btn v-if="!readonly" text color="primary" @click="internal.due_date = null">
                {{ $t('label.clear') }}
              </v-btn>
              <v-btn v-if="!readonly" text color="primary" @click="dueDateModal = false">
                {{ $t('label.cancel') }}
              </v-btn>
              <v-btn text color="primary" @click="$refs.dueDateDialog.save(internal.due_date)">
                {{ $t('label.ok') }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>      
      </v-row>

      <v-row class="mt-0 mb-2">
        <v-col sm="6" md="4" class="py-1">
          <v-dialog
            ref="designationDateDialog"
            v-model="designationDateDialog"
            :return-value.sync="internal.date_of_designation"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="internal.date_of_designation"
                :label="$t('dossier.date_of_designation')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append-outer>
                  <help-dialog help-key="dossier.date_of_designation"/>
                </template>
              </v-text-field>
            </template>
            <v-date-picker v-model="internal.date_of_designation" :locale="locale" scrollable :disabled="readonly">
              <v-spacer></v-spacer>
              <v-btn v-if="!readonly" text color="primary" @click="internal.date_of_designation = null">
                {{ $t('label.clear') }}
              </v-btn>
              <v-btn v-if="!readonly" text color="primary" @click="designationDateDialog = false">
                {{ $t('label.cancel') }}
              </v-btn>
              <v-btn text color="primary" @click="$refs.designationDateDialog.save(internal.date_of_designation)">
                {{ $t('label.ok') }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>      
      </v-row>

      <tip-tap
        spellcheck="false"
        v-model="internal.description"
        :label="$t('dossier.description')"
        help-key="dossier.description"
        :loading="isLoading"
        :readonly="readonly"
      />

      <v-row v-if="!readonly">
        <v-col>
          <v-btn color="primary" @click="handleSave" 
            :disabled="disableSave" :loading="isSaving">
            {{ $t('label.save') }}
          </v-btn>
          <v-btn color="primary" text @click="handleCancel">
            {{ $t('label.cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <ListComment v-if="priv('agent')"
      :parent_id="this.$route.params.designation_dossier_id"
      parent_class="designation_dossier"
      :readonly="readonly">
    </ListComment>

  </v-container>  
</template>

<script>
import _ from 'lodash';
import ObjectUtils from '@/utils/ObjectUtils';
import i18n, { locales } from '@/i18n';
import ListComment from '@/components/comment/ListComment.vue'
import {privileges} from '@/mixins/privileges.js';

export default {
  name: 'DossierGeneralInfo',
  components: {ListComment},
  mixins: [privileges],
  props: ['readonly'],
  data: function() {return {
    isFormValid: true,
    storeData: null,
    internal: {name_lang: {}},
    isLoading: true,
    isSaving: false,
    dossierStatusOptions: [],
    dossierTypeOptions: [],

    startDateModal: false,
    dueDateModal: false,
    designationDateDialog: false,
  }},

  computed: {
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    },
    locale() {return i18n.locale;},
  },

  methods: {
    refresh() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/dossier/get.php',
          { params: { designation_dossier_id: this.$route.params.designation_dossier_id }})
        .then(response => {
          this.storeData = response.data.dossier;
          this.internal = _.cloneDeep(this.storeData);
          this.isLoading = false;
        });
      this.$axios
        .get(this.$root.$data.server_base + '/api/admin/enum/list.php',
          {params: {family_key: 'dossier_status'}})
        .then((response) => {
          this.dossierStatusOptions = response.data.enums;
        });
      this.$axios
        .get(this.$root.$data.server_base + '/api/admin/enum/list.php',
          {params: {family_key: 'dossier_type'}})
        .then((response) => {
          this.dossierTypeOptions = response.data.enums;
        });
    },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }  
      let obj = {};
      let api = "";
      api = "/api/contact/update.php";
      obj = ObjectUtils.getObjectDiff(this.internal, this.storeData);  
      obj.designation_dossier_id = this.internal.designation_dossier_id;
      obj.service_provider_id = this.$route.params.service_provider_id;
      
      this.saving = true;
      this.$emit('saving', 'saving');
      this.$axios
        .put(this.$root.$data.server_base + '/api/dossier/update.php', obj)
        .then((response) => {
          this.storeData = response.data.dossier;
          this.internal = _.cloneDeep(this.storeData);
          this.saving = false;
          this.$emit('refresh');
          this.$emit('saving', 'success');
        })
        .catch((error) => this.$emit('saving', 'error'));
    },
    handleCancel() {
      this.refresh();
    },
  },
  mounted () {
    this.refresh();
  }
}
</script>