<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <ticket-header :ticket="ticket"></ticket-header>
        
        <h2 class="mt-2">{{ ticket.title }}&nbsp;</h2>
        
      </v-col>
      <v-col cols="2" align="right">
        <saving-indicator ref="saving" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <toggable-text-field 
          ref="title"
          v-model="ticket.title"
          :label="$t('ticket.title')"
          :rules="[(v) => $validators.required(v)]"
          @save="handleSaveOneParam('title', ...arguments)"
        ></toggable-text-field>
        
        <div v-if="ticket.service_provider_id" class="form-group">
          <div class="font-weight-medium mt-3 mb-0">{{ $tc('provider.label', 1) }}</div>
            <router-link :to="{ name: 'sp_tableaudebord', params: {
                service_provider_id: ticket.service_provider_id }}"
            >
              {{ $langobj.parse(ticket.service_provider_name_lang) }}
            </router-link>
        </div>

        <div v-if="ticket.designation_dossier_id">
          <div class="font-weight-medium mt-3 mb-0">{{ $tc('dossier.label', 1) }}</div>
          <div>
            <router-link :to="{ name: 'dossier_summary', params: {
                service_provider_id: ticket.service_provider_id, 
                designation_dossier_id: ticket.designation_dossier_id}}"
            >
              {{$langobj.parse(ticket.designation_dossier_name_lang)}}
            </router-link>
          </div>
        </div>

        <div v-if="ticket.requirement_id" class="form-group">
          <div class="font-weight-medium mt-3 mb-0">{{ $tc('requirement.label', 1) }}</div>
          <router-link :to="{ name: 'requirement_workplan', params: {
              service_provider_id: ticket.service_provider_id,
              designation_dossier_id: ticket.designation_dossier_id,
              requirement_id: ticket.requirement_id}}"
          >
            {{ticket.requirement_identifier}} - {{$langobj.parse(ticket.requirement_name)}}
          </router-link>
        </div>

        <div v-if="!ticket.is_lifecycle_ticket">
          <v-row>
            <v-col cols="12" sm="4">
              <toggable-date-picker
                ref="effective_at"
                v-model="ticket.effective_at"
                :label="$t('ticket.effective_at')"
                @save="handleSaveOneParam('effective_at', ...arguments)"
              ></toggable-date-picker>
            </v-col>
          </v-row>
        </div>
        
        <div v-if="ticket.is_lifecycle_ticket || ticket.type_key == 'designation_history'">
          <v-row>
            <v-col cols="12" sm="4">
              <toggable-date-picker
                ref="due_date"
                v-model="ticket.due_date"
                :label="$t('ticket.due_date')"
                @save="handleSaveOneParam('due_date', ...arguments)"
              ></toggable-date-picker>
            </v-col>
          </v-row>
        </div>

        <toggable-tip-tap 
          ref="description"
          v-model="ticket.description"
          :label="$t('ticket.details')"
          @save="handleSaveOneParam('description', ...arguments)"
          extended
        ></toggable-tip-tap>
          
        <ListDocuments v-if="ticket && ticket.service_provider_id" :parent-id="$route.params.ticket_id" parent-type="ticket" :service-provider-id="ticket.service_provider_id"></ListDocuments>
                  
        <hr style="color:#d0d0d0;width:100%;height:0;border:0.5px solid">
        <ListComment :parent_id="$route.params.ticket_id" parent_class="ticket"></ListComment>

      </v-col>
      <v-col cols="3">

        <v-select
          clearable
          v-model="responsable"
          :items="responsable_option"
          :label="$t('ticket.responsable')"
          :placeholder="$t('ticket.responsable')"
          item-text="responsable_name"
          item-value="user_id"
          v-on:input="saveAssignee($event)"
          
        >
          <template v-slot:selection="{ item }">
            <user-avatar :user="item" class="mr-2"/>
            {{ item.responsable_name }}
          </template>
          <template v-slot:item="{ item }">
            <user-avatar :user="item" class="mr-2"/>
            {{ item.responsable_name }}
          </template>
        </v-select>

        <v-select
          v-model="status"
          :label="$t('ticket.status')"
          :items="
            (status_option || []).map((option) => ({
              text: $langobj.parse(option.name_lang),
              value: option.ticket_status_id,
            }))"
          v-on:input="saveStatus($event)"
        ></v-select>

        <priority-pulldown
          v-if="ticket.is_lifecycle_ticket"
          v-model="ticket.priority"
          v-on:input="savePriority()"
        ></priority-pulldown>
        
        <p class="created mt-2" :title="ticket.created_at">
          {{ $t('ticket.created', {name: ticket.created_by,
            from: timeString(ticket.created_at)} )}}
        </p>
        <p class="created mt-2" v-if="showModifiedDate" :title="ticket.modified_at">
          {{ $t('ticket.updated') }} {{ timeString(ticket.modified_at) }}
        </p>
        <p class="created mt-2" v-if="showClosedDate" :title="ticket.closed_at">
          {{ $t('ticket.closed') }} {{ timeString(ticket.closed_at) }}
        </p>
       
        <delete-dialog
          v-if="priv('admin')"
          :title="$t('ticket.delete')"
          v-on:delete-object="deleteTicket"
        >
        </delete-dialog>

      </v-col>
    </v-row>

    <dossier-update-services-statuses
      :dispatch="considerUpdatingServices"
      v-on:done="considerUpdatingServices = false"
      :dossier-id="ticket.designation_dossier_id"
      :service-provider-id="ticket.service_provider_id"
    ></dossier-update-services-statuses>
  </v-container>

</template>

<style>
.ticket_type {
  color: #808080;
  font-size: 1.3em;
}
.vdp-datepicker__clear-button {
  position: absolute;
  right: 0.8em;
  top: 50%;
  transform: translateY(-50%);
}
.due-date-picker {
  display: inline-block;
  width: 10em;
  margin-right: 1em;
}

.created {
  color: #808080;
  font-size: 0.8em;
  font-style: italic;
}
</style>

<script>

import axios from 'axios';
import deepClone from "clone-deep";
import equal from "fast-deep-equal";
import ListComment from '@/components/comment/ListComment.vue';
import ListDocuments from '@/components/ListDocuments.vue'
import Datepicker from 'vuejs-datepicker'
import PriorityPulldown from '@/components/PriorityPulldown.vue'
import DossierUpdateServicesStatuses from '@/components/DossierUpdateServicesStatuses.vue'
import TicketHeader from '@/components/ticket/TicketHeader.vue';
import DeleteDialog from '@/components/framework/DeleteDialog.vue';
import UserAvatar from '@/components/user/UserAvatar.vue';

import {privileges} from '@/mixins/privileges.js';

import i18n, { locales } from '@/i18n';
import Vue from 'vue';

export default {
  mixins: [privileges],
  data: function() {return {
    originalTicket: undefined,
    show_confirm:false,
    showsuperadmin:false,
    readmode: {title:true, description:true},
    keyword:'',
    ticket_id:'',
    default_priority: 2,
    ticket: {
      modified_at:'',
      effective_at: null,
      service_provider: '',
      service_provider_id: null,
      requirement_id: null,
      requirement_master_num: '',
      requirement_master_name: '',
      designation_dossier_id: null,
      designation_dossier_name: ''
    },
    errors: {title: '',description:''},
    status:'',
    responsable:'',
    orig_responsable:'',
    orig_priority:null,
    status_option: [],
    responsable_option: [],
    considerUpdatingServices: false
  }},

  name: 'TicketEdition',
  components: {
    ListComment,
    ListDocuments,
    Datepicker,
    PriorityPulldown,
    DossierUpdateServicesStatuses,
    TicketHeader,
    DeleteDialog,
    UserAvatar
  },
  computed: {
    locale() {return i18n.locale;},
    datePickerLanguage() {
      if(this.locale == 'fr')
        return fr;
      return en;
    },
    showSaveButton() {
      return equal(this.ticket, this.originalTicket);
    },
    showModifiedDate() {
      return this.ticket.modified_at && this.ticket.modified_at!="0000-00-00 00:00:00"
    },
    showClosedDate() {
      return this.isClosed
    },
    isClosed() {
      return this.ticket.closed_at && this.ticket.closed_at!="0000-00-00 00:00:00"
    }
  },
  methods: {
    timeString(timestamp) {
      return this.moment.utc(timestamp).local().format("LLL") + ' (' +
        this.moment.utc(timestamp).fromNow() +')';
    },
    refresh: function() {
      axios
        .get(this.$root.$data.server_base + '/api/ticket/get_ticket.php',
          { params: {ticket_id: this.$route.params.ticket_id}})
        .then(response => {
          this.$checkApiErrors(response.data);
          this.originalTicket = deepClone(response.data.ticket);
          this.ticket = response.data.ticket;
          this.ticket_id=this.$route.params.ticket_id;
          this.showsuperadmin=this.ticket.showsuperadmin;
          this.status_option=response.data.ticket_status_options;
          this.status=this.ticket.status;
          this.responsable=this.ticket.responsable_id;
          this.orig_responsable=this.ticket.responsable_id;
          this.responsable_option=response.data.responsable;

          if (this.ticket.is_lifecycle_ticket) {
            if (this.ticket.priority === null) {
              this.ticket.priority = this.default_priority
            }

            this.orig_priority=this.ticket.priority
          }
        })
    },
    save: function()
    {
      this.$refs.saving.saving();
      let data = {
        ticket_id:this.ticket_id,
        ticket_status:this.status,
        responsable:this.responsable,
        title:this.ticket.title,
        effective_at:this.ticket.effective_at,
        description:this.ticket.description
      }

      if (this.ticket.is_lifecycle_ticket) {
        data['due_date'] = this.ticket.due_date
        data['priority'] = this.ticket.priority
      }

      axios
        .post(this.$root.$data.server_base + '/api/ticket_save.php',
            data,
            {headers:{
                'Content-Type': 'application/json',
          }})
        .then((response) => {
          this.$checkApiErrors(response.data);
          this.readmode.title=true;
          this.readmode.description=true;
          this.$refs.saving.success();
          this.refresh();
        })
        .catch((error) => this.$refs.saving.error())
    },
    handleSaveOneParam(key, value) {
      this.$refs.saving.saving();
      this.$axios
        .put(this.$root.$data.server_base + '/api/ticket_save.php',
          { ticket_id: this.ticket_id,
            [key]: this.ticket[key]})
        .then((response => {
          this.$refs[key].markAsSaved();
          this.$refs.saving.success();
          this.refresh();
        }))
        .catch((error) => this.$refs.saving.error())
    },
    saveStatus: function(event)
    {
      this.$refs.saving.saving();
      let data = {
        ticket_id:this.ticket_id,
        ticket_status: this.status,
      }

      axios
        .post(this.$root.$data.server_base + '/api/ticket_save.php',
            data,
            {headers:{
                'Content-Type': 'application/json',
          }})
        .then((response) => {
          this.$checkApiErrors(response.data);
          this.catchImpactOfDesignation(response.data);
          this.ticket.modified_at = response.data.modified_at;
          this.ticket.closed_at = response.data.closed_at;
          this.$refs.saving.success();
        })
        .catch((error) => this.$refs.saving.error())
    },

    saveAssignee () {
      this.$refs.saving.saving();
    
      let data = {
        ticket_id:this.ticket_id,
        responsable: this.responsable ? this.responsable : 0
      }

      axios
        .post(this.$root.$data.server_base + '/api/ticket_save.php',
            data,
            {headers:{
                'Content-Type': 'application/json',
          }})
        .then((response) => {
          this.$checkApiErrors(response.data);
          this.ticket.modified_at = response.data.modified_at;
          this.$refs.saving.success();
        })
        .catch((error) => this.$refs.saving.error())
    },

    savePriority: function()
    {
      Vue.nextTick(() => {
        this.$refs.saving.saving();
        if(this.ticket.priority===this.orig_priority) return;

        let data = {
          ticket_id:this.ticket_id,
          priority:this.ticket.priority
        }

        axios
          .post(this.$root.$data.server_base + '/api/ticket_save.php',
              data,
              {headers:{
                  'Content-Type': 'application/json',
            }})
          .then((response) => {
            this.$checkApiErrors(response.data);
            this.ticket.modified_at = response.data.modified_at;
            this.$refs.saving.success();
          })
          .catch((error) => this.$refs.saving.error())
      })
    },

    deleteTicket: function()
    {
      axios
        .get(this.$root.$data.server_base + '/api/ticket/ticket_delete.php',
          { params:{ticket_id:this.ticket_id}})
        .then((response) => {
          if(!this.$checkApiErrors(response.data))
            this.$router.go(-1);
          this.show_confirm = false;
        })
    },

    fixDate(event) {
      if (event === null) { return null }
      return this.moment(event).format('YYYY-MM-DD');
    },
    parseDate(dateStr) {
      const [year, month, date] = dateStr.substring(0, 10).split('-')
      if (year === undefined || month === undefined || date === undefined ) { return null }
      if (Number(year) === 0 || Number(month) === 0 || Number(date) === 0 ) { return null }
      return new Date(year, month - 1, date)
    },
    catchImpactOfDesignation(response) {
      if (response.impact === "dossier_updated_to_designated") {
        this.considerUpdatingServices = true;
      }
    }
  },
  

  mounted () {
    this.refresh();
  }
}
</script>
