<template>
  <div>
    <router-link class="text-decoration-none" :to="{name: 'admin-ticket-status'}">
      <span class="text-h6 text--primary">←&nbsp;</span>
      {{ $t('label.return_to_list') }}
    </router-link>
    <h2>{{ isCreate ? $t('ticket_status.create') : $t('ticket_status.modify') }}</h2>
    <v-row class="mt-2">
      <v-col cols=3>
        <div>
          <v-card-text>         
            <div class="mt-2 grey--text">{{ $langobj.parse(internal.name) }}</div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <div>
              <v-btn class="px-1" color="primary" text @click="$root.notDone">
                <v-icon class="mr-1">mdi-delete</v-icon> {{ $t('label.delete') }}
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-col>

      <v-col cols=9>
        <v-card>
          <v-form v-model="isFormValid" ref="form">
            <v-card-text>
              <v-text-field
                readonly
                v-model="internal.ticket_type_lang[locale]"
                :label="$t('ticket_status.ticket_type')"
                :rules="[(v) => $validators.required(v, [$t('ticket_status.ticket_type')])]"
              ></v-text-field>
              <v-text-field
                readonly
                v-model="internal.ticket_status_key"
                :label="$t('ticket_status.ticket_status_key')"
                :rules="[(v) => $validators.required(v, [$t('ticket_status.ticket_status_key')])]"
              ></v-text-field>
              <v-text-field
                v-model="internal.sort"
                :label="$t('ticket_status.sort')"
                :rules="[(v) => $validators.number(v, [$t('ticket_status.sort')])]"
              ></v-text-field>
              <v-text-field
                v-model="internal.aes.colour"
                :label="$t('ticket_status.colour')"
              ></v-text-field>
              <v-row
                v-for="locale in $locales"
                :key="locale.value"
                class="mt-2"
              >
                <v-col cols=1>
                  <p class="text-h4 primary--text">{{ locale.toLocaleUpperCase() }}</p>
                </v-col>
                <v-col cols=11>
                  <v-text-field
                    spellcheck="false"
                    v-model="internal.name_lang[locale]"
                    :label="$langobj.label($t('ticket_status.name'), locale)"
                    :rules="[(v) => $validators.required(v, [$t('ticket_status.name')])]"
                    :loading="isLoading"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn color="primary" @click="handleSave" :disabled="disableSave">
              {{ $t('label.save') }}
            </v-btn>
            <v-btn color="primary" text @click="handleCancel">
              {{ $t('label.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>  
</template>

<script>
import _ from 'lodash';
import ObjectUtils from '@/utils/ObjectUtils';
import i18n, { locales } from '@/i18n';

export default {
  name: 'AdminTicketStatusDetails',
  data: function() {
    return {
      storeData: null,
      internal: {ticket_status_id: null, name_lang: {}, aes: {}, ticket_type_lang: {}},
      isLoading: false,
      isFormValid: true,
    }
  },

  computed: {
    locale() {return i18n.locale;},
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    },
    isCreate() {
      return this.$route.params.ticket_status_id == 'cr';
    },
  },

  methods: {
    getDataFromApi() {
      if(this.$route.params.ticket_status_id == 'cr')
        return;
      this.isLoading = true;
      this.$axios
        .get("/api/admin/ticket_status/get.php",
          {params:{ticket_status_id: this.$route.params.ticket_status_id}})
        .then(response => {
          this.storeData = response.data.ticket_status;
          if(this.storeData.aes == null) this.storeData.aes = {};
          this.internal = _.cloneDeep(this.storeData);
          this.isLoading = false;
      });
    },
    // handleDelete() {
    //   this.$axios
    //     .delete('/api/admin/ticket_status/remove.php', {
    //       params: {ticket_status_id: this.$route.params.ticket_status_id}})
    //     .then(response => {
    //       if(!response.data.server_error)
    //         this.$router.push({name: 'admin-ticket-status'});
    //     });
    // },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }        
      let obj = {};
      let api = "";
      if(this.isCreate) {
        api = "/api/admin/ticket_status/create.php";
        obj = this.internal;
      }
      else {
        api = "/api/admin/ticket_status/update.php";
        obj = ObjectUtils.getObjectDiff(this.internal, this.storeData);  
        obj.ticket_status_id = this.internal.ticket_status_id;
      }

      this.$axios
        .put(api, obj, { headers: {'Content-Type': 'application/json' }})
        .then((response) => {          
          this.$router.push({name: 'admin-ticket-status'});
        });
    },
    handleCancel() {
      if(this.isCreate || this.disableSave) {
        this.$router.push({name: 'admin-ticket-status'});
      } else {
        this.getDataFromApi();
      }
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>