import LocaleUtils from './LocaleUtils';

// interface Email {
//   key: string;
//   name: LanguageObject;
//   subject?: LanguageObject;
//   body?: LanguageObject;
//   signature?: LanguageObject;
// }

const generateDefault = () => {
  return {
    user_id: 0,
    username: null,
    email: null,
    first_name: null,
    last_name: null,
    state: null,
    state_lng: LocaleUtils.langobj.generate(),
    language_preference: null,
    last_login: null,
    
  };
};

// const api = {
//   list: async () => {
//     const resp = await axios.get<Email[]>('email/list.php');
//     return resp.data;
//   },
//   get: async (key: string) => {
//     const resp = await axios.get<Email>('email/get.php', {
//       params: { key: key },
//     });
//     return resp.data;
//   },
//   create: async (item: Partial<Email>) => {
//     const resp = await axios.post<Email>('email/create.php', {
//       ...APIUtils.clean(item),
//     });
//     return resp.data;
//   },
//   update: async (item: Partial<Email>) => {
//     const resp = await axios.put<Email>('email/update.php', {
//       ...APIUtils.clean(item),
//     });
//     return resp.data;
//   },
//   remove: async (key: string) => {
//     const resp = await axios.delete<Email>('email/remove.php', {
//       params: { key: key },
//     });
//     return resp.data;
//   },
// };

export default { generateDefault };
// export { Email };