<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Date d'échéance pour l'envoi"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" locale="fr-ca" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
              Annuler
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-stepper v-model="e6" vertical>
      <template v-for="item in items">      
        <v-stepper-step :key="'s'+item.step" :complete="item.step <= e6" :step="item.step">
          {{item.text_short}}
        <small>{{item.text_long}}</small>
        </v-stepper-step>
        <v-stepper-content :key="'c'+item.step" :step="item.step" class="pb-3 pt-3">
          <v-card color="grey lighten-3"
            class="mb-4"
            height="70px"
          >Ici vont les instructions pour passer à la prochaine étape</v-card>
          <v-btn color="primary" @click="e6++;">
          Passer à la prochaine étape
          </v-btn>
        </v-stepper-content>
      </template>
    </v-stepper>
  </v-container>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'OngletStatut',

  data () {
    return {
      e6: 0,
      items: [
        { step: 0, text_short: "Démarrage", text_long: "Le dossier a été créé" },
        { step: 1, text_short: "Identification", text_long: "Identification initiale des programmes et services assujetis" },
        { step: 2, text_short: "Auto-évaluation", text_long: "Cochez, pour chaque exigence, les pratiques en cours." },
        { step: 3, text_short: "Mise en oeuvre et documentation", text_long: "Les mécanismes et protocoles requis sont en place et documentés" },
        { step: 4, text_short: "Soumission pour évaluation", text_long: "La documentation a été soumise pour évaluation par l'Entité" },
        { step: 5, text_short: "Évaluation", text_long: "L'évaluation par l'entité a été complétée et envoyée" },
        { step: 6, text_short: "Présentation au comité", text_long: "La demande de soumission est présentée au comité" },
        { step: 7, text_short: "Présentation au CA", text_long: "La demande de soumission est présentée au CA" },
        { step: 8, text_short: "Envoi au ministère", text_long: "La demande de soumission est envoyée au ministère" },
        { step: 9, text_short: "Approbation du ministère", text_long: "L'organisme est désigné / attestation conforme" },
      ],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
    }    
  }
});
</script>