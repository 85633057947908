<script>
import { HorizontalBar } from 'vue-chartjs'
import Stacked100 from 'chartjs-plugin-stacked100';
import chroma from "chroma-js";
import i18n from '@/i18n';

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export default {
  extends: HorizontalBar,
  props: ['options','height','data'],
  methods: {
    newData: function() {
      // console.log(this.l_datasets);
      this.renderChart({
        labels: [this.$tc('dossier.step')],
        datasets: this.l_datasets},
        {
          plugins: { 
            stacked100: { enable: true },
          },
          tooltips: {mode: "nearest"},
          responsive: true,
          maintainAspectRatio: false,

          legend: {
            // display: false
          },
          // title: {
          //   display: true,
          //   fontSize: 20,
          //   text: 'État des dossiers'
          // },
          // Can't just just `stacked: true` like the docs say
          scales: {
            yAxes: [{
              stacked: true
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: '% dossiers'
              },
              stacked: true
            }]
          },
          animation: {
            duration: 500,
          },
        }
      )
    }
  },
  computed: {
    locale() { return i18n.locale; },
    l_datasets() {
      // create color palette
      // const colors = chroma.scale(['#fbab26','#226CBF']).mode('lch').colors(this.data.length);

      // console.log(this.data.length);
      return this.data.map((item, index) => ({
        label: this.$langobj.parse(item.name),
        data: [item.count],
        fill: true,
        backgroundColor: hex2rgba(item.aes.colour,0.2),
        borderColor: item.aes.colour,
        borderWidth: 1
      }));
    },
  },
  components: {
    Stacked100
  },
  watch: {
    data: function() {
      this.newData();
    },
    locale () {
      this.newData();
    }
  },
  mounted() {
    this.newData();
  }
}
</script>
