<template>
  <v-navigation-drawer
    :clipped="true"
    style="font-size: 14px; background-color: #f8f8f8"
    app
    width="250"
    permanent
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6" style="white-space: normal">
          {{ $langobj.parse(service_provider.name) }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-icon class="mr-2">mdi-map-marker-outline</v-icon>
          {{ service_provider.address.city }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="service_provider.url">
          <a :href="$langobj.parse(service_provider.url)" target="_blank">
            <v-icon class="mr-2">mdi-open-in-new</v-icon>Site Web
          </a>
        </v-list-item-subtitle>
         <v-list-item-subtitle>
          <v-icon class="mr-2">mdi-file-certificate-outline</v-icon>
          {{ $langobj.parse(service_provider.design_status_name) }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="agentName">
          <v-icon class="mr-2">mdi-face-agent</v-icon>
          {{ this.agentName }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="service_provider.state == 2" color="red">
          <v-icon class="mr-2" color="red">mdi-hand-back-left-off</v-icon>
          <span class="font-weight-bold red--text">{{ $t('label.inactive') }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        router :to="item.to"
        link
        active-class="oz_menu_active"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}
          <v-badge 
            v-if="item.count"
            color="primary"
            inline
            class="mt-0"
            >
              <template v-slot:badge>{{ item.count }}</template>
          </v-badge>
          </v-list-item-title>
          
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        router
        exact
        :to="{name:'dossiers'}"
        link
        active-class="oz_menu_active"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>{{ dossierSelected ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ this.$tc('navigation.dossier', dossierSelected ? 1 : 2) }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="dossierSelected" active-class="oz_menu_active">
            {{ $langobj.parse(dossier_name) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-for="item in dossierItems"
        :key="item.title"
        router :to="item.to"
        dense
        link
        active-class="oz_menu_active"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        router
        exact
        :to="{name:'resources'}"
        link
        active-class="oz_menu_active"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>mdi-library</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ this.$t('navigation.resources') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>

<style>
.oz_menu_active {
  background-image: linear-gradient(to right, #89b3e1, #216bbf);
  color: white !important;
  border-radius: 0 25px 25px 0;
  margin-right: 8px;
}
</style>


<script>
import {privileges} from '@/mixins/privileges.js';
import axios from 'axios';
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'

export default {
  name: 'ServiceProviderSideNav',
  mixins: [privileges],

  data() { return {
    service_provider: {address: {}},
    dossier_name: {},
    currentSelectedDossier: null,
  }},

  computed: {
    agentName() {
      if(this.service_provider.first_name || this.service_provider.last_name )
        return this.service_provider.first_name.trim() + ' ' +
          this.service_provider.last_name.trim();
      return null;
    },
    items() { 
      return this.filterArrayForPrivilege([
        {
          title: this.$tc('navigation.dashboard'),
          icon: 'mdi-view-dashboard', to: {name: 'sp_tableaudebord'}
        },
        {
          title: this.$tc('navigation.general'),
          icon: 'mdi-folder-information', to: {name: 'sp_info'}
        },
        { 
          title: this.$tc('navigation.contact'),
          icon: 'mdi-card-account-mail', to: {name:'contacts'}
        },
        // {
        //   title: this.$tc('navigation.service', 2), 
        //   icon: 'mdi-toolbox-outline', to: {name:'services'}
        // },
        // {
        //   title: this.$tc('navigation.ticket', 2),
        //   icon: 'mdi-pen', 
        //   to: {
        //     name: 'providertickets',
        //     query: {ticket_resolution: 'open'}
        //   },
        //   count: this.service_provider.open_ticket_count,
        //   privilege: 'agent'
        // },
        {
          title: this.$tc('navigation.note', 2),
          icon: 'mdi-notebook',
          to: { name: 'provider_notes' },
          count: this.service_provider.note_count,
          privilege: 'agent'
        },
        {
          title: this.$tc('navigation.flsreport', 2),
          icon: 'mdi-file-chart', to: {name:'flsreports'}
        },       
        { 
          title: this.$tc('navigation.document_library'),
          icon: 'mdi-folder-file', to: {name:'documents'}
        },
      ])
    },
    dossierSelected() {
      return this.currentSelectedDossier > 0;
    },
    dossierItems() {
      if(!this.dossierSelected)
        return [];
      return this.filterArrayForPrivilege([
        {
          title: this.$t('dossier.menu.summary'),
          icon: 'mdi-view-dashboard',
          to: {
            name: 'dossier_summary',
            params: {designation_dossier_id: this.currentSelectedDossier}
          },      
        },
        {
          title: this.$t('dossier.menu.general'),
          icon: 'mdi-information',
          to: {
            name: 'dossier_general',
            params: {designation_dossier_id: this.currentSelectedDossier}
          },
        },
        {
          title: this.$t('dossier.menu.workplan'),
          icon: 'mdi-account-hard-hat',
          to: {
            name: 'dossier_workplan',
            params: {designation_dossier_id: this.currentSelectedDossier}
          },
        },
        {
          title: this.$t('dossier.menu.elements'),
          icon: 'mdi-format-list-checks',
          to: {
            name: 'dossier_elements',
            params: {designation_dossier_id: this.currentSelectedDossier}
          },
        },
        {
          title: this.$t('dossier.menu.requirements'),
          icon: 'mdi-playlist-check',
          to: {
            name: 'dossier_requirements',
            params: {designation_dossier_id: this.currentSelectedDossier}
          },
        },
        {
          title: this.$t('dossier.menu.documents'),
          icon: 'mdi-file-document-multiple',
          to: {
            name: 'dossier_documents',
            params: {designation_dossier_id: this.currentSelectedDossier}
          },
        },
        // {
        //   title: this.$t('dossier.menu.hr'),
        //   icon: 'mdi-doctor',
        //   to: {
        //     name: 'dossier_hr',
        //     params: {designation_dossier_id: this.currentSelectedDossier}
        //   },
        // },
      ]);
    }
  },

  watch: {
    '$route.params.designation_dossier_id'(value) {
      if(value > 0 ) {
        this.getDossierInfo();
      }
    },
    '$route.params.service_provider_id'(value) {
      if(value > 0 ) {
        this.refresh();
      }
    }
  },

  methods: {
    refresh() {
      axios
        .get(this.$root.$data.server_base + '/api/service_provider/get_concise.php',
          { 
            params: { 
              service_provider_id: this.$route.params.service_provider_id
          }})
        .then(response => (
          this.service_provider = response.data.service_provider
        ))
    },
    getDossierInfo() {
      if(this.$route.params.designation_dossier_id > 0) {
        this.currentSelectedDossier = this.$route.params.designation_dossier_id;
        axios.get(this.$root.$data.server_base + '/api/dossier/get.php', {
          params: { 
            service_provider_id: this.$route.params.service_provider_id,
            designation_dossier_id: this.$route.params.designation_dossier_id,
          }})
          .then(response => {
            this.dossier_name = response.data.dossier.name_lang;
          });
      }
    }
  },
  mounted () {
    this.refresh();
    this.getDossierInfo();
  },
  created() {
    // adds the event listener function that will handle the event
    GlobalEventEmitter.$on('refreshNavSideBar', () => {
      this.refresh();
    })
  },
  beforeDestroy() {
    // removes event listener
    GlobalEventEmitter.$off('refreshNavSideBar')
  },
}
</script>
