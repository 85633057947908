<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <div>
          <v-icon>mdi-web</v-icon>
          <span>
            {{ locales[selected].value.toUpperCase() }}
          </span>
        </div>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item-group v-model="selected" color="primary">
          <v-list-item v-for="(locale, i) in locales" :key="i">
            <v-list-item-title>{{ locale.label }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import i18n, { locales, setI18n } from '@/i18n';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    locales() {
      return locales;
    },

    selected: {
      get() {
        return this.locales.findIndex((locale) => locale.value === i18n.locale);
      },
      set(selected) {
        setI18n(this.locales[selected].value);
      },
    },
  },
});
</script>