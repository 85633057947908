<template>
  <v-navigation-drawer :clipped="true" style="font-size: 14px;" app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ $t('navigation.admin') }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav >
      <v-list-item
        v-for="item in items"
        :key="item.title"
        router :to="item.to"
        link
      >
        <template>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {privileges} from '@/mixins/privileges.js';

export default {
  name: 'TheSideBar',
  mixins: [privileges],

  computed: {
    items() { 
      return this.filterArrayForPrivilege([
        {
          title: this.$tc('admin.users'),
          icon: 'mdi-account-supervisor', to: {name: 'admin-users'},
          privilege: 'superadmin'
        },
        {
          title: this.$tc('admin.emails'),
          icon: 'mdi-email', to: {name: 'admin-emails'},
          privilege: 'superadmin'
        },
        {
          title: this.$tc('admin.provider'),
          icon: 'mdi-hand-heart', to: {name: 'admin-providers'},
          privilege: 'admin'
        },
        {
          title: this.$tc('admin.designation_models'),
          icon: 'mdi-certificate', to: {name: 'admin-design-models'},
          privilege: 'admin'
        },
        {
          title: this.$tc('admin.enum'),
          icon: 'mdi-clipboard-list', to: {name: 'admin-enumerations'},
          privilege: 'admin'
        },
        {
          title: this.$tc('admin.ticket_status'),
          icon: 'mdi-ticket-confirmation', to: {name: 'admin-ticket-status'},
          privilege: 'admin'
        },
         {
          title: this.$tc('help.label', 2),
          icon: 'mdi-help-box-multiple', to: {name: 'admin-help-dialogs'},
          privilege: 'admin'
        },
        {
          title: this.$tc('admin.version'),
          icon: 'mdi-git', to: {name: 'version'},
          privilege: 'admin'
        },
        {
          title: this.$tc('admin.scripts'),
          icon: 'mdi-script-text-play', to: {name:'scripts'},
          privilege: 'superadmin'
        },
      ])
    },
  }
}
</script>
