<template>
  <div>
    <h2>{{ $t('admin.ticket_status') }}</h2>
    <v-row class="justify-end">
      <v-col class="text-right">
        <v-btn @click="$root.notDone" color="primary" outlined>
          <v-icon>mdi-plus</v-icon>
          {{ $t('label.add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      item-key="enum_id"
      :loading="isLoading"
      @click:row="handleRowClick"
    >
      <template v-slot:[`item.ticket_type_lang`]="{ item }">
        {{ $langobj.parse(item.ticket_type_lang) }}
      </template>
      <template v-slot:[`item.name_lang`]="{ item }">
        {{ $langobj.parse(item.name_lang) }}
      </template>
    </v-data-table>
  </div>  
</template>

<script>
export default {
  name: 'AdminTicketStatus',
  data: function() {
    return {
      items: [],
      isLoading: true,
    }
  },

  computed: {
    headers() {
      return [
        { text: this.$tc('ticket_status.ticket_type'), value: 'ticket_type_lang', sortable: false },
        { text: this.$tc('ticket_status.ticket_status_key'), value: 'ticket_status_key' },
        { text: this.$tc('ticket_status.name'), value: 'name_lang' },
        { text: this.$tc('ticket_status.sort'), value: 'sort' },
      ];
    }
  },

  methods: {
    getDataFromApi() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + "/api/admin/ticket_status/list.php",
          {params:{}})
        .then(response => {
          this.items = response.data.items;
          this.isLoading = false
        })
    },
    handleRowClick(row) {
      this.$router.push(
        { name: 'admin-ticket-status-details', params: { ticket_status_id: row.ticket_status_id }}
      );
    },
    // handleCreate() {
    //   this.$router.push(
    //     {name: 'admin-enumeration-details', params: { enum_id: 'cr' }}
    //   );
    // },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>