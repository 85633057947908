<template>
  <v-container fluid>
    <are-you-sure-dialog
      v-model="purgeDialog"
      :title="$t('document.purge')"
      :btnLabel="$t('document.purge')"
      btnIcon="mdi-folder-remove-outline"
      @confirmed="handlePurge"
    >
      {{ $t('document.purge_explanation') }}
    </are-you-sure-dialog>
    <delete-from-list-dialog
      v-model="deleteDialog"
      :title="$t('document.delete')"
      :id="itemToDelete"
      v-on:delete-object="reallyDoDeleteItem"
    >
      {{ $t('document.delete_library_explanation') }}
    </delete-from-list-dialog>
    <v-row class="mb-0">
      <v-col cols="10">
        <h2>{{ $tc("document.label", 2) }}</h2>
      </v-col>
      <v-col cols="2" align="right">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="purgeDialog=true">
              <v-list-item-icon class="mr-2"><v-icon>mdi-folder-remove-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('document.purge') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="mb-2  mt-0">
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('label.search')"
          single-line
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      dense
      :items-per-page="20"
      :footer-props="{itemsPerPageOptions:[20,50,100]}"
      :headers="headers"
      :items="documents_2"
      :loading="isLoading"
      :search="search"
      class="row-pointer"
      @click:row="handleRowClick"
    >
      <template v-slot:[`item.title`]="{ item }">
        <v-icon v-if="item.obsolete_at" color="red" small>mdi-clock-alert</v-icon>
        <a @click="handleDownload(item)">
          <document-mdi-icon :url="item.url" />{{item.title}}
        </a>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <div v-html="item.description"></div>
      </template>
      <template v-slot:[`item.linked_items`]="{ item }">
        <div v-html="linkedSummary(item.linked_items)"></div>
      </template>
      <template v-slot:[`item.audience`]="{ item }">
        <v-icon v-if="item.audience=='all'" small class="mr-2">mdi-eye</v-icon>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at ? item.created_at.substring(0, 10) : "" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="handleEditItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="handleDeleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios';
import DocumentMdiIcon from '@/components/DocumentMdiIcon';
import AreYouSureDialog from '@/components/framework/AreYouSureDialog';
import DeleteFromListDialog from '@/components/framework/DeleteFromListDialog.vue';
import DocumentUtils from '@/utils/DocumentUtils';

export default {
  components: {
    DocumentMdiIcon,
    AreYouSureDialog,
    DeleteFromListDialog
  },
  name: 'DocumentList',
  data: function() {return {
      documents_2: [],
      search: "",
      isLoading: false,
      purgeDialog: false,
      deleteDialog: false,
      itemToDelete: null,
      oziOnPrefix: "",
  }},
  computed: {
    headers() {
      return [
        { text: this.$tc('document.title'), value: 'title' },
        { text: this.$tc('document.description'), value: 'description' },
        { text: this.$t('document.linked_items'), value: 'linked_items' },
        { text: this.$t('document.audience'), value: 'audience', width: '130px', align: 'center' },
        { text: this.$t('document.created'), value: 'created_at', width: '110px' },
        { text: 'Actions', value: 'actions', sortable: false , width: '60px'}
      ];
    },
  },
  
  methods: {
    linkedSummary(linked_items) {
      if(!linked_items) return '';
      const totals = linked_items.reduce((previous, item) => {
        previous[item.item_type] = previous[item.item_type] ?
          previous[item.item_type] + 1 : 1;
        return previous;
      }, {} );

      let output = "";
      for (const [key, value] of Object.entries(totals)) {
        output += this.$tc('count.'+key, value, {count: value}) + '<br />';
      }
      return output;
    },
    handleDownload(item) {
      DocumentUtils.downloadFile(item, this.oziOnPrefix)
    },
    handleRowClick() {},
    handleEditItem(item) {
      this.$root.notDone();
    },

    handleDeleteItem(item) {
      this.deleteDialog = true;
      this.itemToDelete = item.document_id;
    },
    reallyDoDeleteItem(document_id) {
      this.$axios
        .put(this.$root.$data.server_base + '/api/document/remove.php', {
            service_provider_id: this.$route.params.service_provider_id,
            document_id: document_id
          }
        )
        .then(response => {
          this.refresh();
          // console.log(response);
        });
    },
    handlePurge() {
      this.$axios
        .put(this.$root.$data.server_base + '/api/document/purge_docs_from_ozi.php',
          { service_provider_id: this.$route.params.service_provider_id }
        )
        .then(response => {
          this.purgeDialog = false;
          this.$notify({type: 'success', group: 'std',
            title: this.$t('server_success.title'),
            text: this.$t('document.purge'),
              duration: 4000
            });
          this.dialog = false;
          this.refresh();
        })
        .catch(error => {
          this.purgeDialog = false;
          this.isImporting = false;
          this.$notify({type: 'error', group: 'std',
            title: this.$t('server_error.title'),
            text: this.$t('server_error.unknown'),
            duration: 4000
          })
        });
    },
    refresh() {
      this.isLoading = true
      this.$axios
        .get(this.$root.$data.server_base + '/api/service_provider/document_list.php',
          { params: {
            service_provider_id: this.$route.params.service_provider_id
          }})
        .then(response => {
          this.documents_2 = response.data.documents;
          this.oziOnPrefix = response.data.ozi_on_prefix;
          this.isLoading = false;
        })
        
    },
    
    addDocument(d) {
      this.saveDocument(d, () => {
        this.readmode = true
      })
    },
    
    saveDocument(d, callback) {
      let data = {
        service_provider_id: this.$route.params.service_provider_id
      }

      const fields = 'document_id url title description'.split(' ')

      fields.forEach(fieldName => {
        if (d[fieldName] !== undefined) {
          data[fieldName] = d[fieldName]
        }
      })

      axios
        .post(this.$root.$data.server_base + '/api/document_save.php',
          data,
          {headers:{
              'Content-Type': 'application/json',
          }})
        .then(() => {
          this.refresh()
          if (callback !== undefined && typeof callback === 'function') {
            callback()
          }
        })
    },
    
    confirmDocumentDelete(document_id) {
      this.documentIdToDelete = document_id
      this.showDeleteModal = true
    },
    
    deleteDocument(document_id) {
      var data = {
        service_provider_id: this.$route.params.service_provider_id,
        document_id:this.documentIdToDelete
      }
      
      axios
        .post(this.$root.$data.server_base + '/api/document_delete.php',
          data,
          {headers:{
              'Content-Type': 'application/json',
        }})
        .then(response => {
          this.documentIdToDelete = null
          this.showDeleteModal = false
          this.refresh();
        })
    },
    
    isAttachedServiceProviderOnly(document) {
      return !(document.designation_dossier_id_list.length > 0 || document.service_id_list.length > 0 || document.ticket_id_list.length > 0 || document.requirement_num_list.length > 0)
    },

    
  },
  mounted() {
    this.refresh()
  }
}
</script>