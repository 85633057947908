<template>
  <div>
    <div class="columns">
      <ul v-for="(level, index) in drilldown" :key="index" class="column" ref="drilldownColumn" :data-is-leaf-column="level.type === 'leafs'" :class="`column-${level.type}`">
        <template v-if="level.type === 'nodes'">
          <li 
            v-for="entry in level.entries"
            :key="entry.key"
            class="row row-node" :class="{ 'selected': entry.key === drilldownPath[index] }"
            @click="updateDrilldownPath(index, entry.key)"
          >
            <div class="row-node-label">
              <div class="row-node-label-text" :title="entry.name">{{ entry.name }}</div>
              <div class="row-node-label-badge">
                <span v-if="entry.count" class="badge">{{ entry.count }}</span>
              </div>
            </div>
            <span v-show="!entry.updating" class="node-icon">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </span>
            <span v-show="entry.updating" class="node-icon node-icon-updating">
              <i class="loading-icon fas fa-circle-notch fa-spin"></i>
            </span>
          </li>
        </template>
        <template v-if="level.type === 'leafs' && !drilldownUpdating">
          <li v-for="entry in level.entries" :key="entry.id" class="row row-leaf" :class="{ 'selected': entry.selected }">
            <label>
              <input type="checkbox" :checked="entry.selected" @change="toggleEntry($event, entry.type, entry.id)" v-show="!entry.updating" />
              <i class="loading-icon fas fa-circle-notch fa-spin" v-show="entry.updating"></i>
              {{ entry.name }}
              <span v-if="entry.type !== 'requirement'" class="text-muted">#{{ entry.id }}</span>
            </label>
          </li>
          <li v-if="level.entries.length === 0 && doneFirstLoad" class="row-no-entries">
            <small class="text-muted">Aucune entrée disponible dans cette catégorie</small>
          </li>
        </template>
      </ul>
    </div>
    <div style="margin-top:10px">
      <button type="button" class="btn btn-primary btn-sm" @click="done()">Terminé</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.columns {
  min-height: 150px;
  max-height: 250px;
  overflow-x: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-left: 1px solid #ccc;
}

.column {
  list-style: none;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-left: none;
  max-width: 300px;
  min-width: 150px;
}

.column-leafs {
  max-width: 600px;
  min-width: 250px;
}

.row {
  padding: 6px 15px 6px 15px;
  margin: 0;
  cursor: pointer;
  
  &:hover, &:focus {
    background-color: #eee;
  }
  
  &.selected {
    background-color: #ddd;
  }
  
  label {
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

.row-node {
  position: relative;
  padding-right: 25px;
  
  .node-icon {
    position: absolute;
    right: 10px;
    top: 6px;
    color: #ccc;
  }
  
  .node-icon-updating {
    top: 6px;
    right: 6px;
  }
  
  &.selected .node-icon {
    color: inherit;
  }
}

.row-node-label {
  display: flex;
  flex-direction: row;
}

.row-node-label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
}

.row-node-label-badge {
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 3px;
  
  .badge {
    transform: translateY(-2px);
  }
}

.row-leaf {
  padding-left: 40px;
  position: relative;
  
  input, .loading-icon {
    position: absolute;
    left: 15px;
    top: 6px;
  }
  
  .loading-icon {
    top: 10px;
  }
}

.row-no-entries, .row-updating {
  padding: 6px 15px 6px 15px;
  margin: 0;
}

</style>

<script>
  import axios from 'axios'

  const defaultFirstColumn = {
    type: "nodes",
    entries: [
      { name: "Billet", key: 'ticket' },
      { name: "Dossier", key: 'designation_dossier' },
      { name: "Exigence", key: 'requirement' },
      { name: "Service", key: 'service' }
    ]
  }
  
  export default {
    props: ['parents'],
    data: function() {
      return {
        drilldownPath: [],
        drilldown: [],
        drilldownUpdating: false,
        doneFirstLoad: false
      }
    },
    components: {
      
    },
    watch: {
      drilldownPath(newDrilldownPath) {
        if (this.doneFirstLoad) {
          this.markNodeAsUpdatingInDrilldown(newDrilldownPath)
        }
        this.refresh(() => {
          this.$nextTick(() => {
            this.scrollToFirstEntryInDrilldown()
          })
        })
      },
      parents() {
        this.drilldown = this.addCountsAndSelectedToDrilldown(this.drilldown)
      }
    },
    methods: {
      save() {
        
      },
      done() {
        this.$emit('cancel')
      },
      getDrilldownPathOfFirstParent() {
        const parentTypesInFirstColumn = defaultFirstColumn.entries.map(e => e.key)
        
        if (this.parents.length === 0) {
          return [parentTypesInFirstColumn[0]];
        }
        
        const firstParentTypeWithParents = parentTypesInFirstColumn.find(type => {
          return this.parents.some(parent => {
            return parent.drilldown[0] === type
          })
        })
        
        return [firstParentTypeWithParents]
      },
      updateDrilldownPath(level, key) {
        let newPath = this.drilldownPath.slice(0, level + 1)
        newPath[level] = key
        this.drilldownPath = newPath
        this.drilldownUpdating = true
      },
      refresh(callback) {
        axios
          .get(this.$root.$data.server_base + '/api/document_parents_selection_drilldown.php',
            { 
              params: {
                service_provider_id: this.$route.params.service_provider_id,
                drilldown_path: this.drilldownPath
              }
            }
          )
          .then(response => {
            this.drilldown = this.addCountsAndSelectedToDrilldown([
              JSON.parse(JSON.stringify(defaultFirstColumn)),
              ...response.data.drilldown
            ]);
            this.drilldownUpdating = false
            this.doneFirstLoad = true
            if (callback !== undefined && typeof callback === 'function') {
              callback()
            }
          })
      },
      addCountsAndSelectedToDrilldown(drilldown) {
        return drilldown.map((level, level_index) => {
          if (level.type === 'nodes') {
            level.entries = level.entries.map((entry) => {
              const parentsMatchingType = this.parents.filter(parent => parent.drilldown[level_index] && parent.drilldown[level_index] === entry.key);
              if (parentsMatchingType) {
                entry.count = parentsMatchingType.length
              }
              
              return entry
            })  
          } else if (level.type === 'leafs') {
            level.entries = level.entries.map((entry) => {
              entry.selected = this.parents.some(parent => parent.drilldown[0] === entry.type && parent.id === entry.id)
              entry.updating = false
              
              return entry
            })
          }
          
          return level
        })
      },
      toggleEntry(event, type, id) {
        this.markParentAsUpdatingInDrilldown(type, id)
        
        if (event.target.checked) {
          this.addEntry(type, id)
        } else {
          this.removeEntry(type, id)
        }
      },
      addEntry(type, id) {
        this.$emit('add-parent', type, id)
      },
      removeEntry(type, id) {
        this.$emit('remove-parent', type, id)
      },
      markParentAsUpdatingInDrilldown(type, id) {
        const lastDrilldownLevel = this.drilldown[this.drilldown.length - 1]
        const matchingEntry = lastDrilldownLevel.entries.find((entry) => entry.type === type && entry.id === id)
        matchingEntry.updating = true
        this.drilldown = this.drilldown.slice()
      },
      markNodeAsUpdatingInDrilldown(drilldownPath) {
        const level = this.drilldown[drilldownPath.length - 1]
        if (!level) { return }
        let matchingEntry = level.entries.find((entry) => entry.key === drilldownPath[drilldownPath.length - 1])
        matchingEntry.updating = true
        this.drilldown = this.drilldown.slice()
      },
      scrollToFirstEntryInDrilldown() {
        const columnWithEntries = this.$refs.drilldownColumn.find(column => {
          return column.dataset.isLeafColumn === "true"
        })
        
        if (!columnWithEntries) {
          this.drilldownFurtherIfColumnShownWithMoreTypesContainingItems()
          return
        }
        
        const firstItem = columnWithEntries.querySelector(`.row-leaf`)
        const firstItemSelected = columnWithEntries.querySelector(`.row-leaf.selected`)
        if (!firstItemSelected) { return }
        
        const scrollToY = firstItemSelected.offsetTop - firstItem.offsetTop - (firstItem.offsetHeight / 2) // show a bit of item above
        columnWithEntries.scroll(0, scrollToY)
      },
      drilldownFurtherIfColumnShownWithMoreTypesContainingItems() {
        const lastColumn = this.drilldown[this.drilldown.length - 1]
        if (lastColumn.type !== "nodes") {
          return
        }
        
        const firstNodeWithSelectedItems = lastColumn.entries.find(node => {
          return node.count > 0
        })
        
        if (!firstNodeWithSelectedItems) { return }
        
        this.drilldownPath.push(firstNodeWithSelectedItems.key)
      }
    },
    mounted() {
      this.drilldown = [
        {
          entries: [],
          type: 'nodes'
        },
        {
          entries: [],
          type: 'leafs'
        }
      ]
      this.drilldownPath = this.getDrilldownPathOfFirstParent()
      this.refresh()
    }
  }
</script>