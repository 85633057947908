<template>
<v-container fluid>
  <h2>{{ $tc('dossier.label', 2)}}</h2>
  
    <!-- <label class="form-checkbox-input" style="cursor: pointer;">
    <input class="form-check-input" type="checkbox" v-model="include_archived" @change="newStart">
    Inclure les archivés
  </label> -->
  <dossiers-step-stacked :data="graphData" :styles="{ width: '100%', height: '150px'}"
  ></dossiers-step-stacked>
  <v-row class="mb-2">
    <v-col cols="2">
      <v-text-field
        v-model="keywords"
        class="mt-3"
        append-icon="mdi-magnify"
        :label="$tc('label.search')"
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols=2>
      <v-checkbox
        class="mt-4"
        dense
        v-model="show_archived"
        :label="$t('dossier.show_archived')"
      ></v-checkbox>
    </v-col>
    <v-col cols="2">
      <v-select
        v-model="dossier_type_key"
        :items="dossier_type_option"
        item-text="name"
        item-value="key"
        :label="$tc('dossier.type')"
        clearable
      ></v-select>
    </v-col>
    <v-col cols="2">
      <v-select
        v-model="dossier_status_key"
        :items="
          (dossier_status_option || []).map((option) => ({
            text: $langobj.parse(option.name),
            value: option.enum_key,
          }))
        "
        :label="$tc('dossier.step')"
        clearable
      ></v-select>
    </v-col>
    <v-col cols="2">
      <v-select
        v-model="agency_status_id"
        :items="agency_status_option"
        item-text="status_name"
        item-value="status_id"
        :label="$tc('provider.designation_status')"
        clearable
      ></v-select>
    </v-col>
    <v-col cols="2">
      <v-select
        v-model="responsable"
        :items="responsable_option"
        item-text="responsable_name"
        item-value="user_id"
        :label="$tc('provider.responsable')"
        clearable
      ></v-select>
    </v-col>
  </v-row>

  <v-data-table
    :items-per-page="25"
    :footer-props="{itemsPerPageOptions:[10,25,50,-1]}"
    dense
    :headers="headers"
    :items="localeAwareFilteredItems"
    item-key="element_id"
    :search="keywords"
    :loading="isLoading"
    @click:row="handleRowClick"
  >
    <template v-slot:[`item.name_lang`]="{ item }">
      <v-icon v-if="item.status=='archived'">mdi-archive-lock</v-icon>
      {{ item.name_lang }}
    </template>
    <template v-slot:[`item.last_update_time`]="{ item }">
      <span class="text-no-wrap">
        {{ moment.utc(item.last_update_time).local().fromNow() }}
      </span>
    </template>
    <template v-slot:[`item.start_date`]="{ item }">
      {{ item.start_date }}
      <v-icon v-if="!item.start_date">mdi-calendar-question-outline</v-icon>
    </template>
    <template v-slot:[`item.date_of_designation`]="{ item }">
      {{ item.date_of_designation }}
      <v-icon
        v-if="(!item.date_of_designation && item.last_step_key == 'obtention_de_designation')"
      >
        mdi-calendar-question-outline
      </v-icon>
    </template>
    <template v-slot:[`item.duration`]="{ item }">
      <span class="text-no-wrap">
        {{ item.duration ? item.duration + ' ' + $tc('dossier.years', item.duration) : '' }}
        <v-icon
          v-if="item.showTimeAlert"
          class="ml-1 mr-1"
          color="red"
        >
          mdi-timer-alert-outline
        </v-icon>
      </span>
    </template>
    <template v-slot:[`item.dossier_status`]="{ item }">
      <v-icon :color="item.dossier_status_aes.colour">mdi-circle-medium</v-icon>
      {{ item.dossier_status_lang }}
    </template>

  </v-data-table>
</v-container>
</template>

<script>

import axios from 'axios';
import DossiersStepStacked from '@/components/graphs/DossiersStepStacked.vue';

function scrollToTop(){
  window.setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, 200);
}

export default {
  data: function() {return {
    // filters
    keywords:'',
    responsable: null,
    agency_status_id: null,
    dossier_status_key: null,
    dossier_type_key: null,

    show_archived: false,
    responsable_option: [],
    agency_status_option: [],
    dossier_type_option: [],
    dossier_status_option: [],
    // dossier_appreciation_option: [],

    dossiers: [],

    isLoading: false
  }},
  name: 'DossierList',
  components: {
    DossiersStepStacked
  },
  watch: {
    show_archived() {
      this.getList();
    },
  },
  computed: {
    headers() {
      return [
        { text: this.$tc('provider.label'), value: 'service_provider_name' },
        { text: this.$tc('provider.des_status'), value: 'designation_status_lang' },
        { text: this.$tc('dossier.label'), value: 'name_lang' },
        // { text: this.$tc('dossier.type'), value: this.$langobj.valueName('dossier_type_lang') },
        { text: this.$tc('dossier.start_date'), value: 'start_date', filterable: false },
        { text: this.$tc('dossier.due_date'), value: 'due_date', filterable: false },
        { text: this.$tc('dossier.date_of_designation'), value: 'date_of_designation', filterable: false },
        { text: "Durée", value: 'duration', filterable: false},
        { text: this.$tc('dossier.model'), value: 'designation_model_name' },
        { text: this.$tc('dossier.step'), value: 'dossier_status' },
        // { text: this.$tc('dossier.since'), value: 'last_update_time' },
        // { text: this.$tc('dossier.appreciation'), value: this.$langobj.valueName('appreciation_lang') },
        { text: this.$tc('provider.responsable'), value: 'responsable' },
      ];
    },
    localeAwareFilteredItems() {
      return this.dossiers
        .filter(dossier => this.agency_status_id == null |
          dossier.designation_status_id == this.agency_status_id)
        .filter(dossier => this.responsable == null |
          dossier.primary_agent_user_id == this.responsable)
        .filter(dossier => this.dossier_status_key == null |
          dossier.dossier_status == this.dossier_status_key)
        .filter(dossier => this.dossier_type_key == null |
          dossier.dossier_type_key == this.dossier_type_key)
        .map(item => {
          const start = item.start_date
            ? this.moment(item.start_date)
            : this.moment(item.due_date).subtract(3, 'year');
          const end = item.date_of_designation ? 
            this.moment(item.date_of_designation) : this.moment();
          const diff = Math.round(end.diff(start, 'years', true)*10)/10;
          const showTimeAlert = diff > 3 && 
            (item.dossier_status == 'not_started' || item.dossier_status == 'in_progress');
          return {...item,
            service_provider_name: this.$langobj.parse(item.service_provider_name),
            name_lang: this.$langobj.parse(item.name_lang),
            designation_status_lang: this.$langobj.parse(item.designation_status_lang),
            designation_model_name: this.$langobj.parse(item.designation_model_name),
            dossier_status_lang: this.$langobj.parse(item.dossier_status_lang),
            state_lang: item.state == 1 ? this.$t('label.active'): this.$t('label.inactive'),
            duration: diff,
            showTimeAlert: showTimeAlert
          };
        })
    },
    graphData() {
      const initialValue = this.dossier_status_option.slice();
      initialValue.forEach(item => {item.count = 0;});

      return this.localeAwareFilteredItems.reduce((previousValue, dossier) => {
        const item = previousValue.find(el => el.enum_key == dossier.dossier_status)
        if(item) {
          item.count +=1;
        }
        return previousValue;
      }, initialValue)
    }
  },
  methods: {
    handleRowClick(row) {
      this.$router.push(
        { name: 'dossier_summary', 
          params: {
            service_provider_id: row.service_provider_id,
            designation_dossier_id: row.designation_dossier_id
          }
      });
    },
    
    loadFilters: function() {
      axios
        .get(this.$root.$data.server_base + '/api/dossier/dossier_filter_dropdown.php',
          { })
        .then(response => {
          this.responsable_option = response.data.responsable;
          this.agency_status_option = response.data.agency_status.filter((entry) => {
            return entry.status_name != "Non-identifié"
          }).map((entry) => {
            // entry.status_name = reLabelAgencyStatus(entry.status_name)
            return entry
          });
          this.dossier_type_option = response.data.dossier_type;
          this.dossier_status_option = response.data.dossier_status.filter((entry) => {
            if (entry.key == "dh_suivi") return false;
            if (this.include_archived) return true;
            return entry.key != "dh_archive"
          });
          this.dossier_appreciation_option = response.data.dossier_appreciation;
        })
    },
          
    getList() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/dossier/list.php',
          { params: { show_archived: this.show_archived }})
        .then(response => {
          this.$checkApiErrors(response.data);
          this.dossiers = response.data.dossiers;
          this.isLoading = false
        });
    },    
  },
  mounted() {
    this.getList();
    this.loadFilters();
  }
}

</script>
