<template>
  <v-dialog
    v-model="dialog"
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false; $refs.form.resetValidation();">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ isCreate ? $t('dossier.history.create') : $t('dossier.history.modify') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="handleSave" :loading="isSaving">{{$t('label.save')}}</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-form ref="form">
          <v-select
            v-model="internal.status"
            :label="$t('dossier.history.event')"
            :items="
              (ticketStatusKeyOptions || []).map((option) => ({
                text: $langobj.parse(option.name_lang),
                value: option.ticket_status_id,
              }))
            "
            :rules="[(v) => $validators.required(v)]"
            :loading="isLoading"
          >
          </v-select>
          <v-row class="mt-0">
            <v-col cols="12" sm="4">
              <toggable-date-picker
                v-model="internal.due_date"
                native-label
                :label="$t('dossier.history.due_date')"
                :rules="rules"
                :loading="isLoading"
              ></toggable-date-picker>
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                class="mt-2"
                v-model="internal.options.due_date_fake"
                :label="$t('dossier.history.due_date_fake')"
                :loading="isLoading"
              ></v-checkbox>
            </v-col>
          </v-row>  

          <v-row class="mt-0">
            <v-col cols="12" sm="4">
              <toggable-date-picker
                v-model="internal.effective_at"
                native-label
                :label="$t('dossier.history.effective_at')"
                :rules="rules"
                :loading="isLoading"
              ></toggable-date-picker>
            </v-col>
          </v-row>

          <v-row class="mt-0 mb-2">
            <v-col cols="12" sm="4">
              <v-switch
                v-model="internal.visibleToSp"
                :label="$t('dossier.history.audience')"
                :loading="isLoading"
              ></v-switch>
            </v-col>
          </v-row>

          <tip-tap
            v-model="internal.description"
            :label="$t('dossier.history.description')"
            :loading="isLoading"
          ></tip-tap>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import TipTap from '../framework/TipTap.vue';

export default Vue.extend({
  name: 'EditHistoryDialog',
  components: {
    TipTap
  },
  props: {
    value: false,
    id: {type: Number}
  },
  data() {return {
    storeData: null,
    internal: {
      due_date: null,
      effective_at: null,
      responsable: 0,
      options:{due_date_fake: false},
      visibleToSp: true,
    },
    isLoading: false,
    isSaving: false,
    
    ticketStatusKeyOptions: []
  }},
  computed: {
    isCreate() {
      return this.id == 0;
    },
    dialog: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    },
    rules() {
      const valid = !!(this.internal.due_date || this.internal.effective_at);
      return [() => valid || this.$t('error.at_least_one_date')];
    }
  },
  watch: {
    value() {
      if(this.value && this.id != 0) {
        this.get(); 
      } else {
        // Reset values
        this.internal = {
          due_date: null,
          effective_at: null,
          responsable: 0,
          options:{due_date_fake: false},
          visibleToSp: true};
      }
    }
  },
  methods: {
    get() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/ticket/get_ticket.php',
          { params: { ticket_id: this.id }})
        .then(response => {
          this.storeData = response.data.ticket;
          if(this.storeData.options == null)
            this.storeData.options = {due_date_fake: false};
          this.storeData.visibleToSp = this.storeData.audience == 'all';
          this.internal = _.cloneDeep(this.storeData);
          this.isLoading = false;
        });
    },
    getOptions() {
      this.$axios
        .get(this.$root.$data.server_base + "/api/admin/ticket_status/list.php",
          {params:{ticket_type_id: 5}})
        .then(response => {
          this.ticketStatusKeyOptions = response.data.items;
        })
    },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }
      if(this.isSaving) {
        return;
      }
      this.isSaving = true;
      
      let obj = {
        ticket_id: this.id,
        designation_dossier_id: Number(this.$route.params.designation_dossier_id),
        ticket_type_key: 'designation_history',
        ticket_status: this.internal.status,
        service_provider_id: Number(this.$route.params.service_provider_id),
        title: this.internal.title,
        responsable: this.internal.responsable,
        description: this.internal.description,
        effective_at: this.internal.effective_at,
        due_date: this.internal.due_date,
        priority: null,
        options: this.internal.options,
        audience: this.internal.visibleToSp ? 'all' : null,
      };

      this.$axios
        .put(this.$root.$data.server_base + '/api/ticket_save.php',
           obj, { headers: {'Content-Type': 'application/json' }})
        .then((response) => {
          this.$refs.form.resetValidation();
          this.$emit('refresh');
          this.dialog = false;
          this.isSaving = false;
          // this.$router.push({name: 'admin-ticket-status'});
        });
    }
  },
  mounted() {
    this.getOptions();
  }
});
</script>