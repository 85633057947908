<template>
  <div>
    <p class="db-graph-title mb-0">{{ title }}</p>

    <v-progress-linear :value="percent"
      :color="done ? '#9066aa' : (color || '#aa87bd')"
      height="20"
    >
      <template v-slot:default="{}">
        <strong :class="{'white--text': done}">
          {{ numerator+'/'+denominator }}
        </strong>
      </template>
    </v-progress-linear>
  
  </div>
</template>

<script>
export default {
  name: 'SPElementProgress',
  props: ['title', 'numerator', 'denominator', 'color'],
  computed: {
    percent() {
      return this.numerator*100/this.denominator;
    },
    done() {
      return this.numerator==this.denominator;
    }
  }
}
</script>

<style scoped lang="scss">
p.db-graph-title {
  font-size: 0.9em;
  font-weight: 400;
  color: black;
}
</style>