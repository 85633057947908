<template>
  <v-dialog
    :value='value'
    @input="$emit('input', $event)"
    width="900"
    persistent
  >
    <v-card :loading="loading">
      <v-card-title class="text-h5 grey lighten-2 mb-3">
        {{ $tc('user.send_email', 1) }}
      </v-card-title>

      <v-card-text>
        <v-select
          v-model="email_key"
          :label="$t('email.label')"
          :items="
            (emailOptions || []).map((option) => ({
              text: $langobj.parse(option.name) + ' ('+option.key+')',
              value: option.key
          }))"
          @change="disableSave = false"
        >
        </v-select>
       
      </v-card-text>

      <v-card-text v-if="showEmailPreview">
        <v-card>
          <v-card-text>
            <div>
              <strong>
                {{ $t('email.subject') }}: <span v-html="emailPreview.subject"></span>
              </strong>
            </div>
            <v-divider class="my-2"></v-divider>
            <div v-html="emailPreview.body"></div>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" @click="handlePreview" :disabled="disableSave">
          {{ $t('label.preview') }}
        </v-btn>
        <v-btn color="primary" @click="handleSend" :disabled="disableSave">
          {{ $t('label.send') }}
        </v-btn>
        <v-btn color="primary" text @click="$emit('input', false)">
          {{ $t('label.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>     
</template>

<script>
export default {
  name: 'UserRoleDialog',
  props: ['value','userId'],
  data() { return {
    email_key: null,
    loading: false,
    success: false,
    emailOptions: [],
    disableSave: false,
    emailPreview: {},
    showEmailPreview: false,
  }},
  watch: {
    value() {
      if(this.value==true) {
        // The dialog was just opened.
        this.getProvisionOptions();
        // this.internal = _.cloneDeep(this.role);
        // this.internal.providerSelection = (this.role.providers || [])
        //   .map(e => e.service_provider_id);
        // this.internal.regionSelection = (this.role.regions || [])
        //   .map(e => e.region_id);
        // this.storedData = _.cloneDeep(this.internal);
        this.disableSave = true;
        this.loading = false;
      }
    }
  },
  computed: {
    isCreate() {
      return this.internal.user_role_id == null;
    },
  },
  methods: {
    roleIdForKey(role_key) {
      return this.roleOptions.find(e => e.role_key == role_key).role_id;
    },

    getProvisionOptions() {
      this.loading = true;
      this.$axios
        .get("/api/admin/email/list.php")
        .then(response => {
          this.emailOptions = response.data;
          this.loading = false;
      })
    },

    handlePreview() {
      this.$axios
        .put("/api/admin/email/email_to_user.php",
          {user_id: this.userId, email_key: this.email_key, preview: true})
        .then(response => {
          this.emailPreview = response.data;
          this.showEmailPreview = true;
      })
    },
    
    handleSend() {
       this.$axios
        .put("/api/admin/email/email_to_user.php",
          {user_id: this.userId, email_key: this.email_key, preview: false})
        .then(response => {
          this.emailPreview = response.data;
          this.showEmailPreview = false;
          this.$emit('input', false);
      })
    }
  }
}
</script>