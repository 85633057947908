<template>
  <v-container fluid color="blue">
    <v-row class="mb-0">
      <v-col cols="12" lg="6">
        <v-card>
          <v-card-title>{{ $t('provider.dashboard.active_dossiers') }}</v-card-title>
          <v-card-text v-if="!isLoading && dossierList.length == 0">
            <em>{{ $t('error.no_content') }}</em>
          </v-card-text>
          <v-card-text v-else>
            <SPDossier 
              v-for="dossier in dossierList"
              :key="dossier.designation_dossier_id"
              :dossier="dossier"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card>
          <v-card-title>Notifications</v-card-title>
          <v-card-text>
            <v-skeleton-loader
              :boilerplate="true"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
            <em>{{ $t('error.feature_not_ready') }}</em>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
  <!-- <v-row class="mt-3">
    <v-col cols="6">
      <v-card>
        <SPRequirementStatus :requirementsstatus="requirementsstatus"></SPRequirementStatus>
      </v-card>
    </v-col> 
    <v-col cols="6">
      <SPHumanResources :humanresources="humanresources"></SPHumanResources>
    </v-col>
    
  </v-row> -->
  </v-container>
</template>

<script>
import SPRequirementStatus from '@/components/dashboard/SPRequirementStatus.vue'
import SPHumanResources from '@/components/dashboard/SPHumanResources.vue'
import SPDossier from '@/components/dashboard/SPDossier.vue'

export default {
  data: function() {return {
    isLoading: false,
    service_provider_id:'',
    serviceprovider:{},
    requirementsstatus:{},
    humanresources:{},
    dossiers:{},
    dossierList: []
  }},
  name: 'ServiceProviderTdb',
  components: {
    SPRequirementStatus,
    SPHumanResources,
    SPDossier,
  },
  methods: {

    refresh() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/service_provider/get_tdb.php',
          { params: {service_provider_id: this.$route.params.service_provider_id}})
        .then(response => {
          this.$checkApiErrors(response.data);
          this.serviceprovider = response.data.serviceprovider;
          this.requirementsstatus = response.data.requirementsstatus;
          this.humanresources = response.data.humanresources;
          this.dossiers = response.data.dossiers}
        )

      this.$axios
        .get(this.$root.$data.server_base + '/api/service_provider/dossier_list.php',
          { params: {
            service_provider_id: this.$route.params.service_provider_id,
            show_archived: this.showArchived
          }})
        .then(response => (
          this.$checkApiErrors(response.data),
          this.dossierList = response.data.dossiers,
          this.isLoading = false
        ))
        
    },
    

  },
  watch: {
    '$route.params.service_provider_id'(value) {
      if(value > 0 ) {
        this.refresh();
      }
    }
  },
  mounted () {
    this.refresh();
    this.service_provider_id=this.$route.params.service_provider_id;
  }
}
</script>
