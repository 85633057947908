var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","to":{name:'billet_creation'}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$tc('ticket.create'))+" ")],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStatus),expression:"showStatus"}],attrs:{"items":(_vm.status_option || []).map((option) => ({
              text: _vm.$langobj.parse(option.name_lang),
              value: option.status_id,
            })),"label":_vm.$tc('ticket.status'),"clearable":""},on:{"change":_vm.newStart},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),(!_vm.typeKeyFilter)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":(_vm.ticket_type_option || []).map((option) => ({
              text: _vm.$langobj.parse(option.name),
              value: option.ticket_type_key,
            })),"label":_vm.$tc('ticket.type'),"clearable":""},on:{"change":_vm.loadStatus},model:{value:(_vm.type_key),callback:function ($$v) {_vm.type_key=$$v},expression:"type_key"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.responsable_option,"item-text":"responsable_name","item-value":"user_id","label":_vm.$tc('ticket.responsable'),"clearable":""},on:{"change":_vm.newStart},model:{value:(_vm.responsable),callback:function ($$v) {_vm.responsable=$$v},expression:"responsable"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('label.search'),"single-line":"","hide-details":""},on:{"input":function($event){return _vm.debounceSearch()}},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}})],1)],1),_c('v-data-table',{attrs:{"items-per-page":20,"footer-props":{itemsPerPageOptions:[20,50,100]},"dense":"","headers":_vm.headers,"options":_vm.options,"items":_vm.localeAwareItems,"server-items-length":_vm.totalRecords,"item-key":"element_id","loading":_vm.isLoading},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment.utc(item.created_at).local().format('YYYY-MM-DD'))+" ")]}},{key:`item.responsable`,fn:function({ item }){return [_c('user-avatar',{staticClass:"mr-2",attrs:{"user":item.responsable}}),_vm._v(" "+_vm._s(item.responsable.full_name)+" ")]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }