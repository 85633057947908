<template>
  <div>
    <h2>{{ $tc('help.label', 2) }}</h2>
    <v-row class="justify-end">
      <v-col class="text-right">
        <v-btn @click="handleCreate" color="primary" outlined>
          <v-icon>mdi-plus</v-icon>
          {{ $t('label.add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items-per-page="25"
      :footer-props="{itemsPerPageOptions:[10,25,50,-1]}"
      :items="helpDialogs"
      item-key="enum_id"
      :loading="isLoading"
      @click:row="handleRowClick"
    >
    </v-data-table>
  </div>  
</template>

<script>
export default {
  name: 'AdminHelpDialogs',
  data: function() {
    return {
      helpDialogs: [],
      isLoading: true,
    }
  },

  computed: {
    headers() {
      return [
        { text: this.$tc('help.key'), value: 'key' },
        { text: this.$tc('label.modified'), value: 'modified_at' },
      ];
    }
  },

  methods: {
    getDataFromApi() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + "/api/admin/help_dialog/list.php",
          {params:{}})
        .then(response => {
          this.helpDialogs = response.data.help_dialogs;
          this.isLoading = false
        })
    },
    handleRowClick(row) {
      this.$router.push(
        { name: 'admin-help-dialog-details', params: { key: row.key }}
      );
    },
    handleCreate() {
      this.$router.push(
        {name: 'admin-help-dialog-details', params: { key: 'cr' }}
      );
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>