<template>
  <v-container fluid class="mt-4">
    <upload-document-dialog
      v-model="uploadDialog"
      @refresh="refresh"
    />
    <edit-document-dialog
      v-model="editDialog"
      :id="documentIdToEdit"
      @refresh="refresh"
    />
    <remove-document-dialog
      v-model="removeDialog"
      :id="documentIdToRemove"
      @refresh="refresh"
    />
    <remove-all-dossier-documents-dialog
      v-model="removeAllDialog"
      @refresh="refresh"
    />
    <document-import-dialog
      v-model="importDialog"
      @refresh="refresh"
    />
    <download-all-dialog
      v-model="downloadAllDialog"
    />

    <div class="d-flex justify-space-between">
      <p class="text-h5">{{ $t('document.dossier') }}</p>
      <span>
        <v-btn icon><v-icon @click="refresh">mdi-refresh</v-icon></v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="removeAllDialog=true">
              <v-list-item-icon class="mr-2"><v-icon>mdi-folder-remove-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('document.remove_all_from_dossier') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="handleDownloadArchive">
              <v-list-item-icon class="mr-2"><v-icon>mdi-folder-download-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('label.download') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </div>

    <v-row class="mb-2 mt-3">
     
      <v-col offset="2" cols="2">
        <v-text-field 
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('label.search')"
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-checkbox dense v-model="showGoverningDocs" :label="$t('document.cat.governing')"></v-checkbox>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="pt-0" cols="5" align="right">
        <v-btn v-if="false" class="mr-2" color="primary" @click="importDialog = true">
          <v-icon>mdi-upload</v-icon>{{ $t('dossier.import') }}
        </v-btn>
        <v-btn color="primary" @click="uploadDialog = !uploadDialog">
          <v-icon>mdi-file-plus</v-icon>{{ $t('label.add') }}
        </v-btn>
      </v-col>
    </v-row>

    <document-list-section
      v-show="unclassified_documents.length > 0"
      :label="$t('document.cat.unclassified')"
      helpKey = "document.cat.unclassified"
      :documents="unclassified_documents"
      :search="search"
      :loading="isLoading"
      :oziOnPrefix="oziOnPrefix"
      @editDocument="handleEditItem"
      @deleteDocument="handleDeleteItem"
    />

    <document-list-section
      v-if="showGoverningDocs"
      :label="$t('document.cat.governing')"
      helpKey = "document.cat.governing"
      :documents="governing_documents"
      :search="search"
      :loading="isLoading"
      :oziOnPrefix="oziOnPrefix"
      disableDelete
      @editDocument="handleEditItem"
      @deleteDocument="handleDeleteItem"
    />

    <document-list-section
      :label="$t('document.cat.engagement')"
      helpKey = "document.cat.engagement"
      :documents="commitment_documents"
      :search="search"
      :loading="isLoading"
      :oziOnPrefix="oziOnPrefix"
      @editDocument="handleEditItem"
      @deleteDocument="handleDeleteItem"
    />

    <document-list-section
      :label="$t('document.cat.policy')"
      helpKey = "document.cat.policy"
      :documents="policy_documents"
      :search="search"
      :loading="isLoading"
      :oziOnPrefix="oziOnPrefix"
      @editDocument="handleEditItem"
      @deleteDocument="handleDeleteItem"
    />

    <document-list-requirement-section
      :label="$t('document.cat.requirement')"
      helpKey = "document.cat.requirement"
      :requirements="requirement_documents"
      :search="search"
      :loading="isLoading"
      :oziOnPrefix="oziOnPrefix"
      @editDocument="handleEditItem"
      @deleteDocument="handleDeleteItem"
    />
  </v-container>
</template>

<style lang="scss" scoped>
.doc_cat {
  border-right: solid 2px #fbab26;
  border-top: solid 2px #fbab26;
  // color: #999;
  // font-size: 85%;
}
</style>

<script>
import axios from 'axios';
import DocumentMdiIcon from '@/components/DocumentMdiIcon';
import UploadDocumentDialog from '@/components/document/UploadDocumentDialog.vue';
import EditDocumentDialog from '@/components/document/EditDocumentDialog.vue';
import RemoveDocumentDialog from '@/components/document/RemoveDocumentDialog.vue';
import RemoveAllDossierDocumentsDialog from '@/components/document/RemoveAllDossierDocumentsDialog.vue';
import DocumentImportDialog from '@/components/dossiers/DocumentImportDialog.vue';
import DownloadAllDialog from '@/components/document/DownloadAllDialog.vue';
import DocumentListSection from '@/components/document/DocumentListSection.vue';
import DocumentListRequirementSection from '@/components/document/DocumentListRequirementSection.vue';

export default {
  components: {
    DocumentMdiIcon,
    UploadDocumentDialog,
    EditDocumentDialog,
    RemoveDocumentDialog,
    RemoveAllDossierDocumentsDialog,
    DocumentImportDialog,
    DocumentListSection,
    DocumentListRequirementSection,
    DownloadAllDialog
  },
  name: 'DocumentList',
  props: ['readonly'],
  data: function() {return {
      editDialog: false,
      documentIdToEdit: 0,
      removeDialog: false,
      removeAllDialog: false,
      downloadAllDialog: false,
      documentIdToRemove: 0,
      requirement_documents: [],
      other_documents: [],
      search: "",
      isLoading: false,  
      oziOnPrefix: '',
      uploadDialog: false,
      importDialog: false,
      get showGoverningDocs() {
        return localStorage.getItem('dossierDocumentList.showGoverningDocs') === "true"
          || false;
      },
      set showGoverningDocs(newVal) {
        localStorage.setItem('dossierDocumentList.showGoverningDocs', newVal);
      }
  }},
  computed: {
    headers() {
      return [
        { text: this.$tc('document.title'), value: 'title' },
        { text: this.$tc('document.description'), value: 'description' },
        { text: this.$tc('requirement.label'), value: 'linked_items', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ];
    },
    other_docs_headers() {
      return [
        { text: this.$tc('document.title'), value: 'title' },
        { text: this.$tc('document.description'), value: 'description' },
        { text: 'Actions', value: 'actions', sortable: false }
      ];
    },
    commitment_documents() {
      return this.other_documents.filter((doc) => (
        doc.dossier_assoc_details ? doc.dossier_assoc_details.commitment : false
      ));
    },
    policy_documents() {
      return this.other_documents.filter((doc) => (
        doc.dossier_assoc_details ? doc.dossier_assoc_details.policy : false
      ));
    },
    governing_documents() {
      return this.other_documents.filter((doc) => (
        doc.provider_assoc_details ? doc.provider_assoc_details.governing : false
      ));
    },
    unclassified_documents() {
      return this.other_documents.filter((doc) => (
        doc.dossier_assoc_details == null ? false : 
          !(doc.dossier_assoc_details.commitment ||
            doc.dossier_assoc_details.policy ||
            (doc.dossier_assoc_details.demonstration &&
            doc.dossier_assoc_details.requirements.length !=0))
      ));
    }

  },
  
  methods: {
    handleRowClick() {},
    handleEditItem(item) {
      this.documentIdToEdit = item.document_id;
      this.editDialog = true;
    },
    handleDeleteItem(item) {
      this.documentIdToRemove = item.document_id;
      this.removeDialog = true;
    },
    handleDownloadArchive() {
      this.downloadAllDialog = true;
    },
    refresh() {
      this.isLoading = true
      this.$axios
        .get(this.$root.$data.server_base + '/api/dossier/document_list.php',
          { params: {
            service_provider_id: this.$route.params.service_provider_id,
            designation_dossier_id: this.$route.params.designation_dossier_id
          }})
        .then(response => {
          this.requirement_documents = response.data.requirement_documents;
          this.other_documents = response.data.other_documents;
          this.oziOnPrefix = response.data.ozi_on_prefix;
          this.isLoading = false;
        })
    },
    
    addDocument(d) {
      this.saveDocument(d, () => {
        this.readmode = true
      })
    },
    
    saveDocument(d, callback) {
      let data = {
        service_provider_id: this.$route.params.service_provider_id
      }

      const fields = 'document_id url title description'.split(' ')

      fields.forEach(fieldName => {
        if (d[fieldName] !== undefined) {
          data[fieldName] = d[fieldName]
        }
      })

      axios
        .post(this.$root.$data.server_base + '/api/document_save.php',
          data,
          {headers:{
              'Content-Type': 'application/json',
          }})
        .then(() => {
          this.refresh()
          if (callback !== undefined && typeof callback === 'function') {
            callback()
          }
        })
    },
    
    confirmDocumentDelete(document_id) {
      this.documentIdToDelete = document_id
      this.showDeleteModal = true
    },
    
    deleteDocument(document_id) {
      var data = {
        service_provider_id: this.$route.params.service_provider_id,
        document_id:this.documentIdToDelete
      }
      
      axios
        .post(this.$root.$data.server_base + '/api/document_delete.php',
          data,
          {headers:{
              'Content-Type': 'application/json',
        }})
        .then(response => {
          this.documentIdToDelete = null
          this.showDeleteModal = false
          this.refresh();
        })
    },
    
    isAttachedServiceProviderOnly(document) {
      return !(document.designation_dossier_id_list.length > 0 || document.service_id_list.length > 0 || document.ticket_id_list.length > 0 || document.requirement_num_list.length > 0)
    },

    
  },
  mounted() {
    this.refresh()
  }
}
</script>