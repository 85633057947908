<template>
  <v-dialog v-model="propModel" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ $t('document.remove_all_from_dossier') }}
      </v-card-title>

      <v-card-text class="mt-3">
        <slot></slot>  
        <div v-if="!$slots.default">
          {{ $t('document.remove_all_from_dossier_explanation') }}
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="propModel = false"
        >
          {{ $t('label.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="handleRemove" :loading="saving">
          <v-icon class="mr-2">mdi-file-remove</v-icon>
          {{ $t('label.remove') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  components: {
  },

  props: {
    value: false,
    title: {
      type: "",
    },
  },

  data() { return {
    saving: false,
  }},

  computed: {
    propModel: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },

  methods: {
    handleRemove() {
      this.saving = true;
      this.$axios
        .put(this.$root.$data.server_base + '/api/document/remove_all_assocs_from_dossier.php', {
          designation_dossier_id: this.$route.params.designation_dossier_id,
          service_provider_id: this.$route.params.service_provider_id,
        })
        .then(response => {
          this.saving = false;
          this.propModel = false;
          const deletedRows = response.data.deleted_rows;
          if(deletedRows == 0) {
            this.$notify({type: 'warn', group: 'std',
              title: this.$t('server_warning.title'),
              text: this.$tc('document.remove_notify',
                deletedRows, {count: deletedRows} ),
              duration: 4000
            });
          } else {
            this.$emit('refresh');
            this.$notify({type: 'success', group: 'std',
              title: this.$t('server_success.title'),
              text: this.$tc('document.remove_notify',
                deletedRows, {count: deletedRows} ),
              duration: 4000
            });
          }
        });
    }
  },
});
</script>

<style scoped lang="scss">
</style>
