<template>
  <div class="row">
    <div class="col-sm-12" style="padding-bottom: 10px">
      <div class="form-group za-label">
        <strong class="control-label">Documents </strong>
        <a class="za-label-action" v-on:click="showAttachModal = true" ><i class="fas fa-plus fa-spacer" aria-hidden="true"></i>Ajouter</a>
      </div>
    </div>
    
    
    <div class="col-sm-12">
      <Document v-for="document in documents" :document="document" v-on:confirmDelete="confirmDocumentDelete" :key="document.id" :service-provider-id="serviceProviderId" />
    </div>
    
    <DocumentAttachModal
      :showModal="showAttachModal"
      v-on:done="showAttachModal = false"
      v-on:attached="refresh"
      :parent-object-type="parentType"
      :parent-object-id="parentId"
      :service-provider-id="serviceProviderId"
    />
    <DocumentDeleteModal :document_id="documentIdToDelete" :documentToDelete="documentToDelete" :showModal="showDeleteModal" v-on:confirmDelete="detachDocument" v-on:cancel="showDeleteModal = false" />
  </div>
</template>

<style>
.editing + .editing {
  margin-top: -6px;
}
.editing:nth-of-type(1) {
  border-top: 0!important;
  margin-top: -6px;
}
.editing:nth-of-type(1) .za-add {
  margin-top: 0;
}

</style>

<script>
  import axios from 'axios'
  import DocumentEdit from '@/components/DocumentEdit.vue'
  import Document from '@/components/ListDocumentsItem.vue'
  import DocumentAttachModal from '@/components/DocumentAttachModal.vue'
  import DocumentDeleteModal from '@/components/DocumentDeleteModal.vue'
  
  export default {
    name: 'ListDocuments',
    props: ['parentType', 'parentId', 'serviceProviderId'],
    data: function() { return {
      title: '',
      url: '',
      description: '',
      showAttachModal: false,
      documentIdToDelete: null,
      showDeleteModal: false,
      documents: [],
      errors: {}
    }},
    components: {
      Document,
      DocumentEdit,
      DocumentAttachModal,
      DocumentDeleteModal
    },
    watch: {
      parentId() {
        this.refresh();
      }
    },
    methods: {
      refresh() {
        if (this.parentId !== 0) {
          axios
            .get(this.$root.$data.server_base + '/api/documents.php',
              { params: {
                parent_id:this.parentId,
                parent_type: this.parentType,
                service_provider_id: this.serviceProviderId
              }})
            .then(response => {
              this.documents = response.data.documents
            })
        }
      },
      confirmDocumentDelete(document_id) {
        this.documentIdToDelete = document_id
        this.showDeleteModal = true
      },
      detachDocument(document_id) {
        let data = {
          service_provider_id: this.serviceProviderId,
          parent_object_type: this.parentType,
          parent_object_id: this.parentId,
          action: 'detach',
          document_id: this.documentIdToDelete
        }
        
        axios
          .post(this.$root.$data.server_base + '/api/document_attach_detach.php',
            data,
            {headers:{
                'Content-Type': 'application/json',
            }})
          .then(() => {
            this.documentIdToDelete = null
            this.showDeleteModal = false
            this.refresh();
          })
      }
    },
    mounted() {
      this.refresh()
    },
    computed: {
      documentToDelete() {
        if (this.documentIdToDelete === null) return null
        return this.documents.find((d) => d.document_id == this.documentIdToDelete)
      }
    }
  }
</script>