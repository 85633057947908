<template>
  <div>
    <v-row align="center">
      <v-col cols="12" sm="10">
        <toggable-text-field 
          ref="person_responsible"
          v-model="requirement.person_responsible"
          :label="$t('requirement.responsible')"
          :rules="[(v) => $validators.required(v)]"
          @save="handleSaveOneParam('person_responsible', ...arguments)"
          :readonly="readonly"
        ></toggable-text-field>     
      </v-col>
    </v-row>
    
    <v-row align="center">
      <v-col class="pb-0" cols="12">
        <p class="text-h6">Dates</p>
      </v-col>
      <v-col class="py-0" cols="12" sm="5">
        <toggable-date-picker
          ref="planned_start_date"
          v-model="requirement.planned_start_date"
          native-label
          :label="$t('requirement.planned_start_date')"
          @save="handleSaveOneParam('planned_start_date', ...arguments)"
          :readonly="readonly"
        ></toggable-date-picker>
      </v-col>

      <v-col cols="12" sm="5">
        <toggable-date-picker
          ref="planned_end_date"
          v-model="requirement.planned_end_date"
          native-label
          :label="$t('requirement.planned_end_date')"
          @save="handleSaveOneParam('planned_end_date', ...arguments)"
          :readonly="readonly"
        ></toggable-date-picker>
      </v-col>
    </v-row>

    <v-row class="mt-0" align="center">
      <v-col>
        <toggable-tip-tap
          ref="sp_content"
          v-model="requirement.sp_content.workplan"
          :label="$t('requirement.workplan')"
          @save="handleSaveOneParam('sp_content', ...arguments)"
          :readonly="readonly"
        ></toggable-tip-tap>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';


export default Vue.extend({
  name: 'TabRealisations',
  props: ['readonly', 'requirement'],
  components: {},
  data () { return {
  }},
  computed: {
  },
  methods: {
    handleSaveOneParam(key, value) {
      this.$axios
        .put(this.$root.$data.server_base + '/api/requirement/update.php',
          { requirement_id: this.requirement.requirement_id,
            service_provider_id: this.$route.params.service_provider_id,
            [key]: this.requirement[key]})
        .then((response => {
          this.$refs[key].markAsSaved();
        }))
    },
  },
 
});
</script>