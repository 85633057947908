<template>
  <v-container>
    <h2>{{ $tc("navigation.general") }}</h2>
    <v-form v-model="isFormValid" ref="form">
      <v-text-field
        v-for="locale in $locales"
        :key="locale.value"
        v-model="internal.name[locale]"
        :label="$langobj.label($t('provider.name'), locale)"
        :rules="[
          (v) => $validators.required(v, [$t('provider.name')]),
        ]"
        :loading="loading"
      />
      <v-text-field
        v-model="internal.name_letters_patent"
        :label="$t('provider.name_letters_patent')"
        :rules="[(v) => $validators.required(v, [$t('provider.name_letters_patent')])]"
        :loading="loading"
      />
      <v-text-field
        v-for="locale in $locales"
        :key="locale.value"
        v-model="internal.url[locale]"
        :label="$langobj.label($t('provider.website'), locale)"
        :rules="[
          (v) => $validators.url(v, [$t('provider.website')]),
        ]"
        :loading="loading"
      >
        <template v-slot:prepend>
          <a v-if="internal.url[locale]" :href="internal.url[locale]" target="_blank">
            <v-icon class="mr-1">mdi-open-in-new</v-icon>
          </a>
          <v-icon v-else class="mr-1" color="grey lighten-2">
            mdi-checkbox-blank-off-outline
          </v-icon>
        </template>
      </v-text-field>
      <v-row class="my-2">
        <v-col cols="2">
          <v-icon class="mr-3">mdi-map-marker</v-icon>
          {{ $t('address.label') }}
        </v-col>
        <v-col cols="10">
          <v-text-field dense
            v-model="internal.address.line_1"
            :loading="loading"
            :label="$t('address.line_1')"
          />
          <v-text-field dense
            v-model="internal.address.line_2"
            :loading="loading"
            :label="$t('address.line_2')"
          />
          <v-row>
            <v-col cols="8">
              <v-text-field dense
                v-model="internal.address.city"
                :loading="loading"
                :label="$t('address.city')"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field dense
                v-model="internal.address.province"
                :loading="loading"
                :label="$t('address.province')"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field dense
                v-model="internal.address.postal_code"
                :loading="loading"
                :label="$t('address.postal_code')"
              />
            </v-col>
          </v-row>

        </v-col>
      </v-row>
      <v-select
        v-if="priv('agent')"
        clearable
        label="Agent primaire"
        v-model="internal.primary_agent_user_id"
        :items="responsable_options"
        item-text="responsable_name"
        item-value="user_id"
        :placeholder="$tc('label.none')"
        :loading="loading"
      ></v-select>

      <v-select
        :label="$t('provider.designation_status')"
        v-model="internal.designation_status_id"
        :items="
          (status_options || []).map((option) => ({
            text: $langobj.parse(option.name),
            value: option.id,
          }))
        "
        :placeholder="$tc('label.none')"
        :loading="loading"
        :disabled="!priv('agent')"
      ></v-select>

      <template v-if="priv('admin')">
        <v-select 
          label="Statut"
          v-model="internal.state"
          :loading="loading"
          :items="status_items"
        ></v-select>
        <v-text-field
          v-model="internal.ohfs_number"
          :label="$t('provider.ohfs_number')"
          :rules="[(v) => $validators.number(v, [$t('provider.ohfs_number')])]"
          :loading="loading"
        />  
      </template>

      <v-btn color="primary" @click="handleSave" :disabled="disableSave" :loading="saving">
        {{ $t('label.save') }}
      </v-btn>
      <v-btn color="primary" text @click="handleCancel">
        {{ $t('label.cancel') }}
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import _ from 'lodash';
import ObjectUtils from '@/utils/ObjectUtils';
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter';
import {privileges} from '@/mixins/privileges.js';

export default {
  data() { return {
    isFormValid: true,
    internal: { name: {}, url: {}, state: null, address: {}, designation_status_id: null, ohfs_number: null },
    storeData: null,
    responsable_options: [],
    status_options: [],
    loading: true,
    saving: false,
  }},

  components: {
  },
  mixins: [privileges],

  computed: {
    status_items() { return [
      { text: this.$tc('label.active'), value: 1 },
      { text: this.$tc('label.inactive'), value: 2 },
    ]},
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    }
  },

  methods: {
    refresh() {
      this.loading = true;
      this.saving = false;
      this.$axios
        .get(this.$root.$data.server_base + '/api/service_provider/get_general_info.php',
          { params: { 
              service_provider_id: this.$route.params.service_provider_id
          }})

        .then((response) => {
          this.storeData = response.data.service_provider;
          if(this.storeData.url === null) this.storeData.url = {};

          this.internal = _.cloneDeep(this.storeData);
          this.responsable_options = response.data.responsable_options;
          this.status_options = response.data.status_options;
          this.loading = false;
        });
    },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }  
      let obj = ObjectUtils.getObjectDiff(this.internal, this.storeData);
      obj.service_provider_id = this.internal.service_provider_id;
      this.saving = true;
      this.$axios
        .put("/api/service_provider/update.php", obj,
          { headers: {'Content-Type': 'application/json' }})
        .then((response) => {
          this.storeData = response.data;
          if(this.storeData.url === null) this.storeData.url = {};
          this.internal = _.cloneDeep(this.storeData);
          this.saving = false;
          GlobalEventEmitter.$emit('refreshNavSideBar');
        });
    },
    handleCancel() {
      this.refresh();
    },
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
</style>