import i18n from '@/i18n';

const validators = {
  required: (val, messages) => {
    return !!val || i18n.t('error.required', messages);
  },

  number: (val, messages) => {
    const regExp = /^[0-9]*$/g;
  
    return (
      (val ? regExp.test(val) : true) || i18n.t('validator.number')
    );
  },

  url: (val, messages) => {
    const urlRegExp = 
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
    
    return (
      (val ? urlRegExp.test(val) : true) || i18n.t('error.invalid', messages)
    );
  },

  phone_number: (val, messages) => {
    const phoneRegExp =
      /(?=\(|\b)(?:\+?1 ?[-.]?)?(?:\(\d{3}\)|\d{3}) ?[-.]? ?\d{3} ?[-.]? ?\d{4}\b/g;
    return (
      (val ? phoneRegExp.test(val) : true) || i18n.t('error.invalid', messages)
    );
  },

  password: (val, messages) => {
    const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

    return (
      (val ? passwordRegExp.test(val) : true) || i18n.t('error.invalid_password_format', messages)
    );
  },
};

export default { validators };
