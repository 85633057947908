<template>
  <v-container fluid>
    <h2>{{ $tc('provider.label', 2)}}</h2>
    <div class="d-flex my-4">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$tc('label.search')"
        dense
        outlined
        hide-details
        class="shrink"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-checkbox
        dense
        v-model="showInactive"
        :label="$t('provider.show_inactive')"
      ></v-checkbox>
      <v-select
        v-model="designation_status"
        :items="
          (designation_status_options || []).map((option) => ({
              text: $langobj.parse(option.name),
              value: option.id,
            }))
          "
        :label="$tc('provider.designation_status')"
        dense
        clearable
        class="ml-4 filter-box"
      ></v-select>
      <v-select
        v-model="responsable"
        :items="responsable_options"
        item-text="responsable_name"
        item-value="user_id"
        :label="$tc('provider.responsable')"
        dense
        clearable
        class="ml-4 filter-box"
      ></v-select>
    </div>
    
    <v-data-table
      :items-per-page="25"
      :footer-props="{itemsPerPageOptions:[10,25,50,-1]}"
      dense
      :headers="columns"
      :items="localeAwareFilteredItems"
      item-key="service_provider_id"
      :item-class="rowClass"
      :search="search"
      sort-by="name"
      :loading="loading"
      @click:row="handleRowClick"
    >
      <template v-slot:[`item.website`]="{item}">
        <a v-if="item.website" :href="item.website" target="_blank" @click.stop>
          <v-icon class="mr-1">mdi-open-in-new</v-icon>
        </a>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import {privileges} from '@/mixins/privileges.js';

export default {
  data: function() {return {
    search: "",
    responsable: null,
    designation_status: null,
    designation_status_options: [],
    responsable_options: [],
    providers: [],
    showInactive: false,
    loading: false,
  }},

  name: 'ServiceProviderList',
  mixins: [privileges],

  methods: {
    handleRowClick(row) {
      this.$router.push(
        { name: 'fournisseurs', params: { service_provider_id: row.service_provider_id }}
      );
    },
    rowClass(item) {
      return item.state == 2 ? "text--disabled text-decoration-line-through" : "";
    },
    refresh() {
      this.loading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/service_provider/service_provider_dropdown.php',
          { params:{entity:this.entity}})
        .then(response => (
          this.designation_status_options = response.data.designation_status,
          this.responsable_options = response.data.responsable
        ))
      this.$axios
        .get(this.$root.$data.server_base + '/api/service_provider/list.php')
        .then((response) => {
          this.providers = response.data.providers;
          this.loading = false;
        })
    },
  },
  computed: {
    localeAwareFilteredItems() {
      return this.providers
        .filter(item => item.state == 1 || (this.showInactive && item.state != 1))
        .filter(item => this.responsable == null ||
          this.responsable == item.primary_agent_user_id)
        .filter(item => this.designation_status == null ||
          this.designation_status == item.designation_status_id)
        .map(item => {
          return {...item,
            name: this.$langobj.parse(item.name),
            website: this.$langobj.parse(item.url),
            region_lang: this.$langobj.parse(item.region_lang),
            designation_status_lang: this.$langobj.parse(item.designation_status_lang),
            state_lang: item.state == 1 ? this.$t('label.active'): this.$t('label.inactive'),
            reports: item.bdssf_report_count + item.ozi_report_count,
          };
        })
    },

    columns() {
      const headers = this.filterArrayForPrivilege([
        { text: this.$tc('provider.label'), value: 'name' },
        { text: this.$tc('provider.website'), value: 'website', searchable: false },
        { text: this.$tc('provider.region'), value: 'region_lang' },
        { text: this.$tc('provider.des_status'), value: 'designation_status_lang' },
        { text: this.$tc('report.label', 2), value: 'reports' },
        { text: this.$tc('provider.responsable'), value: 'primary_agent_fullname' },
        { text: this.$tc('provider.state'), value: 'state_lang', privilege: 'agent' }
      ]);
      return headers;
    }
  },
  
  mounted () {
    this.refresh();
  }
}
</script>

<style lang="scss" scoped>
.filter-box {
  max-width: 250px;
}
</style>
