<template>
  <table class="table table-bordered table-striped mb-none table-grid-general">
    <thead v-show="!hideHeaderRow">
      <tr>
        <th style="vertical-align:middle;" tabindex="0" v-for="key in columns"
          @click="((toSort[key.field] > 0) ? sortBy(key.field) : '')"
          :class="((toSort[key.field] > 0) ? ((sortOrders[key.field] > 0) ? 'sorting_asc' : 'sorting_desc') : '')" v-html="key.title" :key="key.field">
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(entry, index) in filteredData"  class="row-hover" :key="index">
        <component :is="typeOfGridCell(entry)" v-for="key in columns" :entry="entry" :key="key.field">
          <template v-if="hasSlot(key.field)">
            <slot :name="key.field" :entry="entry"></slot>
          </template>
          <template v-else>
            <span v-html="entry[key.field]"></span>
          </template>
        </component>
      </tr>
    </tbody>
  </table>
</template>

<script>
import GridCellClickable from '@/components/GridCellClickable.vue';
import GridCellNonClickable from '@/components/GridCellNonClickable.vue';
export default {
  name: 'GridGeneral',
  props: ['data','columns','sortColumns','loadData', 'hideHeaderRow'],
  components: {
    GridCellClickable,
    GridCellNonClickable
  },
  data: function () {
    var sortOrders = {}
    this.columns.forEach(function (key) {
      sortOrders[key.field] = 1
    })

    
    var toSort = {};
    this.sortColumns.forEach(function (key) {
      toSort[key] = 1
    })


    return {
      sortKey: '',
      sortOrders: sortOrders,
      toSort: toSort,
      rliss:'',
      admin:'',

    }
  },

  computed: 
  {

    filteredData: function () {

      // var sortKey = this.sortKey
      // var order = this.sortOrders[sortKey] || 1
      var data = this.data

      return data
    },

  
  },
  methods: 
  {

    sortBy: function (key) {
      this.sortKey = key
      this.$parent.sort = key;
      
      this.$parent.sortOrder = this.sortOrders[key];
      this.sortOrders[key] = this.sortOrders[key] * -1
      this.$parent.refresh();

    },
    
    hasSlot: function(field) {
      return this.$scopedSlots[field] !== undefined
    },
    
    typeOfGridCell(entry) {
      if (entry.url !== undefined) {
        return GridCellClickable
      }
      return GridCellNonClickable
    }
  }
}
</script>

<style>

.sorting_asc
{
  cursor:pointer;
  white-space: nowrap;
}

.sorting_asc::after
{
  position: relative;
  content: "\00a0\00a0\00a0\00a0";
  font-size: 13px;
  bottom: 0;
}

.sorting_asc:hover::after
{
  position: relative;
  content: "  \25bc";
  font-size: 11px;
  bottom: 0;
  width:20px
}


.sorting_desc
{
  cursor:pointer;
  white-space: nowrap;
}

.sorting_desc::after
{
  position: relative;
  content: "\00a0\00a0\00a0\00a0";
  font-size: 13px;
  bottom: 0;
}

.sorting_desc:hover::after
{
  position: relative;
  content: "  \25b2";
  font-size: 11px;
  bottom: 0;
}

.table-grid-general .row-hover {
  cursor: default;
}

</style>
