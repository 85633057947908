<template>
  <router-link class="nav-link" tag="td" :to="entry.url">
    <slot></slot>
  </router-link>
</template>

<style scoped>
td {
  cursor: pointer;
}
</style>

<script>
  export default {
    name: 'GridCellClickable',
    props: ['entry']
  }
</script>