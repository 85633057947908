<template>
  <div>
    <h3>Statuts des exigences</h3>
    <table class="table table-sm">
      <thead>
        
        <tr>
          <th>Statut</th>
          <th style="text-align:center;border-top:0px">Nb</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='requirementstatus in requirementsstatus' >
          <td v-text="requirementstatus.status"></td>
          <td style="text-align:center" v-text="requirementstatus.nbstatus"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SPRequirementStatus',
  props: {
    requirementsstatus: {},
  },

}
</script>
