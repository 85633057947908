<template>
  <v-container fluid>
    <h2>{{ $tc("navigation.flsreport", 2) }}</h2>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :loading="loading"
      class="row-pointer"
      @click:row="handleRowClick"
    >
      <template v-slot:[`item.design_status_name`]="{ item }">
        {{ $langobj.parse(item.design_status_name) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import GridGeneral from '@/components/GridGeneral.vue'

import axios from 'axios';

export default {
  data() { return {
    ozi_reports: [],
    bdssf_reports: [],
    loading: false,
  }},
  name: "ReportList",
  components: {
  },

  computed: {
    headers() {
      return [
        { text: this.$tc('report.source'), value: 'source' },
        { text: this.$tc('report.period'), value: 'period' },
        { text: this.$tc('report.designation'), value: 'design_status_name' },
        { text: this.$tc('report.extracted_date'), value: 'submitted_date'},
      ];
    },
    items() {
      const ozi_reports = this.ozi_reports.map(e => {
        return {
          source: "OZi",
          period: e.period,
          design_status_name: e.design_status_name,
          submitted_date: e.submitted_date,
          workplan_id: e.workplan_id,
        }
      });
      const bdssf_reports = this.bdssf_reports.map(e => {
        return {
          source: this.$tc('report.bdssf'),
          period: e.period,
          design_status_name: e.designation_status_lang,
          submitted_date: e.extracted_at,
          report_id: e.report_id,
        }
      });

      return ozi_reports.concat(bdssf_reports);
        // .sort()
    },
  },

  methods: {
    handleRowClick(row) {
      if(row.workplan_id > 0) {
        this.$router.push(
          { name: 'flsreport', params:
            {
              service_provider_id: this.$route.params.service_provider_id,
              workplan_id: row.workplan_id,
            }
          }
        );
      } else {
        this.$router.push(
          { name: 'bdssf_report', params:
            {
              service_provider_id: this.$route.params.service_provider_id,
              report_id: row.report_id,
            }
          }
        );
      }
    },
    refresh: function() {
      this.loading = true;
      axios
        .get(this.$root.$data.server_base + '/api/service_provider/get_report_list.php',
          { params: { 
              service_provider_id: this.$route.params.service_provider_id
          }})

        .then(response => (
          this.ozi_reports = response.data.reports,
          this.loading = false
        ))
      axios
        .get(this.$root.$data.server_base + '/api/bdssf_report/list.php',
          { params: { 
              service_provider_id: this.$route.params.service_provider_id
          }})

        .then(response => (
          this.bdssf_reports = response.data.reports,
          this.loading = false
        ))
    },
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/collab.scss";
</style>