<template>
  <div>
    <h2>{{ $tc("report.fls34.tab_6") }}</h2>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() { return {
    report: {}    
  }},

  components: {
  },

  computed: {
  },

  methods: {
    refresh: function() {
      // axios
      //   .get(this.$root.$data.server_base + '/api/service_provider/get_general_info.php',
      //     { params: { 
      //         service_provider_id: this.$route.params.service_provider_id
      //     }})

      //   .then(response => (
      //     this.service_provider = response.data.service_provider
      //   ))
    },
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
</style>