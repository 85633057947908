<template>
  <v-container fluid>
    <h2 class="mt-2">{{ $t('ticket.create') }}</h2>
    <v-form v-model="isFormValid" ref="form">
      <v-row>
        <v-col cols="12" sm="4">
          <v-select
            v-model="ticket_type_key"
            :label="$t('ticket.type')"
            @change="loadStatus"
            :items="
              (ticket_type_option || []).map((option) => ({
                text: $langobj.parse(option.name),
                value: option.ticket_type_key,
              }))"
            :rules="[(v) => $validators.required(v)]"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row v-show="showStatus" class="mt-0">
        <v-col cols="12" sm="4">
          <v-select
            v-model="status"
            :label="$t('ticket.status')"
            :items="
              (status_option || []).map((option) => ({
                text: $langobj.parse(option.name_lang),
                value: option.status_id,
              }))"
            :rules="[(v) => $validators.required(v)]"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="4">
          <v-select
            v-model="responsable"
            :label="$t('ticket.responsable')"
            :items="responsable_option"
            item-text="responsable_name"
            item-value="user_id"
            :rules="[(v) => $validators.required(v)]"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" sm="4">
          <v-select
            v-model="service_provider_id"
            :label="$tc('provider.label', 1)"
            :items="
              (service_provider_option || []).map((option) => ({
                text: $langobj.parse(option.name),
                value: option.service_provider_id,
              }))
              .sort((a,b) => a.text.localeCompare(b.text))"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row v-show="showEffectiveDate">
        <v-col cols="12" sm="4">
          <toggable-date-picker
            v-model="effective_at"
            native-label
            :label="$t('ticket.effective_at')"
          ></toggable-date-picker>
        </v-col>
      </v-row>

      <v-row class="mt-0" v-show="showDueDate">
        <v-col cols="12" sm="4">
          <toggable-date-picker
            v-model="due_date"
            native-label
            :label="$t('ticket.due_date')"
            :messages="dueDateMessages"
          ></toggable-date-picker>
        </v-col>
      </v-row>

      <v-row v-show="showPriority">
        <v-col cols="12" sm="4">
          <priority-pulldown v-model="priority"></priority-pulldown>
        </v-col>
      </v-row>

      <v-text-field
        v-model="title"
        :label="$t('ticket.title')"
        :rules="[(v) => $validators.required(v)]"
      >
      </v-text-field>

      <tip-tap
        v-model="description"
        :label="$t('ticket.details')"
      >
      </tip-tap>

      <v-row class="mt-2">  
        <v-col>
          <v-btn class="mr-2" color="primary" @click="handleSave" :loading="saving">
            {{ $t('label.save') }}
          </v-btn>
          <v-btn color="primary" text @click="$router.go(-1)">
            {{ $t('label.cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<style>
.due-date-picker {
  display: inline-block;
  width: 10em;
  margin-right: 1em;
}
</style>


<script>

import Datepicker from 'vuejs-datepicker'
import PriorityPulldown from '@/components/PriorityPulldown.vue'

export default {
  data: function() {return {
    isFormValid: true,
    saving: false,

    ticket_id:0,
    keyword:'',
    ticket_type_key:'',
    responsable:'',
    requirement:'',
    status: null,
    service_provider_id: 0,
    ticket_type_option: [],
    responsable_option: [],
    requirement_option: [],
    service_provider_option: [],
    status_option: [],
    effective_at: null,
    due_date: null,
    title:'',
    description:'',
    showStatus:false,
    showEffectiveDate:false,
    showDueDate:false,
    showPriority:false,
    priority: 2,
    errors:{ticket_type:'',status:'',responsable:'',service_provider_id:'',title:'',description:''},
  }},

  name: 'TicketCreation',
  components: {
    Datepicker,
    PriorityPulldown,
  },
  computed: {
    dueDateMessages() {
      if(this.isPassedDueDate)
        return this.$t('ticket.warning.passed_due');
      else if( this.isSoonDue)
        return this.$t('ticket.warning.due_soon');
      return null;
    },
    isPassedDueDate() {
      return this.moment.utc(this.due_date).isBefore(this.moment().subtract(1, 'days'))
    },
    isSoonDue() {
      return !this.isPassedDueDate && this.moment.utc(this.due_date).isBefore(this.moment().add(5, 'days'))
    },
  },
  methods: {
    loadFilters() {
      this.$axios
        .get(this.$root.$data.server_base + '/api/ticket/ticket_filter_dropdown.php',
          { })
        .then(response => {
          this.ticket_type_option = response.data.ticket_type
            .filter((option) => option.ticket_type_key != 'designation_history' 
              && option.ticket_type_key!='requirement_appreciation');
          this.responsable_option = response.data.responsable;
          this.service_provider_option = response.data.service_provider; 
        });
    },

    loadStatus() {
      this.$axios
        .get(this.$root.$data.server_base + '/api/ticket/ticket_dropdown_status.php',
          { params:{ticket_type_key:this.ticket_type_key}})
        .then(response => (
          this.status_option = response.data.ticket_status,
          this.status=response.data.default_status,
          this.showStatus=true,
          this.showEffectiveDate=response.data.showEffectiveDate,
          this.showDueDate=response.data.showDueDate,
          this.showPriority=response.data.showPriority))
    },

    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }

      const data = {
        ticket_type_key: this.ticket_type_key,
        ticket_status: this.status,
        responsable: this.responsable,
        service_provider_id: this.service_provider_id,
        // requirement_master_id: this.requirement,
        title: this.title,
        description: this.description,
        effective_at: this.effective_at,
        due_date: this.due_date,
        priority: this.showPriority? this.priority : null
      };
      this.saving = true;
      this.$axios
        .put(this.$root.$data.server_base + '/api/ticket_save.php', data )
        .then(() => {
          this.saving=false;
          this.$router.go(-1);
      });
      
    },
  },
  mounted () {
    this.loadFilters();
  }
}

</script>
