import { setI18n } from '@/i18n';
import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/account/Login.vue'
import ResetPassword from './views/account/ResetPassword.vue'
import ForgotPassword from './views/account/ForgotPassword.vue'
import TicketList from './views/ticket/TicketList.vue'
import TicketCreation from './views/ticket/TicketCreation.vue'
import TicketEdition from './views/ticket/TicketEdition.vue'
import ServiceProviderList from './views/ServiceProviderList.vue'

import DossierList from './views/dossier/DossierList.vue'

import AdministrationTop from './views/admin/AdministrationTop.vue'
import AdminServiceProvider from './views/admin/AdminServiceProvider.vue'
import AdminServiceProviderDetail from './views/admin/AdminServiceProviderDetail.vue'
import AdminVersion from './views/admin/AdminVersion.vue'
import AdminScripts from './views/admin/AdminScripts.vue'
import AdminUsers from './views/admin/AdminUsers.vue'
import AdminUserDetails from './views/admin/AdminUserDetails.vue'
import AdminEmails from './views/admin/AdminEmails.vue'
import AdminEmailDetails from './views/admin/AdminEmailDetails.vue'
import AdminDesignationModels from './views/admin/AdminDesignationModels.vue'
import AdminDesignationModelDetails from './views/admin/AdminDesignationModelDetails.vue'
import AdminRequirementDefDetail from './views/admin/AdminRequirementDefDetail.vue'
import AdminElementDefDetail from './views/admin/AdminElementDefDetail.vue'
import AdminEnumerations from './views/admin/AdminEnumerations.vue';
import AdminEnumerationDetails from './views/admin/AdminEnumerationDetails.vue';
import AdminTicketStatus from './views/admin/AdminTicketStatus.vue';
import AdminTicketStatusDetails from './views/admin/AdminTicketStatusDetails.vue';
import AdminHelpDialogs from './views/admin/AdminHelpDialogs.vue';
import AdminHelpDialogDetails from './views/admin/AdminHelpDialogDetails.vue';

import OnTdb from './views/dashboard/Tdb.vue'
import ServiceProviderTop from './views/ServiceProviderTop.vue'
import ServiceProviderTdb from './views/serviceProvider/dashboard/ServiceProviderTdb.vue'
// import ServiceProviderRequirement from './views/ServiceProviderRequirement.vue'
// import ServiceProviderRequirementDetail from './views/ServiceProviderRequirementDetail.vue'
//import EtablissementServices from './views/EtablissementServices.vue'
import ServiceProviderDossier from './views/serviceProvider/ServiceProviderDossier.vue'
import AccDossierTop from '@/views/dossier/AccDossierTop.vue';
import DossierSummary from '@/views/dossier/DossierSummary.vue';
// import ServiceProviderDossierCreation from './views/ServiceProviderDossierCreation.vue'
// import ServiceProviderDossierDetail from './views/ServiceProviderDossierDetail.vue'

import ServiceProviderService from './views/ServiceProviderService.vue'
// import ServiceProviderServiceCreation from './views/ServiceProviderServiceCreation.vue'
// import ServiceProviderServiceDetail from './views/ServiceProviderServiceDetail.vue'

import ContactList from './views/serviceProvider/ContactList.vue'
import ContactDetail from './views/serviceProvider/ContactDetail.vue'

import ServiceProviderReportList from '@/views/serviceProvider/ReportList';
import ServiceProviderTickets from '@/views/serviceProvider/ProviderTicketList';
import ServiceProviderGeneralInfo from '@/views/serviceProvider/GeneralInfo';

import FlsReportTop from '@/views/serviceProvider/report/ReportTop';
import FlsReportGeneral from '@/views/serviceProvider/report/ReportGeneral';
import FlsReportDesignation from '@/views/serviceProvider/report/ReportDesignation';
import FlsReportClientele from '@/views/serviceProvider/report/ReportClientele';
import FlsReportRequirements from '@/views/serviceProvider/report/ReportRequirements';
import FlsReportRequirementDetail from '@/views/serviceProvider/report/ReportRequirementDetail';
import FlsReportCommSupport from '@/views/serviceProvider/report/ReportCommSupport';
import FlsReportPractices from '@/views/serviceProvider/report/ReportPractices';
import FlsReportHR from '@/views/serviceProvider/report/ReportHR';

import BdssfReportTop from '@/views/serviceProvider/bdssfReport/BdssfReportTop';
import BdssfReportGeneral from '@/views/serviceProvider/bdssfReport/BdssfReportGeneral';
import BdssfReportDesignation from '@/views/serviceProvider/bdssfReport/BdssfReportDesignation';
import BdssfReportClientele from '@/views/serviceProvider/bdssfReport/BdssfReportClientele';
import BdssfReportRequirements from '@/views/serviceProvider/bdssfReport/BdssfReportRequirements';
import BdssfReportRequirementDetail from '@/views/serviceProvider/bdssfReport/BdssfReportRequirementDetail';
import BdssfReportHR from '@/views/serviceProvider/bdssfReport/BdssfReportHR';

import ServiceProviderDocuments from './views/serviceProvider/DocumentList.vue'
import ServiceProviderDocumentDetail from './views/ServiceProviderDocumentDetail.vue'

import ServiceProviderResources from '@/views/serviceProvider/Resources.vue';

import Perdu from './views/Perdu.vue'

import TheTopBar from './components/menus/TheTopBar.vue';
import AccompTopBar from './components/menus/AccompTopBar.vue';
import CollabTopBar from './components/menus/CollabTopBar.vue';
import TheSideNav from './components/menus/TheSideNav.vue';
import AdminSideNav from './components/menus/AdminSideNav.vue';
import ServiceProviderSideNav from './components/menus/ServiceProviderSideNav.vue';
import CollabSideNav from './components/menus/CollabSideNav.vue';

import CollabTop from './views/fss/CollabTop.vue';
// import CollabDashboard from './views/fss/Dashboard.vue';
import InfoGenerale from './views/fss/InfoGenerale.vue';
import ListeDossiers from './views/fss/DossierList.vue';
import DossiersTop from './views/fss/DossierTop.vue';

import ListeExigences from './components/dossiers/ListeExigences.vue';
import UneExigence from './components/dossiers/UneExigence.vue';
import OngletStatut from './components/dossiers/OngletStatut.vue';
import OngletSommaire from './components/dossiers/OngletSommaire.vue';
import DossiersServices from './components/dossiers/Services.vue';
import DossierGeneralInfo from './views/dossier/DossierGeneralInfo.vue';
import GeneralInfoTop from './views/dossier/GeneralInfoTop.vue';
import DossierDocuments from './views/dossier/DossierDocuments.vue';
import DossierWorkplan from './views/dossier/DossierWorkplan.vue';
import DossierElements from './views/dossier/DossierElements.vue';
import DossierHistory from './views/dossier/DossierHistory.vue';
import CollabRH from './components/dossiers/RessourcesHumaines.vue';
import TabDefinition from '@/components/requirement/TabDefinition.vue';
import TabWorkplan from '@/components/requirement/TabWorkplan.vue';
import TabRealisations from '@/components/requirement/TabRealisations.vue';
import TabEvaluation from '@/components/requirement/TabEvaluation.vue';
import TabConversation from '@/components/requirement/TabConversation.vue';

Vue.use(Router)

const router = new Router({
  base: "/",
  mode: 'history',
  linkActiveClass: 'is-active',
  routes: [
    { path: '', redirect: 'login' },
    {
      meta: {version: 'collab'},
      name: 'login',
      path: '/login',
      components: {fullScreen: Login}
    },
    {
      meta: {version: 'collab'},
      name: 'pw-reset',
      path: '/reset-password/:user_id/:token',
      components: {fullScreen: ResetPassword}
    },
    {
      meta: {version: 'collab'},
      name: 'forgot-password',
      path: '/forgot-password',
      components: {fullScreen: ForgotPassword}
    },
    {
      meta: {version: 'collab'},
      name: 'tableaudebord',
      path: '/tableaudebord',
      components: {
        default: OnTdb,
        topBar: AccompTopBar,
        // sideNav: TheSideNav
      }
    },
    {
      path: '/billets',
      name: 'liste_billets',
      components: {
        default: TicketList,
        topBar: AccompTopBar,
        // sideNav: TheSideNav
      }
    },
    {
      path: '/billets/creation',
      name: 'billet_creation',
      components: {
        default: TicketCreation,
        topBar: AccompTopBar,
        // sideNav: TheSideNav
      }
    },
    {
      path: '/billets/:ticket_id',
      name: 'billet',
      components: {
        default: TicketEdition,
        topBar: AccompTopBar,
        // sideNav: TheSideNav
      }
    },
    {
      path: '/fournisseurs',
      name :'liste_fournisseurs',
      components: {
        default: ServiceProviderList,
        topBar: AccompTopBar,
        // sideNav: TheSideNav
      }
    },

    {
      path: '/fournisseurs/:service_provider_id',

      components: {
        default: ServiceProviderTop,
        topBar: AccompTopBar,
        sideNav: ServiceProviderSideNav
      },
      children: [
        {
          path: '',
          name :'fournisseurs',
          redirect: {name: 'sp_tableaudebord'}
        },
        {
          name: 'sp_tableaudebord',
          path: '/fournisseurs/:service_provider_id/tableaudebord',
          meta: {greyBg: true},
          component: ServiceProviderTdb
        },
        {
          name: 'sp_info',
          path: '/fournisseurs/:service_provider_id/info',
          component: ServiceProviderGeneralInfo
        },
        // {
        //   name: 'exigences',
        //   path: '/fournisseurs/:service_provider_id/exigence',
        //   component: ServiceProviderRequirement
        // },
        // {
        //   name: 'exigenceDetail',
        //   path: '/fournisseurs/:service_provider_id/exigence/:requirement_num',
        //   component: ServiceProviderRequirementDetail
        // },

        {
          name: 'dossiers',
          path: '/fournisseurs/:service_provider_id/dossiers',
          component: ServiceProviderDossier
        },

        {
          name: 'resources',
          path: '/fournisseurs/:service_provider_id/resources',
          component: ServiceProviderResources,
        },

        {
          name: 'dossier',
          path: '/fournisseurs/:service_provider_id/dossiers/:designation_dossier_id',
          // component: ServiceProviderDossierDetail,
          component: AccDossierTop,

          children: [
            { path: '', redirect: {name: 'dossier_summary'}},
            { 
              path: 'summary',
              name: 'dossier_summary',
              meta: {showOptionsMenu: true, greyBg: true},
              component: DossierSummary
            },
            { path: 'documents', name: 'dossier_documents', component: DossierDocuments },
            { path: 'workplan', name: 'dossier_workplan', component: DossierWorkplan },
            { path: 'elements', name: 'dossier_elements', component: DossierElements },
            { path: 'hr', name: 'dossier_hr', component: CollabRH },
            { path: 'requirements', name: 'dossier_requirements', component: ListeExigences },
            { 
              path: 'requirements/:requirement_id',
              name: 'dossier_one_requirement',
              component: UneExigence,
              children: [
                { path: '', redirect: {name: 'requirement_resources'}},
                { path: 'workplan', name: 'requirement_workplan', component: TabWorkplan },
                { path: 'realisations', name: 'requirement_realisations', component: TabRealisations },
                { path: 'evaluation', name: 'requirement_evaluation', component: TabEvaluation },
                { path: 'conversation', name: 'requirement_conversation', component: TabConversation },
                { path: 'resources', name: 'requirement_resources', component: TabDefinition },
              ]
            },
            { 
              path: 'general',  
              component: GeneralInfoTop,
              children: [
                { path: '', redirect: {name: 'dossier_general'}},
                { path: 'info', name: 'dossier_general', component: DossierGeneralInfo },
                { path: 'history', name: 'dossier_history', component: DossierHistory },
                { path: 'scope', name: 'dossier_scope', component: OngletStatut },
                { path: 'clientele', name: 'dossier_clientele', component: OngletStatut },
              ]
            },
          ]
        },
      
        {
          name: 'services',
          path: '/fournisseurs/:service_provider_id/services',
          component: ServiceProviderService
        },

        {
          name: 'flsreports',
          path: '/fournisseurs/:service_provider_id/rapports',
          component: ServiceProviderReportList
        },

        {
          name: 'providertickets',
          path: '/fournisseurs/:service_provider_id/billets',
          component: ServiceProviderTickets
        },

        {
          name: 'provider_notes',
          path: '/fournisseurs/:service_provider_id/notes',
          component: ServiceProviderTickets,
          props: {typeKeyFilter: 'note'}
        },

        {
          name: 'flsreport',
          path: '/fournisseurs/:service_provider_id/rapports/:workplan_id',
          component: FlsReportTop,
          redirect: {name: 'flsreport_general'},
          children: [
            {
              path: '',
              redirect: {name: 'flsreport_general'}
            },
            {
              path: 'general',
              name :'flsreport_general',
              component: FlsReportGeneral,
            },
            {
              path: 'designation',
              name :'flsreport_designation',
              component: FlsReportDesignation,
            },
            {
              path: 'community',
              name :'flsreport_community',
              component: FlsReportClientele,
            },
            {
              path: 'requirements',
              name :'flsreport_requirements',
              component: FlsReportRequirements,
            },
            {
              path: 'requirements/:requirement_id',
              name :'flsreport_requirement_detail',
              component: FlsReportRequirementDetail,
            },
            {
              path: 'commsupport',
              name :'flsreport_commsupport',
              component: FlsReportCommSupport,
            },
            {
              path: 'practices',
              name :'flsreport_practices',
              component: FlsReportPractices,
            },
            {
              path: 'hr',
              name :'flsreport_hr',
              component: FlsReportHR,
            },
          ]
        },

        {
          name: 'bdssf_report',
          path: '/fournisseurs/:service_provider_id/bdssf/:report_id',
          component: BdssfReportTop,
          redirect: {name: 'bdssf_report_general'},
          children: [
            {
              path: '',
              redirect: {name: 'bdssf_report_general'}
            },
            {
              path: 'general',
              name :'bdssf_report_general',
              component: BdssfReportGeneral,
            },
            {
              path: 'designation',
              name :'bdssf_report_designation',
              component: BdssfReportDesignation,
            },
            {
              path: 'community',
              name :'bdssf_report_community',
              component: BdssfReportClientele,
            },
            {
              path: 'requirements',
              name :'bdssf_report_requirements',
              component: BdssfReportRequirements,
            },
            {
              path: 'requirements/:requirement_id',
              name :'bdssf_report_requirement_detail',
              component: BdssfReportRequirementDetail,
            },
            {
              path: 'hr',
              name :'bdssf_report_hr',
              component: BdssfReportHR,
            },
          ]
        },

        {
          name: 'contacts',
          path: '/fournisseurs/:service_provider_id/contacts',
          component: ContactList
        },

        {
          name: 'contact',
          path: '/fournisseurs/:service_provider_id/contacts/:contact_id',
          component: ContactDetail
        },
        
        {
          name: 'documents',
          path: '/fournisseurs/:service_provider_id/documents',
          component: ServiceProviderDocuments,
        },
        
        {
          name: 'document',
          path: '/fournisseurs/:service_provider_id/documents/:document_id',
          component: ServiceProviderDocumentDetail
        },

        // {
        //   name: 'service_creation',
        //   path: '/fournisseurs/:service_provider_id/services/creation',
        //   component: ServiceProviderServiceCreation,
        // },

        // {
        //   name: 'service',
        //   path: '/fournisseurs/:service_provider_id/services/:service_id',
        //   component: ServiceProviderServiceDetail,
        // },
      ]
    },
    
    
    {
      path: '/dossiers',
      name :'liste_dossiers',
      components: {
        default: DossierList,
        topBar: AccompTopBar,
        // sideNav: TheSideNav
      }
    },


    {
      path: '/admin',
      components: {
        default: AdministrationTop,
        topBar: AccompTopBar,
        sideNav: AdminSideNav
      },
      children: [
        {
          name: 'admin',
          path: '',
          redirect: {name: 'version'}
        },
        {
          name: 'admin-providers',
          path: '/admin/providers',
          component: AdminServiceProvider
        },
        {
          name: 'admin-provider-details',
          path: '/admin/provider/:service_provider_id',
          component: AdminServiceProviderDetail
        },
        {
          name: 'version',
          path: '/admin/version',
          component: AdminVersion
        },
        {
          name: 'scripts',
          path: '/admin/scripts',
          component: AdminScripts
        },
        {
          name: 'admin-users',
          path: '/admin/users',
          component: AdminUsers
        },
        {
          name: 'admin-user-details',
          path: '/admin/users/:user_id',
          component: AdminUserDetails
        },
        {
          name: 'admin-emails',
          path: '/admin/emails',
          component: AdminEmails
        },
        {
          name: 'admin-email-details',
          path: '/admin/emails/:key',
          component: AdminEmailDetails
        },
        {
          name: 'admin-design-models',
          path: '/admin/models',
          component: AdminDesignationModels
        },
        {
          name: 'admin-design-model-details',
          path: '/admin/models/:designation_model_id',
          component: AdminDesignationModelDetails
        },
        {
          name: 'admin-requirement-def-detail',
          path: '/admin/models/:designation_model_id/requirement/:requirement_def_id',
          component: AdminRequirementDefDetail
        },
        {
          name: 'admin-element-def-detail',
          path: '/admin/models/:designation_model_id/element/:element_def_id',
          component: AdminElementDefDetail
        },
        {
          name: 'admin-enumerations',
          path: '/admin/enums',
          component: AdminEnumerations
        },
        {
          name: 'admin-enumeration-details',
          path: '/admin/enums/:enum_id',
          component: AdminEnumerationDetails
        },
        {
          name: 'admin-ticket-status',
          path: '/admin/ticketstatus',
          component: AdminTicketStatus
        },
        {
          name: 'admin-ticket-status-details',
          path: '/admin/ticketstatus/:ticket_status_id',
          component: AdminTicketStatusDetails
        },
        {
          name: 'admin-help-dialogs',
          path: '/admin/help_dialogs',
          component: AdminHelpDialogs
        },
        {
          name: 'admin-help-dialog-details',
          path: '/admin/help_dialogs/:key',
          component: AdminHelpDialogDetails
        },
      ]
    },

    {
      path: '*', redirect: "perdu"
    },
    {
      path: '/perdu',
      components: {
        default: Perdu,
        topBar: AccompTopBar,
        // sideNav: TheSideNav
      }
    },

    // Collab - fournisseur
    // {
    //   path: '/collab',
    //   meta: {version: 'collab'},

    //   components: {
    //     default: CollabTop,
    //     topBar: CollabTopBar,
    //     sideNav: CollabSideNav
    //   },
    //   children: [
    //     {
    //       path: '',
    //       name :'home',
    //       redirect: {name: 'collab_dashboard'}
    //     },
    //     {
    //       meta: {version: 'collab'},
    //       name: 'collab_dashboard',
    //       path: 'dashboard',
    //       component: CollabDashboard,
    //     },
    //     {
    //       meta: {version: 'collab'},
    //       name: 'collab_info_generale',
    //       path: 'info',
    //       component: InfoGenerale,
    //     },
    //     {
    //       meta: {version: 'collab'},
    //       name: 'collab_liste_dossiers',
    //       path: 'dossiers',
    //       component: ListeDossiers,
    //     },
    //     {
    //       meta: {version: 'collab'},
    //       name: 'collab_dossiers_services',
    //       path: 'services',
    //       component: DossiersServices,
    //     },
    //     {
    //       meta: {version: 'collab'},
    //       name: 'collab_dossiers_top',
    //       path: 'dossiers/:id',
    //       component: DossiersTop,
    //       children: [
    //         {
    //           meta: {version: 'collab'},
    //           name: 'collab_dossiers_exigences',
    //           path: 'exigences',
    //           component: ListeExigences,
    //         },
    //         {
    //           meta: {version: 'collab'},
    //           name: 'collab_dossiers_exigences_une',
    //           path: 'exigences/:exigenceId',
    //           component: UneExigence,
    //         },
    //         {
    //           meta: {version: 'collab'},
    //           name: 'collab_dossiers_statut',
    //           path: 'statut',
    //           component: OngletStatut,
    //         },
    //         {
    //           meta: {version: 'collab'},
    //           name: 'collab_dossiers_sommaire',
    //           path: 'sommaire',
    //           component: OngletSommaire,
    //         },
    //         {
    //           meta: {version: 'collab'},
    //           name: 'collab_dossiers_rh',
    //           path: 'rh',
    //           component: CollabRH,
    //         },
           
            
    //       ]
    //     },
    //   ]
    // },
  ]
})

router.beforeEach(async (to, from, next) => {
  await setI18n();
  if (
    to.matched.some((route) => route.meta.session) &&
    !SessionVM.$data.session
  ) {
    next({ name: 'home' }); // redirect home
  } else {
    next();
  }
});

export default router;