<template>  
  <v-container fluid class="d-flex justify-center align-center points" style="height: 100%">
    <div style="opacity: 1">
      <v-container>
        <v-row style="margin-bottom: 20px;" class="d-flex align-end">
          <v-img
            alt="OZi"
            class="shrink mr-2"
            contain
            src="@/assets/logo.svg"
            transition="scale-transition"
            width="150"
            height="47.25"
          />
          <v-spacer />
          <div>Collab</div>
        </v-row>
        <v-form @submit.prevent="login">
          <v-card width="500">
            <v-card-text>
              <v-text-field
                v-model="username"
                :label="`${$tc('user.username')}*`"
                :rules="[(v) => $validators.required(v, [$t('user.username')])]"
                @input="error = null"
                required
              />               
              <v-text-field
                v-model="password"
                @input="error = null"
                :label="`${$tc('user.password')}*`"
                :rules="[(v) => $validators.required(v, [$t('user.password')])]"
                :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (showPassword = !showPassword)"
                :type="showPassword ? 'text' : 'password'"
              />
            
              <v-alert dense v-show="error" type="error" outlined>
                {{error}}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="primary">
                <v-icon left>mdi-login</v-icon>
                {{$tc('label.login')}}
              </v-btn>
              <v-spacer />
              <v-btn color="primary" text :to="{name: 'forgot-password'}">{{ $tc('login.forgotPassword') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <div class="d-flex justify-center mt-4">
          <locale-selector />
        </div>
      </v-container>
    </div>
  </v-container>

</template>

<script>
import LocaleSelector from '@/components/locale/LocaleSelector.vue';
import {session} from '@/mixins/session.js';
import {privileges} from '@/mixins/privileges.js';

export default {
  name: 'Login',
  mixins: [privileges, session],

  data: function() {return {
    username: "",
    password: "",
    respData: {},
    error: null,
    showPassword: false,
  }},
  components: {
    LocaleSelector
  },
  methods: {
    login: function () {
      const { username, password } = this;
      this.$axios.post(
          this.$root.$data.server_base + '/api/auth.php',
          {username: username.trim(), password: password}
      ).then(resp => {
        if(resp.data.error) {
          this.clearSession();
          this.error = this.$tc('login.invalid');
        } else {
          this.storeSession(resp.data);
          this.readFromLocalStorage(); // initializes the role
          // this.setRole(resp.data.currentRole.role_key);

          this.navigateToHomePage();
        }
      }).catch((error) => {
        this.clearSession();
        this.error=true;
      });
    }
  },
  mounted () {
    window.scrollTo(0, 0);
  }

}
</script>

<style>
.points::before {
  content: "";
  opacity: 0.4;
  background: url('~@/assets/img/points.png');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
</style>