<template>
  <div>
    <v-card outlined class="mb-2">
      <v-card-text>
        <div class="text-h6">{{$t('requirement.deliverable')}}</div>
        <div v-html="requirement.deliverable_description"></div>
      </v-card-text>
    </v-card>
    <v-row align="center">
      <v-col>
        <toggable-tip-tap
          ref="interpretation"
          v-model="requirement.interpretation"
          :label="$t('requirement.interpretation')"
          @save="handleSaveOneParam('interpretation', ...arguments)"
          :readonly="readonly"
        ></toggable-tip-tap>
      </v-col>
    </v-row>
     <v-row align="center">
      <v-col>
        <toggable-tip-tap
          ref="other_feedback"
          v-model="requirement.other_feedback.observations"
          :label="$t('requirement.observations')"
          @save="handleSaveOneParam('other_feedback', ...arguments)"
          :readonly="readonly"
        ></toggable-tip-tap>
      </v-col>
    </v-row>
    <ListAppreciation
      :service_provider_id="$route.params.service_provider_id"
      :requirement_id="requirement.requirement_id"
      :readonly="readonly"
    ></ListAppreciation>
    <ListComment
      :parent_id="this.requirement.requirement_id"
      parent_class="requirement"
      :readonly="readonly"
    ></ListComment>
 
  </div>
</template>

<script>
import Vue from 'vue';
import ListComment from '@/components/comment/ListComment.vue';
import ListAppreciation from '@/components/appreciation/ListAppreciation.vue';

export default Vue.extend({
  name: 'TabEvaluation',
  components: {ListComment, ListAppreciation},

  props: ['requirement', 'readonly'],
  data () { return {   
  }},
  methods: {
    handleSaveOneParam(key, value) {
      this.$axios
        .put(this.$root.$data.server_base + '/api/requirement/update.php',
          { requirement_id: this.requirement.requirement_id,
            service_provider_id: this.$route.params.service_provider_id,
            [key]: this.requirement[key]})
        .then((response => {
          this.$refs[key].markAsSaved();
        }))
    }
  }
});
</script>