<template>
  <v-container>
    <router-view />
  </v-container>
</template>

<script>

import axios from 'axios';
import {privileges} from '@/mixins/privileges.js';

export default {
  name: 'AdministrationTop',
  mixins: [privileges],

  data: function() {return {
  }},

  components: {
  },

  methods: {
  },
  mounted () {
  },
}
</script>
