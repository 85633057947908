<template>
  <div>
    <h2>{{ $t('admin.users') }}</h2>
    <v-row class="mt-2">
      <v-col cols=2 class="mt-2">
        <v-text-field
          v-model="search"
          outlined
          dense
          :label="$t('label.search')"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="privilege_id"
          :items="
            (privilege_options || []).map((option) => ({
              text: $langobj.parse(option.privilege_lang),
              value: option.privilege_id,
            }))
          "
          :label="$tc('user.privilege')"
          clearable
        ></v-select>
      </v-col>
      <v-col cols=2 class="mt-2">
        <v-checkbox
          dense
          v-model="show_inactive_users"
          :label="$t('user.show_inactive')"
        ></v-checkbox>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-right">
        <v-btn @click="handleCreate" color="primary" outlined>
          <v-icon>mdi-plus</v-icon>
          {{ $t('label.add') }}
        </v-btn>
      </v-col>
      
    </v-row>
    <v-data-table
      :items-per-page="20"
      :footer-props="{itemsPerPageOptions:[20,50,100]}"
      dense
      :headers="headers"
      :items="filteredItems"
      item-key="user_id"
      :loading="isLoading"
      @click:row="handleRowClick"
      :search="search"
      :item-class="rowClass"
    >
      <template v-slot:[`item.privilege_lang`]="{ item }">
        {{ $langobj.parse(item.privilege_lang) }}
      </template>
      <template v-slot:[`item.state_lng`]="{ item }">
        <v-icon v-if="item.session_expiration_at && item.state==1" color="green darken-2">
          mdi-account-badge
        </v-icon>
        <v-icon v-else-if="item.state==1" color="grey">
          mdi-account-outline
        </v-icon>
        <v-icon v-else color="grey lighten-1">
          mdi-account-off-outline
        </v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click.stop="handleToggleState(item)">
          {{ item.state == 2 ? 'mdi-account' : 'mdi-account-off-outline' }}
        </v-icon>
      </template>
      <template v-slot:[`item.last_login`]="{ item }">
        {{ item.last_login ? moment.utc(item.last_login).local().fromNow() : '' }}
      </template>
    </v-data-table>
  </div>  
</template>

<style lang="scss">
  .active-user {
    background-color: teal;
  }
</style>

<script>
export default {
  name: "AdminUsers",
  data: function() {
    return {
      users: [],
      isLoading: true,
      search: "",
      show_inactive_users: false,
      state_options: [],
      privilege_id: null,
      privilege_options: [],
    }
  },

  computed: {
    headers() {
      return [
        { text: this.$tc('user.username'), value: 'username' },
        { text: this.$tc('user.email'), value: 'email' },
        { text: this.$tc('user.first_name'), value: 'first_name' },
        { text: this.$tc('user.last_name'), value: 'last_name' },
        { text: this.$tc('user.privilege'), value: 'privilege_lang' },
        { text: this.$tc('user.last_login'), value: 'last_login', searchable: false },
        { text: this.$tc('user.state'), value: 'state_lng' },
        { text: 'Actions', value: 'actions', sortable: false }
      ];
    },
    filteredItems() {
      return this.users
        .filter(user => this.privilege_id == null |
          user.privilege_id == this.privilege_id)
    },
  },

  methods: {
    rowClass(item) {
      return item.state == 2 ? "text--disabled" : 
        item.session_expiration_at ? "green lighten-5" : "";
    },
    getDataFromApi() {
      this.isLoading = true;
      this.$axios
        .get("/api/admin/user/list.php",
          {params:{show_inactive_users: this.show_inactive_users}})
        .then(response => {
          this.users = response.data;
          this.isLoading = false
        });
      this.$axios
        .get("/api/admin/user/filter_options.php")
        .then(response => {
          this.privilege_options = response.data.privilege_options;
          this.state_options = response.data.state_options;
        });
    },
    handleRowClick(row) {
      this.$router.push(
        { name: 'admin-user-details', params: { user_id: row.user_id }}
      );
    },
    handleCreate() {
      this.$router.push(
        { name: 'admin-user-details', params: { user_id: 'cr' }}
      );
    },
    handleToggleState(item) {
      const newState = (item.state == 2 ? 1 : 2);
      const newStateLang =
        this.state_options.find(item => (item.state_id === newState));
      this.$axios
        .put("/api/admin/user/update.php", {
          user_id: item.user_id,
          state: newState
        })
        .then((response) => {
          item.state=newState;
          item.state_lng = newStateLang.state_lang;
        });
    },
  },
  watch: {
    show_inactive_users: {
      handler () { this.getDataFromApi() }
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>