<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="za-label" style="margin-bottom: 10px;">
        En lien avec  
        <template v-if="!showParentsSelection">
          <a @click="showParentsSelection = true" class="za-label-action" style="cursor:pointer; margin-left: 20px; margin-right: 10px;">Modifier</a>
        </template>
      </div>
      <template v-if="parents && parents.total > 0 && showParentsSelection === false">
        <div class="app_row row" v-for="parent in parents.all" :key="parent.id">
          <div class=" col-sm-11" @click="$router.push(parent.url)">
            {{ parent.prefix }}: {{ parent.name }}
          </div>
          <div class="col-sm-1">
            <a v-on:click="confirmParentDelete(parent.type, parent.id)" style="cursor:pointer"><em class="fas fa-trash-alt fa-spacer" aria-hidden="true"></em></a>
          </div>
        </div>
      </template>
      <template v-if="showParentsSelection">
        <DocumentParentsSelection :parents="parents.all" v-on:add-parent="addParent" v-on:remove-parent="removeParent" v-on:cancel="showParentsSelection = false"></DocumentParentsSelection>
      </template>
      <div class="row" v-if="parents && parents.total === 0">
        <div class="col-sm-12">
          <small class="text-muted">Seulement associé au fournisseur en général, pas à un dossier, à un service, à un billet ou à une exigence spécifique.</small>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="za-label" style="padding-top: 10px; margin-bottom: -10px;">
        Modifier la référence
      </div>
      
      <DocumentEdit 
        :document="document"
        :only-update-parents="parentsUpdating"
        :show-cancel-button="true"
        cancel-button-label="Retour"
        v-on:save="save"
        v-on:done="back"
      />
    </div>
    
    <transition name="modal" >
      <div class="modal-mask" v-show="parentToDelete !== null">
          <div class="modal-block modal-block">
            <section class="panel">
              <header class="panel-heading">
                  <strong>Êtes-vous certain?</strong>
              </header>
              <div class="panel-body">
                <div class="modal-wrapper">
                  <div class="modal-text">
                    <p>Êtes-vous certain de vouloir supprimer le lien avec cet item?</p>

                    <div v-if="parentToDelete">
                      {{ parentToDelete.prefix }}: {{ parentToDelete.name }}
                    </div>
                  </div>
                </div>
              </div>
              <footer class="panel-footer">
                <div class="row">
                  <div class="col-md-12 text-right" v-if="parentToDelete">
                    <a class="btn btn-default" v-on:click="parentToDelete = null">Non</a>&nbsp;&nbsp;<a class="btn btn-default" v-on:click="removeParent(parentToDelete.drilldown[0], parentToDelete.id); parentToDelete = null">Oui</a>
                  </div>
                </div>
              </footer>
            </section>
          </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';
import DocumentEdit from '@/components/DocumentEdit.vue'
import DocumentParentsSelection from '@/components/DocumentParentsSelection.vue'
import { getDetailsOnDocumentParents } from '@/mixins/documents.js'

export default {
  data: function() {return {
    document: {},
    dossierDetails: {},
    serviceDetails: {},
    ticketDetails: {},
    parents: undefined,
    showParentsSelection: false,
    parentsUpdating: false,
    showConfirmDeleteParent: false,
    parentToDelete: null
  }},
  components: {
    DocumentEdit,
    DocumentParentsSelection
  },
  methods: {

    refresh: function(callback) {
      axios
        .get(this.$root.$data.server_base + '/api/document.php',
          { params: {
            document_id: this.$route.params.document_id
          }})
        .then(response => {
          this.document = response.data.document
          this.dossierDetails = response.data.dossier_details
          this.serviceDetails = response.data.service_details
          this.ticketDetails = response.data.ticket_details
          Vue.nextTick(() => {
            this.getDetailsOnDocumentParents()
          })
          
          if (typeof callback === 'function') {
            callback()
          }
        })
    },
    
    back: function() {
      this.$router.go(-1);
    },

    save: function(d, callback) {
      let data = {
        service_provider_id: this.$route.params.service_provider_id,
        designation_dossier_id_list: d.designation_dossier_id_list.join(','),
        service_id_list: d.service_id_list.join(','),
        ticket_id_list: d.ticket_id_list.join(','),
        requirement_num_list: d.requirement_num_list.join(',')
      }

      const fields = 'document_id url title description'.split(' ')

      fields.forEach(fieldName => {
        if (d[fieldName] !== undefined) {
          data[fieldName] = d[fieldName]
        }
      })

      axios
        .post(this.$root.$data.server_base + '/api/document_save.php',
          data,
          {headers:{
              'Content-Type': 'application/json',
          }})
        .then(() => {
          this.refresh(callback)
        })
    },
    
    addParent(type, id) {
      let d = this.document
      switch(type) {
        case 'designation_dossier': d.designation_dossier_id_list.push(id); break;
        case 'service': d.service_id_list.push(id); break;
        case 'ticket': d.ticket_id_list.push(id); break;
        case 'requirement': d.requirement_num_list.push(id); break;
      }
      
      this.parentsUpdating = true
      this.save(d, () => {
        Vue.nextTick(() => {
          this.parentsUpdating = false
        })
      })
    },
    
    removeParent(type, id) {
      let d = this.document
      switch(type) {
        case 'designation_dossier': arrayRemoveItem(d.designation_dossier_id_list, id); break;
        case 'service': arrayRemoveItem(d.service_id_list, id); break;
        case 'ticket': arrayRemoveItem(d.ticket_id_list, id); break;
        case 'requirement': arrayRemoveItem(d.requirement_num_list, id); break;
      }
      this.parentsUpdated = true
      this.save(d, () => {
        Vue.nextTick(() => {
          this.parentsUpdating = false
        })
      })
    },
    
    getDetailsOnDocumentParents() {
      this.parents = getDetailsOnDocumentParents(
        this.document,
        this.$route.params.service_provider_id,
        this.dossierDetails,
        this.serviceDetails,
        this.ticketDetails
      )
    },
    
    confirmParentDelete(type, id) {
      this.parentToDelete = this.parents.all.find(parent => parent.type === type && parent.id === id);
    },
  },
  mounted () {
    this.refresh();
  }
}

function arrayRemoveItem(array, item) {
  const index = array.findIndex(i => i === item)
  if (index === -1) { return array.slice() }
  return array.splice(index, 1)
}
</script>
