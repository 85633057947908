<template>
  <div v-if="parents">
    <template v-if="parents.total === 1">
      <a v-if="linkToItems" @click="$router.push(parents.first.url)" style="cursor:pointer">{{parents.first.prefix}}: {{ parents.first.name }}</a>
      <span v-else>{{parents.first.prefix}}: {{ parents.first.name }}</span>
    </template>
    <template v-else>
      <details>
        <summary>{{ parents.summary }} </summary>
        <div v-for="parent in parents.all" :key="parent.id" style="margin-top: 0.3em;">
          <a v-if="linkToItems" @click="$router.push(parent.url)" style="cursor:pointer">{{parent.prefix}}: {{ parent.name }}</a>
          <span v-else>{{parent.prefix}}: {{ parent.name }}</span>
        </div>
      </details>
    </template>
  </div>
</template>

<style scoped>
summary {
  display: list-item;
}
</style>

<script>
import { getDetailsOnDocumentParents } from '@/mixins/documents.js'

export default {
  props: ['document', 'dossierDetails', 'serviceDetails', 'ticketDetails', 'linkToItems'],
  data: function() {
    return {
      parents: undefined
    }
  },
  methods: {
    getDetailsOnDocumentParents() {
      this.parents = getDetailsOnDocumentParents(
        this.document,
        this.$route.params.service_provider_id,
        this.dossierDetails,
        this.serviceDetails,
        this.ticketDetails
      )
    }
  },
  watch: {
    document() {
      this.getDetailsOnDocumentParents()
    }
  },
  mounted() {
    this.getDetailsOnDocumentParents()
  }
}
</script>
