<template>
  <div>
    <report-row :label="$t('report.fls34.annexe-1')">
      <report-documents :url_prefix="urlPrefix" :documents="documentsFor('annexe-1')" />
    </report-row>
    <report-row :label="$t('report.fls34.annexe-2')">
      <report-documents :url_prefix="urlPrefix" :documents="documentsFor('annexe-2')" />
    </report-row>
    <report-row :label="$t('report.fls34.annexe-3')">
      <report-documents :url_prefix="urlPrefix" :documents="documentsFor('annexe-3')" />
    </report-row>
    <report-row :label="$t('report.fls34.annexe-4')">
      <report-documents :url_prefix="urlPrefix" :documents="documentsFor('annexe-4')" />
    </report-row>
    <report-row :label="$t('report.fls34.section-5')">
      <report-documents :url_prefix="urlPrefix" :documents="documentsFor('section-5')" />
    </report-row>
  </div>
</template>

<script>
import axios from 'axios';
import ReportRow from '@/components/report/reportRow.vue';
import ReportDocuments from '@/components/report/reportDocuments.vue';

export default {
  data() { return {
    sections: null,
    urlPrefix: ''
  }},

  components: {
    ReportRow,
    ReportDocuments
  },

  computed: {
  },

  methods: {
    documentsFor(section) {
      return this.sections ?
        (this.sections[section] ? this.sections[section].documents : null) : null;
    },
    refresh: function() {
      axios
        .get(this.$root.$data.server_base + '/api/report/get_report_community_support.php',
          { params: { 
              workplan_id: this.$route.params.workplan_id,
              service_provider_id: this.$route.params.service_provider_id
          }})

        .then(response => (
          this.sections = response.data.sections,
          this.urlPrefix = response.data.prefix
        ))
    },
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
</style>