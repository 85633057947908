<template>
  <div>
    <h2>{{ $t('admin.enum') }}</h2>
    <v-row class="justify-end">
      <v-col class="text-right">
        <v-btn @click="handleCreate" color="primary" outlined>
          <v-icon>mdi-plus</v-icon>
          {{ $t('label.add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items-per-page="25"
      :footer-props="{itemsPerPageOptions:[10,25,50,-1]}"
      :items="enums"
      item-key="enum_id"
      :loading="isLoading"
      @click:row="handleRowClick"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ $langobj.parse(item.name) }}
      </template>
      <template v-slot:[`item.aes`]="{ item }">
        <v-chip small v-if='item.aes && item.aes.colour' :color='item.aes.colour'>
          {{ item.aes.colour }}
        </v-chip>
      </template>
    </v-data-table>
  </div>  
</template>

<script>
export default {
  name: 'AdminEnumerations',
  data: function() {
    return {
      enums: [],
      isLoading: true,
    }
  },

  computed: {
    headers() {
      return [
        { text: this.$tc('enum.family_key'), value: 'family_key' },
        { text: this.$tc('enum.enum_key'), value: 'enum_key' },
        { text: this.$tc('enum.name'), value: 'name' },
        { text: this.$tc('enum.colour'), value: 'aes', sortable: false },
        { text: this.$tc('enum.sort'), value: 'sort' },
        { text: this.$tc('enum.status'), value: 'is_active' },
      ];
    }
  },

  methods: {
    getDataFromApi() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + "/api/admin/enum/list.php",
          {params:{}})
        .then(response => {
          this.enums = response.data.enums;
          this.isLoading = false
        })
    },
    handleRowClick(row) {
      this.$router.push(
        { name: 'admin-enumeration-details', params: { enum_id: row.enum_id }}
      );
    },
    handleCreate() {
      this.$router.push(
        {name: 'admin-enumeration-details', params: { enum_id: 'cr' }}
      );
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>