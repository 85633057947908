<template>
  <transition name="modal" >
    <div class="modal-mask" v-show="showModal">
      <div class="modal-block modal-block">
        <section class="panel">
          <header class="panel-heading">
              <strong>Êtes-vous certain?</strong>
          </header>
          <div class="panel-body">
            <div class="modal-wrapper">
              <div class="modal-text">
                <p>Êtes-vous certain de vouloir supprimer ce document?</p>
                
                <ul v-if="documentToDelete">
                  <li>{{ documentToDelete.title }}</li>
                </ul>
                
                <p style="margin-top: 10px"><small>Note: Seul le lien vers le document (et son titre et sa description) seront supprimés. Le document retrouvé à l'adresse URL restera intact.</small></p>
              </div>
            </div>
          </div>
          <footer class="panel-footer">
            <div class="row">
              <div class="col-md-12 text-right">
                <a class="btn btn-default" v-on:click="cancel">Non</a>&nbsp;&nbsp;<a class="btn btn-default" v-on:click="confirmDelete">Oui</a>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    props: ['document_id', 'documentToDelete', 'showModal'],
    data: function() { return {
      
    }},
    methods: {
      cancel() {
        this.$emit('cancel')
      },
      confirmDelete() {
        this.$emit('confirmDelete', this.document_id)
      }
    }
  }
</script>