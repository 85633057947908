<template>
  <v-select
    v-model="newValue"
    :items="priorities"
    :label="$t('ticket.priority')"
    item-text="label"
    item-value="value"
    v-on:input="emitChange"
  >
    <template v-slot:selection="{ item }">
      <v-icon class="mr-2" :color="item.color">{{ item.iconClass }}</v-icon>
      {{ $langobj.parse(item.label) }}
    </template>
    <template v-slot:item="{ item }">
      <v-icon class="mr-2" :color="item.color">{{ item.iconClass }}</v-icon>
      {{ $langobj.parse(item.label) }}
    </template>
  </v-select>  
</template>
<script>
import {priorities} from '@/mixins/priorities.js';

export default {
  props: ['value'],
  data: function() {return {
    priorities,
    newValue: null,
  }},
  watch: {
    value(newVal) {
      this.newValue = newVal
    }
  },
  methods: {
    emitChange() {
      this.$emit('input', this.newValue);
    }
  },
  mounted() {
    this.newValue = this.value
  }
}
</script>