// Privilege levels:
//  - superadmin: 
//  - admin
//  - agent: agent, without provider items (useful for view as provider)
//  - sp (user)

export const privileges = {
  computed: {
    privAllowRoleChange() {
      return this.$root.privilege ? this.$root.privilege.role_key != 'sp' : false;
    },
    privAllowSPChange() {
      return this.$root.privilege ? this.$root.privilege.multiServiceProvider : false;
    },
  },
  directives: {
    // for use as v-can="'admin'". The item is still rendered, but hidden
    can: {
      bind: function (el, binding, vnode) {
        vnode.context.readFromLocalStorage();
        
        if(!(vnode.context.$root.privilege[binding.value] === true )) {        
          vnode.elm.style.display = "none";
        }
      }
    }
  },
  methods: {
    // for use as v-if="priv('admin')". The item is not rendered.
    priv(value) {
      this.readFromLocalStorage();
      return this.$root.privilege ? this.$root.privilege[value] === true : false;
    },

    readFromLocalStorage() {
      if(this.$root.privilege == null) {
        const localSession = JSON.parse(localStorage.getItem('session'));
        if(localSession) {
          this.$root.privilege = {
            role_key: localSession.currentRole.role_key,
            multiServiceProvider: (
              localSession.currentRole.role_key 
              && localSession.multi_service_provider === true),
          };
          this.$root.serviceProvider = localSession.service_provider_id;
          this.evaluatePrivilegeFlags();
        }
      }
    },
    
    setViewAsProvider(flag) {
      this.$root.privilege.viewAsProvider = flag;
      this.evaluatePrivilegeFlags();
    },

    navigateToHomePage() {
      this.$router.push(this.homePage());
    },
    homePage() {
      let route = {name: 'tableaudebord'};
      if(this.$root.privilege && this.$root.privilege.role_key == 'sp') {
        route = 
          {
            name:'sp_tableaudebord',
            params: { service_provider_id: this.$root.serviceProvider}
          };
      }
      return route;
    },

    evaluatePrivilegeFlags() {
      this.$root.privilege =
        {...this.$root.privilege, superadmin: false, admin: false, agent: false};
      
      if(this.$root.privilege.viewAsProvider != true) {
        switch(this.$root.privilege.role_key) {
          case 'superadmin':
            this.$root.privilege.superadmin = true;
          case 'admin':
            this.$root.privilege.admin = true;
          case 'agent':
            this.$root.privilege.agent = true;
        }
      }
    },
    // a function that takes in an array, and returns a filtered array based on the
    // privilege attribute
    filterArrayForPrivilege(items) {
      this.readFromLocalStorage();
      if(this.$root.privilege == null)
        return [];
      return items.filter(item =>
        (item.privilege == null) ||
        (item.privilege == 'superadmin' && this.$root.privilege.superadmin) ||
        (item.privilege == 'admin' && this.$root.privilege.admin) ||
        (item.privilege == 'agent' && this.$root.privilege.agent)
      )
    }
  },
}
