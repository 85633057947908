<template>
  <div @drop="prevent" @drop.prevent @dragover="prevent" @dragover.prevent>
    <v-app app>
      <notifications group="std" position="bottom center" />
      <router-view name="fullScreen"></router-view>
      <router-view name="topBar"></router-view>
      <router-view name="sideNav" />
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
    <!-- <div v-else>
      <app-zone-a></app-zone-a>      
    </div> -->
  </div> 
</template>

<script>
// import AppZoneA from './AppZoneA.vue';

export default {
  name: 'App',

  components: {
    // AppZoneA,
  },

  data: () => ({
    //
  }),
  methods: {
    prevent(event) {
      event.preventDefault();
    }
  }
};
</script>
<style lang="scss">
@import 'assets/css/za.css';

.v-application {
  font-weight: 300;
  p {
    margin: 0px;
    padding: 0px;

    min-height: 1.25em; // empty <p> tags should signify a blank row
    line-height: 1.25;
    margin-bottom: 0.25em !important;
  }
}

html,
body {
  width: 100%;
  height: 100vh;
}
</style>

