<template>
  <div>
    <h2>{{ $t('admin.provider') }}</h2>
    <v-row class="mt-2">
      <v-col cols=2>
        <v-text-field
          v-model="search"
          outlined
          dense
          :label="$t('label.search')"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="region_id"
          :items="
            (region_options || []).map((option) => ({
              text: $langobj.parse(option.name),
              value: option.region_id,
            }))
          "
          :label="$tc('provider.region')"
          clearable
        ></v-select>
      </v-col>
      
      <v-spacer></v-spacer>
        
      <v-col cols=2 class="text-right">
        <v-btn @click="handleCreate" color="primary" outlined>
          <v-icon>mdi-plus</v-icon>
          {{ $t('label.add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :items-per-page="20"
      :footer-props="{itemsPerPageOptions:[20,50,100]}"
      dense
      :headers="headers"
      :items="filteredItems"
      item-key="user_id"
      :loading="isLoading"
      @click:row="handleRowClick"
      :search="search"
      :item-class="rowClass"
      :custom-filter="$langobj.dataTableFilter"
      sort-by="name"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ $langobj.parse(item.name) }}
      </template>
      <template v-slot:[`item.region_lang`]="{ item }">
        {{ $langobj.parse(item.region_lang) }}
      </template>
       <template v-slot:[`item.designation_status_lang`]="{ item }">
        {{ $langobj.parse(item.designation_status_lang) }}
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <v-icon small class="mr-2">
          {{ item.state == 1 ? 'mdi-account' : 'mdi-account-off-outline' }}
        </v-icon>
      </template>
    </v-data-table>
  </div>  
</template>

<script>
export default {
  name: "AdminProviders",
  data: function() {
    return {
      providers: [],
      isLoading: true,
      search: "",
      state_options: [],
      region_id: null,
      region_options: [],
    }
  },

  computed: {
    headers() {
      return [
        { text: this.$tc('provider.label'), value: 'name', sort: this.$langobj.sort },
        { text: this.$tc('provider.region'), value: 'region_lang', filterable: false,  sort: this.$langobj.sort },
        { text: this.$tc('provider.designation_status'), value: 'designation_status_lang', filterable: false, sort: this.$langobj.sort },
        // { text: this.$tc('user.first_name'), value: 'first_name' },
        // { text: this.$tc('user.last_name'), value: 'last_name' },
        // { text: this.$tc('user.privilege'), value: 'privilege_lang' },
        // { text: this.$tc('user.last_login'), value: 'last_login', filterable: false },
        // { text: this.$tc('provider.state'), value: 'state_lng' },
        { text: this.$tc('provider.state'), value: 'state', sortable: false, filterable: false }
      ];
    },
    filteredItems() {
      return this.providers
        .filter(item => this.region_id == null ||
          item.region_id == this.region_id)
    },
  },

  methods: {
    rowClass(item) {
      return item.state == 2 ? "text--disabled" : "";
    },
    getDataFromApi() {
      this.isLoading = true;
      this.$axios
        .get("/api/service_provider/list.php")
        .then(response => {
          this.providers = response.data.providers;
          this.isLoading = false
        });
      this.$axios
        .get("/api/service_provider/options.php")
        .then(response => {
          this.region_options = response.data.regions;
          this.state_options = response.data.state_options;
        });
    },
    handleRowClick(row) {
      this.$router.push(
        { name: 'admin-provider-details', params: { service_provider_id: row.service_provider_id }}
      );
    },
    handleCreate() {
      this.$router.push(
        {name: 'admin-provider-details', params: { service_provider_id: 'cr' }}
      );
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>