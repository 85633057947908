<template>
  <v-container fluid>
    <h2>{{ $tc("navigation.flsreport", 1) }} - {{ report.period }}</h2>
    <v-tabs
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tabs-slider color="#fbab26"></v-tabs-slider>
      <v-tab
        v-for="item in tabItems"
        :key="item.title"
        :to="item.to"
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <div class="report-tab mt-6">
      <v-slide-x-transition>
        <router-view :report="report" :requirements="requirements"></router-view>
      </v-slide-x-transition>
    </div>
  </v-container>
</template>

<script>
export default {
  data() { return {
    report: {regions_served: []},
    requirements: []
  }},

  components: {
  },

  computed: {
    tabItems() {
      return [
        {
          title: this.$tc('report.bdssf34.tab_1'),
          to: {name: 'bdssf_report_general'}
        },
        {
          title: this.$tc('report.bdssf34.tab_2'),
          to: {name: 'bdssf_report_designation'}
        },
        {
          title: this.$tc('report.bdssf34.tab_3'),
          to: {name: 'bdssf_report_community'}
        },
        {
          title: this.$tc('report.bdssf34.tab_4'),
          to: {name: 'bdssf_report_requirements'}
        },
        // {
        //   title: this.$tc('report.bdssf34.tab_5'),
        //   to: {name: 'bdssfreport_commsupport'}
        // },
        // {
        //   title: this.$tc('report.bdssf34.tab_6'),
        //   to: {name: 'bdssf_report_practices'}
        // },
        {
          title: this.$tc('report.bdssf34.tab_7'),
          to: {name: 'bdssf_report_hr'}
        },
      ]
    }
  },

  methods: {
    refresh: function() {
      this.$axios
        .get(this.$root.$data.server_base + '/api/bdssf_report/get.php',
          { params: { 
              report_id: this.$route.params.report_id,
              service_provider_id: this.$route.params.service_provider_id
          }})

        .then(response => (
          this.report = response.data.report
      ));
      this.$axios
        .get(this.$root.$data.server_base + '/api/bdssf_report/get_requirements.php',
          { params: {
              report_id: this.$route.params.report_id,
              service_provider_id: this.$route.params.service_provider_id,
          }})

        .then(response => (
          this.requirements = response.data.requirements
      ));
    },
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
.report-tab {
  font-size: 0.9em;
  line-height: 1.2;
}
</style>