<template>
  <v-navigation-drawer :clipped="true" style="font-size: 14px;" app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          Zone-A
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('navigation.subtitle') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav >
      <v-list-item
        v-for="item in items"
        :key="item.title"
        router :to="item.to"
        link
      >
        <template v-if="priv('admin')">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {privileges} from '@/mixins/privileges.js';

export default {
  name: 'TheSideBar',
  mixins: [privileges],

  computed: {
    items() { 
      return [
        {
          title: this.$tc('navigation.dashboard'),
          icon: 'mdi-view-dashboard', to: {name: 'tableaudebord'}
        },
        {
          title: this.$tc('navigation.ticket', 2),
          icon: 'mdi-stack-overflow', to: {name: 'liste_billets'}
        },
        {
          title: this.$tc('navigation.service_providers'),
          icon: 'mdi-hand-heart', to: {name:'liste_fournisseurs'}
        },
        {
          title: this.$tc('navigation.dossier', 2), 
          icon: 'mdi-file-cabinet', to: {name:'liste_dossiers'}
        },
        { 
          title: this.$tc('navigation.admin'), admin: true,
          icon: 'mdi-cogs', to: {name:'admin'}
        },
      ]
    },
  }
}
</script>
