<template>
  <div>
    <v-row>
      <v-col cols="3">
        <router-link class="text-decoration-none" :to="{name: 'bdssf_report_requirements'}">
          <span class="text-h6 text--primary">←&nbsp;</span>Retour à la liste
        </router-link>
      </v-col>
      <v-col cols="9">
        <v-select 
          v-model="selectedRequirement"
          :items="requirements"
          item-value="requirement_id"
          outlined
          dark
          background-color="primary"
          loading="isLoading"
          @input="handleNextRequirement"
        >
          <template v-slot:selection="{ item }">
            {{ item.identifier }} - {{ $langobj.parse(item.name) }}
          </template>
          <template v-slot:item="{ item }">
            {{ item.identifier }} - {{ $langobj.parse(item.name) }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    
    <report-row :label="$t('report.bdssf34.declared_status')">
      {{ $langobj.parse(requirement.status_lang) }}
    </report-row>

    <report-row :label="$t('report.bdssf34.start_date')">
      {{ requirement.start_date }}
    </report-row>

    <report-row :label="$t('report.bdssf34.anticipated_end_date')">
      {{ requirement.anticipated_end_date }}
    </report-row>
    
    <report-row :label="$t('report.bdssf34.person_responsable')">
      {{ requirement.responsible }}
    </report-row>

    <report-row :label="$tc('navigation.document', 2)">
      <ul>
        <li v-for="document in requirement.documents" :key="document">{{ document }}</li>
      </ul>
    </report-row>
    
    <h4 class="text-decoration-underline mt-4">{{ $t('report.bdssf34.activities') }}</h4>

    <report-row :label="$t('report.bdssf34.activities')">
      <div v-html="requirement.activities" />
    </report-row>

    <report-row :label="$t('report.bdssf34.additional_info')">
      <div v-html="requirement.additional_info" />
    </report-row>

  </div>
</template>

<script>
import ReportRow from '@/components/report/reportRow.vue';
import ReportDocuments from '@/components/report/reportDocuments.vue';

export default {
  name: "ReportRequirementDetail",
  data() { return {
    requirement: {},
    urlPrefix: '',
    selectedRequirement: 0,
    isLoading: false,
  }},

  props: {
    requirements: Array
  },

  components: {
    ReportRow,
    ReportDocuments
  },

  computed: {
  },

  watch: {
    $route() { this.refresh(); }
  },
  methods: {
    handleNextRequirement() {
      this.$router.push(
        { name: 'bdssf_report_requirement_detail', params:
          { requirement_id: this.selectedRequirement }
        }
      );
    },
    refresh() {
      this.selectedRequirement = Number(this.$route.params.requirement_id);
      this.isLoading = true;

      this.$axios
        .get(this.$root.$data.server_base + '/api/bdssf_report/get_requirement_detail.php',
          { params: {
              requirement_id: this.$route.params.requirement_id,
              report_id: this.$route.params.report_id,
              service_provider_id: this.$route.params.service_provider_id,
          }})

        .then(response => {
          this.requirement = response.data.requirement;
          if(this.requirement.documents == null)
            this.requirement.documents = [];
          this.isLoading=false;
        })
    },
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/collab.scss";
</style>