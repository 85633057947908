<template>
  <v-app-bar :clipped-left="true" app color="primary" dark>
    <changeOrganizationDialog
      v-model="changeOrgDialog"
    />
    <router-link :to="homePage()">
      <v-img
        alt="OZN"
        class="shrink mr-5"
        contain
        src='@/assets/img/ozi_logo_white.svg'
        :transition="false"
        :reverse-transition="false"
        width="120"
        height="37.8"
        style="cursor: pointer"
      />
    </router-link>
    <v-tabs v-if="priv('agent')" align-with-title slider-color="#fbab26">
      <v-tab
        v-for="item in menuItems"
        :key="item.title"
        router :to="item.to"
      >
        <v-icon class="mr-1">{{ item.icon }}</v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-spacer />
       
    <div class="mr-4">
      <locale-selector />
    </div>
    <help-dialog
      icon="mdi-help-circle"
      activatorClass="ml-0 mr-2"
      help-key="top.help"
      edit-priv="admin"
      :title="$t('help.support')"
    />
    <v-divider inset vertical />
    <v-menu offset-y :min-width="300" :max-width="300">
        <template v-slot:activator="{ on }">
          <v-btn class="ml-2" v-on="on" icon>
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            {{ session.firstName }} {{ session.lastName }}
          </v-card-title>
          <v-card-subtitle>
            {{ $langobj.parse(session.currentRole.role_name) }}
          </v-card-subtitle>
          <v-divider />
          <v-list dense>
            <v-list-item color="primary" @click="profile">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $tc('navigation.profile') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <template v-if="showRoleMenuItem && privAllowRoleChange">
              <v-list-item v-if="$root.privilege.viewAsProvider"
                color="primary"
                @click="setViewAsProvider(false)"
              >             
                <v-list-item-icon>
                  <v-icon>mdi-face-agent</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('role.return_to_role') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else
                color="primary"
                @click="setViewAsProvider(true)"
              >             
                <v-list-item-icon>
                  <v-icon>mdi-account-eye-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('role.view_as_provider') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-if="privAllowSPChange">
              <v-list-item color="primary" @click="changeOrgDialog = true">
                <v-list-item-icon>
                  <v-icon>mdi-hat-fedora</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('role.change_org') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item color="primary" @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $tc('navigation.logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

  </v-app-bar>
</template>

<script>

import Vue from 'vue';
import LocaleSelector from '@/components/locale/LocaleSelector.vue';
import ChangeOrganizationDialog from '@/components/session/ChangeOrganizationDialog.vue';
import {privileges} from '@/mixins/privileges.js';
import {session} from '@/mixins/session.js';

export default Vue.extend({
  name: 'AccompTopBar',
  components: {
    LocaleSelector,
    ChangeOrganizationDialog
  },
  mixins: [privileges, session],
  data() { return {
    changeOrgDialog: false
  }},
  computed: {
    menuItems() {
      return this.filterArrayForPrivilege([
        {
          title: this.$tc('navigation.dashboard'),
          icon: 'mdi-view-dashboard', to: {name: 'tableaudebord'}
        },
        {
          title: this.$tc('navigation.service_providers'),
          icon: 'mdi-hand-heart', to: {name:'liste_fournisseurs'}
        },
        {
          title: this.$tc('navigation.dossier', 2), 
          icon: 'mdi-folder-open', to: {name:'liste_dossiers'}
        },
        {
          title: this.$tc('navigation.ticket', 2),
          icon: 'mdi-stack-overflow', to: {name: 'liste_billets'}
        },
        { 
          title: this.$tc('navigation.admin'), admin: true,
          icon: 'mdi-cogs', to: {name:'admin'},
          privilege: 'admin'
        },
      ])
    },
    showRoleMenuItem() {
      if(this.$route.params.service_provider_id) {
        return true;
      }
      return false;
    }
  },
  methods: {
    profile() {
      this.$router.push({name:'perdu'});
    },
    role() {
      this.$router.push({name:'perdu'});
    },
    logout() {
      this.$router.push({name:'login'});
      this.clearSession();
      this.$axios.get(this.$root.$data.server_base + '/api/common/logout.php');
    }
  }
});
</script>

<style>
</style>
