<template>
  <v-dialog
    :value='value'
    @input="$emit('input', $event)"
    width="700"
    persistent
  >
    <v-card :loading="loading">
      <v-card-title class="text-h5 grey lighten-2 mb-3">
        {{ $tc('role.change_org', 1) }}
      </v-card-title>

      <v-card-text>
        <v-select
          v-model="service_provider_id"
          :label="$tc('provider.label', 1)"
          :items="
            (providerOptions || []).map((option) => ({
              text: $langobj.parse(option.name),
              value: option.service_provider_id
            }))
            .sort((a,b) => a.text.localeCompare(b.text))"
          @change="disableSave = false"
        >
        </v-select>
        
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" @click="handleChange" :disabled="disableSave">
          {{ $t('role.change_org') }}
        </v-btn>
        <v-btn color="primary" text @click="$emit('input', false)">
          {{ $t('label.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>     
</template>

<script>
import {session} from '@/mixins/session.js';
import {privileges} from '@/mixins/privileges.js';

export default {
  name: 'UserChangeSPDialog',
  mixins: [session, privileges],

  props: ['value'],
  data() { return {
    service_provider_id: null,
    loading: false,
    providerOptions: [],
    disableSave: false,
  }},
  watch: {
    value() {
      if(this.value==true) {
        // The dialog was just opened.
        this.getProvisionOptions();
        this.disableSave = true;
      }
    }
  },
  methods: {
    getProvisionOptions() {
      this.$axios
        .get("/api/session/get_service_providers.php")
        .then(response => {
          this.providerOptions = response.data.providers;
      })
    },

    handleChange() {
      const obj = {
        service_provider_id: Number(this.service_provider_id),
      }
      
      this.$axios
        .put("/api/session/change_service_provider.php", obj,
          { headers: {'Content-Type': 'application/json' }}
        )
        .then((response) => {
          this.$emit('input', false);
          let session = this.getSession();
          session.service_provider_id = Number(this.service_provider_id);
          console.log(session);
          this.storeSession(session);
          this.$root.privilege = null;
          this.readFromLocalStorage();
          this.navigateToHomePage();
          this.$forceUpdate();
        });
    }
  }
}
</script>