<template>
  <v-container fluid>
    <h2>{{ $tc('ticket.label', 2)}}</h2>
    <div class="text-right">
      <v-btn color="primary" :to="{name:'billet_creation'}">
        <v-icon>mdi-plus</v-icon>{{ $tc('ticket.create') }}
      </v-btn>
    </div>

    <v-row class="mb-2">
      <v-spacer></v-spacer>
      <v-col cols="2">
        <v-select
          v-show="showStatus"
          v-model="status"
          :items="
              (status_option || []).map((option) => ({
                text: $langobj.parse(option.name_lang),
                value: option.status_id,
              }))"
          :label="$tc('ticket.status')"
          clearable
          @change="newStart"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="type_key"
          :items="
              (ticket_type_option || []).map((option) => ({
                text: $langobj.parse(option.name),
                value: option.ticket_type_key,
              }))"
          :label="$tc('ticket.type')"
          clearable
          @change="loadStatus"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="service_provider_id"
          :items="
              (service_provider_option || []).map((option) => ({
                text: $langobj.parse(option.name),
                value: option.service_provider_id,
              }))
              .sort((a,b) => a.text.localeCompare(b.text))"
          :label="$tc('provider.label')"
          clearable
          @change="newStart"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="responsable"
          :items="responsable_option"
          item-text="responsable_name"
          item-value="user_id"
          :label="$tc('ticket.responsable')"
          clearable
          @change="newStart"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="keywords"
          append-icon="mdi-magnify"
          :label="$tc('label.search')"
          single-line
          hide-details
          @input="debounceSearch()"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :items-per-page="20"
      :footer-props="{itemsPerPageOptions:[20,50,100]}"
      dense
      :headers="headers"
      :options.sync="options"
      :items="localeAwareItems"
      :server-items-length="totalRecords"
      item-key="element_id"
      :loading="isLoading"
      @click:row="handleRowClick"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{ moment(item.created_at).format('LLL') }}
      </template>
      <template v-slot:[`item.responsable`]="{ item }">
        <user-avatar :user="item.responsable" class="mr-2"/>
        {{ item.responsable.full_name }}
      </template>
    </v-data-table>

  </v-container>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar.vue';

function scrollToTop(){
  window.setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, 200);
}

export default {
  data: function() {return {
    options: {},
    keywords:'',
    tickets: [],

    type_resolution: '',
    title:'',

    // filter
    ticket_type_option: [],
    responsable_option: [],
    // requirement_option: [],
    service_provider_option: [],
    status_option: [],
    type_key: null,
    service_provider_id: null,
    requirement: null,
    responsable: null,
    status:null,
    showStatus:false,

    totalRecords: 0,
    isLoading: false
  }},

  name: 'TicketList',
  components: {
    UserAvatar
  },

  computed: {
    localeAwareItems() {
      return this.tickets
        .map(item => {
          return {...item,
            service_provider: this.$langobj.parse(item.service_provider_lang),
            ticket_status_lang: this.$langobj.parse(item.ticket_status_lang),
            ticket_type_lang: this.$langobj.parse(item.ticket_type_lang),
          };
        })
    },
    headers() {
      return [
        { text: this.$t('ticket.created_at'), value: 'created_at' },
        { text: this.$tc('ticket.type'), value: 'ticket_type_lang' },
        { text: this.$tc('ticket.title'), value: 'title' },
        { text: this.$tc('provider.label'), value: 'service_provider' },
        { text: this.$tc('ticket.responsable'), value: 'responsable', sortable: false },
        { text: this.$tc('ticket.status'), value: 'ticket_status_lang' },
      ];
    }
  },
  methods: {
    handleRowClick(row) {
      this.$router.push(
        { name: 'billet', params: { ticket_id: row.element_id }}
      );
    },

    debounceSearch: function(val){
      if(this.search_timeout) clearTimeout(this.search_timeout);
      
      const self = this;
      this.search_timeout = setTimeout(function() {
        self.getDataFromApi();
      }, 250);
    },

    newStart: function () {
      this.getDataFromApi();
      this.loadFilters();
    },

    loadFilters: function() {
      this.$axios
        .get(this.$root.$data.server_base + '/api/ticket/ticket_filter_dropdown.php',
          { })
        .then(response => {
          this.ticket_type_option = response.data.ticket_type;
          this.responsable_option = response.data.responsable;
          this.service_provider_option = response.data.service_provider;
        })
    },

    loadStatus: function()
    {
      this.showStatus=false;
      if(this.type_key)
        this.$axios
          .get(this.$root.$data.server_base + '/api/ticket/ticket_dropdown_status.php',
            { params:{ticket_type_key:this.type_key}})
          .then(response => (
            this.status_option = response.data.ticket_status,
            this.showStatus = true,
            this.status=null
            ))
      this.newStart();
    },

    getDataFromApi () {
      this.isLoading = true;

      this.$axios
        .get(this.$root.$data.server_base + '/api/ticket/get_list.php',
          { params: {
            sort: this.options.sortBy[0],
            sortOrder: this.options.sortDesc[0] ? 0 : 1,
            type_key: this.type_key,
            ticket_resolution: this.ticket_resolution,
            status: this.status,
            service_provider_id: this.service_provider_id,
            requirement: this.requirement,
            responsable: this.responsable,
            keywords: this.keywords,
            page: this.options.page,
            page_size: this.options.itemsPerPage
          }})
        .then(response => {
          this.$checkApiErrors(response.data);
          this.tickets = response.data.tickets;
          this.totalRecords = response.data.total;
          this.isLoading = false
          scrollToTop()
        })
    }

  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted () {
    this.service_provider_id=this.$route.query.service_provider_id || '';
    this.type_key=this.$route.query.type_key || '';
    this.ticket_resolution=this.$route.query.ticket_resolution || '';
    this.responsable=this.$route.query.responsable || '';
    this.getDataFromApi();
    this.loadFilters();
  }
}
</script>
