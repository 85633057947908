<template>
  <v-avatar size="24" color="primary" class="mr-2">
    <span v-if="identifier.length > 2" 
      class="white--text" style="font-size: 0.5em; font-weight: bold;">
      {{identifier}}
    </span>
    <span v-else 
      class="white--text" style="font-size: 0.9em; font-weight: bold;">
      {{identifier}}
    </span>
  </v-avatar>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    identifier: String
  },
});
</script>
