<template>
  <div>
    <v-row>
      <v-col cols="3">
        <router-link class="text-decoration-none" :to="{name: 'flsreport_requirements'}">
          <span class="text-h6 text--primary">←&nbsp;</span>Retour à la liste
        </router-link>
      </v-col>
      <v-col cols="9">
        <v-select 
          v-model="selectedRequirement"
          :items="requirements"
          item-value="workplan_requirements_id"
          outlined
          dark
          background-color="primary"
          loading="isLoading"
          @input="handleNextRequirement"
        >
          <template v-slot:selection="{ item }">
            {{ item.num }} - {{ $langobj.parse(item.name) }}
          </template>
          <template v-slot:item="{ item }">
            {{ item.num }} - {{ $langobj.parse(item.name) }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    
    <report-row :label="$t('report.fls34.declared_status')">
      {{ $langobj.parse(requirement.status) }}
    </report-row>
    
    <report-row :label="$t('report.fls34.person_responsable')">
      {{ requirement.person_in_charge }}
    </report-row>

    <report-row :label="$tc('navigation.document', 2)">
      <report-documents :url_prefix="urlPrefix" :documents="requirement.documents" />
    </report-row>
    
    <h4 class="text-decoration-underline mt-4">{{ $t('report.fls34.activities') }}</h4>

    <report-row :label="$t('report.fls34.activities')">
      <div v-html="requirement.activities" />
    </report-row>

    <report-row :label="$t('report.fls34.additional_info')">
      <div v-html="requirement.additional_information" />
    </report-row>

    <h4 class="text-decoration-underline mt-4">{{ $t('report.fls34.realisations') }}</h4>

    <report-row :label="$t('report.fls34.deliverable_description')">
      <div v-html="requirement.deliverable_description" />
    </report-row>

    <report-row v-if="requirement.num == '4.1.3'" :label="$t('report.fls34.ca')">
      <div>
        {{requirement.ca_francophone}} sur {{requirement.ca_total}}
        <template v-if="requirement.ca_total">
          ({{Math.round(requirement.ca_francophone *100 / requirement.ca_total)}}%)
        </template>
      </div>
    </report-row>

    <report-row :label="$t('report.fls34.comments')">
      <div v-html="requirement.comments" />
    </report-row>

    <report-row :label="$t('report.fls34.other')">
      <div v-html="requirement.other" />
    </report-row>

    <h4 class="text-decoration-underline mt-4">Grille</h4>
  
    <report-row :label="$t('report.fls34.appreciation')">
      {{ $langobj.parse(requirement.appreciation_obj) }}
    </report-row>

    <report-row :label="$t('report.fls34.missing_info')">
      <div v-html="requirement.info_missing" />
    </report-row>

    <report-row :label="$t('report.fls34.recommendation')">
      <div v-html="requirement.recommandation_compliance" />
    </report-row>

    <report-row :label="$t('report.fls34.possibility_improvement')">
      <div v-html="requirement.possibility_improvement" />
    </report-row>

    <report-row :label="$t('report.fls34.other_comments')">
      <div v-html="requirement.other_comments" />
    </report-row>

  </div>
</template>

<script>
import ReportRow from '@/components/report/reportRow.vue';
import ReportDocuments from '@/components/report/reportDocuments.vue';

export default {
  name: "ReportRequirementDetail",
  data() { return {
    requirement: {},
    urlPrefix: '',
    selectedRequirement: 0,
    isLoading: false,
  }},

  props: {
    requirements: Array
  },

  components: {
    ReportRow,
    ReportDocuments
  },

  computed: {
  },

  watch: {
    $route() { this.refresh(); }
  },
  methods: {
    handleNextRequirement() {
      this.$router.push(
        { name: 'flsreport_requirement_detail', params:
          { requirement_id: this.selectedRequirement }
        }
      );
    },
    refresh() {
      this.selectedRequirement = Number(this.$route.params.requirement_id);
      this.isLoading = true;

      this.$axios
        .get(this.$root.$data.server_base + '/api/report/get_report_requirement_detail.php',
          { params: {
              requirement_id: this.$route.params.requirement_id,
              workplan_id: this.$route.params.workplan_id,
              service_provider_id: this.$route.params.service_provider_id,
          }})

        .then(response => {
          this.requirement = response.data.requirement;
          this.urlPrefix = response.data.prefix;
          if(this.requirement.documents == null)
            this.requirement.documents = [];
          this.isLoading=false;
        })
    },
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/collab.scss";
</style>