<template>
  <div>
    <v-skeleton-loader v-if="!documents" type="text"></v-skeleton-loader>
    <v-simple-table dense v-else-if="documents && documents.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('report.filename') }}
            </th>
            <th class="text-left">
              {{ $t('report.description') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in documents"
            :key="item.filename"
          >
            <td>
              <v-icon color="primary">{{ iconForFilename(item.filename) }}</v-icon>
              <a class="text-decoration-none"
                :href="urlForDocument(item)"
                target="_blank"
              >
                {{ item.filename }}
              </a>
            </td>
            <td>{{ item.title }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p v-else class="text--disabled">{{ $t('report.no_document') }}</p>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: "ReportDocuments",
  props: ['documents', 'url_prefix'],
  methods: {
    urlForDocument(document) {
      return this.url_prefix + document.workplan_id + '/' + document.filename;
    },
    iconForFilename(str) {     
      switch(str) {
        case (str.match(/\.pdf$/) || {}).input:
          return "mdi-file-pdf-box";
          
        case (str.match(/\.xls(s)?/) || {}).input:
          return "mdi-file-excel";
          
        case (str.match(/\.doc(x)?/) || {}).input:
          return "mdi-file-word";
       
        case (str.match(/\.ppt(x)?/) || {}).input:
          return "mdi-file-powerpoint";

        case (str.match(/\.jpg$/) || {}).input:
        case (str.match(/\.jpeg$/) || {}).input:
        case (str.match(/\.png$/) || {}).input:
        case (str.match(/\.gif$/) || {}).input:
        case (str.match(/\.svg$/) || {}).input:
          return "mdi-file-image";

        case (str.match(/\.txt$/) || {}).input:
          return "mdi-text-box";
      }
      return "mdi-file";
    }
  },
});
</script>