<template>
  <div>
    <h2>{{ $tc("report.hr.label") }}</h2>
    <v-card class="mt-3">
      <v-card-text>
        <div><strong>{{ $t('report.hr.summary.label') }}</strong></div>
        <v-row>
          <v-col cols="3">
            <p>{{ $t('report.hr.summary.total') }}:
              {{ summary.tot }} {{ $t('report.hr.summary.fte') }}
            </p>
            <p>{{ $t('report.hr.summary.filled_flc') }}:
              {{ summary.fil }} {{ $t('report.hr.summary.fte') }}
            </p>
          </v-col>
          <v-col cols="3">
            <p>{{ $t('report.hr.summary.req') }}: {{ summary.req }}
              {{ $t('report.hr.summary.fte') }}
            </p>
            <p>{{ $t('report.hr.summary.req_filled') }}: {{ summary.fil_actual }}
              {{ $t('report.hr.summary.fte') }}
            </p>
            <p>{{ $t('report.hr.summary.req_filled_over') }}: {{ summary.fil_over }}
              {{ $t('report.hr.summary.fte') }}
            </p>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="6">
                <p class="text-right">{{ $t('report.hr.summary.perc_req') }}:</p>
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  v-if="!isNaN(summary.req_perc)"
                  v-model="summary.req_perc"
                  color="blue-grey"
                  height="20"
                >
                  <template v-slot:default="{ value }">
                    <strong>{{ value }}%</strong>
                  </template>
                </v-progress-linear>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <p class="text-right">{{ $t('report.hr.summary.perc_filled') }}:</p>
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  v-if="!isNaN(summary.fil_actual_perc)"
                  v-model="summary.fil_actual_perc"
                  color="blue-grey"
                  height="20"
                >
                  <template v-slot:default="{ value }">
                    <strong>{{ value }}%</strong>
                  </template>
                </v-progress-linear>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row class="mt-2">
      <v-col cols=2>
        <v-text-field
          v-model="search"
          outlined
          dense
          :label="$t('label.search')"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="itemsFlattened"
      item-key="human_resource_id"
      :loading="isLoading"
      :search="search"
      :items-per-page="50"
      :footer-props="{itemsPerPageOptions:[25,50,100,-1]}"
      @current-items="newFilteredList"
    >
      <template v-slot:[`item.total`]="{ item }">
        <v-row>
          <v-col cols="4">
            {{ item.hr_count.tot_ft }}
          </v-col>
          <v-col cols="4">
            {{ item.hr_count.tot_pt }}
          </v-col>
          <v-col cols="4">
            {{ item.hr_count.tot_cas }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.rqrd`]="{ item }">
        <v-row>
          <v-col cols="4">
            {{ item.hr_count.req_ft }}
          </v-col>
          <v-col cols="4">
            {{ item.hr_count.req_pt }}
          </v-col>
          <v-col cols="4">
            {{ item.hr_count.req_cas }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.actual`]="{ item }">
        <v-row>
          <v-col cols="4">
            {{ item.hr_count.fill_ft }}
          </v-col>
          <v-col cols="4">
            {{ item.hr_count.fill_pt }}
          </v-col>
          <v-col cols="4">
            {{ item.hr_count.fill_cas }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.comp`]="{ item }">
        {{item.linguistic_profile.lp_oral || '?'}}
        <span class="grey--text">|</span>
        {{item.linguistic_profile.lp_written || '?'}}
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: "ReportHR",
  data() { return {
    hr: [],
    filteredHr: [],
    isLoading: false,
    search: ""
  }},

  components: {
  },

  computed: {
    headers() {
      return [
        { text: this.$t('report.hr.department'), value: 'department' },
        { text: this.$t('report.hr.program'), value: 'service' },
        { text: this.$t('report.hr.position'), value: 'position' },
        { text: this.$t('report.hr.total'), value: 'total',
          filterable: false, sortable: false, align: 'center' },
        { text: this.$t('report.hr.rqrd'), value: 'rqrd',
          filterable: false, sortable: false, align: 'center' },
        { text: this.$t('report.hr.actual'), value: 'actual',
          filterable: false, sortable: false, align: 'center' },
        { text: this.$t('report.hr.comp'), value: 'comp',
          filterable: false, sortable: false, align: 'center' },
      ];
    },
    itemsFlattened() {
      return this.hr.map(item => (
        { ...item,
          point_service_one_lng: this.$langobj.parse(item.point_service_lng),
          functional_center_one_lng: this.$langobj.parse(item.functional_center_lng),
        }
      ))
    },
    summary() {
      const values = this.filteredHr.map(item => ({
        tot: item.hr_count.tot_ft + item.hr_count.tot_pt*0.5 + item.hr_count.tot_cas*0.2,
        req: item.hr_count.req_ft + item.hr_count.req_pt*0.5 + item.hr_count.req_cas*0.2,
        fil: item.hr_count.fill_ft + item.hr_count.fill_pt*0.5 + item.hr_count.fill_cas*0.2,
        actual_fil: Math.min(item.hr_count.req_ft, item.hr_count.fill_ft)
      })).reduce((a, b) => (
        {
          tot: a.tot + b.tot,
          req: a.req + b.req,
          fil: a.fil + b.fil,
          fil_actual: a.fil_actual + Math.min(b.req, b.fil),
          fil_over: a.fil_over + Math.max(0, b.fil-b.req)
        }),
        {tot: 0, req: 0, fil: 0, fil_actual: 0, fil_over: 0}
      );
      values.tot = Math.round(values.tot*10)/10;
      values.req = Math.round(values.req*10)/10;
      values.fil = Math.round(values.fil*10)/10;
      values.fil_actual = Math.round(values.fil_actual*10)/10;
      values.fil_over = Math.round(values.fil_over*10)/10;
      values.fil_actual_perc = Math.round(values.fil_actual*1000/values.req)/10;
      values.req_perc = Math.round(values.req*1000/values.tot)/10;
      return values;
    },
  },

  methods: {
    refresh: function() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/bdssf_report/get_report_hr.php',
          { params: { 
              service_provider_id: this.$route.params.service_provider_id,
              report_id: this.$route.params.report_id,
        }})
        .then(response => {
          this.hr = response.data.hr;
          this.isLoading = false;
        });
    },
    newFilteredList(items) {
      console.log("New");
      this.filteredHr = items;
    }
  },
  mounted () {
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
</style>