<template>
  <v-hover v-slot="{ hover }">
    <v-card class="my-3" outlined>
      <v-app-bar dense flat>
        <v-toolbar-title>
          {{ $langobj.parse(value.contact_type_lang) }}
        </v-toolbar-title>
       
        <v-spacer></v-spacer>
        <v-btn icon :disabled="!hover" @click="$emit('edit', value.contact_id)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon :disabled="!hover" @click="$emit('delete', value.contact_id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-app-bar>
      
      <v-card-title class="pt-2">
        {{ value.first_name + " " + value.last_name }}
      </v-card-title>
      <v-card-subtitle class="pt-2">
        {{ value.position }}
        
      </v-card-subtitle>
      <v-card-text>
        <p v-if="value.telephone">
          <v-icon>mdi-phone</v-icon>
          {{ value.telephone }}
        </p>
        <p v-if="value.mobile_phone">
          <v-icon>mdi-cellphone</v-icon>
          {{ value.mobile_phone }}
        </p>
        <p v-if="value.email">
          <v-icon>mdi-email</v-icon>
          {{ value.email }}
        </p>
        <v-chip v-if="value.is_active == 0" class="mt-2" color="orange" label outlined>
          {{ $t('label.inactive') }}
        </v-chip>
      </v-card-text>
    </v-card>
  </v-hover>
</template>
    
<script>

export default {
  props: ['value'],
  
  methods: {
  }
}
</script>