<template>
  <v-app-bar :clipped-left="true" app color="primary" dark>
    <router-link :to="{ name: 'home' }">
      <v-img
        alt="OZN"
        class="shrink mr-5"
        contain
        src='@/assets/img/ozi_logo_white.svg'
        :transition="false"
        :reverse-transition="false"
        width="120"
        height="37.8"
        style="cursor: pointer"
      />
    </router-link>
    <v-spacer />

    <div class="mr-4">
      <locale-selector />
    </div>
  </v-app-bar>
</template>


<script>
import LocaleSelector from '@/components/locale/LocaleSelector.vue';

export default {
  components: {
    'locale-selector': LocaleSelector,
  },
  data: function() {return {
    showbox: false,

  }},
  methods: {
    logout() {
      localStorage.removeItem('jwt');
      this.$router.push({name:'login'});
      this.$axios.get(this.$root.$data.server_base + '/api/common/logout.php');
    },
    openbox: function() {
      this.showbox=!this.showbox;

    }
  }
}
</script>

<style>

.logoutbox
{
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;

  -webkit-background-clip: padding-box;

  background-clip: padding-box;

  border: 1px solid #ccc;

  border: 1px solid rgba(0, 0, 0, .15);

  border-radius: 4px;
  font-size: 13px;

  text-align: center;
  padding:20px 20px

}

</style>
