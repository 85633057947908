<template>
  <div>
    <router-link class="text-decoration-none" :to="{name: 'admin-design-model-details'}">
      <span class="text-h6 text--primary">←&nbsp;</span>
      {{ $t('label.return_to_list') }}
    </router-link>
    <h2>{{ isCreate ? $t('designation_model.element.create') : $t('designation_model.element.modify') }}</h2>
    <v-row class="mt-2">
      <v-col cols=3>
        <div>
          <v-card-text>
            <v-avatar size="44" :style="avatarStyle"
              color="primary" class="exigence-no">
              <span class="white--text">{{internal.identifier}}</span>
            </v-avatar>          
            <div class="mt-2 grey--text">{{ $langobj.parse(internal.name) }}</div>
            <small v-if="internal.modified_at" class="grey--text font-italic">
              {{ $t('label.modified') }}
              {{ moment.utc(internal.modified_at).local().fromNow() }}
            </small>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <div>
              <v-btn class="px-1" color="primary" text @click="handleDelete">
                <v-icon class="mr-1">mdi-delete</v-icon> {{ $t('label.delete') }}
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-col>

      <v-col cols=9>
        <v-card>
          <v-form v-model="isFormValid" ref="form">
            <v-card-text>
              <v-text-field
                v-model="internal.sort"
                :label="$t('designation_model.element.sort')"
                :rules="[(v) => $validators.number(v, [$t('designation_model.element.sort')])]"
              ></v-text-field>
              <v-select
                v-model="internal.requirement_def_id"
                :items="requirementOptions"
                :label="$t('designation_model.element.requirement')"
                clearable
              ></v-select>
              <v-select
                v-model="internal.obligation"
                :items="obligationItems"
                :label="$t('designation_model.element.obligation.label')"
                :rules="[(v) => $validators.required(v, [$t('designation_model.element.obligation.label')])]"
              ></v-select>
              <v-select
                v-model="internal.type"
                :items="typeItems"
                :label="$t('designation_model.element.type.label')"
                :rules="[(v) => $validators.required(v, [$t('designation_model.element.type.label')])]"
              ></v-select>
              <v-row
                v-for="locale in $locales"
                :key="locale.value"
                class="mt-2"
              >
                <v-col cols=1>
                  <p class="text-h4 primary--text">{{ locale.toLocaleUpperCase() }}</p>
                </v-col>
                <v-col cols=11>
                  <v-textarea
                    spellcheck="false"
                    v-model="internal.text[locale]"
                    :label="$langobj.label($t('designation_model.element.text'), locale)"
                    :rules="[(v) => $validators.required(v, [$t('designation_model.element.text')])]"
                    :loading="isLoading"
                    rows="3"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn color="primary" @click="handleSave" :disabled="disableSave">
              {{ $t('label.save') }}
            </v-btn>
            <v-btn color="primary" text @click="handleCancel">
              {{ $t('label.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>  
</template>

<script>
import _ from 'lodash';
import ObjectUtils from '@/utils/ObjectUtils';

export default {
  name: 'ElementDefDetails',
  data: function() {
    return {
      storeData: null,
      internal: {
        element_def_id: null,
        text: {},
        requirement_def_id: null
      },
      isLoading: false,
      isFormValid: true,
      requirementItems: []
    }
  },

  computed: {
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    },
    isCreate() {
      return this.$route.params.element_def_id == 'cr';
    },
    avatarStyle() {
      return String(this.internal.identifier).length < 3 
        ? 'font-size: x-large; font-weight: bold;' : 'font-weight: bold;'
    },
    test() {
      return String(this.internal.identifier).length;
    },
    obligationItems() {
      return [
        { text: this.$t('designation_model.element.obligation.required'), value: 'required' },
        { text: this.$t('designation_model.element.obligation.best_practice'), value: 'best_practice' },
      ];
    },
    typeItems() {
      return [
        { text: this.$t('designation_model.element.type.commitment'), value: 'commitment' },
        { text: this.$t('designation_model.element.type.policy'), value: 'policy' },
        { text: this.$t('designation_model.element.type.demonstration'), value: 'demonstration' },
      ];
    },
    requirementOptions() {
      return (this.requirementItems || [])
        .filter(item => item.item_type == 'requirement')
        .map((item) => ({
          text: item.identifier + ' - ' + this.$langobj.parse(item.name),
          value: item.requirement_def_id
        }));
    }
  },

  methods: {
    getDataFromApi() {
      this.$axios
        .get("/api/admin/requirement_def/list.php",
          {params:{designation_model_id: this.$route.params.designation_model_id}})
        .then(response => {
          this.requirementItems = response.data.requirements;
      });
      if(this.$route.params.element_def_id == 'cr')
        return;
      this.isLoading = true;
      this.$axios
        .get("/api/admin/element_def/get.php",
          {params:{element_def_id: this.$route.params.element_def_id}})
        .then(response => {
          this.storeData = response.data.element;
          this.internal = _.cloneDeep(this.storeData);
          this.isLoading = false;
      });
    },

    handleDelete() {
      this.$axios
        .delete('/api/admin/element_def/remove.php', {
          params: {element_def_id: this.$route.params.element_def_id}})
        .then(response => {
          if(!response.data.server_error)
            this.$router.push({name: 'admin-design-model-details'});
        });
    },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }        
      let obj = {};
      let api = "";
      if(this.isCreate) {
        api = "/api/admin/element_def/create.php";
        obj = this.internal;
        obj.designation_model_id = this.$route.params.designation_model_id;
      }
      else {
        api = "/api/admin/element_def/update.php";
        obj = ObjectUtils.getObjectDiff(this.internal, this.storeData);  
        obj.element_def_id = this.internal.element_def_id;
      }

      this.$axios
        .put(api, obj, { headers: {'Content-Type': 'application/json' }})
        .then((response) => {
          this.isLoading = false;

          this.$router.push({name: 'admin-design-model-details'});            
        });
    },
    handleCancel() {
      this.getDataFromApi();
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>