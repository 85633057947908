<template>
  <div>
    <transition name="modal" >
      <div class="modal-mask" v-show="showModal" ref="mask" @mousedown="handleClickOnMask" @mouseup="handleClickOnMask" style="overflow-y: scroll; display: block; cursor: pointer">
        <div class="modal-block modal-block-large" style="cursor: default" @click="$event.stopPropagation()">
          <section class="panel">
            <header class="panel-heading">
              <button type="button" class="close" @click="done" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <strong>Ajouter un document ou associer des documents existants</strong>
            </header>
            <div class="panel-body">
              <div class="modal-wrapper" style="padding-top: 0;">
                <div class="modal-text">
                  <div style="margin-bottom: -10px;">
                    <strong>Ajouter un document</strong>
                  </div>
                  
                  <DocumentEdit
                   :is-new="true" 
                   :show-cancel-button="false"
                   v-on:save="addDocumentAndAttach"
                   v-on:url-updated="checkIfUrlIsAlreadyAdded"
                   v-on:done="done">
                   <template #url-help-text>
                     <span v-if="urlAlreadyAdded" class="help-block">
                       L'adresse URL a <a href="#" @click="debounceSearch(urlAlreadyAdded)">déjà été utilisé dans un autre document</a>.
                     </span>
                   </template>
                  </DocumentEdit>
                  
                  <hr />
                  
                  <div class="row" style="margin-bottom: 1em;">
                    <div class="col-sm-6" style="padding-top: 5px;">
                      <strong>Ou associer des documents existants:</strong>
                    </div>
                    <div class="col-sm-6">
                      <label style="margin-right:10px; width: 100%;">
                        <input style="width: 100%;" v-bind:value="keywords" v-on:input="debounceSearch($event.target.value)" class="form-control" placeholder="Recherche" aria-controls="listing-datatable">
                      </label>
                    </div>
                  </div>
                  
                  <div class="table-responsive">
                    <div class="table-responsive">
                      <GridGeneral
                      :data="recent_documents"
                      :columns="gridColumns"
                      :sortColumns="gridColumnsSort"
                      :loadData="refresh">
                        <template #action="slotProps">
                          <button v-if="isAlreadyAttached(slotProps.entry)" @click="detachDocument(slotProps.entry)" class="btn btn-sm btn-primary">Dissocier</button>
                          <button v-else @click="attachDocument(slotProps.entry)" class="btn btn-sm btn-default">Associer</button>
                        </template>
                        <template #document="slotProps">
                          <div class="document-with-icon">
                            <a :href="slotProps.entry.document_url" target="_blank"><DocumentIcon :url="slotProps.entry.document_url" class="document-icon" />{{ slotProps.entry.title }}</a>
                          </div>
                        </template>
                        <template #parent="slotProps">
                          <template v-if="isAttachedServiceProviderOnly(slotProps.entry)">
                            <small class="text-muted">Non spécifié</small>
                          </template>
                          <template v-else>
                            <DocumentParentsSummary 
                              :linkToItems="false"
                              :document="slotProps.entry"
                              :dossierDetails="dossierDetails"
                              :serviceDetails="serviceDetails"
                              :ticketDetails="ticketDetails"
                            />
                          </template>
                        </template>
                        <template #created_at="slotProps">
                          <small class="text-muted text-nowrap">{{ moment(slotProps.entry.created_at).fromNow() }}</small>
                        </template>
                      </GridGeneral>
                      <div v-if="doneFirstLoad && recent_documents.length === 0" style="padding: 40px 0; text-align: center;">
                        <small class="text-muted">
                          Aucun document n'a encore été ajouté à des items du fournisseur actuel.
                        </small>
                      </div>
                      <div v-if="totalRecords > recent_documents.length && doneFirstLoad" style="margin-top: 1em;">
                        <button class="btn btn-default btn-sm" @click="fetchAdditionalPageOfDocuments" :disabled="isLoading">
                          En afficher davantage&hellip;
                          <i class="loading-icon fas fa-circle-notch fa-spin" v-show="isLoading"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">

.document-with-icon {
  position: relative;
  padding-left: 20px;
  
  .document-icon {
    position: absolute;
    left: 0;
    top: 3px;
  }
}

.modal-block-large {
  max-width: 1000px !important;
}
</style>

<script>
import axios from 'axios'
import GridGeneral from '@/components/GridGeneral.vue'
import DocumentEdit from '@/components/DocumentEdit.vue'
import DocumentIcon from '@/components/DocumentIcon.vue'
import DocumentParentsSummary from '@/components/DocumentParentsSummary.vue'

export default {
  props: ['showModal', 'parentObjectType', 'parentObjectId', 'serviceProviderId'],
  components: {
    GridGeneral,
    DocumentEdit,
    DocumentIcon,
    DocumentParentsSummary
  },
  data: function() { return {
    recent_documents: [],
    doneFirstLoad: false,
    dossierDetails: {},
    serviceDetails: {},
    ticketDetails: {},
    keywords: '',
    sort:'created_at',
    sortOrder:0,
    gridColumns: [
      {field:'action',title:'Sélection'},
      {field:'document',title:'Document'},
      {field:'description',title:'Description'},
      {field:'parent',title:'En lien avec'},
      {field:'created_at',title:'Créé'},
    ],
    gridColumnsSort : ['document', 'created_at'],
    pageNum: 0,
    pageSize: 50,
    totalRecords: 0,
    isLoading: false,
    urlAlreadyAdded: null,
    hasReceivedMousedownOnMask: false
  }},
  methods: {
    handleClickOnMask(event) {
      if (event.target !== this.$refs.mask) { return }
      // fix a problem with quitting full-screen in the rich-text field contained in the modal triggering a mouseup event inadvertently
      if (event.type === 'mouseup' && this.hasReceivedMousedownOnMask) {
        this.done()
        this.hasReceivedMousedownOnMask = false
        return
      }
      if (event.type === 'mousedown' && !this.hasReceivedMousedownOnMask) {
        this.hasReceivedMousedownOnMask = true
      }
    },
    done() {
      this.$emit('done')
    },
    debounceSearch(val){
      this.keywords = val;
      if(this.search_timeout) clearTimeout(this.search_timeout);
      
      const self = this;
      this.search_timeout = setTimeout(function() {
        self.refresh();
      }, 200);
    },
    refresh() {
      this.pageNum = 1
      this.fetchPageOfDocuments(this.pageNum, (response) => {
        if (!this.doneFirstLoad) { this.doneFirstLoad = true }
        this.recent_documents = response.data.documents
        this.dossierDetails = response.data.dossier_details
        this.serviceDetails = response.data.service_details
        this.ticketDetails = response.data.ticket_details
        this.pageNum = response.data.page_num
        this.totalRecords = response.data.total
      })
    },
    fetchPageOfDocuments(pageNum, callback) {
      this.isLoading = true
      this.fetchDocumentsWithParams({
        service_provider_id: this.serviceProviderId,
        keywords: this.keywords,
        sort: this.sort,
        sortOrder: this.sortOrder,
        page: pageNum,
        page_size: this.pageSize
      }, (response) => {
        this.isLoading = false
        callback(response)
      })
    },
    fetchDocumentsWithParams(params, callback) {
      axios
      .get(this.$root.$data.server_base + '/api/service_provider_documents.php',
        { params })
      .then(response => {
        callback(response)
      })
    },
    fetchAdditionalPageOfDocuments() {
      this.pageNum = this.pageNum + 1
      this.fetchPageOfDocuments(this.pageNum, (response) => {
        this.recent_documents = this.recent_documents.concat(response.data.documents)
        this.dossierDetails = {...this.dossierDetails, ...response.data.dossier_details}
        this.serviceDetails = {...this.serviceDetails, ...response.data.service_details}
        this.ticketDetails = {...this.ticketDetails, ...response.data.ticket_details}
        this.pageNum = response.data.page_num
        this.totalRecords = response.data.total
      })
    },
    isAttachedServiceProviderOnly(document) {
      return !(document.designation_dossier_id_list.length > 0 || document.service_id_list.length > 0 || document.ticket_id_list.length > 0 || document.requirement_num_list.length > 0)
    },
    isAlreadyAttached(document) {
      const parentObjectId = Number(this.parentObjectId)
      switch(this.parentObjectType) {
        case 'designation_dossier': return document.designation_dossier_id_list.includes(parentObjectId);
        case 'service': return document.service_id_list.includes(parentObjectId);
        case 'requirement': return document.requirement_num_list.includes(this.parentObjectId);
        case 'ticket': return document.ticket_id_list.includes(parentObjectId);
      }
      return false;
    },
    checkIfUrlIsAlreadyAdded(url) {
      this.urlAlreadyAdded = null
      this.fetchDocumentsWithParams({
        service_provider_id: this.serviceProviderId,
        keywords: url,
        sort: this.sort,
        sortOrder: this.sortOrder,
        page: 1,
        page_size: 1
      }, (response) => {
        if (response.data.total && response.data.total > 0) {
          this.urlAlreadyAdded = url
        } else {
          this.urlAlreadyAdded = null
        }
      })
    },
    addDocumentAndAttach(d) {
      this.saveDocument(d, () => {
        this.showModal = false
      })
    },
    saveDocument(d, callback) {
      let data = {
        service_provider_id: this.serviceProviderId
      }
      
      switch(this.parentObjectType) {
        case 'designation_dossier': data['designation_dossier_id_list'] = `${this.parentObjectId}`; break;
        case 'service': data['service_id_list'] = `${this.parentObjectId}`; break;
        case 'requirement': data['requirement_num_list'] = `${this.parentObjectId}`; break;
        case 'ticket': data['ticket_id_list'] = `${this.parentObjectId}`; break;
      }

      const fields = 'document_id url title description'.split(' ')

      fields.forEach(fieldName => {
        if (d[fieldName] !== undefined) {
          data[fieldName] = d[fieldName]
        }
      })

      axios
        .post(this.$root.$data.server_base + '/api/document_save.php',
          data,
          {headers:{
              'Content-Type': 'application/json',
          }})
        .then(() => {
          this.$emit('attached')
          if (callback !== undefined && typeof callback === 'function') {
            callback()
          }
        })
    },
    attachDocument(d) {
      this.attachDetachDocument(d.document_id, 'attach');
    },
    detachDocument(d) {
      this.attachDetachDocument(d.document_id, 'detach');
    },
    attachDetachDocument(document_id, action) {
      let data = {
        service_provider_id: this.serviceProviderId,
        parent_object_type: this.parentObjectType,
        parent_object_id: this.parentObjectId,
        action: action,
        document_id: document_id
      }
      
      axios
        .post(this.$root.$data.server_base + '/api/document_attach_detach.php',
          data,
          {headers:{
              'Content-Type': 'application/json',
          }})
        .then(() => {
          this.refresh()
          this.$emit('attached')
        })
    }
  },
  watch: {
    showModal(newVal) {
      if (newVal == true) {
        this.refresh()
      }
    }
  },
  mounted() {
    if (this.showModal) {
      this.refresh()
    }
  }
}
</script>