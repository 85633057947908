<template>
  <div>  
    <h3 class="mt-3">{{$langobj.parse(requirement.help_text_1.title)}}</h3>
    <div v-html="$langobj.parse(requirement.help_text_1.text)"></div>

    <h3 class="mt-3">{{$langobj.parse(requirement.help_text_2.title)}}</h3>
    <div v-html="$langobj.parse(requirement.help_text_2.text)"></div>

    <h3 class="mt-3">{{$langobj.parse(requirement.help_text_3.title)}}</h3>
    <div v-html="$langobj.parse(requirement.help_text_3.text)"></div>

  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'TabDefinition',
  props: ['requirement'],
  data () {
    return {
    }
  }
});
</script>