<template>
  <div>
    <router-link class="text-decoration-none" :to="{name: 'admin-enumerations'}">
      <span class="text-h6 text--primary">←&nbsp;</span>
      {{ $t('label.return_to_list') }}
    </router-link>
    <h2>{{ isCreate ? $t('enum.create') : $t('enum.modify') }}</h2>
    <v-row class="mt-2">
      <v-col cols=3>
        <div>
          <v-card-text>         
            <div class="mt-2 grey--text">{{ $langobj.parse(internal.name) }}</div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text v-if="!isCreate">
            <div>
              <v-btn class="px-1" color="primary" text @click="handleDelete">
                <v-icon class="mr-1">mdi-delete</v-icon> {{ $t('label.delete') }}
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-col>

      <v-col cols=9>
        <v-card>
          <v-form v-model="isFormValid" ref="form">
            <v-card-text>
              <v-text-field
                v-model="internal.family_key"
                :label="$t('enum.family_key')"
                :rules="[(v) => $validators.required(v, [$t('enum.family_key')])]"
              ></v-text-field>
              <v-text-field
                v-model="internal.enum_key"
                :label="$t('enum.enum_key')"
                :rules="[(v) => $validators.required(v, [$t('enum.enum_key')])]"
              ></v-text-field>
              <v-text-field
                v-model="internal.sort"
                :label="$t('enum.sort')"
                :rules="[(v) => $validators.number(v, [$t('enum.sort')])]"
              ></v-text-field>
              <v-text-field
                v-model="internal.aes.colour"
                :label="$t('enum.colour')"
              >
                <template v-slot:append>
                  <v-icon v-if="internal.aes.colour" :color="internal.aes.colour">
                    mdi-circle
                  </v-icon>
                </template>
              </v-text-field>
              <v-row
                v-for="locale in $locales"
                :key="locale.value"
                class="mt-2"
              >
                <v-col cols=1>
                  <p class="text-h4 primary--text">{{ locale.toLocaleUpperCase() }}</p>
                </v-col>
                <v-col cols=11>
                  <v-text-field
                    spellcheck="false"
                    v-model="internal.name[locale]"
                    :label="$langobj.label($t('enum.name'), locale)"
                    :rules="[(v) => $validators.required(v, [$t('enum.name')])]"
                    :loading="isLoading"
                  ></v-text-field>
                  <v-text-field
                    spellcheck="false"
                    v-model="internal.description[locale]"
                    :label="$langobj.label($t('enum.description'), locale)"
                    :loading="isLoading"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn color="primary" @click="handleSave" :disabled="disableSave">
              {{ $t('label.save') }}
            </v-btn>
            <v-btn color="primary" text @click="handleCancel">
              {{ $t('label.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>  
</template>

<script>
import _ from 'lodash';
import ObjectUtils from '@/utils/ObjectUtils';

export default {
  name: 'AdminEnumDetails',
  data: function() {
    return {
      storeData: null,
      internal: {enum_id: null, name: {}, description: {}, aes: {}},
      isLoading: false,
      isFormValid: true,
    }
  },

  computed: {
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    },
    isCreate() {
      return this.$route.params.enum_id == 'cr';
    },
  },

  methods: {
    getDataFromApi() {
      if(this.$route.params.enum_id == 'cr')
        return;
      this.isLoading = true;
      this.$axios
        .get("/api/admin/enum/get.php",
          {params:{enum_id: this.$route.params.enum_id}})
        .then(response => {
          this.storeData = response.data.enum;
          if(this.storeData.description == null) this.storeData.description = {};
          if(this.storeData.aes == null) this.storeData.aes = {};
          this.internal = _.cloneDeep(this.storeData);
          this.isLoading = false;
      });
    },
    handleDelete() {
      this.$axios
        .delete('/api/admin/enum/remove.php', {
          params: {enum_id: this.$route.params.enum_id}})
        .then(response => {
          if(!response.data.server_error)
            this.$router.push({name: 'admin-enumerations'});
        });
    },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }        
      let obj = {};
      let api = "";
      if(this.isCreate) {
        api = "/api/admin/enum/create.php";
        obj = this.internal;
      }
      else {
        api = "/api/admin/enum/update.php";
        obj = ObjectUtils.getObjectDiff(this.internal, this.storeData);  
        obj.enum_id = this.internal.enum_id;
      }

      this.$axios
        .put(api, obj, { headers: {'Content-Type': 'application/json' }})
        .then((response) => {          
          this.$router.push({name: 'admin-enumerations'});
        });
    },
    handleCancel() {
      if(this.isCreate || this.disableSave) {
        this.$router.push({name: 'admin-enumerations'});
      } else {
        this.getDataFromApi();
      }
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>