<template>
  <div>
    <div class="text-h6 mt-3 mb-1">{{ $t('requirement.status') }}</div>
    <div v-if="readonly || !editMode" @click="handleEdit">
      <div v-if="hasContent" :class="readonly ? '' : 'edit-box'">
        <v-icon left :color="currentSelection.aes.colour">mdi-circle-medium</v-icon>
        {{ $langobj.parse(currentSelection.name) }}
      </div>
      <div v-else :class="readonly ? '' : 'edit-box'">
        <em class="grey--text">{{ $t('error.no_content') }}</em>
      </div>
    </div>
    <div v-show="editMode" class="mb-3">
      <v-form v-model="isFormValid" ref="form">
        <v-select
          ref="field"
          v-model="internal"
          :items="statusOptions"
          item-value="enum_key"
          :rules="rules"
        >
          <template v-slot:item='{ item }'>
            <v-icon left :color="item.aes.colour">mdi-circle-medium</v-icon>
            {{ $langobj.parse(item.name) }}
          </template>
          <template v-slot:selection='{ item }'>
            <v-icon :color="item.aes.colour">mdi-circle-medium</v-icon>
            {{ $langobj.parse(item.name) }}
          </template>
        </v-select>
        <v-btn 
          color="primary"
          class="mr-2"
          :loading="isSaving"
          @click="handleSave"
          small
        >
          {{ $t('label.save') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          small
          @click="handleCancel"
        >
          {{ $t('label.cancel') }}
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusSelector',
  props: {
    value: String,
    label: String,
    readonly: Boolean,
    rules: Array,
  },
  data() {return {
    editMode: false,
    backup: null,
    isSaving: false,
    isFormValid: true,
    statusOptions: []
  }},
  computed: {
    hasContent() {
      return !!this.currentSelection;
    },
    internal: {
      get() { return this.value; },
      set(val) { this.$emit('input', val);}
    },
    currentSelection() {
      return this.statusOptions.find((item) => item.enum_key == this.value );
    }
  },
  methods: {
    handleEdit() {
      if(this.readonly)
        return;
      this.backup = this.value;
      this.editMode = true;
      this.$nextTick(() => this.$refs.field.focus());
    },
    handleCancel() {
      this.internal = this.backup;
      this.editMode = false;
    },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return;
      }
      this.isSaving = true;
      this.$emit('save', this.value);
    
    },
    markAsSaved() {
      this.isSaving = this.editMode = false;
    },
    getStatusOptions() {
      this.$axios
        .get(this.$root.$data.server_base + '/api/admin/enum/list.php',
          {params: {family_key: 'requirement_status'}})
        .then((response) => {
          this.statusOptions = response.data.enums;
        });
    },
  },
  mounted () {
    this.getStatusOptions();
  }
}
</script>

<style lang="scss" scoped>
.edit-box {
  margin: -8px;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
}
</style>