<template>
  <td>
    <slot></slot>
  </td>
</template>

<script>
  export default {
    name: 'GridCellNonClickable',
    props: ['entry']
  }
</script>