<template>
  <div>
    <report-row :label="$tc('report.fls34.regions', regions.length)">
      <ul>
        <li v-for="region in regions" :key="region">{{ region }}</li>
      </ul>
    </report-row>

    <report-row :label="$t('report.fls34.population_served')">
      <report-populations :populations="report.populations"></report-populations>
    </report-row>

    <report-row :label="$t('report.fls34.clientele_description')">
      {{ $langobj.parse(report.clientele_description) }}
    </report-row>
    
    <report-row :label="$t('report.fls34.population')">
      <report-clientele :report="report"></report-clientele>
    </report-row>
  </div>
</template>

<script>
import Vue from 'vue';
import ReportPopulations from '@/components/report/reportPopulations.vue';
import ReportClientele from '@/components/report/reportClientele.vue';
import ReportRow from '@/components/report/reportRow.vue';

export default Vue.extend({
  props: ['report'],
 
  components: {
    ReportPopulations,
    ReportClientele,
    ReportRow,
  },

  computed: {
    regions() {
      const regionList = [];
      if(this.report) { 
        if(this.report.city_reg1) regionList.push(this.report.city_reg1);
        if(this.report.city_reg2) regionList.push(this.report.city_reg2);
        if(this.report.city_reg3) regionList.push(this.report.city_reg3);
      }
      return regionList;
    }
  },
});
</script>

<style scoped lang="scss">
</style>