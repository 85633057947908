<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-card class="d-flex pa-1" flat>
        <user-avatar :user="appreciation.created_by_user" class="mr-2 mb-1" />
        <div>
          <strong>{{createdBy}}</strong>
          
          <span class="created_at"> - {{ createdAtStr }}</span>&nbsp;&nbsp;
          <span v-if="appreciation.aes.colour" class="mr-2">
            <v-chip dark small class="mb-1" :color="appreciation.aes.colour">
              {{ $langobj.parse(appreciation.status_lang) }}
            </v-chip>  
          </span>
          <span v-else class="mr-2">{{ $langobj.parse(appreciation.status_lang) }}</span>
          <span v-if="!readonly">
            <v-btn v-if="appreciation.is_published" small icon color="#faab26">
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
            <v-btn v-show="hover" small icon color="primary" @click="handleEdit">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn 
              v-show="hover"
              v-if="appreciation.is_published" 
              small icon color="primary"
              @click="handleUnpublish"
            >
              <v-icon small>mdi-publish-off</v-icon>
            </v-btn>
            <v-btn 
              v-show="hover"
              v-else
              small icon color="primary"
              @click="handleMarkAsPublished"
            >
              <v-icon small>mdi-publish</v-icon>
            </v-btn>
          </span>
          <div class="mb-1">
            <div v-html="appreciation.description"></div>
          </div>
        </div>
      </v-card>
    </v-hover>
  </div>
</template>

<style lang="scss" scoped>
.created_at {
  color: #999;
  font-size: 85%;
}
</style>

<script>
import _ from 'lodash';
import UserAvatar from '@/components/user/UserAvatar.vue';

export default {
  name: 'Appreciation',
  props: {
    appreciation: Object,
    readonly: Boolean
  },
  
  components: {
    UserAvatar
  },

  data: function() {return {
  }},

  computed: {
    createdBy() {
      return this.appreciation.created_by_user.first_name+
        ' '+this.appreciation.created_by_user.last_name;
    },
    createdAtStr() {
      return this.moment.utc(this.appreciation.created_at).local().format("LLL") + ' (' +
        this.moment.utc(this.appreciation.created_at).fromNow() +')';
    }
  },
  methods:
  {
    handleEdit() {
      this.$router.push({name: 'billet',
        params: {ticket_id: this.appreciation.ticket_id}});
    },
    handleMarkAsPublished() {
      this.$axios
        .put(this.$root.$data.server_base + '/api/requirement/update.php',
          { requirement_id: Number(this.$route.params.requirement_id),
            service_provider_id: Number(this.$route.params.service_provider_id),
            published_appreciation_ticket_id: this.appreciation.ticket_id})
        .then((response => {
          this.$emit('refresh');
        }))
    },
    handleUnpublish() {
      this.$axios
        .put(this.$root.$data.server_base + '/api/requirement/update.php',
          { requirement_id: Number(this.$route.params.requirement_id),
            service_provider_id: Number(this.$route.params.service_provider_id),
            published_appreciation_ticket_id: null})
        .then((response => {
          this.$emit('refresh');
        }))
    },
  },
  mounted () {
    this.messages = this.$root.$data.messages;
  },
  

}
</script>
