<template>
  <div>
    <router-link class="text-decoration-none" :to="{name: 'admin-help-dialogs'}">
      <span class="text-h6 text--primary">←&nbsp;</span>
      {{ $t('label.return_to_list') }}
    </router-link>
    <h2>{{ isCreate ? $t('help.create') : $t('help.modify') }}</h2>
    <v-row class="mt-2">
      <v-col cols=2>
        <div>
          <v-card-text>         
            <div class="mt-2 grey--text">{{ internal.key }}</div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
        </div>
      </v-col>

      <v-col cols=10>
        <v-card>
          <v-form v-model="isFormValid" ref="form">
            <v-card-text>
              <v-text-field
                v-model="internal.key"
                :label="$t('help.key')"
                :rules="[(v) => $validators.required(v, [$t('help.key')])]"
              ></v-text-field>
              <v-row
                v-for="locale in $locales"
                :key="locale.value"
                class="mt-2"
              >
                <v-col cols=1>
                  <p class="text-h4 primary--text">{{ locale.toLocaleUpperCase() }}</p>
                </v-col>
                <v-col cols=11>
                  <tip-tap
                    extended
                    spellcheck="false"
                    v-model="internal.text[locale]"
                    :label="$langobj.label($t('help.text'), locale)"
                    :loading="isLoading"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn color="primary" @click="handleSave" :disabled="disableSave">
              {{ $t('label.save') }}
            </v-btn>
            <v-btn color="primary" text @click="handleCancel">
              {{ $t('label.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>  
</template>

<script>
import _ from 'lodash';
import ObjectUtils from '@/utils/ObjectUtils';

export default {
  name: 'AdminHelpDialogDetails',
  data: function() {
    return {
      storeData: null,
      internal: {key: null, text: {}},
      isLoading: false,
      isFormValid: true,
    }
  },

  computed: {
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    },
    isCreate() {
      return this.$route.params.key == 'cr';
    },
  },

  methods: {
    getDataFromApi() {
      if(this.$route.params.key == 'cr')
        return;
      this.isLoading = true;
      this.$axios
        .get("/api/admin/help_dialog/get.php",
          {params:{key: this.$route.params.key}})
        .then(response => {
          this.storeData = response.data.help_dialog;
          if(this.storeData.text == null) this.storeData.text = {};
          this.internal = _.cloneDeep(this.storeData);
          this.isLoading = false;
      });
    },
    // handleDelete() {
    //   this.$axios
    //     .delete('/api/admin/help_dialog/remove.php', {
    //       params: {enum_id: this.$route.params.enum_id}})
    //     .then(response => {
    //       if(!response.data.server_error)
    //         this.$router.push({name: 'admin-help-dialogs'});
    //     });
    // },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return
      }        
      
     this.$axios
        .put("/api/admin/help_dialog/upsert.php", this.internal, { headers: {'Content-Type': 'application/json' }})
        .then((response) => {          
          this.$router.push({name: 'admin-help-dialogs'});
        });
    },
    handleCancel() {
      if(this.isCreate || this.disableSave) {
        this.$router.push({name: 'admin-help-dialogs'});
      } else {
        this.getDataFromApi();
      }
    },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>