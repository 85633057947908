<template>
  <div>
    <report-row :label="$t('report.fls34.description')">
      <div v-html="description" />
    </report-row>

    <report-row :label="$t('report.fls34.person_responsable')">
      <report-contact :contact="report.contact_responsable"></report-contact>
    </report-row>

    <report-row :label="$t('report.fls34.person_resource')">
      <report-contact :contact="report.contact_resource"></report-contact>
    </report-row>

    <report-row :label="$t('report.fls34.sectors_declared')">
      <report-sectors :sectors="report.sectors_self_declared"></report-sectors>
    </report-row>

    <report-row :label="$t('report.fls34.sectors_lhin')">
      <report-sectors :sectors="report.sectors_lhin_declared"></report-sectors>
    </report-row>
  </div>
</template>

<script>
import ReportContact from '@/components/report/reportContact.vue';
import ReportSectors from '@/components/report/reportSectors.vue';
import ReportRow from '@/components/report/reportRow.vue';

export default {
  props: ['report'],

  components: {
    ReportContact,
    ReportSectors,
    ReportRow,
  },

  computed: {
    description() {
      if(this.report && this.report.organisation_description)
        return this.report.organisation_description.replace(/\n/g, '<br />');
      return '';
    }
  },
}
</script>

<style scoped lang="scss">
</style>