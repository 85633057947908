<template>
  <v-card
    flat
    height="100%"
    :color="$route.meta.greyBg ? 'grey lighten-4' : ''"
    class="rounded-0"
  >
    <v-card class="rounded-0 mt-3 px-4">
      <v-row class="pt-1">
        <v-col cols="10">
          <v-tooltip bottom open-delay="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" 
                class="mr-4" icon exact :to="{name: 'dossiers'}"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.return_to_list') }}</span>
          </v-tooltip>

          <span class="text-h6">
            <v-icon>mdi-folder-open</v-icon>
            {{ $langobj.parse(dossier.name_lang) }}
          </span>
          
        </v-col>
        <v-col cols="2" align="right">
          <saving-indicator ref="saving" />
          <dossier-conversion
            v-model="importDialog"
            :dossier="dossier" 
            :requirements="requirements" @refresh="get"
          />
          <delete-dialog v-model="deleteDialog"
            :title="$t('label.delete')"
            v-on:delete-object="handleDelete"
          />
          <delete-dialog v-model="permanentDeleteDialog"
            :title="$t('label.delete')"
            v-on:delete-object="handlePermanentDelete"
          />
          
          <v-icon class="mr-3" v-if="dossier.status == 'archived'">mdi-archive-lock</v-icon>
          
          <v-menu offset-y v-if="showOptionsMenu">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-if="dossier.status == 'active'" @click="importDialog=true">
                <v-list-item-icon class="mr-2"><v-icon>mdi-import</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('dossier.import') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="dossier.status == 'archived'" @click="changeState('active')">
                <v-list-item-icon class="mr-2"><v-icon>mdi-lock-open-variant-outline</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('dossier.reactivate') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="dossier.status == 'active'" @click="changeState('archived')">
                <v-list-item-icon class="mr-2"><v-icon>mdi-archive-lock</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('dossier.archive') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="deleteDialog=true">
                <v-list-item-icon class="mr-2"><v-icon>mdi-trash-can</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('label.delete') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-can="'superadmin'" @click="permanentDeleteDialog=true">
                <v-list-item-icon class="mr-2"><v-icon>mdi-delete-outline</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('label.permanent_delete') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
    <v-slide-x-transition>
      <router-view 
        :dossier="dossier" 
        :requirements="requirements"
        :readonly="dossier.status!='active'"
        v-on:refresh='get'
        @saving="(what) => $refs.saving.event(what)"/>
    </v-slide-x-transition>
  </v-card>
</template>

<script>
import Vue from 'vue';
import ListeExigences from '@/components/dossiers/ListeExigences';
import DossierConversion from '@/components/dossiers/DossierConversion.vue';
import DeleteDialog from '@/components/framework/DeleteFromListDialog.vue';
import {privileges} from '@/mixins/privileges.js';

export default Vue.extend({
  name: 'AccDossierTop',
  mixins: [privileges],
  components: {
    ListeExigences,
    DossierConversion,
    DeleteDialog
  },
  data () {
    return {
      deleteDialog: false,
      permanentDeleteDialog: false,
      importDialog: false,
      isLoading: false,
      dossier: {name_lang: {}, dossier_status_aes: {}},
      requirements: [],
    }
  },
  computed: {
    showOptionsMenu() {
      return this.priv('agent') && this.$route.meta.showOptionsMenu;
    },
    greyBg() {
      return this.$route.meta.greyBg;
    },
  },
  methods: {
    handleDelete() {
      this.$axios
        .put(this.$root.$data.server_base + '/api/dossier/update.php', {
          designation_dossier_id: this.$route.params.designation_dossier_id,
          status: 'deleted'
        })
        .then(() => { this.$router.push({name: 'dossiers'}) })
        .catch((error) => this.$refs.saving.event('error'));
    },
    handlePermanentDelete() {
      this.$axios
        .put(this.$root.$data.server_base + '/api/dossier/remove.php', {
          designation_dossier_id: Number(this.$route.params.designation_dossier_id),
          service_provider_id: Number(this.$route.params.service_provider_id),
        })
        .then(() => { this.$router.push({name: 'dossiers'}) })
        .catch((error) => this.$refs.saving.event('error'));
    },
    changeState(state) {
      this.$axios
        .put(this.$root.$data.server_base + '/api/dossier/update.php', {
          designation_dossier_id: this.$route.params.designation_dossier_id,
          status: state
        })
        .then(() => { this.$router.push({name: 'dossiers'}) })
        .catch((error) => this.$refs.saving.event('error'));
    },
    get() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/dossier/get.php',
          { params: { designation_dossier_id: this.$route.params.designation_dossier_id }})
        .then(response => {
          this.dossier = response.data.dossier;
          this.isLoading = false;
          if(this.dossier.status == 'deleted') {
            this.$notify({type: 'error', group: 'std',
              title: this.$t('error.invalid_page'),
              text: this.$t('error.gone'),
              duration: 4000
            });
            this.$router.push({name: 'dossiers'});
          }
        });

      this.$axios
        .get(this.$root.$data.server_base + '/api/requirement/list.php',
          { params: {
            designation_dossier_id: this.$route.params.designation_dossier_id,
            service_provider_id: this.$route.params.service_provider_id,
          }})
        .then(response => {
          this.requirements = response.data.requirements;
          this.isLoading = false
        });
    }
  },
  mounted () {
    this.get();
  }
});

</script>