<template>
  <div>
    <div v-if="false" class="row">
      <div class="col-sm-12">
        <div class="col-sm-12" style="margin:0;padding:5px">
            <router-link :to="{name:'service_creation'}" class="btn btn-primary"><i class="fa fa-plus"></i> Créer un service</router-link>
        </div>
        <div class="col-sm-12" style="margin:0;padding:5px">
          <div class="table-responsive">
            <GridGeneral
            :data="services"
            :columns="gridColumns"
            :sortColumns="gridColumnsSort"
            :loadData="refresh">
            </GridGeneral>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      Fonction incomplète
    </div>
  </div>
</template>

<script>
import GridGeneral from '@/components/GridGeneral.vue'

import axios from 'axios';

export default {
  data: function() {return {
    service:'',
    serviceprovider:{},
    service_id:'',
    services:{},
    sort:'',
    sortOrder:1,
    gridColumns: [{field:'name',title:'Service'},{field:'demande',title:'Demande'},{field:'status',title:'Statut'}],
    gridColumnsSort : ['name','status'],
  }},


  components: {
    GridGeneral,
  },
  methods: {

    refresh: function() {
      axios
        .get(this.$root.$data.server_base + '/api/service_provider_services.php',
          { params: {sort:this.sort,sortOrder:this.sortOrder,service_provider_id: this.$route.params.service_provider_id}})
        .then(response => (this.serviceprovider = response.data.serviceprovider,this.services = response.data.services))
    },
    back: function()
    {
      this.$router.go(-1);
    },


  },
  mounted () {
    this.refresh()
    this.service_provider_id=this.$route.params.service_provider_id;
  }
}
</script>
