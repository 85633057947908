<template>
  <v-app-bar :clipped-left="true" app color="primary" dark>
    <router-link :to="{ name: 'home' }">
      <v-img
        alt="OZN"
        class="shrink mr-5"
        contain
        src='@/assets/img/ozi_logo_white.svg'
        :transition="false"
        :reverse-transition="false"
        width="120"
        height="37.8"
        style="cursor: pointer"
      />
    </router-link>
    <v-spacer />
    <div class="mr-4">
      <locale-selector />
    </div>
    <v-divider inset vertical />
    <v-menu offset-y :min-width="300" :max-width="300">
        <template v-slot:activator="{ on }">
          <v-btn class="ml-2" v-on="on" icon>
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            {{ session.firstName }} {{ session.lastName }}
          </v-card-title>
          <v-card-subtitle>
            {{ $langobj.parse(session.currentRole.role_name) }}
          </v-card-subtitle>
          <v-divider />
          <v-list dense>
            <v-list-item color="primary" @click="profile">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $tc('navigation.profile') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item color="primary" @click="role">
              <v-list-item-icon>
                <v-icon>mdi-account-tie-hat</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $tc('navigation.role') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item color="primary" @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $tc('navigation.logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

  </v-app-bar>
</template>

<script>

import Vue from 'vue';
import LocaleSelector from '@/components/locale/LocaleSelector.vue';
import {session} from '@/mixins/session.js';

export default Vue.extend({
  name: 'TopBar',
  mixins: [session],
  components: {
    'locale-selector': LocaleSelector,
  },
  data: function() {return {
    showbox: false,

  }},
  methods: {
    profile() {
      this.$router.push({name:'perdu'});
    },
    role() {
      this.$router.push({name:'perdu'});
    },
    logout() {
      localStorage.removeItem('jwt');
      this.$router.push({name:'login'});
      this.$axios.get(this.$root.$data.server_base + '/api/common/logout.php');
    }
  }
});
</script>

<style>
</style>
