<template>
  <v-container fluid>
    <edit-history-dialog
      v-model="editDialog"
      :id="ticket_id"
      @refresh="refresh"
    >
    </edit-history-dialog>
    <delete-dialog v-model="deleteDialog"
      :title="$t('dossier.history.delete')"
      :id="deleteTicketId"
      v-on:delete-object="deleteHistory"
    >
    </delete-dialog>
    <v-row class="mt-2">
      <v-col cols="12" sm="4">
        <toggable-date-picker
          ref="start_date"
          v-model="dossier.start_date"
          native-label
          :label="$t('dossier.start_date')"
          @save="handleSaveDossierParam('start_date', ...arguments)"
          :readonly="readonly || !priv('agent')"
        ></toggable-date-picker>
      </v-col>
      <v-col cols="12" sm="4">
        <toggable-date-picker
          ref="due_date"
          v-model="dossier.due_date"
          native-label
          :label="$t('dossier.due_date')"
          @save="handleSaveDossierParam('due_date', ...arguments)"
          :readonly="readonly || !priv('agent')"
        ></toggable-date-picker>
      </v-col>
    </v-row>

    <v-row v-if="priv('agent')" class="justify-end">
      <v-col class="text-right">
        <v-btn v-if="!readonly" @click="handleAddHistory" color="primary" outlined>
          <v-icon>mdi-plus</v-icon>
          {{ $t('label.add') }}
        </v-btn>
      </v-col>
    </v-row>
    
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 20px"></th>
            <th class="text-left">{{ $t('dossier.history.event')}}</th>
            <th class="text-left">{{ $t('dossier.history.description')}}</th>
            <th class="text-left">{{ $t('dossier.history.due_date')}}</th>
            <th class="text-left">{{ $t('dossier.history.effective_at')}}</th>
            <th v-if="!readonly && priv('agent')" class="text-center" style="width: 120px; min-width: 120px">{{ $t('label.actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredHistoryTickets" :key="item.ticket_id" >
            <td>
              <v-icon color="primary" :disabled="false">
                {{ item.effective_at ? 'mdi-check-circle' : 'mdi-circle-outline' }}
              </v-icon>
            </td>
            <td>{{ $langobj.parse(item.name_lang) }}</td>
            <td v-html="item.description"></td>
            <td>
              <template v-if="item.options && item.options.due_date_fake">
                <em class="grey--text">{{ item.due_date }} ({{ $t('dossier.history.tentative')}})</em>
              </template>
              <template v-else>
                {{ item.due_date }}
              </template>
            </td>
            <td>{{ item.effective_at }}</td>
            <td v-if="!readonly && priv('agent')" class="text-center">
              <v-icon small class="mr-1" color="primary" v-if="item.audience=='all'" @click="handleToggleVisible(item)">mdi-eye-outline</v-icon>
              <v-icon small class="mr-1" color="grey" v-else @click="handleToggleVisible(item)">mdi-eye-off-outline</v-icon>
              <v-icon small class="mr-1" @click="handleEditHistory(item)">mdi-pencil</v-icon>
              <v-icon small @click="handleDeleteEvent(item.ticket_id)">mdi-delete</v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container> 
</template>

<script>
import EditHistoryDialog from '@/components/dossiers/EditHistoryDialog.vue';
import DeleteDialog from '@/components/framework/DeleteFromListDialog.vue';
import {privileges} from '@/mixins/privileges.js';

export default {
  name: 'DossierHistory',
  components: {
    EditHistoryDialog,
    DeleteDialog
  },
  mixins: [privileges],
  props: ['readonly'],
  data: function() {return {
    editDialog: false,
    deleteDialog: false,
    deleteTicketId: null,
    ticket_id: 0,
    dossier: {},
    historyTickets: [],
  }},

  computed: {
    filteredHistoryTickets() {
      if(this.priv('agent'))
        return this.historyTickets;
      return this.historyTickets.filter( history => (
        history.audience == 'all'
      ));
    }
  },

  methods: {
    refresh() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/dossier/get_history.php',
          { params: { designation_dossier_id: this.$route.params.designation_dossier_id }})
        .then(response => {
          this.dossier = response.data.dossier;
          this.historyTickets = response.data.history_tickets;
          this.isLoading = false;
        });
    },
    handleSaveDossierParam(key, value) {
      this.$emit('saving', 'saving');
      this.$axios
        .put(this.$root.$data.server_base + '/api/dossier/update.php',
          { designation_dossier_id: this.$route.params.designation_dossier_id,
            [key]: this.dossier[key]})
        .then((response => {
          this.$refs[key].markAsSaved();
          this.$emit('saving', 'success');
          this.refresh();
        }))
        .catch((error) => this.$emit('saving', 'error'))
    },
    handleAddHistory() {
      this.ticket_id = 0;
      this.editDialog = true;
    },
    handleEditHistory(item) {
      this.ticket_id = item.ticket_id;
      this.editDialog = true;
    },
    handleDeleteEvent(ticket_id) {
      this.deleteDialog = this.deleteTicketId = ticket_id;
    },
    deleteHistory(ticket_id) {
      this.$axios
        .delete(this.$root.$data.server_base + '/api/ticket/remove.php', {
          params: {ticket_id: ticket_id}})
        .then(response => {
          this.refresh();
        });
    },
    handleToggleVisible(item) {
      if(this.isReadOnly) return;

      const visible = item.audience == 'all' ? null : 'all';
      // item.audience = visible;
      this.$emit('saving', 'saving');
      this.$axios
        .put(this.$root.$data.server_base + '/api/ticket/update.php',
          { ticket_id: item.ticket_id, audience: visible })
        .then((response => {
          this.$emit('saving', 'success');
          this.refresh();
        }))
        .catch((error) => this.$emit('saving', 'error'))

    },
  },
  mounted () {
    this.refresh();
  }
}
</script>