export const getParentUrl = function(type, id, service_provider_id) {
  if (type === null) { return null }
  switch(type) {
    case 'designation_dossier':
      return `/fournisseurs/${service_provider_id}/dossiers/${id}`
    case 'service':
      return `/fournisseurs/${service_provider_id}/services/${id}`
    case 'ticket':
      return `/billets/${id}`
    case 'requirement':
      return `/fournisseurs/${service_provider_id}/exigence/${id}`
  }
  return null
}

export const getDetailsOnDocumentParents = function(document, service_provider_id, dossierDetails, serviceDetails, ticketDetails) {
  let dossiers = {
    singular: "Dossier",
    plural: "Dossiers",
    entries: document.designation_dossier_id_list.map((id) => {
      return {
        id: id,
        prefix: 'Dossier',
        type: dossierDetails[id].type,
        drilldown: [dossierDetails[id].type],
        name: dossierDetails[id].name,
        url: getParentUrl('designation_dossier', id, service_provider_id)
      }
    })
  }
  
  let services = {
    singular: "Service",
    plural: "Services",
    entries: document.service_id_list.map((id) => {
      return {
        id: id,
        prefix: 'Service',
        type: serviceDetails[id].type,
        drilldown: [serviceDetails[id].type],
        name: serviceDetails[id].name,
        url: getParentUrl('service', id, service_provider_id)
      }
    })
  }
  
  let tickets = {
    singular: "Billet",
    plural: "Billets",
    entries: document.ticket_id_list.map((id) => {
      return {
        id: id,
        prefix: 'Billet',
        type: ticketDetails[id].type,
        drilldown: ['ticket', ticketDetails[id].type],
        name: ticketDetails[id].name,
        url: getParentUrl('ticket', id, service_provider_id)
      }
    })
  }
  
  let requirements = {
    singular: "Exigence",
    plural: "Exigences",
    entries: document.requirement_num_list.map((id) => {
      return {
        id: id,
        prefix: 'Exigence',
        type: 'requirement',
        drilldown: ['requirement'],
        name: id,
        url: getParentUrl('requirement', id, service_provider_id)
      }
    })
  }
  
  let summary = ''
  const groups = [dossiers, services, tickets, requirements]
  groups.forEach((group, index, array) => {
    if (group.entries.length === 0) { return }
    if (summary !== '' && index < array.length - 1) { summary += ', ' }
    if (summary !== '' && index === array.length - 1) { summary += ' et ' }
    summary += `${group.entries.length} ` + (group.entries.length > 1? group.plural.toLowerCase(): group.singular.toLowerCase())
  })
  
  let parents = {
    dossiers,
    services,
    tickets,
    requirements,
    summary
  }
  
  parents.all = [].concat(parents.dossiers.entries, parents.services.entries, parents.tickets.entries, parents.requirements.entries)
  parents.total = parents.all.length
  parents.first = parents.all[0]
  
  return parents
}