<template>
  <span>
    <v-dialog v-model="errorDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          {{ $t('document.upload.file_exists') }}
        </v-card-title>
        <v-card-text v-if="fileError == 'file_exists'">
          {{ $t('document.upload.file_exists_1') }}
          {{ currentUploadingFile ? currentUploadingFile.name : '' }}
          <v-radio-group v-model="dialogFileExistsResolution">
            <v-radio :label="$t('document.upload.use_existing')" value="skip" />
            <v-radio :label="$t('document.upload.replace_file')" value="overwrite" />
            <v-radio :label="$t('document.upload.rename_new_file')" value="rename" />
            <v-expand-transition>
              <div class="pl-8" v-if="dialogFileExistsResolution=='rename'">
                <small>{{ $t('document.upload.rename_to') }} {{ altFileName }}</small>
              </div>
            </v-expand-transition>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="handleRetry">
            {{ $t('label.ok') }}
          </v-btn>
          <v-btn color="primary" text @click="dialogFileExistsResolution = 'ignore'; handleRetry()">
            {{ $t('label.ignore') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('label.upload') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-form>
            <v-row>
              <v-col cols="1">
                {{ $t('document.files') }}
              </v-col>
              <v-col cols="11">
                <v-file-input
                  show-size
                  counter
                  multiple
                  v-model="files"
                  :label="$t('document.choose')"
                ></v-file-input>
                <div v-for="file in files" :key="file.name">
                  <mime-type-icon :mimeType="file.type" />
                  {{ file.name }}
                  <div class="ml-7">
                    <v-progress-linear
                      :value="file.percentage"
                      background-color="white"
                      color="primary"
                      height="5"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">
                {{ $t('document.content') }}
              </v-col>
              <v-col cols="11">
                <v-checkbox
                  class="mt-0"
                  v-model="document.provider_assoc_details.governing"
                  hide-details
                >
                  <template v-slot:label>
                    <div>
                      <p class="mb-0">{{ $t('document.cat.governing') }}</p>
                      <small>{{ $t('document.cat.governing_small') }}</small>
                    </div>
                  </template>
                </v-checkbox>

                <v-checkbox
                  class="mt-1"
                  v-model="document.dossier_assoc_details.commitment"
                  hide-details
                >
                  <template v-slot:label>
                    <div>
                      <p class="mb-0">{{ $t('document.cat.engagement') }}</p>
                      <small>{{ $t('document.cat.engagement_small') }}</small>
                    </div>
                  </template>
                </v-checkbox>

                <v-checkbox
                  class="mt-1"
                  v-model="document.dossier_assoc_details.policy"
                  hide-details
                >
                  <template v-slot:label>
                    <div>
                      <p class="mb-0">{{ $t('document.cat.policy') }}</p>
                      <small>{{ $t('document.cat.policy_small') }}</small>
                    </div>
                  </template>
                </v-checkbox>

                <v-checkbox
                  class="mt-1"
                  v-model="document.dossier_assoc_details.demonstration"
                  hide-details
                >
                  <template v-slot:label>
                    <div>
                      <p class="mb-0">{{ $t('document.cat.requirement') }}</p>
                      <small>{{ $t('document.cat.requirement_small') }}</small>
                    </div>
                  </template>
                </v-checkbox>
                
                <v-expand-transition>
                  <v-select
                    v-show="document.dossier_assoc_details.demonstration"
                    class="ml-8"
                    v-model="document.dossier_assoc_details.requirements"
                    :items="requirementItems"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$tc('requirement.label', 2)"
                    multiple
                    :hint="$t('document.cat.requirement_choose')"
                    persistent-hint
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip>
                        <span>{{ item.identifier }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </v-expand-transition>

                <tip-tap
                  class="mt-4"
                  v-model="document.description"
                  :label="$t('document.description')"
                  :loading="isLoading"
                ></tip-tap>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="11">
                <div class="mt-6">
                  <v-btn color="primary" @click="handleUpload" :loading="currentUploadingFile != null">
                    <v-icon>mdi-upload</v-icon>{{ $t('label.upload') }}
                  </v-btn>
                  <v-btn class="ml-2" color="primary" outlined @click="handleCancel">
                    {{ $t('label.cancel') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>   
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import Vue from 'vue';
import TipTap from '../framework/TipTap.vue';
import MimeTypeIcon from './MimeTypeIcon.vue';

export default Vue.extend({
  name: 'UploadDocumentDialog',
  components: {
    TipTap, MimeTypeIcon
  },
  props: {
    value: false,
    id: {type: Number},

    // optional, if seeded from parent
    requirement_id: null,
    draggedFiles: null,
  },
  data() {return {
    document: {
      metadata: {},
      dossier_assoc_details: {},
      provider_assoc_details: {},
    },
    requirements: [],
    isLoading: false,
    files: [],
    fileUploadQueue: [],
    currentUploadingFile: null,
    errorDialog: false,
    fileError: '',
    currentFileExistsResolution: null,
    dialogFileExistsResolution: 'skip',
    altFileName: '',
  }},
  computed: {
    dialog: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    },
    headers() {
      return [
        { text: this.$tc('document.assoc_type'), value: 'item_type' },
        { text: this.$tc('document.assoc_name'), value: 'item_name' },
        { text: this.$tc('label.action'), value: 'actions' },
      ];
    },
    requirementItems() {
      return (this.requirements || [])
        .filter(e => e.item_type == 'requirement')
        .map((option) => ({
          identifier: option.identifier,
          text: option.identifier + ' - ' + this.$langobj.parse(option.name),
          value: option.requirement_id,
        }))

    }
  },
  watch: {
    value() {
      if(this.value) {
        this.get();
        if(this.requirement_id) {
          this.document.dossier_assoc_details = {
            demonstration: true,
            requirements: [this.requirement_id]
          }
        }
      } else {
        this.document= { 
          metadata: {},
          dossier_assoc_details: {},
          provider_assoc_details: {},
        };
        this.files = [];
      }
    },
    draggedFiles() {
      this.files = Array.from(this.draggedFiles);
    }
  },
  methods: {
    handleCancel() {
      this.currentUploadingFile = null;
      this.dialog = false;
    },
    async handleUpload() {
      this.fileUploadQueue = [...this.files];
      this.uploadNextFile();
    },
    async handleRetry() {
      if(this.dialogFileExistsResolution != 'ignore') {
        this.fileUploadQueue.unshift(this.currentUploadingFile);
        this.currentFileExistsResolution = this.dialogFileExistsResolution;
      }
      this.errorDialog = false;
      await this.uploadNextFile();

      // next, we clear the conflict resolution method.
      this.currentFileExistsResolution = null;
    },
    async uploadNextFile() {
      this.currentUploadingFile = this.fileUploadQueue.shift();
      
      if(this.currentUploadingFile == null) {
        // All done, refresh and close
        this.$emit('refresh');
        this.dialog = false;
        return;
      }

      let formData = new FormData();
      formData.append("file", this.currentUploadingFile);
      formData.append("service_provider_id", this.$route.params.service_provider_id);
      formData.append("designation_dossier_id", this.$route.params.designation_dossier_id);
      formData.append("document", JSON.stringify(this.document));
      if(this.currentFileExistsResolution)
        formData.append("conflict_resolution", this.currentFileExistsResolution);

      await this.$axios
        .post(this.$root.$data.server_base + '/api/repo/upload.php',
          formData, {
            headers: {"Content-Type": "multipart/form-data"},
            onUploadProgress: (event) => {
              this.currentUploadingFile.percentage = Math.round((100 * event.loaded) / event.total);
            },
          })
        .then(response => {
          if(!response.data.error) {
            // no error, go to next file
            this.$nextTick(this.uploadNextFile)
          }
          else if(response.data.error == 'file_exists') {
            // file with same name already exists, enable conflict resolution dialog
            this.fileError = response.data.error;
            this.altFileName = response.data.new_file_name;
            this.errorDialog = true;
          }
          else {
            // some other error, cancel and notify
            cancelOnError(response.data.error);
          }
        })
        .catch((error) => {
          this.cancelOnError('Error on server');
        });
    },
    cancelOnError(text) {
      this.$notify({type: 'error', group: 'std',
        title: 'Téléversement',
        text: text,
        duration: 4000
      });
      this.handleCancel();
    },
    get() {
      this.isLoading = true;
      this.$axios
        .get(this.$root.$data.server_base + '/api/requirement/list.php',
          { params: {
              designation_dossier_id: this.$route.params.designation_dossier_id,
              service_provider_id: this.$route.params.service_provider_id
          }})
        .then(response => {
          this.requirements = response.data.requirements;
          this.isLoading = false
        });
    }
  }
});
</script>