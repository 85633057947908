<template>
  <v-row dense>
    <v-col cols="4">
      <p class="db-graph-title mt-0 mb-0 text-right">
        <v-icon v-if="numerator==denominator" color="green" small>mdi-check-bold</v-icon>
        {{ title }}
      </p>
    </v-col>
    <v-col cols="8">
      <v-progress-linear :value="percent"
        :color="done ? '#9066aa' : '#aa87bd'"
        height="16"
      >
        <template v-slot:default="{}">
          <strong :class="{'white--text': done}">
            {{ numerator+'/'+denominator }}
          </strong>
        </template>
      </v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SPElementProgress',
  props: ['title', 'numerator', 'denominator'],
  computed: {
    percent() {
      return this.numerator*100/this.denominator;
    },
    done() {
      return this.numerator==this.denominator;
    }
  }
}
</script>
