import _ from 'lodash';

const getObjectDiff = (internal, storeData) => {
  let diff = _.cloneDeep(internal);
  for(const key in diff) {
    if(_.isEqual(diff[key], storeData[key])) {
      delete diff[key];
    }
  }

  return diff;
}

export default { getObjectDiff };
