<template>
  <v-avatar
    :color="avatarColor"
    size="28"
  >
    <span
      class="white--text"
      style="font-weight: bold"
    >
      {{ userInitials }}
    </span>
  </v-avatar>
</template>

<script>
import Vue from 'vue';

const avatarColors = [
  '#9ECBFF',
  '#C785FF',
  '#2c92ff',
  '#9066B3',
  '#50a988',
  '#faab26',
  '#9a2b64',
  '#a7a9ac',
  '#e23f93',
  '#216bbf',
];

export default Vue.extend({
  components: {
  },

  props: ['user'],

  data () {
    return {
      dialog: false,
    }
  },

  computed: {
    userInitials () {
      return (
        (this.user.first_name ? this.user.first_name.charAt(0) : '') +
        (this.user.last_name ? this.user.last_name.charAt(0) : '')
      );
    },

    avatarColor() {
      const charCodes = this.userInitials
        .split('')
        .map((char) => char.charCodeAt(0))
        .join('');
      const value = parseInt(charCodes, 10);
      return avatarColors[value % avatarColors.length];
    },
  },

});
</script>

<style scoped lang="scss">
</style>
