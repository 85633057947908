<template>
  <v-card
      flat
      height="100%"
      :color="$route.meta.greyBg ? 'grey lighten-4' : ''"
      class="rounded-0"
    >
    <router-view></router-view>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ServiceProviderTop',
  data: function() {return {
    url_workplan:'',
    url_oziweb:'',
    agency:'',
  }},

  components: {
  },

  methods: {

    loadServiceProvider: function() {
      axios
        .get(this.$root.$data.server_base + '/api/service_provider_subnav.php',
          { params:{service_provider_id:this.$route.params.service_provider_id}})
        .then(response => (
          this.url_workplan = response.data.url_workplan,
          this.url_oziweb = response.data.url_oziweb,
          this.agency = response.data.agency))
    },

    refreshRightCol: function() {
      this.$refs.right_col.refresh();
    }

  },
  mounted () {
    this.loadServiceProvider();
  },

  computed: {
    crumbs() { return [
        {
          text: 'Dashboard',
          disabled: false,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Link 1',
          disabled: false,
          href: 'breadcrumbs_link_1',
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'breadcrumbs_link_2',
        },
      ]
    }
  }
}
</script>
