<template>
  <div
    v-if="editor"
    :class="`${
      errorMessages && errorMessages.length > 0 ? 'tiptap-error' : ''
    }`"
  >
    <v-dialog v-model="urlDialog" max-width="600px">
      <v-card>
        <v-card-title>
          URL
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="url" label="URL"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="handleSetLink">
            {{ $t('label.ok') }}
          </v-btn>
          <v-btn color="primary" text @click="handleResetLink">
            {{ $t('label.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <label>
      {{ label }} <help-dialog v-if="helpKey" :help-key="helpKey" />
    </label>
    <div class="tiptap-container">
      <div class="menu-bar">
        <v-icon
          @click="editor.chain().focus().toggleBold().run()"
          :class="`menu-item ${editor.isActive('bold') ? 'active' : ''}`"
        >
          mdi-format-bold
        </v-icon>

        <v-icon
          @click="editor.chain().focus().toggleItalic().run()"
          :class="`menu-item ${editor.isActive('italic') ? 'active' : ''}`"
        >
          mdi-format-italic
        </v-icon>

        <v-icon
          @click="editor.chain().focus().toggleStrike().run()"
          :class="`menu-item ${editor.isActive('strike') ? 'active' : ''}`"
        >
          mdi-format-strikethrough
        </v-icon>

        <v-icon
          @click="editor.chain().focus().toggleParagraph().run()"
          :class="`menu-item ${editor.isActive('paragraph') ? 'active' : ''}`"
        >
          mdi-format-paragraph
        </v-icon>

        <v-icon
          v-for="index in [1, 2, 3]"
          :key="index"
          @click="editor.chain().focus().toggleHeading({ level: index }).run()"
          :class="`menu-item ${
            editor.isActive('heading', { level: index }) ? 'active' : ''
          }`"
        >
          {{ `mdi-format-header-${index}` }}
        </v-icon>

        <v-icon
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="`menu-item ${editor.isActive('bulletList') ? 'active' : ''}`"
        >
          mdi-format-list-bulleted
        </v-icon>

        <v-icon
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="`menu-item ${editor.isActive('orderedList') ? 'active' : ''}`"
        >
          mdi-format-list-numbered
        </v-icon>

        <v-icon
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="`menu-item ${editor.isActive('blockquote') ? 'active' : ''}`"
        >
          mdi-format-quote-open
        </v-icon>

        <v-icon
          @click="editor.chain().focus().setHorizontalRule().run()"
          :class="`menu-item`"
        >
          mdi-minus
        </v-icon>

        <v-icon v-if="extended"
          @click="handleLinkToggle"
          :class="`menu-item`"
        >
          mdi-link
        </v-icon>
      </div>
      <v-progress-linear v-if="loading" indeterminate :height="2" />
      <editor-content :editor="editor" />
    </div>
    <div v-if="errorMessages && errorMessages.length > 0">
      <div
        class="error-messages"
        v-for="(error, index) in errorMessages"
        :key="index"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';

export default Vue.extend({
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: "",
    },
    label: {
      type: "",
    },
    loading: {
      type: false,
    },
    'error-messages': {
      type: [],
    },
    'help-key': {
      type: String
    },
    extended: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      uuid: uuidv4(),
      editor: undefined,
      urlDialog: false,
      url: '',
    };
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [StarterKit, Link],
      onUpdate: this.update,
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  watch: {
    value() {
      if (this.editor.getHTML() !== this.value) {
        this.editor.commands.setContent(this.value, false);
      }
    },
  },

  methods: {
    update() {
      const json = this.editor.getJSON();
      if (json.content.some((row) => !!row.content)) {
        this.$emit('input', this.editor.getHTML());
      } else {
        this.$emit('input', null);
      }
    },
    handleLinkToggle() {
      this.url = this.editor.getAttributes('link').href;
      this.urlDialog = true;
    },
    handleSetLink() {
      this.urlDialog = false;
      if(this.url === null)
        return;
      // empty
      if (this.url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return
      }
      // update link
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.url }).run();
    },
    handleResetLink() {
      this.urlDialog = false;
      this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
    }
  },
});
</script>

<style scoped lang="scss">
label {
  color: rgba(0, 0, 0, 0.6);
  height: 20px;
  line-height: 20px;
}

.tiptap-container {
  border: 1px solid #ccc;
  margin-bottom: 20px;

  .menu-bar {
    display: flex;
    border-bottom: 1px solid #ccc;
    background-color: #eee;

    .menu-item {
      font-size: 20px;
      padding: 5px;
      border: 1px solid transparent;

      &.active {
        background-color: rgba($color: #000000, $alpha: 0.1);
        border: 1px solid #ccc;
      }
    }
  }
}

.tiptap-error {
  label {
    color: #ff5252;
  }
  .tiptap-container {
    border: 1px solid #ff5252;
  }
  .menu-bar {
    border-bottom: 1px solid #ff5252;
  }
}

.error-messages {
  color: #ff5252;
  font-size: 12px;
}
</style>

<style lang="scss">
.ProseMirror {
  min-height: 60px;
  padding: 5px;

  &:focus {
    outline: none;
  }

  // TODO: this is just for demo purposes... the actual blockquote tag doesn't seem to have any styling at all
  blockquote {
    border-left: 3px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    padding-left: 0.8rem;
  }
}
</style>
