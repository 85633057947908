<template>
  <v-container fluid>
    <div class="rawtext" v-html="text"></div>
  </v-container>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Resources',
  props: {},

  data () { return {
    textLang: {},
    isLoading: false,
  }},
  computed: {
    text() {
      return this.$langobj.parse(this.textLang);
    }
  },
  methods: {
    getHelpText() {
      this.isLoading = true;

      this.$axios
        .get(this.$root.$data.server_base + '/api/admin/help_dialog/get.php',
          { params: { key: 'resources.main' }})
        .then(response => {
          this.textLang = response.data.help_dialog.text;
          this.isLoading = false;
        })
    },
  },
  mounted() {
    this.getHelpText();
  }
});
</script>
<style lang="scss">
div.rawtext {
  font-family: "Roboto", sans-serif;
  font-size: 0.9em;
  color: rgb(0, 0, 0, 0.87);

  h2 {
    padding-top: 12px;
    padding-bottom: 8px;
  }
  p {
    padding-bottom: 4px;
  }
  strong {
    font-weight: 500;
  }
}
</style>
