<template>
  <div v-if="contact">
    <p>
      <v-icon class="mr-3">mdi-account</v-icon>
      {{ contact.first_name }}
      {{ contact.initial }}
      {{ contact.last_name }}
    </p>
    <p>
      <v-icon class="mr-3">mdi-hat-fedora</v-icon>
      {{ contact.title }}
    </p>
    <p>
      <v-icon class="mr-3">mdi-at</v-icon>
      {{ contact.email }}
    </p>
    <p>
      <v-icon class="mr-3">mdi-phone</v-icon>
      {{ contact.phone }}
      <template v-if="contact.ext">
        x {{ contact.ext }}
      </template>
    </p>
    <p v-if="contact.fax">
      <v-icon class="mr-3">mdi-fax</v-icon>
      {{ contact.fax }}
    </p>

  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['contact'],
  computed: {
  },
});
</script>