<template>
  <v-navigation-drawer :clipped="true" style="font-size: 14px;" app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ $t('navigation.title') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('navigation.subtitle') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav >
      <v-subheader>Nom du fournisseur</v-subheader>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        router :to="item.to"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list dense nav >
      <v-subheader>{{ $t('navigation.community') }}</v-subheader>
      <v-list-item
        v-for="item in items2"
        :key="item.title"
        router :to="item.to"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>

<style>

</style>

<script>
import {privileges} from '@/mixins/privileges.js';

export default {
  name: 'CollabSideBar',
  mixins: [privileges],
  data () {
    return {
      right: null,
    }
  },
  computed: {
    items() { 
      return [
        { title: this.$tc('navigation.dashboard'), icon: 'mdi-view-dashboard', to: '/collab/dashboard' },
        { title: this.$tc('navigation.general'), icon: 'mdi-folder-information', to: '/collab/info' },
        { title: this.$tc('navigation.programs'), icon: 'mdi-account-wrench', to: '/collab/services' },
        { title: this.$tc('navigation.points_of_service'), icon: 'mdi-map-marker-multiple' },
        { title: this.$tc('navigation.dossier', 2), icon: 'mdi-file-cabinet', to: '/collab/dossiers' },
      ]
    },
    items2() {
      return [
        { title: this.$tc('navigation.resources'), icon: 'mdi-library' },
        { title: this.$tc('navigation.navigation'), icon: 'mdi-navigation-variant' },
        { title: this.$tc('navigation.oht'), icon: 'mdi-lightbulb-group', to: '/collab/info' },
      ]
    }, 
  }

}
</script>
