<template>
  <v-container fluid class="mt-2">
    <v-tabs
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tabs-slider color="#fbab26"></v-tabs-slider>
      <v-tab
        v-for="item in tabItems"
        :key="item.title"
        :to="item.to"
      >
        <v-icon>{{item.icon}}</v-icon>&nbsp;{{ item.title }}
      </v-tab>
    </v-tabs>
    <v-slide-x-transition>
      <router-view 
        :dossier="dossier" 
        :requirements="requirements"
        :readonly="dossier.status!='active' || !(priv('agent'))"
        v-on:refresh="$emit('refresh')"
        @saving="(what) => $emit('saving', what)"/>
    </v-slide-x-transition>
  </v-container>
</template>

<script>
import Vue from 'vue';
import {privileges} from '@/mixins/privileges.js';

export default Vue.extend({
  name: 'GeneralInfoTop',
  components: {},
  mixins: [privileges],
  props: ['dossier', 'requirements'],
  data () {
    return {}
  },
  computed: {
    tabItems() {
      return [
        {
          title: this.$t('dossier.menu.general'),
          icon: 'mdi-information',
          to: {name: 'dossier_general'}
        },
        {
          title: this.$t('dossier.menu.history'),
          icon: 'mdi-history',
          to: {name: 'dossier_history'}
        },
        // {
        //   title: this.$t('dossier.menu.scope'),
        //   icon: 'mdi-contain',
        //   to: {name: 'dossier_scope'}
        // },
        // {
        //   title: this.$t('dossier.menu.clientele'),
        //   icon: 'mdi-human-male-female-child',
        //   to: {name: 'dossier_clientele'}
        // },
      ]
    }
  },
});

</script>