<template>  
  <v-container fluid class="d-flex justify-center align-center points" style="height: 100%">
    <div style="opacity: 1">
      <v-container>
        <v-card width="500" :loading="loading">
          <v-form @submit.prevent="handleSubmit" autocomplete="off">
            <v-card-text>
              <v-row class="d-flex align-end mx-0 mt-1">
                <v-img
                  alt="OZi"
                  class="shrink mr-2"
                  contain
                  src="@/assets/logo.svg"
                  transition="scale-transition"
                  width="150"
                  height="47.25"
                />
                <v-spacer />
                <div>Collab</div>
              </v-row>
            </v-card-text>
            <v-card-title>{{ $t('password.reset_password') }}</v-card-title>
            <div v-if="error">
              <v-card-text>
                <v-alert dense type="error" outlined>
                  {{ $t('error.invalid_token') }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text :to="{name: 'forgot-password'}">{{ $tc('login.forgotPassword') }}</v-btn>
              </v-card-actions> 
            </div>
            <div v-else-if="success"> 
              <v-card-text>
                <v-alert dense v-show="success" type="success">
                  {{ $t('password.reset_password_success') }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text :to="{name: 'login'}">{{ $tc('label.login') }}</v-btn>
              </v-card-actions> 
            </div>
            <div v-else-if="tokenGood">
              <v-card-text>
                <input
                  type="text" autocomplete="username"
                  readonly :value="username" style="display: none"
                />
                <v-text-field
                  v-model="password"
                  :label="`${$tc('password.new_password')}*`"
                  :rules="[(v) => $validators.password(v, [$t('user.password')])]"
                  @input="error = null"
                  :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (showPassword = !showPassword)"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="new-password"
                  required
                />               
                <v-text-field
                  v-model="password_confirm"
                  @input="error = null"
                  :label="`${$tc('password.new_password_confirm')}*`"
                  :rules="[passwordsMatch]"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="new-password"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">
                  <v-icon left>mdi-lock-reset</v-icon>
                  {{$tc('label.submit')}}
                </v-btn>
                <v-spacer />
                <v-btn color="primary" text :to="{name: 'forgot-password'}">{{ $tc('login.forgotPassword') }}</v-btn>
              </v-card-actions> 
            </div>
          </v-form>
        </v-card>
        <div class="d-flex justify-center mt-4">
          <locale-selector />
        </div>
      </v-container>
    </div>
  </v-container>

</template>

<script>
import LocaleSelector from '@/components/locale/LocaleSelector.vue';

export default {
  name: 'PasswordReset',
  components: {
    LocaleSelector
  },
  data: function() {return {
    password: "",
    password_confirm: "",
    showPassword: false,
    respData: {},
    error: false,
    success: false,
    loading: true,
    tokenGood: false,
    username: '',
  }},
  computed: {
    passwordsMatch () {
      return this.password_confirm == this.password ? true : "Do not match";
    }
  },
  methods: {
    checkToken() {
      this.$axios
        .put("/api/admin/password/check_token.php", {
            user_id: this.$route.params.user_id,
            token: this.$route.params.token
          })
        .then(response => {
          if(response.data.code < 0)
            this.error = true;
          else {
            this.tokenGood = true;
            this.username = response.data.username;
          }
          this.loading=false;
        }
      );      
    },
    handleSubmit() {
      this.loading = true;
      this.$axios
        .put("/api/admin/password/reset.php", {
            user_id: this.$route.params.user_id,
            token: this.$route.params.token,
            password: this.password
          })
        .then(response => {
          this.loading = false;
          this.error = response.data.error ? true : false;
          this.success = response.data.success ? true : false;
        }
      );      
    },
  },
  mounted () {
    window.scrollTo(0, 0);
    this.checkToken();
  }
}
</script>

<style>
.points::before {
  content: "";
  opacity: 0.4;
  background: url('~@/assets/img/points.png');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
</style>