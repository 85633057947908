<template>
  <v-container>
    <delete-dialog v-model="deleteDialog"
      :title="$t('contact.delete')"
      :id="deleteContactId"
      v-on:delete-object="deleteContact"
    >
    </delete-dialog>
    <h2>{{ $tc('navigation.contact', 2) }}</h2>
    <v-row>
      <v-col cols="6">
        <h3 class="grey--text">{{ $t('contact.key_contacts') }}</h3>
        <contact-card
          v-for="contact in spContacts"
          :key="contact.contact_id"
          :value="contact"
          @edit="handleEdit"
          @delete="handleDeleteEvent"
        >
        </contact-card>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col>
            <h3 class="h3 grey--text">{{ $t('contact.other_contacts') }}</h3>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="handleCreate" color="primary" text>
              <v-icon>mdi-plus</v-icon>
                {{ $t('label.add') }}
            </v-btn>
          </v-col>
        </v-row>
        <contact-card
          v-for="contact in entityContacts"
          :key="contact.contact_id"
          :value="contact"
          @edit="handleEdit"
          @delete="handleDeleteEvent"
        >
        </contact-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import ContactCard from '@/components/serviceProvider/ContactCard.vue';
import DeleteDialog from '@/components/framework/DeleteFromListDialog.vue';

export default {
  components: {
    ContactCard,
    DeleteDialog
  },
  data: function() {
    return {
      contacts: [],
      primaryContactId: null,
      deleteDialog: false,
      deleteContactId: null,
    }
  },
  computed: {
    spContacts() {
      return this.contacts.filter((item) => (item.contact_type != 'autre'))
    },
    entityContacts() {
      return this.contacts.filter((item) => (item.contact_type == 'autre'))
    },    
  },
  methods: {
    handleEdit(contact_id) {
      this.$router.push({ name: 'contact',
        params: { 
          service_provider_id: this.$route.params.service_provider_id,
          contact_id: contact_id
        }
      })
    },
    handleCreate() {
      this.$router.push({ name: 'contact',
        params: { 
          service_provider_id: this.$route.params.service_provider_id,
          contact_id: 'cr'
        }
      })
    },
    handleDeleteEvent(contact_id) {
      this.deleteDialog = this.deleteContactId = contact_id;
    },
    deleteContact(contact_id) {
      this.$axios
        .get(this.$root.$data.server_base + '/api/contact/remove.php',
          { params: {
            service_provider_id: this.$route.params.service_provider_id,
            contact_id: contact_id }})
        .then(response => {
          this.refresh();
        })
    },
    refresh() {
      axios
        .get(this.$root.$data.server_base + '/api/contact/list.php',
          { params: {service_provider_id: this.$route.params.service_provider_id}})
        .then(response => {
          this.contacts = response.data.contacts;
        })
    },
  },
 
  mounted() {
    this.refresh()
  }
}
</script>