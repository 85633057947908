import i18n, { locales } from '@/i18n';
import unorm from 'unorm';

// import CommonUtils from './CommonUtils';

// interface LanguageObject {
//   [key: string]: string | undefined;
// }

const clean = (text) => {
  return unorm
    .nfd(text)
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

const langobj = {
  parse: (val, displayDefault = false) => {
    if (val) {
      let result = val[i18n.locale];

      // if string is not specified in current locale, loop through provided fallback array until one is found
      if (!result) {
        const locale = locales.find((locale) => i18n.locale === locale.value);
        if (locale) {
          locale.fallback.some((fallback) => {
            if (val[fallback])
              result =
                (displayDefault ? '(' + fallback + ') ' : ' ') + val[fallback];
            return !!result;
          });
        }
      }

      return result ? result.toString().trim() : "";
    }
  },

  generate: () => {
    return [];
  },

  // generate: (val?: any): LanguageObject => {
  //   return locales.reduce((result: LanguageObject, locale) => {
  //     if (typeof val === 'string') {
  //       result[locale.value] = val;
  //     } else if (typeof val === 'object') {
  //       // if an object, assume it is a LanguageObject
  //       result[locale.value] = val ? val[locale.value] : '';
  //     }
  //     return result;
  //   }, {});
  // },

  dataTableFilter (value, search, item) {
    return value != null &&
      search != null &&
      typeof value !== 'boolean' &&
      (value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
      (typeof value == 'object' && langobj.filter(value, search)) )
  },

  filter: (value, search) => {
      return locales.some((locale) => {
      const value2 = value[locale.value];
      return value2 ? clean(value2).includes(search.toLocaleLowerCase()) : false;
    });
  },

  sort(a, b) {
    return langobj.parse(a).localeCompare(langobj.parse(b))
  },
  
  label: (label, locale) => {
    return `${label} (${locale.toLocaleUpperCase()})`;
  },

  valueName: (label) => {
    return label + '.' + i18n.locale;
  }
};

export default {
  langobj,
};
// export { LanguageObject };
