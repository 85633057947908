<template>
  <div>   
    <v-container fluid>
      <v-row class="mt-2">
        <v-col cols=3>
          <div>
            <v-card-text>
              <div>{{ internal.key }}</div>
              <div>{{ $langobj.parse(internal.name) }}</div>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              <div>
                <v-btn class="px-1" color="primary" text @click="handleDelete">
                  {{ $t('label.delete') }}
                </v-btn>
              </div>
            </v-card-text>
          </div>
        </v-col>
        <v-col cols=9>
          <v-card>
            <v-card-text>
              <v-text-field
                v-model="internal.key"
                :label="$tc('email.key')"
                disabled
              ></v-text-field>
              <v-row
                v-for="locale in $locales"
                :key="locale.value"
                class="mt-2"
              >
                <v-col cols=1>
                  <p class="text-h4 primary--text">{{ locale.toLocaleUpperCase() }}</p>
                </v-col>
                <v-col cols=11>
                  <v-text-field
                    v-model="internal.name[locale]"
                    :label="$langobj.label($t('email.name'), locale)"
                  ></v-text-field>
                  <v-text-field
                    v-model="internal.subject[locale]"
                    :label="$langobj.label($t('email.subject'), locale)"
                  ></v-text-field>
                  <tip-tap
                    extended
                    v-model="internal.body[locale]"
                    :label="$langobj.label($t('email.body'), locale)"
                  />
                  <tip-tap
                    extended
                    v-model="internal.signature[locale]"
                    :label="$langobj.label($t('email.signature'), locale)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="handleSave" :disabled="disableSave">
                {{ $t('label.save') }}
              </v-btn>
              <v-btn color="primary" text @click="handleCancel">
                {{ $t('label.cancel') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>  
</template>

<script>
import _ from 'lodash';
import EmailUtils from '@/utils/EmailUtils';

export default {
  name: 'UserDetails',
  data: function() {
    return {
      storeData: null,
      internal: EmailUtils.generateDefault(),
      isLoading: true,
    }
  },

  computed: {
    disableSave() {
      return _.isEqual(this.storeData, this.internal);
    }
  },

  methods: {
    getDataFromApi() {
      this.isLoading = true;
      this.$axios
        .get("/api/admin/email/get.php",
          {params:{key: this.$route.params.key}})
        .then(response => {
          this.storeData = response.data;
          this.internal = _.cloneDeep(response.data);
          this.isLoading = false;
      })
    },
    handleDelete() {
      console.log(this.$locales);
    },
    handleSave() {
      this.$axios
        .put("/api/admin/email/update.php", this.internal,
           { headers: {'Content-Type': 'application/json' }})
        .then((response) => {
          this.storeData = response.data;
          this.internal = _.cloneDeep(response.data);
          this.isLoading = false;
        });
    },
    handleCancel() {
      this.getDataFromApi();
    }
  },
  watch: {
    // show_inactive_users: {
    //   handler () { this.getDataFromApi() }
    // },
  },
  mounted () {
    this.getDataFromApi();
  }
}

</script>