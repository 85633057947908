<template>
  <v-icon>{{ name }}</v-icon>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'MimeTypeIcon',
  props: {
    mimeType: {
      type: String,
      required: true,
    }
  },

  computed: {
    name() {
      switch(this.mimeType) {
        case "audio/aac":
        case "audio/mpeg":
        case "audio/ogg":
        case "audio/wav":
        case "audio/webm":
          return "mdi-volume-high";

        case "application/x-bzip":
        case "application/x-bzip2":
        case "application/gzip":
        case "application/x-freearc":
        case "application/vnd.rar":
        case "application/x-tar":
        case "application/zip":
        case "application/x-7z-compressed":
          return "mdi-folder-zip";

        case "image/bmp":
        case "image/avif":
        case "image/svg+xml":
        case "image/tiff":
        case "image/webp":
          return "mdi-file-image";
          
        case "image/gif":
          return "mdi-file-gif-box";

        case "image/jpeg":
          return "mdi-file-jpg-box";
        
        case "image/png":
          return "mdi-file-png-box";

        case "text/csv":
          return "mdi-file-delimited";
        case "":

        case "video/mp4":
        case "video/mpeg":
        case "video/ogg":
        case "video/x-msvideo":
        case "video/mp2t":
        case "video/webm":
          return "mdi-file-video";
        
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "mdi-file-word";


        
        case "application/vnd.oasis.opendocument.presentation":
          return "mdi-presentation-play";

        case "application/vnd.oasis.opendocument.spreadsheet":
          return "mdi-table";

        case "application/vnd.oasis.opendocument.text":
        case "application/rtf":
        case "text/plain":
          return "mdi-text-box";
        
        case "application/pdf":
          return "mdi-file-pdf-box";

        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return "mdi-file-powerpoint";

        case "":
          return "mdi-pdf-box";
        
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "mdi-file-excel";
        
        default:
          return "mdi-file-question-outline";
      }
    }
  }
})
</script>
