<template>
  <v-card class="mb-3" flat 
    @click="$router.push({name: 'dossier_summary',
      params: { designation_dossier_id: dossier.designation_dossier_id } })"
  >
    <v-card-title>{{ $langobj.parse(dossier.name_lang)}}
      <v-chip class="ml-4"
        :color="dossier.dossier_status_aes.colour"
        label
        small
        text-color="white"
      >
        <v-icon left>mdi-circle-medium</v-icon>
        {{ $langobj.parse(dossier.dossier_status_lang) }}
      </v-chip>
    </v-card-title>
    <v-card-subtitle>
      <p v-if="dossier.date_of_designation">
        {{ $t('dossier.dashboard.confirmed_date') }} {{dossier.date_of_designation}}
      </p>
      <p v-else-if="dossier.due_date">
        {{ $t('dossier.dashboard.submission_due') }} {{dossier.due_date}}
      </p>
    </v-card-subtitle>
    <v-card-text>      
      <v-row dense v-if="workplanElements.length != 0">
        <v-col cols="12">
          <p class="text-overline">{{ $t('dossier.dashboard.completed_elements') }}</p>
          <SPElementProgress :title="$t('provider.dashboard.commitments')"
            :numerator="weCommitments.numerator"
            :denominator="weCommitments.denominator" />
          <SPElementProgress :title="$t('provider.dashboard.policy')"
            :numerator="wePolicies.numerator"
            :denominator="wePolicies.denominator" />
          <SPElementProgress :title="$t('provider.dashboard.demonstrations')"
            :numerator="weDemonstrations.numerator"
            :denominator="weDemonstrations.denominator" />
        </v-col>
      </v-row>
      <v-row dense v-if="requirements.length != 0">
        <v-col cols="12">
          <p class="text-overline">{{ $t('dossier.dashboard.requirements') }}</p>
          <SPElementProgress :title="$t('provider.dashboard.completed')"
            :numerator="completedReqs.numerator"
            :denominator="completedReqs.denominator" />
          <SPElementProgress :title="$t('provider.dashboard.conforming')"
            :numerator="conformingReqs.numerator"
            :denominator="conformingReqs.denominator"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SPElementProgress from '@/components/dashboard/SPElementProgress.vue'
import DashboardUtils from '@/utils/DashboardUtils';

export default {
  name: 'SPDossier',
  components: {
    SPElementProgress,
  },
  props: ['dossier'],
  data() { return {
    workplanElements: [],
    requirements: [],
  }},
  computed: {
    weCommitments() { 
      return DashboardUtils.indicator.weCommitments(this.workplanElements);
    },
    wePolicies() { 
      return DashboardUtils.indicator.wePolicies(this.workplanElements);
    },
    weDemonstrations() { 
      return DashboardUtils.indicator.weDemonstrations(this.workplanElements);
    },

    completedReqs() { 
      return DashboardUtils.indicator.reqComplete(this.requirements);
    },
    conformingReqs() { 
      return DashboardUtils.indicator.reqConforming(this.requirements);
    },
  },
  methods: {
    handleClick() {},
    refresh() {
      this.isLoading = true
      this.$axios
        .get(this.$root.$data.server_base + '/api/workplan_element/list.php', 
        { params: {
            service_provider_id: this.$route.params.service_provider_id,
            designation_dossier_id: this.dossier.designation_dossier_id,
        }})
        .then((response => {
          this.workplanElements = response.data.elements;
        }));
      this.$axios
        .get(this.$root.$data.server_base + '/api/requirement/list.php', 
        { params: {
            service_provider_id: this.$route.params.service_provider_id,
            designation_dossier_id: this.dossier.designation_dossier_id,
        }})
        .then((response => {
          this.requirements = response.data.requirements;
        }))
    },
  },
  mounted() {
    this.refresh()
  }
}
</script>
