<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('dossier.create') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text :loading="isImporting" @click="handleCreate">
            {{ $t('label.create') }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <v-form v-model="isFormValid" ref="form">
          <v-row
            v-for="locale in $locales"
            :key="locale.value"
            class="mt-2"
          >
            <v-col cols=1>
              <p class="text-h4 primary--text">{{ locale.toLocaleUpperCase() }}</p>
            </v-col>
            <v-col cols=11>
              <v-text-field
                v-model="name[locale]"
                :label="$langobj.label($t('dossier.name'), locale)"
                :rules="[(v) => $validators.required(v, [$t('dossier.name')])]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select 
                v-model="dossier_type"
                :label="$t('dossier.type')"
                :items="preCreateInfo.typeOptions"
                item-value="enum_key"
                :rules="[(v) => $validators.required(v, [$t('dossier.type')])]"
              >
                <template v-slot:item='{ item }'>
                  {{ $langobj.parse(item.name) }}
                </template>
                <template v-slot:selection='{ item }'>
                  {{ $langobj.parse(item.name) }}
                </template>
              </v-select>
              <v-select
                v-model="designation_model_id"
                :items="preCreateInfo.models"
                :label="$t('dossier.model')"
                item-text="name"
                item-value="id"
                :rules="[(v) => $validators.required(v, [$t('dossier.model')])]"
              >
                <template v-slot:selection="{ item }">
                  {{ $langobj.parse(item.name) }}
                </template>
                <template v-slot:item="{ item }">
                  {{ $langobj.parse(item.name) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
      
          <p class="text-h6 mt-4">Données à importer</p>
          <v-radio-group v-model="seedSelection">
            <v-radio label="Créer un dossier vide" value="rien" />

            <v-radio label="Importer les données d'un rapport OZi" value="ozi"></v-radio>
            <v-expand-transition>
              <div class="pl-8" v-if="seedSelection=='ozi'">
                <small>Ceci inclut l'information entrée par le fournisseur et la grille
                  d'évaluation, y compris les documents préalablement téléversés.
                  Il faut choisir la période cible qui représente le mieux l'année du dossier.
                </small>
                <v-row>
                  <v-col cols="4">
                    <v-select
                      v-model="workplan_id"
                      :items="preCreateInfo.workplans"
                      :label="$t('report.period')"
                      item-text="period"
                      item-value="workplan_id"
                      :disabled="seedSelection!='ozi'"
                      :rules="[(v) => $validators.required(v, [$t('report.period')])]"
                    >
                      <template v-slot:item="{ item }">
                        {{item.period}}
                        {{item.submitted_date ? ' (soumis le '+item.submitted_date+')' : '(non soumis)'}}
                      </template>
                    </v-select>
                  </v-col>
                </v-row>

              </div>
            </v-expand-transition>

            <v-radio label="Importer les données d'un autre dossier pour y donner suite" value="collab" />
            <v-expand-transition>
              <div class="pl-8" v-if="seedSelection=='collab'">
                <small>Ceci inclut l'information entrée par le fournisseur y compris les documents
                  préalablement téléversés. Seulement pour les dossiers ayant un modèle déjà choisi.
                </small>
                <v-row>
                  <v-col cols="4">
                    <v-select
                      v-model="designation_dossier_id"
                      :items="
                        (preCreateInfo.dossiers || []).map((option) => ({
                          text: $langobj.parse(option.name_lang),
                          value: option.designation_dossier_id,
                        }))"
                      :label="$tc('dossier.label', 1)"
                      :disabled="seedSelection!='collab'"
                      :rules="[(v) => $validators.required(v, [$tc('dossier.label', 1)])]"
                    >
                    </v-select>
                  </v-col>
                </v-row>

              </div>
            </v-expand-transition>
              
          </v-radio-group>

        </v-form>
    
        <v-card-actions>
          <v-btn color="primary" :loading="isImporting" @click="handleCreate">
            {{ $t('label.create') }}
          </v-btn>
          <v-btn color="primary" text @click="dialog = false">
            {{ $t('label.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    
  </v-dialog>
</template>

<script>
  export default {
    props: ['value'],
    data () {
      return {
        name: {en: '', fr: ''},
        dossier_type: null,
        designation_model_id: null,
        seedSelection: 'rien',
        
        workplan_id: null,
        designation_dossier_id: null,
        
        preCreateInfo: {},
        isLoading: false,
        isImporting: false,
        isFormValid: true,
      }
    },
    watch: {
      value() {
        if(this.value==true) {
          this.getPreCreationInfo();
        } else {
          this.$refs.form.reset();
          this.seedSelection = 'rien';
        }
      }
    },
    computed: {
      dialog: {
        get () { return this.value },
        set (value) { this.$emit('input', value) }
      },
    },
    methods: {
      getPreCreationInfo() {
        this.isLoading = true;
        this.$axios
          .get(this.$root.$data.server_base + '/api/dossier/precreate_info.php',
            { params: {
              service_provider_id: this.$route.params.service_provider_id,
            }})
          .then(response => {
            this.preCreateInfo = response.data;
            this.isLoading = false
          });
      },
      handleCreate() {
        if(!this.$refs.form.validate()) {
          const invalidField = this.$refs.form.$children.find((e) => !e.valid)
          if (invalidField)
            invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
          return
        }
        
        this.isImporting = true;
        let data = {
          service_provider_id: this.$route.params.service_provider_id,
          designation_model_id: this.designation_model_id,
          name_lang: this.name,
          dossier_type: this.dossier_type,
        };
        if(this.seedSelection == 'ozi') {
          data.workplan_id = this.workplan_id;
        }
        if(this.seedSelection == 'collab') {
          data.seed_dossier = this.designation_dossier_id;
        }

        this.$axios
          .put(this.$root.$data.server_base + '/api/dossier/create_from.php', data)
          .then(response => {
            // this.preConvertInfo = response.data;
            this.isImporting = false;
            this.$notify({type: 'success', group: 'std',
              title: 'Création',
              text: 'Créé avec succès',
              duration: 4000
            });
            this.dialog = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.isImporting = false;
            this.$notify({type: 'error', group: 'std',
              title: 'Création',
              text: 'Erreur du serveur',
              duration: 4000
            })
          });
      }
    },
  }
</script>