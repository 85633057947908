<template>
  <div>
    <report-row :label="$t('report.fls34.designation_status')">
      {{ $langobj.parse(report.design_status_name) }}
    </report-row>

    <report-row :label="$t('report.fls34.application_type')">
      {{ $langobj.parse(report.design_application_type) }}
    </report-row>

    <report-row :label="$t('report.fls34.program_list')">
      <div v-html="programs" />
    </report-row>
  </div>
</template>

<script>
import Vue from 'vue';
import ReportRow from '@/components/report/reportRow.vue';

export default Vue.extend({
  props: ['report'],
  
  components: {
    ReportRow
  },

  computed: {
    programs() {
      if(this.report && this.report.designation_info_2)
        return this.report.designation_info_2.replace(/\n/g, '<br />');
      return '';
    }
  },

  methods: {
  },
});
</script>

<style scoped lang="scss">
</style>