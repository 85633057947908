<template>
  <v-dialog
    :value='value'
    @input="$emit('input', $event)"
    width="500"
  >
    <v-card :loading="loading">
      <v-card-title class="text-h5 grey lighten-2 mb-3">
        {{ $t('password.reset_password') }}
      </v-card-title>

      <v-card-text>
        <p>{{ $t('password.admin_reset_password') }}</p>
        <br />
        <p>{{ $t('user.email') }}: {{email}}</p>
          <v-alert dense v-show="success" type="success">
            {{ $t('password.forgot_password_success') }}
          </v-alert>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" @click="resetPassword()">
          <v-icon class="mr-1">mdi-email-fast</v-icon>{{ $t('label.send') }}     
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.native="$emit('input', false)">
          {{ $t('label.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>     
</template>

<script>
export default {
  name: 'ResetPasswordDialog',
  props: ['value','user_id','email'],
  data() { return {
    loading: false,
    success: false,
  }},
  methods: {
    resetPassword() {
      this.loading = true;
      this.$axios
        .put("/api/admin/password/reset_request.php",
          {user_id: this.user_id})
        .then(response => {
          this.success = response.data.success;
          this.loading = false;
      })
    },
  }
}
</script>