<template>
  <v-container fluid>
    <h1>Liste de dossiers</h1>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="5"
        class="elevation-1"
        @click:row="handleClick"
      ></v-data-table>
    
  </v-container>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ListeDossiers',
  data () {
    return {
      headers: [
        {
          text: 'Nom',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: "Date d'échéance", value: 'date_echeance' },
        { text: "Statut", value: 'statut' },
      ],
      items: [
        {
          name: 'Demande initiale 2014',
          date_echeance: "2014-09-27",
          statut: "Désignation complétée",
        },
        {
          name: 'Attestation de conformité 2018',
          date_echeance: "2018-03-17",
          statut: "Approuvée",
        },
        {
          name: 'Attestation de conformité 2021',
          date_echeance: "2021-10-04",
          statut: "En retard",
        },
      ],
    }
  },
  methods: {
    handleClick(row) {
      this.$router.push('/collab/dossiers/31');
      console.log(row);

    }
  }
});
</script>