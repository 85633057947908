<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="mt-2" v-bind="attrs" v-on="on">
        <v-icon class="mr-2">mdi-trash-can</v-icon>
        {{ $t('label.delete') }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ title ? title : $t('label.delete') }}
      </v-card-title>

      <v-card-text class="mt-3">
        <slot></slot>  
        <div v-if="!$slots.default">
          {{ $t('label.are_you_sure') }}
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          {{ $t('label.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="dialog = false; $emit('delete-object', id)"
        >
          <v-icon class="mr-2">mdi-trash-can</v-icon>
          {{ $t('label.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  components: {
  },

  props: {
    title: {
      type: "",
    },
    id: {
      type: ""
    }
  },

  data () {
    return {
      dialog: false,
    }
  },
  
});
</script>

<style scoped lang="scss">
</style>
