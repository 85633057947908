<template>
  <div>
    <report-row :label="$t('report.bdssf34.description')">
      <div v-html="asHtml(report.description)" />
    </report-row>

    <report-row :label="$t('report.bdssf34.sectors_declared')">
      <div v-html="report.sectors" />
    </report-row>

  </div>
</template>

<script>
import ReportContact from '@/components/report/reportContact.vue';
import ReportSectors from '@/components/report/reportSectors.vue';
import ReportRow from '@/components/report/reportRow.vue';

export default {
  props: ['report'],

  components: {
    ReportContact,
    ReportSectors,
    ReportRow,
  },

  methods: {
    asHtml(str) {
      if(str)
        return str.replace(/\n/g, '<br />');
      return '';
    }
  }
  
}
</script>

<style scoped lang="scss">
</style>