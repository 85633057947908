<template>
  <v-row class="mt-1">
    <v-col class="text-right" cols="3">
      <strong>{{ label }}:</strong>
    </v-col>
    <v-col>
      <slot />
    </v-col>
  </v-row>
</template>
<script> 

export default {
  name: 'ReportRow',
  props: {
    label: String
  }
}
</script>