// TODO: considérer intégrer avec Privileges

export const session = {
  computed: {
    session() {
      return this.getSession();
    }
  },
  methods: {
    getSession() {
      const session = localStorage.getItem('session');
      return JSON.parse(session);
    },
    restoreSession() {
      const session = this.getSession();
      localStorage.jwt = session.token;
      this.$axios.defaults.headers.common['X-Custom-Authorization'] = session.token;
    },
    storeSession(session) {
      localStorage.session = JSON.stringify(session);
      localStorage.jwt = session.token;
      this.$axios.defaults.headers.common['X-Custom-Authorization'] = session.token;
    },
    clearSession() {
      localStorage.removeItem('session');
      localStorage.removeItem('jwt');
      this.$root.privilege = null;
    }
  }
}
