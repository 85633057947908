<template>
  <v-container fluid class="mt-4">
    <div class="d-flex mb-4">
      <p class="text-h5">{{ $t('dossier.menu.workplan') }}</p>
      <v-spacer />
      <v-checkbox
        class="mt-0"
        v-model="hideCompleted"
        :label="$t('dossier.workplan.hide_completed')"
        hide-details
      />
    </div>
    <v-data-table
      :items-per-page="-1"
      :hide-default-footer="true"
      dense
      :headers="headers"
      :items="requirementsFiltered"
      item-key="requirement_id"
      :loading="isLoading"
      @click:row="handleRowClick"
    >
      <template v-slot:[`item.sort`]="{ item }">
        <div class="my-1">
          <v-avatar size="18" color="primary" class="mr-2">
            <span v-if="item.identifier.length > 2" 
              class="white--text" style="font-size: 0.4em; font-weight: bold;">
              {{item.identifier}}
            </span>
            <span v-else 
              class="white--text" style="font-size: 0.8em; font-weight: bold;">
              {{item.identifier}}
            </span>
          </v-avatar>
          <strong>{{ item.name }}</strong>
          {{ item.completed ? '' : ' : ' + item.requirement_text }}
        </div>
      </template>
      <template v-slot:[`item.sp_content.workplan`]="{ item }">
        <div
          class="my-1"
          v-if="item.sp_content"
          v-html="item.sp_content.workplan"
        >
        </div>
      </template>
      
      <template v-slot:[`item.elements`]="{ item }">
        <div class="my-1"
          style="min-width: 100px;"
          @click.stop="$router.push({
            name: 'requirement_realisations',
            params: { requirement_id: item.requirement_id }
          })"
        >
          <p v-for="element in item.elements" :key="element.element_def_id">
            <v-icon small>
              {{ element.checked ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
            </v-icon>
            {{ $langobj.parse(element.text)}}
          </p>
          <p v-if="item.completed">
            <v-icon color="green">mdi-text-box-check</v-icon>
            {{ $t('label.completed') }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.dossier_status`]="{ item }">
        <v-icon :color="item.dossier_status_aes.colour">mdi-circle-medium</v-icon>
        {{ item.dossier_status_lang }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

export default {
  components: {
  },
  name: 'DocumentWorkplan',
  props: ['readonly'],
  data: function() {return {
      workplan: [],
      elements: [],
      isLoading: false,

      get hideCompleted() {
        return localStorage.getItem('dossierWorkplan.hideCompleted') === "true"
          || false;
      },
      set hideCompleted(newVal) {
        localStorage.setItem('dossierWorkplan.hideCompleted', newVal);
      },
  }},
  computed: {
    headers() {
      return [
        { text: this.$tc('requirement.label', 1), value: 'sort' },
        { text: this.$tc('requirement.responsible'), value: 'person_responsible' },
        { text: this.$tc('requirement.planned_start_date'), value: 'planned_start_date', filterable: false },
        { text: this.$tc('requirement.planned_end_date'), value: 'planned_end_date', filterable: false },
        { 
          text: this.$tc('designation_model.element.label', 1),
          value: 'elements',
          sortable: false, filterable: false,
          
        },
        { 
          text: this.$tc('requirement.workplan'),
          value: 'sp_content.workplan',
          sortable: false, filterable: false
        },
      ];
    },
    requirementsFiltered() {
      return this.workplan.filter(item => (
          item.item_type=='requirement' &&
          item.appreciation_status_key != "non_applicable"
        ))
        .map(item => ({
          ...item,
          name: this.$langobj.parse(item.name),
          requirement_text: this.$langobj.parse(item.requirement_text),
          elements: this.elementsForRequirement(item.identifier),
        }))
        .map(item => ({
          ...item,
          completed: item.elements.length == 0,
        }));

    }
  },
  
  methods: {
    elementsForRequirement(identifier) {
      return this.elements.filter(item => (
          item.requirement_identifier == identifier
          && item.obligation == 'required'
          && item.status != 'na'
          && (!this.hideCompleted || !item.checked)
        ))
    },
    handleRowClick(row) {
      this.$router.push(
        {name: 'requirement_workplan', params: { requirement_id: row.requirement_id }}
      );
    },
    refresh() {
      this.isLoading = true
      this.$axios
        .get(this.$root.$data.server_base + '/api/requirement/list_workplan.php', 
        { params: {
            service_provider_id: this.$route.params.service_provider_id,
            designation_dossier_id: this.$route.params.designation_dossier_id,
        }})
        .then((response => {
          this.workplan = response.data.requirements;
          this.isLoading = false;
        }))
      this.$axios
        .get(this.$root.$data.server_base + '/api/workplan_element/list.php', 
        { params: {
            service_provider_id: this.$route.params.service_provider_id,
            designation_dossier_id: this.$route.params.designation_dossier_id,
        }})
        .then((response => {
          this.elements = response.data.elements;
          this.isLoading = false;
        }))  
    },
  
  },
  mounted() {
    this.refresh()
  }
}
</script>