export const messages = {
    required: "Ce champ est obligatoire.",
    pwcheck: "Ce champ est obligatoire et doit contenir minumum 8 caractères, incluant au moins un chiffre, une lettre minuscule et une lettre majuscule.",
    remote: "Please fix this field.",
    email: "Veuillez entrer un courriel valide.",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date (ISO).",
    number: "Please enter a valid number.",
    digits: "Please enter only digits.",
    equalTo: "Votre nouveau mot de passe diffère de la confirmation"};

