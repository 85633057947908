<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('report.fls34.town_mun') }}
            </th>
            <th class="text-left">
              {{ $t('report.fls34.population') }}
            </th>
            <th class="text-left">
              {{ $t('report.fls34.french_pop') }}
            </th>
            <th class="text-left">
              {{ $t('report.fls34.french_percent') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in populations"
            :key="item.town"
          >
            <td>{{ item.town }}</td>
            <td>{{ item.population }}</td>
            <td>{{ item.population_francophone }}</td>
            <td>{{ (item.population_francophone * 100 / item.population).toFixed(2) }} %</td>
          </tr>
          <tr><td colspan="4" style="height: 4px"></td></tr>
          <tr>
            <td><em>Total</em></td>
            <td>{{ total.population }}</td>
            <td>{{ total.population_francophone }}</td>
            <td>{{ (total.population_francophone * 100 / total.population).toFixed(2) }} %</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: "Populations",
  props: ['populations'],
  computed: {
    total() {
      return this.populations.reduce((a, b) => {
        return {
          population: a.population + b.population,
          population_francophone: a.population_francophone + b.population_francophone,
        };
      });
    }
  },
});
</script>