<template>
  <div>
    <div v-if="this.ticket.type_key">
      <a @click="$router.back()" class="mr-6">
      <v-icon>mdi-keyboard-return</v-icon> Retour
      </a>
      <v-icon class="mr-2">mdi-arrow-right</v-icon>
      <v-icon color="primary" class="mr-1">mdi-hand-heart</v-icon>
      <router-link class="crumb-link"
        :to="{ name: 'sp_tableaudebord', params: { service_provider_id: ticket.service_provider_id }}"
        v-text="ticket.service_provider"
      ></router-link> / 
      <v-icon color="green accent-4" class="mr-1">{{ ticketTypeIcon }}</v-icon>
      <a class="crumb-link" :href="$route.path" target="_blank"
      >{{ $tc('ticket.label') + '-' + ticket.ticket_id}}</a>
    </div>
    
  </div>
</template>

<style scoped lang="scss">
.crumb-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

</style>

<script>

import Vue from 'vue';

export default {

  name: 'TicketHeader',
  props: ['ticket'],
  computed: {
    ticketTypeIcon() {
      if(this.ticket.type_key) {
        switch (this.ticket.type_key) {
          case 'note':
            return 'mdi-message-bulleted';
          case 'evaluation_request':
            return 'mdi-comment-question';
          case 'requirement_appreciation':
            return 'mdi-frequently-asked-questions';
          case 'task':
            return 'mdi-calendar-check';
          case 'designation_history':
            return 'mdi-history';
        }
      }
      return 'mdi-ticket';
    }
  },
  methods: {
    
  },
  

}
</script>